import React, { useEffect, useRef, useState, forwardRef } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import ArrowRightAltIcon from '@mui/icons-material/ArrowRightAlt';
import SectionCard from '../section-card';
import { useSchemaStore } from '../store';
import FieldColumn from './field-column';
import AddField from './add-field';
import { OutputSchemaRefTypes } from '../types';
import SchemaOptions from './schema-options';

const Container = styled.div`
  padding: 20px;
`;

const Header = styled.header<{ isSetOutputSchema: boolean }>`
  display: flex;
  justify-content: ${(props) =>
    props.isSetOutputSchema ? 'space-between' : 'flex-end'};
  align-items: center;
`;

const LockedSchemaText = styled(Typography)`
  font-weight: bold;
  font-size: 18px;
`;

const FieldsSection = styled.section`
  margin: 24px 0;
  display: flex;
  align-items: stretch;
  background-color: white;
  justify-content: space-between;
`;

const FieldColumns = styled.div`
  display: flex;
  overflow-x: auto;
  flex: 1;
`;

const NoFieldsContainer = styled.div`
  align-self: stretch;
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  padding-left: 96px;
`;

const NoFieldsText = styled(Typography)`
  font-size: 18px;
  font-weight: bold;
  align-items: center;
  display: flex;
`;

const OutputSchema = forwardRef<OutputSchemaRefTypes>((props, ref) => {
  const [{ fields, isSetOutputSchema }] = useSchemaStore();
  const elementRef = useRef<HTMLDivElement>(null);
  const initialRender = useRef(true);

  const [numFields, setNumFields] = useState<number>(0);

  const handleScrollToRight = () => {
    if (elementRef.current) {
      elementRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'nearest',
        inline: 'end',
      });
    }
  };

  useEffect(() => {
    if (initialRender.current) {
      initialRender.current = false;
    }
  }, [fields]);

  useEffect(() => {
    const numFields = Object.keys(fields).length;
    setNumFields(numFields);
  }, [fields]);

  useEffect(() => {
    if (!initialRender.current) {
      handleScrollToRight();
    }
  }, [numFields]);

  const { schemaCardRef, schemaOptionsRef } = (ref as React.MutableRefObject<
    OutputSchemaRefTypes
  >).current;

  const fieldsCollectionEntries = Object.entries(fields);

  return (
    <SectionCard
      heading="Output Schema"
      disabled={isSetOutputSchema}
      ref={schemaCardRef}
    >
      <Container>
        <Header isSetOutputSchema={isSetOutputSchema}>
          {isSetOutputSchema && (
            <LockedSchemaText>
              Because you selected a database as your output, the schema is
              already defined and cannot be edited.
            </LockedSchemaText>
          )}
        </Header>
        <FieldsSection>
          <FieldColumns>
            {fieldsCollectionEntries.length ? (
              fieldsCollectionEntries.map(([fieldName, fieldData]) => (
                <>
                  <FieldColumn
                    key={fieldName}
                    field={{
                      name: fieldName,
                      ...fieldData,
                    }}
                  />
                  <div ref={elementRef} />
                </>
              ))
            ) : (
              <NoFieldsContainer>
                {!isSetOutputSchema && (
                  <>
                    <NoFieldsText>
                      No output schema specified - click 'Add Field' to get
                      started <ArrowRightAltIcon style={{ fontSize: 48 }} />
                    </NoFieldsText>
                  </>
                )}
              </NoFieldsContainer>
            )}
          </FieldColumns>
          {!isSetOutputSchema && <AddField />}
        </FieldsSection>
        <SchemaOptions ref={schemaOptionsRef} />
      </Container>
    </SectionCard>
  );
});

export default OutputSchema;
