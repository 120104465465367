import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import firebase from 'firebase/app';
import { toast } from 'react-toastify';
import NoMarginTextfield, {
  TextFieldWrapper,
} from '../../common/no-margin-textfield';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const Form = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

interface Props {
  onSuccess: () => void;
}

const ForgotPasswordForm: React.FC<Props> = ({ onSuccess }) => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [emailState, setEmailState] = useState<string>('');

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailState(event.target.value);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    try {
      await firebase.auth().sendPasswordResetEmail(emailState);
      toast.success(
        'Reset password has been sent. Please check your email for next steps!'
      );
      setEmailState('');
      onSuccess();
    } catch (e) {
      toast.error(e.message);
    }
    setIsLoading(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  return (
    <Wrapper>
      <Form>
        <TextFieldWrapper>
          <Typography variant="caption">Email*</Typography>
          <NoMarginTextfield
            variant="outlined"
            margin="dense"
            onChange={onEmailChange}
            onKeyPress={handleKeyPress}
          />
        </TextFieldWrapper>
      </Form>
      <Button
        onClick={onSubmit}
        color="secondary"
        variant="contained"
        disabled={emailState.length === 0}
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          'Send reset password email'
        )}
      </Button>
    </Wrapper>
  );
};

export default ForgotPasswordForm;
