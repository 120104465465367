import React, { useState } from 'react';
import {
  Button,
  Fade,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import { useScriptsStore } from '../../scripts-editor-page/scripts-store';
import useCreateScriptService from '../service/useCreateScriptService';
import ROUTES from '../../../../common/routes';
import FullLoadingOverlay from '../../../common/full-loading-overlay';
import useErrorAlert from '../../../../utils/useErrorAlert';

const ModalContainer = styled(Modal)`
  display: flex;
  flex-direction: column;
  justify-content: center;
`;

const ModalInner = styled(Paper)`
  height: 300px;
  width: 400px;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 16px;
  margin: auto;
`;

const TitleContainer = styled.div`
  margin: 8px;
  padding: 8px;
`;

const StyledButton = styled(Button)`
  width: 120px;
`;

interface Props {
  isModalOpen: boolean;
  onClose: () => void;
}

const CreateScriptModal: React.FC<Props> = ({ isModalOpen, onClose }) => {
  const [
    { name, description },
    { setScriptName, setScriptId, setScriptDescription },
  ] = useScriptsStore();
  const [createScript, error] = useCreateScriptService();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const history = useHistory();
  useErrorAlert(error);

  const saveScriptName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const name = event.target.value;
    setScriptName(name);
  };

  const saveScriptDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const description = event.target.value;
    setScriptDescription(description);
  };

  const handleCreateNewScript = async () => {
    setIsLoading(true);
    if (name !== '') {
      const response = await createScript({
        name,
        language: 'python',
        description,
      });
      if (response) {
        const { scriptId } = response;
        setScriptId(scriptId);
        history.push(`${ROUTES.App}${ROUTES.Scripts}/${scriptId}`);
        onClose();
      }
    } else {
      toast.error('Please give your script a name!');
    }
    setIsLoading(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleCreateNewScript();
    }
  };

  return (
    <ModalContainer open={isModalOpen} onClose={onClose}>
      <Fade in={isModalOpen}>
        <ModalInner>
          <TitleContainer>
            <Typography variant="h5">New Script</Typography>
          </TitleContainer>
          <TextField
            variant="outlined"
            value={name}
            onChange={saveScriptName}
            onKeyPress={handleKeyPress}
            label="Script Name"
            fullWidth
            required
            autoFocus
            style={{ marginBottom: 24 }}
          />
          <TextField
            variant="outlined"
            value={description}
            onChange={saveScriptDescription}
            label="Script Description"
            fullWidth
            rows={2}
            rowsMax={2}
            style={{
              marginBottom: 24,
            }}
            multiline
          />
          <StyledButton
            onClick={handleCreateNewScript}
            variant="contained"
            color="secondary"
          >
            Create
          </StyledButton>
          {isLoading && <FullLoadingOverlay isLoading={isLoading} />}
        </ModalInner>
      </Fade>
    </ModalContainer>
  );
};

export default CreateScriptModal;
