import React from 'react';
import styled from 'styled-components';
import {
  FormLabel,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const SelectWrapper = styled(Select)`
  display: flex;
  margin: 8px 0;
`;

const ErrorMessage = styled(Typography)`
  color: red;
`;

interface Props {
  formRegister: UseFormRegister<Record<string, string>>;
  errors: DeepMap<FieldValues, FieldError>;
}

const SupabasePostgresqlForm: React.FC<Props> = ({ formRegister, errors }) => {
  return (
    <>
      <FormFieldWrapper>
        <FormLabel required>Table name</FormLabel>
        <TextField
          {...formRegister('tableName', {
            required: 'Please enter a table name',
          })}
          variant="outlined"
          color="primary"
          margin="dense"
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel required>On Conflict Action</FormLabel>
        <SelectWrapper
          {...formRegister('onConflictAction', {
            required: 'Please select an on conflict action',
          })}
          variant="outlined"
          color="primary"
        >
          <MenuItem value="ignore">Ignore</MenuItem>
          <MenuItem value="overwrite">Overwrite</MenuItem>
        </SelectWrapper>
      </FormFieldWrapper>
      {errors && (
        <ErrorMessage>{Object.entries(errors)?.[0]?.[1]?.message}</ErrorMessage>
      )}
    </>
  );
};

export default SupabasePostgresqlForm;
