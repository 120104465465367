import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { SETTINGSPAGES } from '../../../common/routes';
import AccountDetailsPage from '../../settings-pages/account-details-page';
import BillingPage from '../../settings-pages/billing-page';
import {TrackPageVisited} from "../../../utils/useTrackEvent";

const SettingsRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={`${path}/${SETTINGSPAGES.Account_Details}`}>
        <TrackPageVisited pageName="Account_Details" />
        <AccountDetailsPage />
      </Route>
      <Route exact path={`${path}/${SETTINGSPAGES.Billing}`}>
        <TrackPageVisited pageName="Billing" />
        <BillingPage />
      </Route>
      <Redirect to={`${path}/${SETTINGSPAGES.Account_Details}`} />
    </Switch>
  );
};

export default SettingsRoutes;
