import React from 'react';
import styled from 'styled-components';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  Theme,
  useTheme,
} from '@mui/material';
import MenuItem from '@mui/material/MenuItem';
import { Typography } from '@material-ui/core';
import { useSchemaStore } from '../../../store';
import InfoButtonAndTooltip from '../../../../../common/info-button-and-tooltip';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
`;

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const EmailValidation: React.FC = () => {
  const [
    { fields, emailValidationFields },
    { setEmailValidation },
  ] = useSchemaStore();

  const fieldsList = Object.keys(fields);

  const theme = useTheme();

  const handleChange = (
    event: SelectChangeEvent<typeof emailValidationFields>
  ) => {
    const {
      target: { value },
    } = event;
    const newEmailValidationFields =
      typeof value === 'string' ? value.split(',') : value;
    setEmailValidation(newEmailValidationFields);
  };

  return (
    <Wrapper>
      <Typography>
        Drop rows that do not have a valid email in field(s):
      </Typography>
      <Select
        multiple
        displayEmpty
        value={emailValidationFields}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>No fields specified</em>;
          }

          return selected.join(', ');
        }}
        size="small"
        sx={{ width: '320px', ml: '8px', backgroundColor: 'white' }}
      >
        {fieldsList.map((fieldName) => (
          <MenuItem
            key={fieldName}
            value={fieldName}
            style={getStyles(fieldName, emailValidationFields, theme)}
            sx={{ m: 0, p: 0 }}
          >
            <Checkbox
              checked={emailValidationFields.indexOf(fieldName) > -1}
              size="small"
            />
            <ListItemText primary={fieldName} sx={{ m: 0, p: 0 }} />
          </MenuItem>
        ))}
      </Select>
      <div style={{ flex: 1, display: 'flex', justifyContent: 'flex-end' }}>
        <InfoButtonAndTooltip text="Email validation can slow down the import processing speed, as it checks that each email entry has a valid domain that can be pinged." />
      </div>
    </Wrapper>
  );
};

export default EmailValidation;
