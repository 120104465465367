import React from 'react';
import styled from 'styled-components';
import { Link } from '@mui/material';
import ConfigStepHeading from '../../config-step-heading';
import COLORS from '../../../../../common/colors';
import { EXTERNAL } from '../../../../../common/routes';
import { useOnboardingWizardStore } from '../../../store';
import useGetSamplePipelineId from '../../service/useGetSamplePipelineId';
import CopyCodeBlock from '../../../../common/copy-code-block';

const StepSection = styled.div`
  margin-bottom: 24px;
`;

const ReactPanel: React.FC = () => {
  const [{ apiKey }] = useOnboardingWizardStore();

  const samplePipelineId = useGetSamplePipelineId();
  const INSTALLATION_TEXT = `yarn add @segna/segna-sdk`;

  const INITIALIZE_TEXT = `import segna, { DataImporter } from '@segna/segna-sdk'

segna.initialize({apiKey: '${apiKey || '<API_KEY>'}'})
`;

  const RENDER_TEXT = `return (
  <DataImporter pipelineId="${samplePipelineId}" />
)
`;

  return (
    <div>
      <StepSection>
        If you use React, you can use our{' '}
        <Link
          href={EXTERNAL.Docs_Prebuilt_UI}
          color={COLORS.DarkBlue}
          target="_blank"
          rel="noopener"
          sx={{
            fontWeight: 'bold',
          }}
        >
          Prebuilt UI Components
        </Link>
        . If you instead wish to hook Segna&apos;s API into your own frontend
        components, check out the <span style={{ fontWeight: 'bold' }}>JS</span>{' '}
        tab above!
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={1} bgColor={COLORS.DarkBlue}>
          Install Segna Client Library
        </ConfigStepHeading>
        <CopyCodeBlock
          text={INSTALLATION_TEXT}
          language="shell"
          hideLineNumbers
        />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={2} bgColor={COLORS.DarkBlue}>
          Import and Initialize
        </ConfigStepHeading>
        You only need to initialize once with your API Key. The API Key can be
        refreshed at any time through our platform.
        <CopyCodeBlock text={INITIALIZE_TEXT} language="typescript" />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={3} bgColor={COLORS.DarkBlue}>
          Render the Data Importer
        </ConfigStepHeading>
        Render our Data Importer anywhere in your app.
        <CopyCodeBlock text={RENDER_TEXT} language="jsx" />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={4} bgColor={COLORS.DarkBlue}>
          Run Job
        </ConfigStepHeading>
        Click the Data Importer button and upload our sample data!
      </StepSection>
    </div>
  );
};

export default ReactPanel;
