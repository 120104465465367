import styled from 'styled-components';
import React, { useRef } from 'react';
import { Divider, Typography } from '@material-ui/core';
import SampleDataResources from './sample-data-sources';
import OutputDataResources from './output-data-sources';
import SubmitButton from './submit-button';
import { useDataSourcesImportingStore } from '../store';
import PipelineNameHeader from '../../pipeline-name-header';
import useSpotlightWithLocalStorage from '../../../spotlight-tour/useSpotlightWithLocalStorage';
import { PIPELINEPAGES } from '../../../../common/routes';

const PageWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: white;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
  padding: 0 8px;
`;

const DataSectionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 16px;
`;

const ADD_SAMPLE_DATA_SPOTLIGHT_TEXT =
  'If your output destination does not have a set schema, you can upload a sample schema separately. Your pipeline does not have to have a schema.';

const ADD_OUTPUT_DEST_SPOTLIGHT_TEXT =
  'Jobs run with this pipeline will push directly to the output destination databases or data lakes.';

const NEXT_BUTTON_SPOTLIGHT_TEXT =
  "Hit 'next' when you're ready, to move on to the schema page.";

const DataSourcesInner: React.FC = () => {
  const [{ pipelineName, isEditing }] = useDataSourcesImportingStore();

  const sampleDataRef = useRef<HTMLDivElement>(null);
  const outputDataRef = useRef<HTMLDivElement>(null);
  const nextRef = useRef<HTMLDivElement>(null);

  useSpotlightWithLocalStorage(
    [
      {
        el: outputDataRef,
        text: ADD_OUTPUT_DEST_SPOTLIGHT_TEXT,
        placement: 'above',
      },
      {
        el: sampleDataRef,
        text: ADD_SAMPLE_DATA_SPOTLIGHT_TEXT,
        placement: 'above',
      },
      {
        el: nextRef,
        text: NEXT_BUTTON_SPOTLIGHT_TEXT,
        placement: 'left',
      },
    ],
    PIPELINEPAGES.Data_Sources
  );

  return (
    <PageWrapper>
      <PipelineNameHeader pipelineName={pipelineName} isEditing={isEditing}/>
      <PageHeader>
        <Typography variant="h5">
          Output Destinations and Sample Schema
        </Typography>
        <SubmitButton ref={nextRef} />
      </PageHeader>
      <Divider style={{ margin: '0 8px' }} />
      <DataSectionWrapper>
        <OutputDataResources ref={outputDataRef} />
        <SampleDataResources ref={sampleDataRef} />
      </DataSectionWrapper>
    </PageWrapper>
  );
};

export default DataSourcesInner;
