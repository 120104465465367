import { useEffect } from 'react';
import segna from '@segna/segna-sdk';
import useFetchSimpleService from '../../../../../api/useFetchSimpleService';
import { SETTINGSAPI } from '../../../../../common/routes';
import { BASE_URL } from '../../../../../common/constants';
import { ApiKeyData } from '../../types';
import useErrorAlert from "../../../../../utils/useErrorAlert";

const BASE_PATH = `${BASE_URL}/public/client-side/v1`;

const useInitSegnaApiKeyService = (): [
  ApiKeyData | null,
  boolean,
  Error | null
] => {
  const [data, isLoading, error] = useFetchSimpleService<ApiKeyData>(
    SETTINGSAPI.Api_Key
  );
  useErrorAlert(error);

  useEffect(() => {
    if (data) {
      const { apiKey } = data;
      if (apiKey) {
        segna.initialize({
          apiKey,
          basePath: BASE_PATH,
        });
      }
    }
  }, [data]);

  return [data, isLoading, error];
};

export default useInitSegnaApiKeyService;
