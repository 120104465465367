import { useCallback, useEffect } from 'react';
import { Spotlight } from '../types';
import { useSpotlightContext } from '../SpotlightProvider';

const SPOTLIGHT_DELAY_MS = 500;

const useSpotlight = (
  newSpotlightData: Spotlight[]
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
): [(page: string) => void] => {
  const {
    startSpotlight,
    setSpotlightData,
    spotlightData: oldSpotlightData,
  } = useSpotlightContext();

  const dataIsSameLength = (oldData: Spotlight[], newData: Spotlight[]) => {
    return oldData.length === newData.length;
  };

  const dataIsIdentical = (oldData: Spotlight[], newData: Spotlight[]) => {
    let identical = false;

    oldData.forEach((oldSpotlight, i) => {
      const newSpotlight = newData[i];

      const sameRef = Object.is(oldSpotlight.el, newSpotlight.el);
      const sameText = oldSpotlight.text === newSpotlight.text;
      const samePlacement = oldSpotlight.placement === newSpotlight.placement;

      if (sameRef && sameText && samePlacement) {
        identical = true;
      }
    });

    return identical;
  };

  const spotlightDataIsSame = useCallback(
    (oldData: Spotlight[], newData: Spotlight[]) => {
      return (
        dataIsSameLength(oldData, newData) && dataIsIdentical(oldData, newData)
      );
    },
    []
  );

  useEffect(() => {
    let timeout: ReturnType<typeof setTimeout> | undefined;
    if (!spotlightDataIsSame(oldSpotlightData, newSpotlightData)) {
      timeout = setTimeout(
        () => setSpotlightData(newSpotlightData),
        SPOTLIGHT_DELAY_MS
      );
    }

    return () => {
      if (timeout) {
        clearTimeout(timeout);
      }
    };
  }, [
    newSpotlightData,
    oldSpotlightData,
    setSpotlightData,
    spotlightDataIsSame,
  ]);

  return [startSpotlight];
};

export default useSpotlight;
