import styled, { keyframes } from 'styled-components';
import React from 'react';

const Stroke = keyframes`
  100% {
    stroke-dashoffset: 0;
  }
`;

const Scale = keyframes`
  0%, 100% {
    transform: none;
  }
  50% {
    transform: scale3d(1.25, 1.25, 1);
  }
`;

const Fill = keyframes`
  100% {
    box-shadow: inset 0 0 0 30px green;
  }
`;

const Checkmark = styled.svg<{ delay?: string }>`
  border-radius: 50%;
  display: block;
  stroke-width: 2;
  stroke: #fff;
  stroke-miterlimit: 10;
  margin: 10% auto;
  box-shadow: inset 0 0 0 green;
  animation: ${Fill} 0.4s ease-in-out 0.4s forwards,
    ${Scale} 0.3s ease-in-out 0.9s both;
  ${(props) => props.delay && `animation-delay: ${props.delay}`}
`;

const CheckmarkCircle = styled.circle<{ delay?: string }>`
  stroke-dasharray: 166;
  stroke-dashoffset: 166;
  stroke-width: 2;
  stroke-miterlimit: 10;
  stroke: green;
  fill: none;
  animation: ${Scale} 0.6s cubic-bezier(0.65, 0, 0.45, 1) forwards;
  ${(props) => props.delay && `animation-delay: ${props.delay}`}
`;

const CheckmarkPath = styled.path<{ delay?: string }>`
  transform-origin: 50% 50%;
  stroke-dasharray: 48;
  stroke-dashoffset: 48;
  animation: ${Stroke} 0.3s cubic-bezier(0.65, 0, 0.45, 1) 0.8s forwards;
  ${(props) => props.delay && `animation-delay: ${props.delay}`}
`;

interface Props {
  radius: string | number;
  delay?: string;
}

const AnimatedCheckmark: React.FC<Props> = ({ radius, delay }) => {
  return (
    <Checkmark
      xmlns="http://www.w3.org/2000/svg"
      viewBox="0 0 52 52"
      height={radius}
      width={radius}
      delay={delay}
    >
      <CheckmarkCircle cx="26" cy="26" r="25" fill="none" delay={delay} />
      <CheckmarkPath
        fill="none"
        d="M14.1 27.2l7.1 7.2 16.7-16.8"
        delay={delay}
      />
    </Checkmark>
  );
};

export default AnimatedCheckmark;
