import React from 'react';
import styled from 'styled-components';
import { TextField, Typography, FormLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { ConnectorComponentProps } from '../types';

const CONNECTOR_DETAILS_TEXT = `For more details on how to connect your AWS RDS MySQL Database, please refer to our documentation.`;

const FieldsSection = styled.div`
  height: 425px;
  overflow: auto;
  margin: 8px 0;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ErrorMessage = styled(Typography)`
  color: red;
`;

const AwsRdsMysqlConnector: React.FC<ConnectorComponentProps> = ({
  errors,
  reset,
  control,
  isEditing,
}) => {
  return (
    <>
      {CONNECTOR_DETAILS_TEXT}
      <FieldsSection>
        <FormFieldWrapper>
          <FormLabel required>Connector Name</FormLabel>
          <Controller
            name="connectorName"
            control={control}
            rules={{ required: 'Please name your connector' }}
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. My AWS RDS MySQL Connector"
              />
            )}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Server</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. database-1.abcdefg12345.ap-southeast-1.rds.amazonaws.com"
              />
            )}
            name="server"
            control={control}
            rules={{ required: 'Please enter the server url' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Port</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. 5439"
              />
            )}
            name="port"
            control={control}
            rules={{ required: 'Please enter your port number' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Database</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. database-1"
              />
            )}
            name="database"
            control={control}
            rules={{ required: 'Please enter a database' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Username</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. AwsUser"
              />
            )}
            name="username"
            control={control}
            rules={{ required: 'Please enter your username' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel>Password</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. hunter2"
                disabled={isEditing}
                type="password"
              />
            )}
            name="password"
            control={control}
          />
        </FormFieldWrapper>
      </FieldsSection>
      {errors && (
        <ErrorMessage>{Object.entries(errors)?.[0]?.[1]?.message}</ErrorMessage>
      )}
    </>
  );
};

export default AwsRdsMysqlConnector;
