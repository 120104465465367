import { useCallback, useState } from 'react';
import { BASE_URL } from '../../common/constants';
import { useAuthContext } from '../../firebase/AuthProvider';

const deleteSimpleService = <ResponseData,>(
  idToken: string | null | undefined,
  route: string,
  setError: (error: Error) => void
): Promise<ResponseData | void> => {
  return fetch(`${BASE_URL}${route}`, {
    method: 'DELETE',
    headers: {
      Authorization: `${idToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      return response.json().then((responseData) => {
        if (response.ok) {
          return responseData;
        }
        setError(new Error(responseData.errorMessage));
        return undefined;
      });
    })
    .catch((error) => {
      setError(new Error(error));
    });
};

/* eslint-disable */
const useDeleteSimpleService = <ResponseData,> (): [((route: string) => Promise<ResponseData | void>), Error | null, () => void] => {
  const { user } = useAuthContext();
  const [error, setError] = useState<Error | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return [useCallback(
    async (route) => {
      clearError();
      const idToken = user && (await user.getIdToken(true));
      return deleteSimpleService<ResponseData>(idToken, route, setError).then(
        (responseData) => responseData
      );
    },
    [user]
  ), error, clearError]
};

export default useDeleteSimpleService;
