enum ROUTES {
  Login = '/',
  SignIn = '/login',
  App = '/app',
  Settings = '/settings',
  Job = '/job',
  Pipeline = '/pipeline',
  Billing = '/billing',
  Quick_Start = '/quick-start',
  Scripts = '/script',
  Connectors = '/connector',
  Onboarding_Wizard = '/onboarding',
}

export default ROUTES;

export enum SETTINGSPAGES {
  Account_Details = 'account-details',
  Billing = 'billing',
}

export enum JOBPAGES {
  Job_Config = 'job-config',
  Fields = 'fields',
  Units = 'units',
  Merge = 'merge',
  Clean = 'clean',
  Filters = 'filters',
  Results = 'results',
}

export enum ONBOARDINGWIZARDPAGES {
  Welcome = 'welcome',
  RunJob = 'run-job',
  ViewData = 'view-data',
}

export enum PIPELINESTATUSROUTES {
  Draft = '/draft',
  Published = '/published',
}

export enum PIPELINEPAGES {
  Data_Sources = 'data-sources',
  Unit = 'units',
  Data = 'data',
  Merge = 'merge',
  Clean = 'clean',
  Field = 'fields',
  Filters = 'filters',
  Script = 'script',
  Summary = 'summary',
  Schema = 'schema',
}

export type PAGES = SETTINGSPAGES | JOBPAGES | PIPELINEPAGES;

export enum PAGEAPIROUTES {
  Create_User = '/create-user',
  Google = '/google',
  Output_Data_Resource = '/output-data-resource',
  Create_Database_Connector = '/connector',
  Data_Snippet = '/data-snippet',
  Share = '/share',
  Sample_Data_Resource = '/sample-data-resource',
}

export enum ONBOARDINGAPI {
  Job_Status = '/onboarding-wizard/job-status',
  Job_Data = '/onboarding-wizard/job-data',
  Status = '/onboarding-wizard/status',
}

export enum SETTINGSAPI {
  Api_Key = '/settings/api-key',
  Access_Keys = '/settings/access-keys',
}

export enum BILLINGAPI {
  Create_Checkout_Session = '/billing/create-checkout-session',
  Create_Portal_Session = '/billing/create-portal-session',
  Dashboard = '/dashboard',
  Cost = '/costs',
  Plan_Status = '/plan-status',
  Data_Usage = '/data-usage',
  Billing_Status = '/status',
}

export enum JOBAPIS {
  Dashboard = '/dashboard',
  Create_Job = '/create-job',
  Job_Config = '/config',
  Results = '/results',
}

export enum PIPELINEAPIS {
  Dashboard = '/dashboard',
  Create_Pipeline = '/create-pipeline',
  Data_Sources = '/data-sources',
  Summary = '/summary',
  Publish = '/publish',
  Published_Summary = '/published-summary',
  Importing_Pipeline_Builder = '/importing-pipeline-builder',
  Schema = '/schema',
  Pipeline_Dashboard = '/pipeline-dashboard',
}

export enum PIPELINEDASHBOARDAPIS {
  Data_Usage = '/activity/data-usage',
  Job_Number = '/activity/job-number',
  Job_Errors = '/activity/job-errors',
  Jobs_List = '/jobs',
  Summary = '/summary',
}

export enum PIPELINEDASHBOARDPAGES {
  Activity = '/activity',
  Jobs = '/jobs',
  Summary = '/summary',
  Test_Job = '/test-job',
}

export enum DECISIONAPIS {
  Field = '/fields',
  Unit = '/units',
  Merge = '/merge',
  Clean = '/clean',
  Filters = '/filters',
  Script = '/script',
  Test_Script = '/test-script',
}

export enum SCRIPTSAPIS {
  DashBoard = '/dashboard',
  Create_Script = '/create',
  Save_Script = '/save',
  Delete_Script = '/delete',
  Upload_Test_Data = '/upload-data',
  Run_Test_Data = '/run-test',
  Share_Script = 'share',
}

export enum CONNECTORAPIS {
  Connectors = '/dashboard',
}

export enum EXTERNAL {
  Docs = 'https://docs.segna.io',
  Docs_Prebuilt_UI = 'https://docs.segna.io/job/prebuilt_ui_components/',
}
