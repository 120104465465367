import { DataTypes } from '../../units-store/types';

const getReadableDataType = (dataType: DataTypes): string => {
  switch (dataType) {
    case DataTypes.RICH_TEXT:
      return 'Rich Text';
    case DataTypes.NUMBER:
      return 'Number';
    case DataTypes.CATEGORY:
      return 'Category';
    case DataTypes.DATETIME:
      return 'Date-Time';
    default:
      return dataType;
  }
};

export default getReadableDataType;
