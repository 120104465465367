import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Paper, Typography } from '@material-ui/core';
import DataResourcesList from '../../../../common/data-resources-list';
import AddDatabaseModal from '../../../../common/add-database-modal';
import { useDataSourcesImportingStore } from '../../store';

const Wrapper = styled.div`
  flex: 1;
  padding: 8px;
`;

const HeaderSection = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 8px;
`;

const OutputCard = styled(Paper)`
  padding: 16px;
`;

const ButtonGrouping = styled.div`
  display: flex;
  & > * {
    margin-left: 8px;
  }
`;

const OutputDataResources = forwardRef<HTMLDivElement>((props, ref) => {
  const [
    { outputDataResources, databaseConnectors },
    { addOutputDataResource, removeOutputDataResource, addDatabaseConnector },
  ] = useDataSourcesImportingStore();

  const [isAddDatabaseModalOpen, setIsAddDatabaseModalOpen] = useState<boolean>(
    false
  );

  const handleAddDataModalOpen = () => {
    setIsAddDatabaseModalOpen(true);
  };

  const handleAddDataModelClose = () => {
    setIsAddDatabaseModalOpen(false);
  };

  return (
    <Wrapper>
      <OutputCard elevation={4} ref={ref}>
        <HeaderSection>
          <Typography variant="h6">Output Destination:</Typography>
          <ButtonGrouping>
            <Button
              variant="contained"
              color="primary"
              onClick={handleAddDataModalOpen}
            >
              Add Database
            </Button>
          </ButtonGrouping>
        </HeaderSection>
        <DataResourcesList
          dataResources={outputDataResources}
          onRemoveDataResource={removeOutputDataResource}
          isOutput
        />
      </OutputCard>
      <AddDatabaseModal
        isOpen={isAddDatabaseModalOpen}
        onClose={handleAddDataModelClose}
        databaseConnectors={databaseConnectors}
        onAddDatabaseTable={addOutputDataResource}
        onAddDatabaseConnector={addDatabaseConnector}
        isOutput
      />
    </Wrapper>
  );
});

export default OutputDataResources;
