import React from 'react';
import {
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  IconButton,
  Modal,
} from '@material-ui/core';
import styled from 'styled-components';
import CloseIcon from '@material-ui/icons/Close';
import COLORS from '../../../../common/colors';

const ModalCard = styled(Card)`
  position: absolute;
  width: 420px;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const StyledCardContent = styled(CardContent)`
  padding-top: 0;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
  padding: 16px;
`;

const ConfirmButton = styled(Button)`
  background-color: ${COLORS.DarkRed};
  color: white;

  &:hover {
    background-color: ${COLORS.DarkRed};
    color: white;
    opacity: 0.85;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onConfirm: () => void;
}

const RemoveModal: React.FC<Props> = ({
  isOpen,
  onClose,
  children,
  onConfirm,
}) => {
  return (
    <Modal open={isOpen} onClose={onClose}>
      <ModalCard>
        <CardHeader
          title="Confirm"
          action={
            <IconButton onClick={onClose} size="small">
              <CloseIcon fontSize="small" />
            </IconButton>
          }
        />
        <StyledCardContent>{children}</StyledCardContent>
        <StyledCardActions>
          <Button onClick={onClose} variant="contained" color="inherit">
            Cancel
          </Button>
          <ConfirmButton
            onClick={onConfirm}
            color="primary"
            variant="contained"
          >
            Confirm
          </ConfirmButton>
        </StyledCardActions>
      </ModalCard>
    </Modal>
  );
};

export default RemoveModal;
