import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import ConnectorsRow from './connectors-row';
import { useConnectorsStore } from '../store';

const TableContainer = styled.div`
  margin: 16px 0;
`;

const MessageContainer = styled(TableCell)`
  margin: 16px 0;
  padding: 8px 0;
  text-align: center;
  background-color: white;
`;

const ConnectorsTable: React.FC = () => {
  const [{ connectors }] = useConnectorsStore();

  return (
    <TableContainer>
      <Table size="small">
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 0 }} />
            <TableCell style={{ width: 300 }}>
              <Typography variant="subtitle1">Name</Typography>
            </TableCell>
            <TableCell style={{ width: 200 }}>
              <Typography variant="subtitle1">ID</Typography>
            </TableCell>
            <TableCell style={{ width: 300 }}>
              <Typography variant="subtitle1">
                Date of Creation (UTC)
              </Typography>
            </TableCell>
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(connectors).length > 0 ? (
            Object.entries(connectors).map(([id, connector]) => (
              <ConnectorsRow id={id} {...connector} />
            ))
          ) : (
            <TableRow>
              <MessageContainer colSpan={4}>
                <Typography variant="subtitle1">
                  No connectors available, please add a new connector
                </Typography>
              </MessageContainer>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ConnectorsTable;
