import React, { useEffect, useState, useContext, useCallback } from 'react';
import { setUserId } from '@amplitude/analytics-browser';
import * as Sentry from '@sentry/react';
import firebase from './firebase';
import { BASE_URL } from '../common/constants';
import ROUTES, { BILLINGAPI } from '../common/routes';
import { STATUS } from '../components/settings-pages/types';

type ContextProps = {
  user: firebase.User | null;
  isAuthenticated: boolean;
  subscriptionStatus: STATUS | null;
  setUser: (user: firebase.User) => void;
  isLoadingAuthState: boolean;
  refreshCurrentUser: () => void;
};

export const AuthContext = React.createContext<ContextProps>({
  user: null,
  isAuthenticated: false,
  subscriptionStatus: null,
  setUser: () => null,
  isLoadingAuthState: false,
  refreshCurrentUser: () => null,
});

type Props = {
  children: React.ReactNode;
};

export const AuthProvider = ({ children }: Props): JSX.Element => {
  const [, updateState] = useState<unknown>();
  const forceUpdate = useCallback(() => updateState({}), []);
  const [user, setUser] = useState<firebase.User | null>(null);
  const [isLoadingAuthState, setIsLoadingAuthState] = useState<boolean>(true);
  const [subscriptionStatus, setSubscriptionStatus] = useState<STATUS | null>(
    null
  );

  const refreshCurrentUser = () => {
    setUser(firebase.auth().currentUser);
    forceUpdate();
  };

  const fetchSubscriptionStatus = useCallback(async () => {
    setIsLoadingAuthState(true);
    try {
      const idToken = user && (await user.getIdToken(true));
      const response = await fetch(
        BASE_URL + ROUTES.Billing + BILLINGAPI.Billing_Status,
        {
          method: 'GET',
          headers: {
            Authorization: `${idToken}`,
          },
        }
      );
      const responseData = await response.json();
      if (response.ok) {
        setSubscriptionStatus(responseData.status);
      }
    } catch (e) {
      setIsLoadingAuthState(false);
    }
    setIsLoadingAuthState(false);
  }, [user]);

  useEffect(() => {
    if (user) {
      fetchSubscriptionStatus();
    }
  }, [fetchSubscriptionStatus, user]);

  useEffect(() => {
    firebase.auth().onAuthStateChanged((firebaseUser: firebase.User | null) => {
      setIsLoadingAuthState(true);
      setUser(firebaseUser);
      if (firebaseUser !== null) {
        if (firebaseUser.uid) {
          setUserId(firebaseUser.uid);
          Sentry.setUser({
            id: firebaseUser.uid,
            email: firebaseUser.email || undefined,
          });
        }
      }
      setIsLoadingAuthState(false);
    });
  }, []);

  return (
    <AuthContext.Provider
      value={{
        user,
        isAuthenticated: user !== null,
        subscriptionStatus,
        setUser,
        isLoadingAuthState,
        refreshCurrentUser,
      }}
    >
      {children}
    </AuthContext.Provider>
  );
};

export const useAuthContext = (): ContextProps => useContext(AuthContext);
