import styled, { keyframes } from 'styled-components';

import React from 'react';

const FadeSlideUpKeyframes = keyframes`
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  
  75% {
    transform: translateY(-2px);
  }
  
  100% {
    opacity: 1;
    transform: none;
  }
`;

const Container = styled.div`
  animation: 375ms ease-out both ${FadeSlideUpKeyframes};
`;

interface Props {
  animationDelay?: string; // e.g. 350ms, 0.25s
}

const FadeSlideUpwards: React.FC<Props> = ({ children, animationDelay }) => {
  return <Container style={{ animationDelay }}>{children}</Container>;
};

export default FadeSlideUpwards;
