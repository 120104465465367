import React from 'react';
import styled from 'styled-components';
import ConfigStepHeading from '../../config-step-heading';
import COLORS from '../../../../../common/colors';
import InlineCodeBlock from '../../../../common/inline-code-block';
import { useOnboardingWizardStore } from '../../../store';
import useGetSamplePipelineId from '../../service/useGetSamplePipelineId';
import CopyCodeBlock from '../../../../common/copy-code-block';

const StepSection = styled.div`
  margin-bottom: 24px;
`;

const JsPanel: React.FC = () => {
  const [{ apiKey }] = useOnboardingWizardStore();
  const samplePipelineId = useGetSamplePipelineId();

  const INSTALLATION_TEXT = `yarn add @segna/segna-sdk`;

  const INITIALIZE_TEXT = `import segna from '@segna/segna-sdk'

segna.init({apiKey: '${apiKey || '<API_KEY>'}')
`;

  const START_JOB_TEXT = `const jobId = segna.startJob({
  pipelineId: '${samplePipelineId}',
  file: File
})`;

  const RUN_JOB_TEXT = `segna.runJob(jobId)`;

  return (
    <div>
      <StepSection>
        <ConfigStepHeading stepNumber={1} bgColor={COLORS.DarkBlue}>
          Install Segna Client Library
        </ConfigStepHeading>
        <CopyCodeBlock
          text={INSTALLATION_TEXT}
          language="shell"
          hideLineNumbers
        />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={2} bgColor={COLORS.DarkBlue}>
          Import and Initialize
        </ConfigStepHeading>
        You only need to initialize once with your API Key. The API Key can be
        refreshed at any time through our platform.
        <CopyCodeBlock text={INITIALIZE_TEXT} language="typescript" />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={3} bgColor={COLORS.DarkBlue}>
          Start Job
        </ConfigStepHeading>
        To begin an import, call our <InlineCodeBlock>startJob</InlineCodeBlock>{' '}
        API. This will return a unique <InlineCodeBlock>jobId</InlineCodeBlock>.
        <CopyCodeBlock text={START_JOB_TEXT} language="jsx" />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={4} bgColor={COLORS.DarkBlue}>
          Run Job
        </ConfigStepHeading>
        Call <InlineCodeBlock>runJob</InlineCodeBlock> with the{' '}
        <InlineCodeBlock>jobId</InlineCodeBlock> from above!
        <CopyCodeBlock text={RUN_JOB_TEXT} language="jsx" hideLineNumbers />
      </StepSection>
    </div>
  );
};

export default JsPanel;
