import styled from 'styled-components';
import { setUserId } from '@amplitude/analytics-browser';
import { toast } from 'react-toastify';
import React from 'react';
import { Box } from '@mui/material';
import firebase from '../../../../firebase/firebase';
import { trackEvent } from '../../../../utils/useTrackEvent';
import useCreateNewGoogleUserService from '../../service/useCreateNewGoogleUserService';
import useErrorAlert from '../../../../utils/useErrorAlert';
import googlelogosvg from '../../../../common/images/google-logo.svg';

const ButtonWrapper = styled(Box)`
  background-color: white;
  height: 40px;
  border-radius: 1px;
  padding: 0 8px;
  display: flex;
  align-items: center;
  cursor: pointer;
  opacity: 1;
  transition: all 0.1s ease-in-out;

  &:hover {
    opacity: 0.7;
  }
`;

const GoogleLogo = styled.img`
  height: 18px;
  width: 18px;
  margin-right: 24px;
`;

const GoogleSignInText = styled.div`
  font-family: Roboto-Medium;
  font-size: 14px;
  color: rgba(0, 0, 0, 0.54);
`;

interface Props {
  setIsLoading: (isLoading: boolean) => void;
}

const GoogleSignInButton: React.FC<Props> = ({ setIsLoading }) => {
  const [createGoogleUserService, error] = useCreateNewGoogleUserService();
  useErrorAlert(error);

  const onGoogleSignIn = async () => {
    const provider = new firebase.auth.GoogleAuthProvider();
    provider.setCustomParameters({
      prompt: 'select_account',
    });

    try {
      setIsLoading(true);
      const userCredentials = await firebase.auth().signInWithPopup(provider);
      userCredentials.user?.uid && setUserId(userCredentials.user.uid);

      const firebaseIdToken = await userCredentials.user?.getIdToken();
      if (firebaseIdToken) {
        const response = await createGoogleUserService(firebaseIdToken);
        if (response) {
          trackEvent('signin completed', {
            type: 'google',
          });
        } else {
          await firebase.auth().signOut(); // Log user out if error during BE call
        }
      }
    } catch (error) {
      const errorCode = error.code as string;
      const errorMessage = error.message as string;
      if (
        errorCode !==
        ('auth/popup-closed-by-user' || 'auth/cancelled-popup-request')
      ) {
        toast.error(`Failed to login with Google - ${errorMessage}`);
        trackEvent('signin failed', {
          type: 'google',
          errorMessage,
        });
      }
      await firebase.auth().signOut();
    }

    setIsLoading(false);
  };

  return (
    <ButtonWrapper onClick={onGoogleSignIn} sx={{ boxShadow: 1 }}>
      <GoogleLogo src={googlelogosvg} />
      <GoogleSignInText>Sign in with Google</GoogleSignInText>
    </ButtonWrapper>
  );
};

export default GoogleSignInButton;
