import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Backdrop,
  Button,
  Card,
  CardActions,
  CardContent,
  Typography,
} from '@material-ui/core';
import { Spotlight } from './types';
import Portal from './portal';
import { setSpotlightLocalStorage } from './spotlight-local-storage';

const StyledBackdrop = styled(Backdrop)`
  z-index: 20;
  background-color: rgba(0, 0, 0, 0.6);
`;

const StyledCard = styled(Card)`
  max-width: 400px;
  min-width: 200px;
`;

const StyledCardActions = styled(CardActions)`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

interface SpotlightTourProps {
  isOpen: boolean;
  spotlightData: Spotlight[];
  finishSpotlight: () => void;
  page: string;
}

const SpotlightTour: React.FC<SpotlightTourProps> = ({
  spotlightData,
  isOpen,
  finishSpotlight,
  page,
}) => {
  const [stepIndex, setStepIndex] = useState<number>(0);
  const currentStep = stepIndex + 1;
  const lastStep = spotlightData.length;
  const spotlight = spotlightData[stepIndex];

  const resetStepIndex = () => {
    setStepIndex(0);
  };

  const increment = () => {
    setStepIndex((stepIndex) => stepIndex + 1);
  };

  const decrement = () => {
    setStepIndex((stepIndex) => stepIndex - 1);
  };

  const handleNextStep = () => {
    if (currentStep >= lastStep) {
      finishSpotlight();
      // Set local storage here rather than in the finishSpotlight method because then all methods do not rely on the
      // page state value as dependencies and wil just be easier to take out when moved on from local storage.
      setSpotlightLocalStorage(page, 'true');
      resetStepIndex();
    } else {
      increment();
    }
  };

  const handlePrevStep = () => {
    if (currentStep > 1) {
      decrement();
    }
  };

  return (
    <>
      {spotlight && <StyledBackdrop open={isOpen} />}
      {isOpen && spotlight && (
        <Portal ref={spotlight.el} placement={spotlight.placement}>
          <StyledCard>
            <CardContent>
              {lastStep > 1 && (
                <Typography variant="h5" component="h2" gutterBottom>
                  {`Step: ${currentStep} / ${lastStep}`}
                </Typography>
              )}
              <Typography>{spotlight.text}</Typography>
            </CardContent>
            <StyledCardActions>
              {currentStep > 1 && (
                <Button
                  onClick={handlePrevStep}
                  variant="contained"
                  color="default"
                >
                  Back
                </Button>
              )}
              <Button
                onClick={handleNextStep}
                variant="contained"
                color={currentStep === lastStep ? 'primary' : 'default'}
              >
                {currentStep === lastStep ? 'Done' : 'Next'}
              </Button>
            </StyledCardActions>
          </StyledCard>
        </Portal>
      )}
    </>
  );
};

export default SpotlightTour;
