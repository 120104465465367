export const BASE_URL = process.env.REACT_APP_BASE_URL;

export const WEBSITE_URL = 'https://www.segna.io';
export const TERMS_CONDITIONS_URL = 'https://www.segna.io/terms-conditions';

export enum ACCESS_TYPE {
  READ = 'read',
  WRITE = 'write',
  ADMIN = 'admin',
  OWNER = 'owner',
}

export enum DATA_RESOURCE_TYPES {
  CSV = 'csv',
  ATHENA = 'athena',
  AWS_RDS_MYSQL = 'aws_rds_mysql',
  S3 = 's3',
  SNOWFLAKE = 'snowflake',
  FIRESTORE = 'firestore',
  GCS = 'gcs',
  SUPABASE_POSTGRESQL = 'supabase_postgresql',
}
