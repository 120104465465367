import React, { useState } from 'react';
import styled from 'styled-components';
import { Divider, Link, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { ReactComponent as SegnaWhiteIcon } from '../../common/images/segna-logo-white-icon.svg';
import COLORS from '../../common/colors';
import LoginForm from './login-form';
import ROUTES from '../../common/routes';
import { useAuthContext } from '../../firebase/AuthProvider';
import FullLoadingOverlay from '../common/full-loading-overlay';
import RegisterForm from './register-form';
import ForgotPasswordForm from './forgot-password-form';
import ThirdPartyLogins from './third-party-logins';
import { TERMS_CONDITIONS_URL } from '../../common/constants';

const BackgroundContainer = styled.div`
  background-color: ${COLORS.DarkBlue};
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const PaperContainer = styled(Paper)`
  background-color: ${COLORS.Grey};
  border-radius: 4px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PaperHeaderSection = styled.div`
  text-align: center;
  margin-bottom: 16px;
`;

const PaperContentSection = styled.div`
  display: flex;
`;

const MiddleDividerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  align-items: center;
  justify-content: center;
`;

const DividerOrText = styled(Typography)`
  margin: 8px 0;
`;

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
`;

const StyledSegnaIcon = styled(SegnaWhiteIcon)`
  height: 40px;
  width: 40px;
  padding-right: 0.5rem;
`;

const SegnaIconText = styled(Typography)`
  padding-bottom: 6px;
  color: white;
`;

const TermsConditions = styled.div`
  font-size: 12px;
  margin: 12px 0;
  text-align: center;
`;

const DocsLink = styled.a`
  text-underline-offset: 4px;
`;

export enum FormType {
  Login,
  Register,
  Forgot_Password,
}

interface Props {
  formType: FormType;
  setFormType: (formType: FormType) => void;
}

const AccountForms: React.FC<Props> = ({ formType, setFormType }) => {
  return (
    <>
      {formType === FormType.Register && (
        <FormContainer>
          <RegisterForm />
          <Divider variant="middle" style={{ margin: '16px' }} />
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setFormType(FormType.Login);
            }}
          >
            Already have an account? Sign in!
          </Link>
        </FormContainer>
      )}
      {formType === FormType.Login && (
        <FormContainer>
          <LoginForm />
          <Divider variant="middle" style={{ margin: '16px' }} />
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setFormType(FormType.Register);
            }}
          >
            Don't have an account? Sign up!
          </Link>
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setFormType(FormType.Forgot_Password);
            }}
          >
            Forgot your password?
          </Link>
        </FormContainer>
      )}
      {formType === FormType.Forgot_Password && (
        <FormContainer>
          <ForgotPasswordForm onSuccess={() => setFormType(FormType.Login)} />
          <Divider variant="middle" style={{ margin: '16px' }} />
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setFormType(FormType.Login);
            }}
          >
            Cancel
          </Link>
        </FormContainer>
      )}
    </>
  );
};

const LoginPage: React.FC = () => {
  const { isAuthenticated, isLoadingAuthState } = useAuthContext();

  const [formType, setFormType] = useState<FormType>(FormType.Register);

  const history = useHistory();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  if (isAuthenticated && !isLoading) {
    history.push(`${ROUTES.App}`);
  }

  return (
    <>
      <BackgroundContainer>
        <HeaderContainer>
          <StyledSegnaIcon />
          <SegnaIconText variant="h4">segna</SegnaIconText>
        </HeaderContainer>
        <PaperContainer elevation={24}>
          <PaperHeaderSection>
            {formType === FormType.Register && (
              <>
                <Typography variant="h4" style={{ fontWeight: 'bold' }}>
                  Let's get started!
                </Typography>
                <Typography>
                  14-day free trial. No credit card required.
                </Typography>
                <TermsConditions>
                  By creating a new account, you agree to our{' '}
                  <DocsLink
                    href={TERMS_CONDITIONS_URL}
                    target="_blank"
                    rel="noreferrer"
                  >
                    terms and conditions
                  </DocsLink>
                </TermsConditions>
              </>
            )}
            {formType === FormType.Login && (
              <>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Login
                </Typography>
              </>
            )}
            {formType === FormType.Forgot_Password && (
              <>
                <Typography variant="h5" style={{ fontWeight: 'bold' }}>
                  Forgot your password?
                </Typography>
              </>
            )}
          </PaperHeaderSection>
          <PaperContentSection>
            <AccountForms formType={formType} setFormType={setFormType} />
            <MiddleDividerWrapper>
              <div style={{ display: 'flex', flex: 1 }}>
                <Divider flexItem orientation="vertical" />
              </div>
              <DividerOrText variant="caption">Or</DividerOrText>
              <div style={{ display: 'flex', flex: 1 }}>
                <Divider flexItem orientation="vertical" />
              </div>{' '}
            </MiddleDividerWrapper>
            <ThirdPartyLogins setIsLoading={setIsLoading} />
          </PaperContentSection>
        </PaperContainer>
      </BackgroundContainer>
      <FullLoadingOverlay isLoading={isLoadingAuthState || isLoading} />
    </>
  );
};

export default LoginPage;
