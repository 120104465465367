import { PAGEAPIROUTES } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';
import { ACCESS_TYPE } from '../../../../../common/constants';

interface ShareResourceRequest {
  selectedEmails: string[];
  selectedAccessType: ACCESS_TYPE;
  updatedSharedUsers: {
    [email: string]: ACCESS_TYPE | null;
  };
}

interface ShareResourceResponse {
  successfulEmails: string[];
  accessType: ACCESS_TYPE;
  updatedSharedUsers: {
    [email: string]: ACCESS_TYPE | null;
  };
}

const useShareService = (
  resourceId: string
): [
  (body: ShareResourceRequest) => Promise<ShareResourceResponse | void>,
  Error | null,
  () => void
] =>
  usePostSimpleService<ShareResourceRequest, ShareResourceResponse>(
    `${PAGEAPIROUTES.Share}/${resourceId}`
  );

export default useShareService;
