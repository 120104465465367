import React from 'react';
import styled from 'styled-components';
import { TextField, Typography, FormLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { ConnectorComponentProps } from '../types';

const CONNECTOR_DETAILS_TEXT = `For more details on how to connect to Snowflake, please refer to our documentation.`;

const FieldsSection = styled.div`
  height: 425px;
  overflow: auto;
  margin: 8px 0;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ErrorMessage = styled(Typography)`
  color: red;
`;

const SnowflakeConnector: React.FC<ConnectorComponentProps> = ({
  errors,
  reset,
  control,
  isEditing,
}) => {
  return (
    <>
      {CONNECTOR_DETAILS_TEXT}
      <FieldsSection>
        <FormFieldWrapper>
          <FormLabel required>Connector Name</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. My Snowflake Connector"
              />
            )}
            name="connectorName"
            control={control}
            rules={{ required: 'Please enter a connector name' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>User</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                // placeholder="e.g. user"
              />
            )}
            name="user"
            control={control}
            rules={{ required: 'Please enter your account user' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Password</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                type="password"
                // placeholder="e.g. ACCOUNTKEY"
              />
            )}
            name="password"
            control={control}
            rules={{ required: 'Please enter your account password' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Account</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                // placeholder="e.g. abcdefghijklmnop1234"
              />
            )}
            name="account"
            control={control}
            rules={{ required: 'Please enter your account identifier' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Warehouse</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                // placeholder="e.g. abcdefghijklmnop1234"
              />
            )}
            name="warehouse"
            control={control}
            rules={{ required: 'Please enter your warehouse' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Database</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                // placeholder="e.g. abcdefghijklmnop1234"
              />
            )}
            name="database"
            control={control}
            rules={{ required: 'Please enter your database' }}
          />
        </FormFieldWrapper>
      </FieldsSection>
      {errors && (
        <ErrorMessage>{Object.entries(errors)?.[0]?.[1]?.message}</ErrorMessage>
      )}
    </>
  );
};

export default SnowflakeConnector;
