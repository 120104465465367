import React from 'react';
import { CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import { PipelineJobListEndpointResponse } from '../../../../api/types/job';
import ROUTES, {
  PIPELINEAPIS,
  PIPELINEDASHBOARDAPIS,
} from '../../../../common/routes';
import JobsList from './jobs-list';
import useErrorAlert from '../../../../utils/useErrorAlert';

const Wrapper = styled.div`
  margin: 8px 0;
  padding: 8px 0;
  border-radius: 8px;
`;

const Container = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const PipelineJobsTab: React.FC = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const [data, isLoading, error] = useFetchSimpleService<
    PipelineJobListEndpointResponse
  >(
    `/${ROUTES.Pipeline}/${pipelineId}${PIPELINEAPIS.Pipeline_Dashboard}${PIPELINEDASHBOARDAPIS.Jobs_List}`
  );
  useErrorAlert(error);

  return (
    <Wrapper>
      {isLoading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : (
        data && <JobsList {...data} />
      )}
    </Wrapper>
  );
};

export default PipelineJobsTab;
