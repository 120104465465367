import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import RefreshIcon from '@material-ui/icons/Refresh';
import useRefreshAccessKeysService from '../../service/useRefreshAccessKeysService';
import useErrorAlert from '../../../../../utils/useErrorAlert';
import useFetchSimpleService from '../../../../../api/useFetchSimpleService';
import { SETTINGSAPI } from '../../../../../common/routes';
import COLORS from '../../../../../common/colors';

const DetailsContainer = styled.div`
  background-color: ${COLORS.LightGrey};
  padding: 16px 16px 0 16px;
  display: grid;
  grid-template-rows: 48px 1fr;
  grid-column-gap: 16px;
  margin: 24px 0;
  border-radius: 12px;
`;

const TitleContainer = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  margin: 24px 0;
`;

const KeyWrapper = styled.div`
  height: 40px;
  display: flex;
  align-items: center;
`;

const KeyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 14px;
`;

const SquareButtonWrapper = withStyles({
  root: {
    minWidth: '0',
  },
})(Button);

const RefreshButton = styled(SquareButtonWrapper)`
  margin-left: 8px;
`;

const AccessKeyFields: React.FC = () => {
  const [accessKeysData, isAccessLoading] = useFetchSimpleService<{
    accessKeyId: string | null;
  }>(SETTINGSAPI.Access_Keys);

  const [refreshPrivateKeys, errorPrivateKeys] = useRefreshAccessKeysService();
  useErrorAlert(errorPrivateKeys);

  const [accessKeyId, setAccessKeyId] = useState<string | null>(null);
  const [secretAccessKey, setSecretAccessKey] = useState<string | null>(null);

  const [isAccessKeyLoading, setIsAccessKeyLoading] = useState<boolean>(false);

  const onRefreshPrivateKeys = async () => {
    setIsAccessKeyLoading(true);
    const response = await refreshPrivateKeys({});
    if (response) {
      setAccessKeyId(response.accessKeyId);
      setSecretAccessKey(response.secretAccessKey);
    }
    setIsAccessKeyLoading(false);
  };

  useEffect(() => {
    if (accessKeysData) {
      setAccessKeyId(accessKeysData.accessKeyId);
      if (accessKeysData.accessKeyId !== null) {
        setSecretAccessKey('************************************');
      }
    }
  }, [accessKeysData]);

  const isAccessKeySectionLoading = isAccessLoading || isAccessKeyLoading;
  return (
    <DetailsContainer>
      <TitleContainer>
        <Typography variant="h5">
          Access Credentials{' '}
          <span role="img" aria-label="key">
            🔑
          </span>
        </Typography>
        <RefreshButton
          onClick={onRefreshPrivateKeys}
          size="small"
          variant="outlined"
          disabled={isAccessKeySectionLoading}
        >
          <RefreshIcon />
        </RefreshButton>
      </TitleContainer>
      <DetailsGrid>
        <span>Access Key ID:</span>
        <KeyWrapper>
          <KeyTextWrapper>
            {isAccessKeySectionLoading ? (
              <CircularProgress color="primary" size={28} />
            ) : (
              <KeyTextWrapper>
                {accessKeyId ?? 'Refresh above to generate an Access Key ID!'}
              </KeyTextWrapper>
            )}
          </KeyTextWrapper>
        </KeyWrapper>
        <span>Secret Access Key: </span>
        <KeyWrapper>
          <KeyTextWrapper>
            {isAccessKeySectionLoading ? (
              <CircularProgress color="primary" size={28} />
            ) : (
              <KeyTextWrapper>
                {secretAccessKey ??
                  'Refresh above to generate a Secret Access Key!'}
              </KeyTextWrapper>
            )}
          </KeyTextWrapper>
        </KeyWrapper>
      </DetailsGrid>
    </DetailsContainer>
  );
};

export default AccessKeyFields;
