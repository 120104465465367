import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';
import { ContainerProps, StoreState } from './types';
import { ACCESS_TYPE } from '../../../../common/constants';

const initialState: StoreState = {
  sharedUsers: {},
  updatedSharedUsers: {},
  selectedEmails: [],
  selectedAccessType: ACCESS_TYPE.ADMIN,
  userAccessType: null,
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setInitialData: () => (
    { setState }: StoreApi,
    { sharedUsers, userAccessType }: ContainerProps
  ) => {
    setState({
      sharedUsers,
      userAccessType,
      updatedSharedUsers: {},
      selectedEmails: [],
      selectedAccessType: ACCESS_TYPE.ADMIN,
    });
  },
  addEmailToSelected: (newEmail: string) => ({
    setState,
    getState,
  }: StoreApi) => {
    const { selectedEmails } = getState();
    if (newEmail !== '') {
      setState({ selectedEmails: [...selectedEmails, newEmail] });
    }
  },
  deleteEmailFromSelected: (email: string) => ({
    setState,
    getState,
  }: StoreApi) => {
    const { selectedEmails } = getState();
    const index = selectedEmails.indexOf(email);
    if (index > -1) {
      selectedEmails.splice(index, 1);
    }
    setState({ selectedEmails });
  },
  setSelectedAccessType: (accessType: ACCESS_TYPE) => ({
    setState,
  }: StoreApi) => {
    setState({ selectedAccessType: accessType });
  },
  updateSharedUserAccessType: (
    email: string,
    accessType: ACCESS_TYPE | null
  ) => ({ setState, getState }: StoreApi) => {
    const { sharedUsers, updatedSharedUsers } = getState();
    if (accessType === sharedUsers[email].accessType) {
      delete updatedSharedUsers[email];
    } else {
      updatedSharedUsers[email] = accessType;
    }
    setState({ updatedSharedUsers });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const useModalStore = createHook(Store);
export const ModalStoreContainer = createContainer(Store, {
  onInit: actions.setInitialData,
  onUpdate: actions.setInitialData,
});
