import React, { useState } from 'react';
import styled from 'styled-components';
import { IconButton, Typography } from '@material-ui/core';
import DeleteIcon from '@material-ui/icons/Delete';
import TrendingFlatIcon from '@material-ui/icons/TrendingFlat';
import { useSchemaStore } from '../../../store';
import RemoveModal from '../../../remove-modal';
import useGetRulesUsedByFieldName from '../../../hooks/useGetRulesUsedByFieldName';
import TooltipChip from '../../../../../common/tooltip-chip';
import EditableInput from '../../../../../common/editable-input';

const Header = styled.header`
  padding: 16px 8px 16px 12px;
  border-bottom: 1px solid rgba(0, 0, 0, 0.12);
  display: flex;
  align-items: center;
  justify-content: space-between;
  height: 48px;
`;

const StyledDeleteIconButton = styled(IconButton)`
  &:hover {
    color: red;
    background-color: rgba(255, 0, 0, 0.1);
  }
`;

const Mappings = styled.div`
  margin: 32px 0 16px;
  max-height: 600px;
  overflow-y: auto;
`;

const MapRow = styled.div`
  display: flex;
  align-items: center;
  margin: 8px 0;
  max-width: 100%;

  & > * {
    margin-right: 24px;
  }
`;

const EditableInputWrapper = styled.div`
  flex-grow: 1;
  min-width: 0; // Stops flex item from overflowing parent container
  margin-right: 12px;
`;

const TOOLTIP_WRAPPER_WIDTH = 140;

interface Props {
  fieldName: string;
}

const FieldColumnHeader: React.FC<Props> = ({ fieldName }) => {
  const [
    { isSetOutputSchema, fields, isAddingAMappingRule, isEditingAMappingRule },
    { updateFieldName, removeProvidedFields },
  ] = useSchemaStore();

  const rulesUsedByFieldName = useGetRulesUsedByFieldName(fieldName);

  const [modalIsOpen, setModalIsOpen] = useState<boolean>(false);

  const generalDisabled =
    isSetOutputSchema || isAddingAMappingRule || isEditingAMappingRule;

  const openModal = () => setModalIsOpen(true);
  const closeModal = () => setModalIsOpen(false);

  const handleSaveEdit = (newValue: string) => {
    updateFieldName(fieldName, newValue);
  };

  const handleStartRemove = () => {
    // If a field is used in a mapping rule, then they need to acknowledge that they will also be
    // removing already existing mapping rules as well.
    if (rulesUsedByFieldName.length) {
      openModal();
    } else {
      removeProvidedFields([fieldName]);
    }
  };

  const handleConfirmRemove = () => {
    removeProvidedFields(rulesUsedByFieldName.map((rule) => rule.outputField));
  };

  return (
    <>
      <Header>
        <EditableInputWrapper>
          <EditableInput
            value={fieldName}
            onSave={handleSaveEdit}
            disabledValues={Object.keys(fields)}
            disabledValuesTooltipText="Field already used"
            isDisabled={generalDisabled}
            textStyles={{
              fontWeight: 'bold',
              fontSize: 18,
            }}
          />
        </EditableInputWrapper>
        <StyledDeleteIconButton
          onClick={handleStartRemove}
          disabled={generalDisabled}
        >
          <DeleteIcon fontSize="small" />
        </StyledDeleteIconButton>
      </Header>
      <RemoveModal
        isOpen={modalIsOpen}
        onClose={closeModal}
        onConfirm={handleConfirmRemove}
      >
        {rulesUsedByFieldName.length > 1 ? (
          <Typography gutterBottom>
            Removing this field means you will also remove these{' '}
            <strong>{rulesUsedByFieldName.length}</strong> defined mapping
            rules.
          </Typography>
        ) : (
          <Typography gutterBottom>
            Removing this field means you will also remove this defined mapping
            rule.
          </Typography>
        )}
        <Mappings>
          {rulesUsedByFieldName.map((rule) => (
            <MapRow key={rule.inputColumnName}>
              <TooltipChip
                label={rule.inputColumnName}
                color="primary"
                wrapperWidth={TOOLTIP_WRAPPER_WIDTH}
              />
              <TrendingFlatIcon fontSize="large" />
              <TooltipChip
                label={rule.outputField}
                color="secondary"
                wrapperWidth={TOOLTIP_WRAPPER_WIDTH}
              />
            </MapRow>
          ))}
        </Mappings>
      </RemoveModal>
    </>
  );
};

export default FieldColumnHeader;
