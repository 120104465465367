import React from 'react';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Clear, Done } from '@material-ui/icons';
import { DataTypes } from '../../../../../decision-pages/units-page/units-store/types';
import getReadableDataType from '../../../../../decision-pages/units-page/field-column-units/units-row/util';
import convertUnitText from '../../../../../../utils/convertUnitText';
import COLORS from '../../../../../../common/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const FieldsContainer = styled.div`
  display: grid;
  align-content: center;
  margin: 16px 0;
  width: 100%;
  overflow-x: auto;
`;

const AddFieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  background-color: ${COLORS.LightGrey};
  border-radius: 4px;
  padding: 4px 8px;
`;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BORDER_STYLE = '1px solid rgba(0, 0, 0, 0.12)';

const FieldColumn = styled.div`
  border-top: ${BORDER_STYLE};
  border-left: ${BORDER_STYLE};
  border-bottom: ${BORDER_STYLE};
  //min-width: 240px;
  //max-width: 240px;
  min-height: 540px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;

  &:last-child {
    border-right: ${BORDER_STYLE};
  }
`;

const Top = styled.div`
  flex: 1;
`;

const Bottom = styled.div`
  padding: 0 16px 16px;
`;

const FieldColumnHeader = styled.div`
  display: grid;
  grid-auto-columns: 240px;
  grid-auto-flow: column;
`;

const FieldInfoContainer = styled.div`
  display: grid;
  grid-auto-flow: column;
  grid-auto-columns: 240px;
`;

const FieldName = styled(Typography)`
  border-top: ${BORDER_STYLE};
  border-left: ${BORDER_STYLE};
  padding: 16px;
  font-weight: bold;
  &:last-child {
    border-right: ${BORDER_STYLE};
  }
`;

const FieldAttribute = styled.div`
  padding: 0 16px;
  margin: 32px 0;
`;

const FieldAttributeLabel = styled(Typography)`
  font-size: 12px;
  font-weight: bold;
`;

const OptionalText = styled(Typography)`
  font-style: italic;
`;

export interface Props {
  fields: {
    [fieldName: string]: {
      dataType: DataTypes;
      unit?: string | null;
      dateTimeFormat?: string | null;
      allowedValues?: string[];
      optional: boolean;
    };
  };
  canAddAdditionalFields: boolean;
  combineAdditionalField: string | null;
  duplicateDropFields: string[][];
}

const SummarySchema: React.FC<Props> = ({
  fields,
  canAddAdditionalFields,
  combineAdditionalField,
  duplicateDropFields,
}) => {
  return (
    <Wrapper>
      {Object.keys(fields).length > 0 ? (
        <FieldsContainer>
          <FieldColumnHeader>
            {Object.entries(fields).map(([fieldName]) => (
              <FieldName key={fieldName}>{fieldName}</FieldName>
            ))}
          </FieldColumnHeader>
          <FieldInfoContainer>
            {Object.entries(fields).map(([fieldName, fieldData]) => (
              <FieldColumn key={fieldName}>
                <Top>
                  <FieldAttribute>
                    <FieldAttributeLabel gutterBottom>
                      Data Type
                    </FieldAttributeLabel>
                    <Typography>
                      {getReadableDataType(fieldData.dataType)}
                    </Typography>
                  </FieldAttribute>
                  {fieldData.unit !== undefined && (
                    <FieldAttribute>
                      <FieldAttributeLabel gutterBottom>
                        Output Unit
                      </FieldAttributeLabel>
                      <Typography>{convertUnitText(fieldData.unit)}</Typography>
                    </FieldAttribute>
                  )}
                  {fieldData.dateTimeFormat !== undefined && (
                    <FieldAttribute>
                      <FieldAttributeLabel gutterBottom>
                        Date Time Format
                      </FieldAttributeLabel>
                      <Typography>
                        {fieldData.dateTimeFormat || 'None'}
                      </Typography>
                    </FieldAttribute>
                  )}
                  {fieldData.dataType === DataTypes.CATEGORY && (
                    <FieldAttribute>
                      <FieldAttributeLabel gutterBottom>
                        Category Values
                      </FieldAttributeLabel>
                      <Typography>
                        {fieldData.allowedValues &&
                        fieldData.allowedValues.length > 0
                          ? fieldData.allowedValues.join(', ')
                          : 'No category values specified - all values will be accepted'}
                      </Typography>
                    </FieldAttribute>
                  )}
                </Top>
                {fieldData.optional && (
                  <Bottom>
                    <OptionalText>Optional</OptionalText>
                  </Bottom>
                )}
              </FieldColumn>
            ))}
          </FieldInfoContainer>
        </FieldsContainer>
      ) : (
        <Typography variant="subtitle1">
          No output schema mappings were provided
        </Typography>
      )}
      <div style={{ display: 'flex' }}>
        <AddFieldsContainer>
          {canAddAdditionalFields ? (
            <>
              <TextContainer>
                <Done />
                <Typography variant="h6">
                  Columns from uploaded data can be added as additional fields.
                </Typography>
              </TextContainer>
              {combineAdditionalField && (
                <div style={{ marginTop: '4px', marginLeft: '24px' }}>
                  All additional fields will combine into field{' '}
                  <span style={{ fontWeight: 'bold' }}>
                    {combineAdditionalField}{' '}
                  </span>
                  as JSON.
                </div>
              )}
            </>
          ) : (
            <TextContainer>
              <Clear />
              <Typography variant="h6">
                Columns from uploaded data cannot be added as additional fields.
              </Typography>
            </TextContainer>
          )}
        </AddFieldsContainer>
      </div>
      {duplicateDropFields[0] && duplicateDropFields.length > 0 && (
        <div style={{ marginTop: '8px' }}>
          <Typography variant="h6">
            Drop rows if there are duplicates in field
            {duplicateDropFields[0].length > 1 && 's'}{' '}
            <span style={{ fontWeight: 'bold' }}>
              {duplicateDropFields[0].join(', ')}
            </span>
            .
          </Typography>
        </div>
      )}
    </Wrapper>
  );
};

export default SummarySchema;
