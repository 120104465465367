import React from 'react';
import styled, { keyframes } from 'styled-components';

const RADIUS_START = '8px';
const RADIUS_END = '80px';

const Container = styled.div`
  position: relative;
  display: inline-block;
`;

const GrowingSignal = keyframes`
  0% {
    width: ${RADIUS_START};
    height: ${RADIUS_START};
    opacity: 1;
  }
  
  75%, 100% {
    height: ${RADIUS_END};
    width: ${RADIUS_END};
    opacity: 0;
  }
`;

const Source = styled.div<{ color: string }>`
  height: ${RADIUS_START};
  width: ${RADIUS_START};
  background-color: ${(props) => props.color};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
`;

const Signal = styled.div<{ color: string }>`
  border: 2px solid ${(props) => props.color};
  border-radius: 50%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  animation: 2.75s infinite ease-out ${GrowingSignal};
`;

interface Props {
  color: string;
}

const ListeningSignal: React.FC<Props> = ({ color }) => {
  return (
    <Container>
      <Source color={color} />
      <Signal color={color} />
    </Container>
  );
};

export default ListeningSignal;
