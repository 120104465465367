import { createMuiTheme } from '@material-ui/core/styles';

const mainTheme = createMuiTheme({
  palette: {
    primary: {
      light: '#3a476b',
      main: '#091946',
      dark: '#061131',
      contrastText: '#fff',
    },
    secondary: {
      light: '#f3b633',
      main: '#f1a400',
      dark: '#a87200',
      contrastText: '#fff',
    },
  },
  typography: {
    fontFamily: [
      'Quicksand',
      'Work Sans',
      '-apple-system',
      'BlinkMacSystemFont',
      '"Segoe UI"',
      'Roboto',
      '"Helvetica Neue"',
      'Arial',
      'sans-serif',
      '"Apple Color Emoji"',
      '"Segoe UI Emoji"',
      '"Segoe UI Symbol"',
    ].join(','),
    h3: {
      fontFamily: 'Nexa Bold',
    },
    h4: {
      fontFamily: 'Nexa Bold',
    },
    h5: {
      fontFamily: 'Nexa Bold',
    },
    subtitle1: {
      fontWeight: 'bold',
    },
    button: {
      textTransform: 'none',
      fontWeight: 'bold',
    },
  },
  overrides: {
    // TODO: Once MuiAutocomplete gets added to styles override, remove this ts ignore
    // eslint-disable-next-line @typescript-eslint/ban-ts-comment
    // @ts-ignore
    MuiAutocomplete: {
      popper: {
        zIndex: 5,
      },
    },
    MuiSelect: {
      select: {
        '&:focus': {
          backgroundColor: 'inherit',
        },
      },
    },
  },
});

export default mainTheme;
