import React, { useEffect } from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import ReportProblemIcon from '@material-ui/icons/ReportProblem';
import { useHistory } from 'react-router-dom';
import * as Sentry from '@sentry/react';
import COLORS from '../../../common/colors';
import ROUTES from '../../../common/routes';
import DataError from './data-error';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const ErrorImage = styled(ReportProblemIcon)`
  height: 150px;
  width: 150px;
  margin-bottom: 16px;
`;

const GenericMessageSection = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const ErrorMessageSection = styled.div`
  display: flex;
  margin-bottom: 8px;
  color: ${COLORS.Red};
`;

const BackToDashboardButtonSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const BackToDashboardButtonWrapper = styled.div`
  display: flex;
  flex-direction: column;
  pointer-events: visible;
  margin-top: 8px;
`;

export class CustomError extends Error {
  constructor(message?: string) {
    super(message);
    Object.setPrototypeOf(this, new.target.prototype);
  }
}

export class StatusCodeError extends CustomError {
  statusCode: number;

  constructor(message: string, statusCode: number) {
    super(message);
    this.statusCode = statusCode;
  }
}

const ErrorScreen: React.FC<{
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}> = ({ error, resetErrorBoundary }) => {
  const history = useHistory();

  const handleBackToDashboard = () => {
    resetErrorBoundary();
    history.push(`${ROUTES.App}`);
  };

  useEffect(() => {
    Sentry.captureException(error);
  }, [error]);

  if (error instanceof StatusCodeError) {
    if (error.statusCode < 500 && error.statusCode >= 400) {
      return (
        <DataError error={error} resetErrorBoundary={resetErrorBoundary} />
      );
    }
  }

  return (
    <Wrapper>
      <ErrorImage />
      <ErrorMessageSection>Error: {error.message}</ErrorMessageSection>
      <GenericMessageSection>
        Something went wrong - please refresh the page!
      </GenericMessageSection>
      <BackToDashboardButtonSection>
        Alternatively, you can go back to the dashboard:
        <BackToDashboardButtonWrapper>
          <Button
            variant="outlined"
            color="secondary"
            onClick={handleBackToDashboard}
          >
            Back to Dashboard
          </Button>
        </BackToDashboardButtonWrapper>
      </BackToDashboardButtonSection>
    </Wrapper>
  );
};

export default ErrorScreen;
