import React from 'react';
import styled from 'styled-components';
import {
  Backdrop,
  Button,
  CircularProgress,
  Fade,
  IconButton,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import COLORS from '../../../common/colors';

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalComponent = styled(Paper)`
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const BodyContainer = styled.div`
  flex: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: flex-end;
  margin-top: 16px;
`;

const Title = styled(Typography)`
  font-weight: bold;
`;

const DeleteButton = styled(Button)`
  margin-left: 16px;
  background-color: ${COLORS.Red};
  color: white;

  &:hover {
    background-color: ${COLORS.Red};
    opacity: 0.6;
  }

  &:disabled {
    background-color: ${COLORS.Red};
    opacity: 0.6;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  confirmationText: string;
  handleDelete: () => void;
  isLoading: boolean;
}

const DeleteModalInner: React.FC<Props> = ({
  onClose,
  confirmationText,
  handleDelete,
  isLoading,
}) => {
  return (
    <>
      <HeaderContainer>
        <Title variant="h4" gutterBottom>
          Delete
        </Title>
        <IconButton aria-label="Delete" onClick={onClose}>
          <CloseIcon />
        </IconButton>
      </HeaderContainer>
      <BodyContainer>
        <Typography>{confirmationText}</Typography>
      </BodyContainer>
      <FooterContainer>
        {isLoading ? (
          <DeleteButton variant="contained" disabled>
            <CircularProgress size="24px" />
          </DeleteButton>
        ) : (
          <>
            <Button onClick={onClose}>Cancel</Button>
            <DeleteButton onClick={handleDelete} variant="contained">
              Delete
            </DeleteButton>
          </>
        )}
      </FooterContainer>
    </>
  );
};

const DeleteModal: React.FC<Props> = (props) => {
  const { onClose, isOpen, isLoading } = props;

  const handleRestrictClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <ModalContainer
      open={isOpen}
      onClose={handleRestrictClose}
      BackdropComponent={Backdrop}
      BackdropProps={{ timeout: 500 }}
      keepMounted
    >
      <Fade in={isOpen}>
        <ModalComponent elevation={3}>
          <DeleteModalInner {...props} />
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default DeleteModal;
