import { BILLINGAPI } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

type Response = {
  url: string;
};

const useCreatePortalSessionService = (): [
  (body: void) => Promise<Response | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<void, Response>(BILLINGAPI.Create_Portal_Session);
};

export default useCreatePortalSessionService;
