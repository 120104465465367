import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import { toast } from 'react-toastify';
import COLORS from '../../../common/colors';
import s3svg from '../../../common/images/s3.svg';
import csvsvg from '../../../common/images/csv.svg';
import mysqlsvg from '../../../common/images/aws_rds_mysql.svg';
import athenasvg from '../../../common/images/athena.svg';
import snowflakesvg from '../../../common/images/snowflake.svg';
import firestoresvg from '../../../common/images/firestore.svg';
import gcssvg from '../../../common/images/gcs.svg';
import supabasesvg from '../../../common/images/supabase.svg';
import { DATA_RESOURCE_TYPES } from '../../../common/constants';
import useDeleteDataResourceService from './service/useDeleteDataResourceService';
import useErrorAlert from '../../../utils/useErrorAlert';

const Wrapper = styled.div`
  min-height: 200px;
  background-color: ${COLORS.Grey};
  border-radius: 6px;
  padding: 16px;
`;

const DataResourceCard = styled(Card)`
  margin: 8px;
  padding: 8px 16px;
  height: 50px;
  display: flex;
  align-items: center;
`;

const DisabledResourceCard = styled.div`
  margin: 8px;
  padding: 8px 16px;
  height: 50px;
  display: flex;
  align-items: center;
  border-radius: 4px;
  background-color: white;
`;

const LogoSvg = styled.img`
  height: 100%;
`;

const NameSection = styled.div`
  margin-left: 16px;
  flex: 1;
  white-space: nowrap;
  width: 0;
`;

const NoWrapTypography = styled(Typography)`
  overflow: hidden;
  text-overflow: ellipsis;
`;

export const selectLogoFromType = (dataSourceType: string) => {
  switch (dataSourceType) {
    case DATA_RESOURCE_TYPES.ATHENA:
      return athenasvg;
    case DATA_RESOURCE_TYPES.AWS_RDS_MYSQL:
      return mysqlsvg;
    case DATA_RESOURCE_TYPES.S3:
      return s3svg;
    case DATA_RESOURCE_TYPES.SNOWFLAKE:
      return snowflakesvg;
    case DATA_RESOURCE_TYPES.CSV:
      return csvsvg;
    case DATA_RESOURCE_TYPES.FIRESTORE:
      return firestoresvg;
    case DATA_RESOURCE_TYPES.GCS:
      return gcssvg;
    case DATA_RESOURCE_TYPES.SUPABASE_POSTGRESQL:
      return supabasesvg;
    default:
      return csvsvg;
  }
};

interface Props {
  dataResources: {
    [dataResourceId: string]: {
      dataResourceName: string;
      dataResourceType: string;
    };
  };
  onRemoveDataResource?: (dataResourceId: string) => void;
  disabledDataResources?: {
    dataResourceName: string;
    dataResourceType: string;
  }[];

  isOutput?: boolean;
}

const DataResourcesList: React.FC<Props> = ({
  dataResources,
  onRemoveDataResource,
  disabledDataResources,
  isOutput = false,
}) => {
  const [deleteDataResourceService, error] = useDeleteDataResourceService(
    isOutput
  );
  useErrorAlert(error);
  const [filesToDelete, setFilesToDelete] = useState<string[]>([]);

  const handleDataSourceDelete = (
    dataResourceId: string,
    dataSourceName: string
  ) => async () => {
    const newArray = [...filesToDelete, dataResourceId];
    setFilesToDelete(newArray);
    if (onRemoveDataResource) {
      const response = await deleteDataResourceService(dataResourceId);
      if (response) {
        onRemoveDataResource(dataResourceId);
        toast.success(
          <>
            <span
              style={{
                fontStyle: 'italic',
              }}
            >
              {dataSourceName}
            </span>
            {' '}removed successfully
          </>
        );
      }
      newArray.filter((id) => {
        return id !== dataResourceId;
      });
      setFilesToDelete(newArray);
    }
  };

  return (
    <Wrapper>
      {disabledDataResources &&
        disabledDataResources.map(({ dataResourceType, dataResourceName }) => (
          <DisabledResourceCard>
            <LogoSvg src={selectLogoFromType(dataResourceType)} />
            <NameSection>
              <NoWrapTypography variant="h6">
                {dataResourceName}
              </NoWrapTypography>
            </NameSection>
            <div />
          </DisabledResourceCard>
        ))}
      {Object.entries(dataResources).map(
        ([dataResourceId, { dataResourceType, dataResourceName }]) => (
          <DataResourceCard>
            <LogoSvg src={selectLogoFromType(dataResourceType)} alt="test" />
            <NameSection>
              <NoWrapTypography variant="h6">
                {dataResourceName}
              </NoWrapTypography>
            </NameSection>

            {filesToDelete.includes(dataResourceId) && (
              <CircularProgress color="inherit" />
            )}
            {onRemoveDataResource && (
              <IconButton
                onClick={handleDataSourceDelete(
                  dataResourceId,
                  dataResourceName
                )}
                disabled={filesToDelete.includes(dataResourceId)}
              >
                <Close fontSize="small" />
              </IconButton>
            )}
          </DataResourceCard>
        )
      )}
    </Wrapper>
  );
};

export default DataResourcesList;
