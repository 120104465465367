import React from 'react';
import PlayCircleFilledIcon from '@material-ui/icons/PlayCircleFilled';
import GavelIcon from '@material-ui/icons/Gavel';
import { Divider } from '@material-ui/core';
import BorderColorIcon from '@material-ui/icons/BorderColor';
import styled from 'styled-components';
import { Link } from 'react-router-dom';
import { Typography } from '@mui/material';
import { CloudCircleOutlined } from '@material-ui/icons';
import NavItem from '../nav-item';
import ROUTES from '../../../common/routes';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const StyledLink = styled(Link)`
  text-decoration: none;
  color: inherit;
  &: hover {
    text-decoration: underline;
  }
`;

interface Props {
  onManualResetErrorBoundary?: () => void;
}

const AppNavItems: React.FC<Props> = ({ onManualResetErrorBoundary }) => {
  return (
    <Wrapper>
      <div>
        <NavItem
          text="Quick Start"
          path={`${ROUTES.App + ROUTES.Quick_Start}`}
          onManualResetErrorBoundary={onManualResetErrorBoundary}
        >
          <PlayCircleFilledIcon />
        </NavItem>
        <Divider style={{ margin: '8px 0' }} />
        <NavItem
          text="Pipelines"
          path={`${ROUTES.App}${ROUTES.Pipeline}`}
          onManualResetErrorBoundary={onManualResetErrorBoundary}
        >
          <GavelIcon />
        </NavItem>
        <NavItem
          path={`${ROUTES.App}${ROUTES.Scripts}`}
          text="Scripts"
          onManualResetErrorBoundary={onManualResetErrorBoundary}
        >
          <BorderColorIcon />
        </NavItem>
        <NavItem
          path={`${ROUTES.App}${ROUTES.Connectors}`}
          text="Connectors"
          onManualResetErrorBoundary={onManualResetErrorBoundary}
        >
          <CloudCircleOutlined />
        </NavItem>
      </div>
      <div style={{ textAlign: 'center' }}>
        <StyledLink to={`${ROUTES.App}${ROUTES.Onboarding_Wizard}`}>
          <Typography variant="caption" style={{ lineHeight: '0' }}>
            Need help getting started? Check out the Onboarding Tour!
          </Typography>
        </StyledLink>
      </div>
    </Wrapper>
  );
};

export default AppNavItems;
