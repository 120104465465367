import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { CircularProgress, Paper, Typography } from '@material-ui/core';
import StepHeading from '../step-heading';
import COLORS from '../../../common/colors';
import FadeSlideUpwards from '../../common/animations/fade-slide-upwards';
import OnboardingWizardBottomBar from '../onboarding-wizard-bottombar';
import DataSnippet from '../data-snippet';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import { ONBOARDINGAPI } from '../../../common/routes';
import { useOnboardingWizardStore } from '../store';
import usePostSimpleService from '../../../api/usePostSimpleService';
import useHasCompletedOnboarding from '../hooks/useHasCompletedOnboarding';
import BackToAppButton from '../back-to-app-button';

const PageContainer = styled.div`
  margin: 60px 0 auto;
  width: 100%;
`;

const ResultsSection = styled.div`
  margin: 16px 0;
`;

const EmptyTable = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  height: 200px;
`;

const FinalText = styled.div`
  margin: 16px 0;
  text-align: center;
`;

const ViewDataPage: React.FC = () => {
  const [
    { dataTable, isDataTableLoading },
    { setDataTable, setHasRunJob },
  ] = useOnboardingWizardStore();

  const shouldFetch = !dataTable && !isDataTableLoading;

  const [data, isLoading] = useFetchSimpleService<{
    dataTable: string[][];
  }>(ONBOARDINGAPI.Job_Data, shouldFetch);

  const isAnyLoading = isDataTableLoading || isLoading;

  const [headerColumns, setHeaderColumns] = useState<string[]>([]);
  const [bodyData, setBodyData] = useState<string[][]>([]);

  useEffect(() => {
    if (dataTable) {
      const dataTableCopy = [...dataTable];
      const header = dataTableCopy.shift();
      if (header) {
        setHeaderColumns(header);
        setBodyData(dataTableCopy);
      }
    }
  }, [dataTable]);

  useEffect(() => {
    if (data?.dataTable) {
      setDataTable(data.dataTable);
      setHasRunJob(true);
    }
  }, [data, setDataTable, setHasRunJob]);

  const [submitFinish] = usePostSimpleService(ONBOARDINGAPI.Status);
  const [isSubmitLoading, setIsSubmitLoading] = useState<boolean>(false);

  const { completed } = useHasCompletedOnboarding();

  const onFinish = async () => {
    setIsSubmitLoading(true);
    const response = await submitFinish(null);
    if (response === undefined) {
      setIsSubmitLoading(false);
      throw new Error('Failed to finish onboarding - please try again.');
    }
  };

  return (
    <>
      {completed && <BackToAppButton />}
      <PageContainer>
        <StepHeading stepNumber={3} bgColor={COLORS.DarkBlue}>
          Job Results{' '}
          <span role="img" aria-label="celebrate">
            🎉
          </span>
        </StepHeading>
        <ResultsSection>
          {isAnyLoading ? (
            <EmptyTable variant="outlined">
              <CircularProgress />
              <Typography variant="caption">Fetching Results</Typography>
            </EmptyTable>
          ) : (
            <>
              <DataSnippet headerColumns={headerColumns} bodyData={bodyData} />
              <FadeSlideUpwards>
                <FinalText>
                  Your data has been cleaned and transformed to fit the
                  Pipeline&apos;s schema.
                  <Typography variant="h6" style={{ marginTop: 16 }}>
                    You are now ready to create your own Data Importing
                    experience! Hit Finish to move to the{' '}
                    <span style={{ fontWeight: 'bold' }}>Quick Start</span> page
                    and get started with creating your own pipeline.
                  </Typography>
                </FinalText>
              </FadeSlideUpwards>
            </>
          )}
        </ResultsSection>
      </PageContainer>
      <OnboardingWizardBottomBar
        isOnProgressionDisabled={isAnyLoading}
        isOnProgressionLoading={isSubmitLoading}
        onProgressionCallback={onFinish}
      />
    </>
  );
};

export default ViewDataPage;
