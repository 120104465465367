import React from 'react';
import AccountCircleIcon from '@material-ui/icons/AccountCircle';
import PaymentIcon from '@material-ui/icons/Payment';
import { Divider } from '@material-ui/core';
import KeyboardBackspaceIcon from '@material-ui/icons/KeyboardBackspace';
import NavItem from '../nav-item';
import ROUTES, { SETTINGSPAGES } from '../../../common/routes';

interface Props {
  onManualResetErrorBoundary?: () => void;
}

const SettingsNavItems: React.FC<Props> = ({ onManualResetErrorBoundary }) => {
  return (
    <>
      <NavItem
        text="Back to App"
        path={`${ROUTES.App + ROUTES.Pipeline}`}
        onManualResetErrorBoundary={onManualResetErrorBoundary}
      >
        <KeyboardBackspaceIcon />
      </NavItem>
      <Divider style={{ margin: '8px 0' }} />
      <NavItem
        text="Account Details"
        path={`${ROUTES.App}${ROUTES.Settings}/${SETTINGSPAGES.Account_Details}`}
        onManualResetErrorBoundary={onManualResetErrorBoundary}
      >
        <AccountCircleIcon />
      </NavItem>
      <NavItem
        text="Billing"
        path={`${ROUTES.App}${ROUTES.Settings}/${SETTINGSPAGES.Billing}`}
        onManualResetErrorBoundary={onManualResetErrorBoundary}
      >
        <PaymentIcon />
      </NavItem>
    </>
  );
};

export default SettingsNavItems;
