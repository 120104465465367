import React from 'react';
import styled from 'styled-components';
import { FormLabel, TextField, Typography } from '@material-ui/core';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ErrorMessage = styled(Typography)`
  color: red;
`;

interface Props {
  formRegister: UseFormRegister<Record<string, string>>;
  errors: DeepMap<FieldValues, FieldError>;
}

const FirestoreForm: React.FC<Props> = ({ formRegister, errors }) => {
  return (
    <>
      <FormFieldWrapper>
        <FormLabel required>Base Collection Path</FormLabel>
        <TextField
          autoFocus
          {...formRegister('baseCollectionPath')}
          variant="outlined"
          color="primary"
          margin="dense"
          placeholder="e.g collection/document/anotherCollection"
        />
      </FormFieldWrapper>
      {errors && <ErrorMessage>{errors?.tableName?.message}</ErrorMessage>}
    </>
  );
};

export default FirestoreForm;
