import React, { forwardRef } from 'react';
import styled from 'styled-components';
import AdditionalFieldsOption from './additional-fields-option';
import DuplicateDrop from './duplicate-drop';
import EmailValidation from './email-validation';
import COLORS from '../../../../../common/colors';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionWrapper = styled.div`
  display: flex;
  background-color: ${COLORS.LightGrey};
  padding: 8px;
  border-radius: 4px;
  margin-bottom: 16px;
`;

const SchemaOptions = forwardRef<HTMLDivElement>((props, ref) => {
  return (
    <Wrapper ref={ref}>
      <OptionWrapper>
        <AdditionalFieldsOption />
      </OptionWrapper>
      <OptionWrapper>
        <DuplicateDrop />
      </OptionWrapper>
      <OptionWrapper>
        <EmailValidation />
      </OptionWrapper>
    </Wrapper>
  );
});

export default SchemaOptions;
