import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Chip, TextField, Typography } from '@material-ui/core';
import { ScriptsList } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const InputContainer = styled.div`
  display: flex;
  margin: 12px 0;
  justify-content: center;
`;

const LozengeContainer = styled.div`
  border: 1px solid lightgray;
  border-radius: 5px;
  height: 80px;
  overflow-y: scroll;
  padding: 4px;
`;

const StyledChip = styled(Chip)`
  max-width: 170px;
  margin: 4px;
`;

const StyledTextField = styled(TextField)``;

const StyledButton = styled(Button)`
  height: 36px;
  width: 60px;
  margin-left: 8px;
`;

interface Props {
  scriptsList: ScriptsList;
  setScriptsList: React.Dispatch<React.SetStateAction<ScriptsList>>;
  disabled: boolean;
}

const TestScriptsList: React.FC<Props> = ({
  scriptsList,
  setScriptsList,
  disabled,
}) => {
  const [inputValue, setInputValue] = useState<string>('');

  const isScriptAdded = scriptsList.preColumnMapping.includes(inputValue);
  const clearInputValue = () => {
    setInputValue('');
  };

  const handleChangeInputValue = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newValue = event.target.value;
    const newInput = newValue.replace(/\s/g, '');

    const specialCharacters = /[~`!#$%^&*+=[\]';,{}|\\":<>?]/;
    const isNotValidText = specialCharacters.test(newInput);

    if (!isNotValidText) {
      setInputValue(newInput);
    }
  };

  const handleAddScript = () => {
    const isScriptAdded = scriptsList.preColumnMapping.includes(inputValue);
    if (inputValue !== '' && !isScriptAdded) {
      const prevScriptsList = [...scriptsList.preColumnMapping];
      const updatedScriptsList: ScriptsList = {
        preColumnMapping: [...prevScriptsList, inputValue],
      };

      setScriptsList(updatedScriptsList);
      clearInputValue();
    }
  };

  const handleDeleteScript = (scriptId: string) => {
    const index = scriptsList.preColumnMapping.indexOf(scriptId);
    const currentScriptsList = [...scriptsList.preColumnMapping];

    if (index > -1) {
      currentScriptsList.splice(index, 1);
    }

    setScriptsList({ preColumnMapping: currentScriptsList });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleAddScript();
    }
  };

  return (
    <Wrapper>
      <InputContainer>
        <StyledTextField
          fullWidth
          size="small"
          variant="outlined"
          placeholder="scriptId1"
          value={inputValue}
          onChange={handleChangeInputValue}
          error={isScriptAdded}
          label={isScriptAdded && 'Invalid entry.'}
          onKeyPress={handleKeyPress}
          disabled={disabled}
        />
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={handleAddScript}
          disabled={disabled}
        >
          Add
        </StyledButton>
      </InputContainer>
      <LozengeContainer>
        {scriptsList.preColumnMapping.length !== 0 ? (
          scriptsList.preColumnMapping.map((scriptId) => (
            <StyledChip
              label={scriptId}
              disabled={disabled}
              onDelete={() => handleDeleteScript(scriptId)}
            />
          ))
        ) : (
          <Typography style={{ color: 'gray', margin: 4 }}>
            No scripts have been added so far. Please enter script Ids in the
            text box above to add one!
          </Typography>
        )}
      </LozengeContainer>
    </Wrapper>
  );
};

export default TestScriptsList;
