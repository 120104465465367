import React from 'react';
import styled from 'styled-components';
import { TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';

export const DateTimeFormats = [
  {
    format: 'ISO_8601',
    example: 'e.g. 24/03/2000 16:04:45+01:00',
  },
  {
    format: 'dd/mm/yyyy HH:mm:ss',
    example: 'e.g. 24/03/2000 16:04:45',
  },
  {
    format: 'mm/dd/yyyy HH:mm:ss',
    example: 'e.g. 03/24/2000 16:04:45',
  },
  {
    format: 'dd/mm/yyyy',
    example: 'e.g. 24/03/2000',
  },
  {
    format: 'mm/dd/yyyy',
    example: 'e.g. 03/24/2000',
  },
  {
    format: 'yy/dd/mm',
    example: 'e.g. 99/20/04',
  },
  {
    format: 'yyyy/mm/dd',
    example: 'e.g. 1999/04/20',
  },
  {
    format: 'yyyy/dd/mm HH:mm:ss',
    example: 'e.g. 99/20/04 17:07:24',
  },
  {
    format: 'yyyy/mm/dd HH:mm:ss',
    example: 'e.g. 1999/04/20 17:07:24',
  },
  {
    format: 'yyyy/dd/mm',
    example: 'e.g. 99/20/04',
  },
  {
    format: 'HH:mm:ss',
    example: 'e.g. 17:07:59',
  },
  {
    format: 'ddd dd mmm yyyy',
    example: 'e.g. Tues 24 Jul 2018',
  },
  {
    format: 'dd mmm yyyy',
    example: 'e.g. 24 Jul 2018',
  },
  // {
  //   format: '',
  //   example: 'no change to original format',
  // },
];

const TextFieldWrapper = styled.div`
  background-color: white;
  display: flex;
  flex-direction: column;
  height: 100%;
  width: 100%;
`;

const StyledAutoComplete = styled(Autocomplete)`
  padding: 0;
  margin-top: 21px;
  left: 0;
`;

export interface Props {
  onDateTimeFormatChange: (value: string) => void;
  disabled: boolean;
  value: string;
}

const DateFormatInput: React.FC<Props> = ({
  onDateTimeFormatChange,
  disabled,
  value,
}) => {
  // const [isFormatSaved, setIsFormatSaved] = useState<boolean>(false);

  const getFormatExample = (newFormat: string) => {
    let newExample = 'Please select an option';
    Object.entries(DateTimeFormats).forEach(([, format]) => {
      if (newFormat === format.format) {
        newExample = format.example;
      }
    });
    return newExample;
  };

  // const ValidDateTimeFormat = new RegExp(
  //   /^((dd|mm|(yy){0,2})[\s./-])(dd|mm)?([\s./-](dd|mm|(yy){0,2}))( (HH|hh)(:mm(:ss)?))?$/
  // );

  // const ValidDateTimeFormat = new RegExp(
  //     /^((dd|mm|(yy){0,2})(?<SEP>[\s./-]))(dd|mm)?(\k<SEP>?(dd|mm|(yy){0,2}))(( (HH|hh)(:mm(:ss)?)?)|( (mm(:ss)?)))?$/
  // );

  // const ValidTimeFormatOnly = new RegExp(/^((HH|hh(:mm(:ss)?)?)|(mm(:ss)?))$/);

  // const validateYear = (format: string) => {
  //   const { length } = format;
  //   if (length === 3) return false;
  //
  //   if (format[0] === 'y' && format[length - 1] === 'y') {
  //     if (length % 2 !== 0) return false;
  //   }
  //
  //   const yLetterCount = format.split('y').length - 1;
  //   if (yLetterCount === 4) {
  //     if (length === 5) {
  //       return false;
  //     }
  //   }
  //
  //   return true;
  // };

  // const validateDateMonth = (format: string) => {
  //   console.log('good day');
  //   console.log(format);
  //   const dLetterCount = format.split('d').length - 1;
  //   if (format.includes(' ')) {
  //     const dateFormat = format.split(' ')[0];
  //     const mLetterCount = dateFormat.split('m').length - 1;
  //
  //     if (mLetterCount > 2 || dLetterCount > 2) {
  //       return false;
  //     }
  //   } else {
  //     const mLetterCount = format.split('m').length - 1;
  //
  //     if (mLetterCount > 2 || dLetterCount > 2) {
  //       return false;
  //     }
  //   }
  //
  //   return true;
  // };

  // const isValidFormat = () => {
  //   const isInputValid =
  //     ValidDateTimeFormat.test(currentValue) ||
  //     ValidTimeFormatOnly.test(currentValue);
  //   const isDateFomrmatValid =
  //     validateDateMonth(currentValue) && validateYear(currentValue);
  //
  //   return isInputValid && isDateFomrmatValid;
  // };

  // const handleSaveFormat = () => {
  //   onDateTimeFormatChange(currentValue);
  //   setIsFormatSaved(true);
  //   // if (isValidFormat()) {
  //   //   onDateTimeFormatChange(currentValue);
  //   //
  //   //   setIsFormatSaved(true);
  //   // }
  // };

  const handleChange = (newFormat: string) => {
    onDateTimeFormatChange(newFormat);

    // change helper text
    // const newExample = getFormatExample(newFormat);
    // setFormatExample(newExample);
    // console.log(event.target.value);
    // onIsDateTimeFormatValidChange(isValidFormat());
  };

  // const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
  //   // check for if both the time and date are valid
  //   if (event.key === 'Enter') {
  //     handleSaveFormat();
  //   }
  // };

  return (
    <TextFieldWrapper>
      <StyledAutoComplete
        size="small"
        options={Object.entries(DateTimeFormats).map(([, format]) => {
          return format.format;
        })}
        value={value}
        disabled={disabled}
        disableClearable
        renderInput={(params) => (
          <TextField
            {...params}
            // autoFocus={false}
            // onChange={(e) => handleChange(e)}
            // onKeyPress={(e) => handleKeyPress(e)}
            // onKeyDown={(e) => handleKeyPress(e)}
            // value={currentValue}
            variant="outlined"
            // focused={!isFormatSaved}
            // onClick={() => handleFocusOnTextField}
            // error={!isValidFormat()}
            // label={isValidFormat() ? '' : 'Invalid format'}
            disabled={disabled}
            size="small"
            // placeholder="dd/MM/yyyy hh:mm:ss"
          />
        )}
        onChange={(event, value) => {
          const newFormat = value as string;
          handleChange(newFormat);
        }}
        getOptionLabel={(option) => {
          // if (option === '') {
          //   return 'Keep original';
          // }
          return option as string;
        }}
        getOptionSelected={(option) => {
          const optionAsString = option as string;
          return value === optionAsString;
        }}
      />
      <Typography
        variant="body2"
        style={{ margin: '0 2px', justifyContent: 'center' }}
      >
        {getFormatExample(value)}
      </Typography>
    </TextFieldWrapper>
  );
};

export default DateFormatInput;
