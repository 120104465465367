import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Paper, TextField, Typography } from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import Fade from '@material-ui/core/Fade';
import { useHistory } from 'react-router-dom';
import ROUTES, {
  PIPELINEPAGES,
  PIPELINESTATUSROUTES,
} from '../../../../common/routes';
import FullLoadingOverlay from '../../../common/full-loading-overlay';
import useCreatePipelineService from '../service/useCreatePipelineService';
import { fireEvent } from '../../../../utils/useFireEvent';
import useErrorAlert from '../../../../utils/useErrorAlert';
import { trackEvent } from '../../../../utils/useTrackEvent';

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalComponent = styled(Paper)`
  height: 200px;
  width: 400px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const BodyContainer = styled.div`
  flex: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  flex-direction: row-reverse;
  padding-top: 16px;
`;

interface Props {
  isOpen: boolean;
  handleClose: () => void;
}

const PipelineCreateModal: React.FC<Props> = ({ isOpen, handleClose }) => {
  const [createJobService, error] = useCreatePipelineService();
  useErrorAlert(error);
  const history = useHistory();
  const [pipelineName, setPipelineName] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onJobNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPipelineName(event.target.value);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await createJobService({ name: pipelineName });
    if (response) {
      const { id, redirectState } = response;
      fireEvent('pipeline created');
      trackEvent('pipeline created');
      if (
        Object.values(PIPELINEPAGES).includes(redirectState as PIPELINEPAGES)
      ) {
        history.push(
          `${ROUTES.App}${ROUTES.Pipeline}/${id}${PIPELINESTATUSROUTES.Draft}/${redirectState}`
        );
      }
    }
    setIsLoading(false);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      onSubmit();
    }
  };

  const isButtonDisabled = pipelineName.length === 0;

  return (
    <ModalContainer open={isOpen} onClose={handleClose}>
      <Fade in={isOpen}>
        <ModalComponent elevation={3}>
          <Typography variant="h5" style={{ fontWeight: 'bold' }} gutterBottom>
            New Pipeline
          </Typography>
          <BodyContainer>
            <TextField
              label="Pipeline name"
              variant="outlined"
              fullWidth
              value={pipelineName}
              onChange={onJobNameChange}
              onKeyPress={handleKeyPress}
              autoFocus
              required
            />
          </BodyContainer>
          <FooterContainer>
            <Button
              variant="contained"
              color="secondary"
              onClick={onSubmit}
              disabled={isButtonDisabled}
            >
              Create
            </Button>
          </FooterContainer>
          <FullLoadingOverlay isLoading={isLoading} />
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default PipelineCreateModal;
