import React, { useState } from 'react';
import { Button } from '@material-ui/core';
import { useHistory, useParams } from 'react-router-dom';
import { useSchemaStore } from '../store';
import { FieldCollection, PostFields, PostSchemaPage } from '../types';
import FullLoadingOverlay from '../../../common/full-loading-overlay';
import { DataTypes } from '../../../decision-pages/units-page/units-store/types';
import ROUTES, {
  PIPELINEAPIS,
  PIPELINEDASHBOARDPAGES,
} from '../../../../common/routes';
import usePostSimpleService from '../../../../api/usePostSimpleService';
import useResourceRouteWithId from '../../../../utils/useResourceRouteWithId';

import useErrorAlert from '../../../../utils/useErrorAlert';
import { trackEvent } from '../../../../utils/useTrackEvent';

const transformFieldsForRequest = (fields: FieldCollection): PostFields => {
  const sentFields: PostFields = {};

  Object.entries(fields).forEach(([fieldName, fieldData]) => {
    sentFields[fieldName] = {
      dataType: fieldData.dataType,
      optional: fieldData.optional,
      dropMissing: fieldData.dropMissing,
    };

    if (fieldData.dataType === DataTypes.DATETIME && fieldData.dateTimeFormat) {
      sentFields[fieldName].dateTimeFormat = fieldData.dateTimeFormat;
      sentFields[fieldName].unit = fieldData.unitType?.unit;
    }

    if (fieldData.dataType === DataTypes.CATEGORY) {
      sentFields[fieldName].allowedValues = fieldData.allowedValues;
    }
  });

  return sentFields;
};

const SubmitButton: React.FC = () => {
  const [
    {
      mappingRules,
      fields,
      canAddFields,
      combineAdditionalField,
      duplicateDropFields,
      emailValidationFields,
    },
  ] = useSchemaStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const idRoute = useResourceRouteWithId();
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const [submit, error] = usePostSimpleService<PostSchemaPage, unknown>(
    `${idRoute}${PIPELINEAPIS.Schema}`
  );

  useErrorAlert(error);
  const history = useHistory();

  const handleSubmit = async () => {
    setIsLoading(true);
    const reshapedFields = transformFieldsForRequest(fields);
    const data: PostSchemaPage = {
      mappingRules,
      canAddFields,
      combineAdditionalField: canAddFields ? combineAdditionalField : null,
      fields: reshapedFields,
      duplicateDropFields,
      validateEmails: emailValidationFields,
    };
    const response = await submit(data);
    if (response) {
      trackEvent('pipeline published');
      history.push(
        `${ROUTES.App}${ROUTES.Pipeline}/${pipelineId}${PIPELINEDASHBOARDPAGES.Test_Job}`
      );
    }
    setIsLoading(false);
  };

  const isPublishable = canAddFields || Object.entries(fields).length !== 0;

  return (
    <>
      <Button
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={!isPublishable}
      >
        Publish
      </Button>
      <FullLoadingOverlay isLoading={isLoading} />
    </>
  );
};

export default SubmitButton;
