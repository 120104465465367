import React, { useState } from 'react';
import {
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import DeleteIcon from '@material-ui/icons/Delete';
import { format } from 'date-fns';
import EditIcon from '@material-ui/icons/Edit';
import { toast } from 'react-toastify';
import { Share } from '@material-ui/icons';
import CopyIdLozenge from '../../../common/copy-id-lozenge';
import DeleteModal from '../../../common/delete-modal';
import ROUTES from '../../../../common/routes';
import useDeleteSimpleService from '../../../../api/useDeleteSimpleService';
import { useConnectorsStore } from '../../store';
import EditConnectorModal from '../edit-connector-modal';
import ShareModal from '../../../common/share-modal';
import useErrorAlert from '../../../../utils/useErrorAlert';
import { ConnectorType } from '../../../common/add-database-modal/types';
import athenasvg from '../../../../common/images/athena.svg';
import mysqlsvg from '../../../../common/images/aws_rds_mysql.svg';
import firestoresvg from '../../../../common/images/firestore.svg';
import gcssvg from '../../../../common/images/gcs.svg';
import csvsvg from '../../../../common/images/csv.svg';
import s3_2 from '../../../../common/images/s3_2.svg';
import snowflake2 from '../../../../common/images/snowflake2.svg';
import supabasesvg from '../../../../common/images/supabase.svg';

interface Props {
  name: string;
  id: string;
  connectorType: ConnectorType;
  createdTime: string;
}

const MenuTableCell = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: center;
`;

const LogoSvg = styled.img`
  height: 32px;
`;

const selectLogoFromType = (dataSourceType: ConnectorType) => {
  switch (dataSourceType) {
    case ConnectorType.ATHENA:
      return athenasvg;
    case ConnectorType.AWS_RDS_MYSQL:
      return mysqlsvg;
    case ConnectorType.S3:
      return s3_2;
    case ConnectorType.SNOWFLAKE:
      return snowflake2;
    case ConnectorType.FIRESTORE:
      return firestoresvg;
    case ConnectorType.GCS:
      return gcssvg;
    case ConnectorType.SUPABASE_POSTGRESQL:
      return supabasesvg;
    default:
      return csvsvg;
  }
};

const ConnectorsRow: React.FC<Props> = ({
  name,
  id,
  createdTime,
  connectorType,
}) => {
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);
  const [, { deleteConnector }] = useConnectorsStore();

  const DeletingText = `Are you sure you want to delete ${name} as a connector, with ID: ${id}?`;

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setIsMenuOpen(true);
  };

  const [isDeletingConnector, setIsDeletingConnector] = useState<string | null>(
    null
  );
  const [isDeleting, setIsDeleting] = useState<boolean>(false);
  const handleOpenDeleteModal = () => {
    handleMenuClose();
    setIsDeletingConnector(id);
  };
  const handleCloseDeleteModal = () => {
    setIsDeletingConnector(null);
  };

  const [sendDeleteRequest, deleteError] = useDeleteSimpleService();
  useErrorAlert(deleteError);
  const handleDeleteConnector = (id: string) => async () => {
    setIsDeleting(true);
    const response = await sendDeleteRequest(`${ROUTES.Connectors}/${id}`);
    if (response) {
      deleteConnector(id);
      toast.success('Connector deleted successfully');
    }
    handleCloseDeleteModal();
    setIsDeleting(false);
  };

  const [isEditingConnector, setIsEditingConnector] = useState<boolean>(false);
  const handleOpenEditingModal = () => {
    handleMenuClose();
    setIsEditingConnector(true);
  };
  const handleCloseEditingModal = () => {
    setIsEditingConnector(false);
  };

  const [isSharingConnector, setIsSharingConnector] = useState<boolean>(false);
  const handleOpenSharingModal = () => {
    setIsSharingConnector(true);
    handleMenuClose();
  };
  const handleCloseSharingModal = () => {
    setIsSharingConnector(false);
  };

  const datetime = new Date(createdTime);
  const formattedDate = format(datetime, 'd MMM yyyy hh:mma');

  return (
    <>
      <TableRow>
        <TableCell style={{ padding: 0 }}>
          <LogoSvg src={selectLogoFromType(connectorType)} />
        </TableCell>
        <TableCell>
          <Typography variant="subtitle2">{name}</Typography>
        </TableCell>
        <TableCell>
          <CopyIdLozenge resourceId={id} />
        </TableCell>
        <TableCell>{formattedDate}</TableCell>
        <TableCell>
          <MenuTableCell>
            <IconButton onClick={handleMenuClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorEl={anchorEl}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              getContentAnchorEl={null}
              anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem
                onClick={handleOpenDeleteModal}
                dense
                style={{ margin: 0 }}
              >
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={handleOpenEditingModal}
                dense
                style={{ margin: 0 }}
              >
                <ListItemIcon>
                  <EditIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Edit</ListItemText>
              </MenuItem>
              <MenuItem
                onClick={handleOpenSharingModal}
                dense
                style={{ margin: 0 }}
              >
                <ListItemIcon>
                  <Share fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share</ListItemText>
              </MenuItem>
            </Menu>
          </MenuTableCell>
        </TableCell>
      </TableRow>
      {isDeletingConnector && (
        <DeleteModal
          isOpen={id === isDeletingConnector}
          onClose={handleCloseDeleteModal}
          confirmationText={DeletingText}
          handleDelete={handleDeleteConnector(id)}
          isLoading={isDeleting}
        />
      )}
      {isEditingConnector && (
        <EditConnectorModal
          isOpen={isEditingConnector}
          onClose={handleCloseEditingModal}
          id={id}
        />
      )}
      {isSharingConnector && (
        <ShareModal
          isOpen={isSharingConnector}
          onClose={handleCloseSharingModal}
          resourceId={id}
          onSuccess={handleCloseSharingModal}
        />
      )}
    </>
  );
};

export default ConnectorsRow;
