import React from 'react';
import styled from 'styled-components';

const StatusLozenge = styled.div`
  margin: 4px;
  background-color: ${(props: { rgbColor: string }) =>
    `rgba(${props.rgbColor}, 0.8)`};
  color: white;
  font-weight: bold;
  font-size: 0.8125rem;
  padding: 4px 8px;
  border-radius: 4px;
`;

export default StatusLozenge;

interface JobStatusProps {
  status: string;
}

export const JobStatusLozenge: React.FC<JobStatusProps> = ({ status }) => {
  switch (status) {
    case 'Not Started':
      return <StatusLozenge rgbColor="169, 169, 169">In Config</StatusLozenge>;
    case 'In Configuration':
      return <StatusLozenge rgbColor="169, 169, 169">In Config</StatusLozenge>;
    case 'Running':
      return <StatusLozenge rgbColor="0, 200, 0">{status}</StatusLozenge>;
    case 'Done':
      return <StatusLozenge rgbColor="0, 200, 0">Completed</StatusLozenge>;
    case 'misconfigured':
      return <StatusLozenge rgbColor="200, 0, 0">Error</StatusLozenge>;
    case 'Error':
      return <StatusLozenge rgbColor="200, 0, 0">Error</StatusLozenge>;
    default:
      return <StatusLozenge rgbColor="169, 169, 169">{status}</StatusLozenge>;
  }
};
