import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import COLORS from '../../../../../common/colors';
import CopyIdLozenge from '../../../../common/copy-id-lozenge';
import { CircularProgress, Typography } from '@material-ui/core';
import OpenInNewIcon from '@material-ui/icons/OpenInNew';

const Wrapper = styled.div`
  border: 2px solid ${COLORS.LightGrey};
  padding: 8px;
  border-radius: 4px;
  display: grid;
  grid-template-columns: 2fr 5fr;
  grid-column-gap: 4px;
  grid-row-gap: 4px;
  width: 500px;
  align-items: center;
`;

const IdLabelTypography = styled(Typography)`
  font-weight: bold;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  apiKey: string | null;
  pipelineId: string;
}

const DetailsCard: React.FC<Props> = ({ apiKey, pipelineId }) => {
  const [shareUrl, setShareUrl] = useState<string | null>(null);
  useEffect(() => {
    if (apiKey && pipelineId) {
      const encodedAuth = window.btoa(apiKey);
      setShareUrl(
        `https://importer.segna.io?pipelineId=${pipelineId}&auth=${encodedAuth}`
      );
    }
  }, [apiKey, pipelineId]);

  const onOpenInNewClick = () => {
    if (shareUrl) {
      window.open(shareUrl, '_blank');
    }
  };

  return (
    <Wrapper>
      <IdLabelTypography variant="caption">
        Data Importer URL:
        {shareUrl && (
          <OpenInNewIcon
            fontSize="small"
            color="secondary"
            onClick={onOpenInNewClick}
            style={{
              cursor: 'pointer',
            }}
          />
        )}
      </IdLabelTypography>
      {shareUrl ? (
        <CopyIdLozenge resourceId={shareUrl} />
      ) : (
        <CircularProgress size={25} />
      )}
      <IdLabelTypography variant="caption">Pipeline Id:</IdLabelTypography>
      <CopyIdLozenge resourceId={pipelineId} />
    </Wrapper>
  );
};

export default DetailsCard;
