import React from 'react';
import { Divider, Link } from '@material-ui/core';
import styled from 'styled-components';
import { FormType } from '../../login-page';
import LoginForm from '../../login-page/login-form';
import ForgotPasswordForm from '../../login-page/forgot-password-form';

const FormContainer = styled.div`
  display: flex;
  flex-direction: column;
  width: 300px;
`;

interface Props {
  formType: FormType;
  setFormType: (formType: FormType) => void;
}

const AccountForms: React.FC<Props> = ({ formType, setFormType }) => {
  return (
    <FormContainer>
      {formType === FormType.Login && (
        <>
          <LoginForm />
          <Divider variant="middle" style={{ margin: '16px' }} />
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setFormType(FormType.Forgot_Password);
            }}
          >
            Forgot your password?
          </Link>
        </>
      )}
      {formType === FormType.Forgot_Password && (
        <>
          <ForgotPasswordForm onSuccess={() => setFormType(FormType.Login)} />
          <Divider variant="middle" style={{ margin: '16px' }} />
          <Link
            component="button"
            variant="body2"
            onClick={() => {
              setFormType(FormType.Login);
            }}
          >
            Cancel
          </Link>
        </>
      )}
    </FormContainer>
  );
};

export default AccountForms;
