import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Card, CircularProgress, Typography } from '@material-ui/core';
import useErrorAlert from '../../../../utils/useErrorAlert';
import useCreatePortalSessionService from '../service/useCreatePortalSessionService';
import { STATUS } from '../../types';

const CardContainer = styled(Card)`
  padding: 24px;
  margin: 16px 0;
`;

const TitleContainer = styled(Typography)``;

const TextContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

interface Props {
  status: STATUS;
}

const PortalManageCard: React.FC<Props> = ({ status }) => {
  const [
    getPortalService,
    portalServiceError,
  ] = useCreatePortalSessionService();
  useErrorAlert(portalServiceError);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleManageClick = async () => {
    setIsLoading(true);
    const response = await getPortalService();
    if (response) {
      window.location.href = response.url;
    }
    setIsLoading(false);
  };

  return (
    <CardContainer>
      <TitleContainer variant="h5" gutterBottom>
        Manage Billing
      </TitleContainer>
      <TextContainer>
        <Typography>
          To update your plan, payment details, or view previous invoices, press
          the Manage button!
        </Typography>
        <Button
          onClick={handleManageClick}
          variant="contained"
          color="secondary"
        >
          {isLoading ? <CircularProgress size={24} /> : 'Manage'}
        </Button>
      </TextContainer>
    </CardContainer>
  );
};

export default PortalManageCard;
