import React from 'react';
import { Button } from '@material-ui/core';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import styled, { keyframes } from 'styled-components';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../common/routes';

const Fade = keyframes`
  from {
    opacity: 0;
  }
  
  to {
    opacity: 0.7;
  }
`;

const StyledButton = styled(Button)`
  position: absolute;
  animation: ${Fade} 100ms ease-out;
  opacity: 0.7;
`;

// Will return the user back to the app if they've already completed onboarding
const BackToAppButton: React.FC = () => {
  const history = useHistory();

  const handleClick = () => {
    history.push(`${ROUTES.App}${ROUTES.Quick_Start}`);
  };

  return (
    <StyledButton
      onClick={handleClick}
      startIcon={<ArrowBackIcon />}
      size="small"
    >
      Back To App
    </StyledButton>
  );
};

export default BackToAppButton;
