import React, { useState } from 'react';
import styled from 'styled-components';
import {
  ClickAwayListener,
  styled as styledMui,
  Tooltip,
  tooltipClasses,
  TooltipProps,
} from '@mui/material';
import InfoIcon from '@material-ui/icons/Info';

const Wrapper = styled.div`
  position: relative;
`;

const IconWrapper = styled.div<{ isPressed: boolean }>`
  cursor: pointer;
  transition: all 0.1s ease-in-out;
  border-radius: 4px;
  display: flex;
  align-items: center;
  ${(props: { isPressed: boolean }) =>
    props.isPressed && 'background-color: lightgrey;'}
  &:hover {
    background-color: lightgrey;
  }
  padding: 2px;
`;

export const LightTooltip = styledMui(
  ({ className, ...props }: TooltipProps) => (
    <Tooltip {...props} classes={{ popper: className }} />
  )
)(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    fontSize: 12,
    textWrap: 'wrap',
  },
}));

interface Props {
  text: string;
}

const InfoButtonAndTooltip: React.FC<Props> = ({ text }) => {
  const [isDialogOpen, setIsDialogOpen] = useState<boolean>(false);

  const onButtonClick = () => {
    setIsDialogOpen((prev) => !prev);
  };

  const handleClose = () => {
    setIsDialogOpen(false);
  };

  return (
    <Wrapper>
      <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
        <div>
          <LightTooltip title={text} placement="top" open={isDialogOpen}>
            <IconWrapper isPressed={isDialogOpen} onClick={onButtonClick}>
              <InfoIcon
                color={isDialogOpen ? 'disabled' : 'primary'}
                fontSize="small"
              />
            </IconWrapper>
          </LightTooltip>
        </div>
      </ClickAwayListener>
    </Wrapper>
  );
};

export default InfoButtonAndTooltip;
