import React from 'react';
import styled from 'styled-components';
import { Button } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ROUTES from '../../../common/routes';

const CenterDiv = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  height: 100%;
  width: 100%;
`;

const InvalidPage: React.FC = () => {
  const history = useHistory();
  return (
    <CenterDiv>
      Oops - invalid route
      <Button color="secondary" onClick={() => history.push(ROUTES.Login)}>
        Go back to home page
      </Button>
    </CenterDiv>
  );
};

export default InvalidPage;
