import React from 'react';
import { useHistory } from 'react-router-dom';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import ExitToAppIcon from '@material-ui/icons/ExitToApp';
import SettingsIcon from '@material-ui/icons/Settings';
import mixpanel from 'mixpanel-browser';
import { setUserId } from '@amplitude/analytics-browser';
import { ReactComponent as SegnaLogoTealIcon } from '../../common/images/segna-logo-teal-icon.svg';
import COLORS from '../../common/colors';
import firebase from '../../firebase/firebase';
import ROUTES from '../../common/routes';
import BillingBanner from '../common/billing-banner';

export const HEADER_HEIGHT = 56;

const StyledAppBar = styled.header`
  background-color: white;
  color: ${COLORS.Orange};
  height: ${HEADER_HEIGHT}px;
  width: 100%;
  position: fixed;
  display: flex;
  z-index: 12;
  box-shadow: 0 1px 1px 0 rgba(0, 0, 0, 0.14),
    0 2px 1px -1px rgba(0, 0, 0, 0.12), 0 1px 3px 0 rgba(0, 0, 0, 0.2);
`;

const StyledSegnaIcon = styled(SegnaLogoTealIcon)`
  height: 40px;
  width: 40px;
  padding-left: 1rem;
  padding-right: 0.5rem;
`;

const SegnaLogoText = styled(Typography)`
  padding-bottom: 6px;
`;

const ToolbarItemsContainer = styled.div`
  width: 100%;
  display: flex;
  justify-content: space-between;
`;

const LeftItemsContainer = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
`;

const RightItemsContainer = styled.div`
  display: flex;
  align-items: center;
`;

const BillingBannerWrapper = styled.div`
  position: fixed;
  top: ${HEADER_HEIGHT}px;
  width: 100%;
  z-index: 11;
`;

const handleLogOut = () => {
  firebase
    .auth()
    .signOut()
    .then(() => {
      setUserId('');
      mixpanel.reset();
    });
};

interface Props {
  onManualResetErrorBoundary?: () => void;
}

const HeaderMenu: React.FC<Props> = ({ onManualResetErrorBoundary }) => {
  const history = useHistory();

  const handleHomeClick = () => {
    onManualResetErrorBoundary && onManualResetErrorBoundary();
    history.push(`${ROUTES.App}`);
  };

  const handleSettingsClick = () => {
    onManualResetErrorBoundary && onManualResetErrorBoundary();
    history.push(`${ROUTES.App + ROUTES.Settings}`);
  };

  return (
    <>
      <StyledAppBar>
        <ToolbarItemsContainer>
          <LeftItemsContainer onClick={handleHomeClick}>
            <StyledSegnaIcon />
            <SegnaLogoText variant="h4" color="primary">
              segna
            </SegnaLogoText>
          </LeftItemsContainer>
          <RightItemsContainer>
            <Button onClick={handleSettingsClick}>
              <SettingsIcon />
            </Button>
            <Button onClick={handleLogOut}>
              <ExitToAppIcon />
            </Button>
          </RightItemsContainer>
        </ToolbarItemsContainer>
      </StyledAppBar>
      <BillingBannerWrapper>
        <BillingBanner />
      </BillingBannerWrapper>
    </>
  );
};

export default HeaderMenu;
