import React, { useState } from 'react';
import styled from 'styled-components';
import { Divider, Paper, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import COLORS from '../../common/colors';
import { ReactComponent as SegnaWhiteIcon } from '../../common/images/segna-logo-white-icon.svg';
import { FormType } from '../login-page';
import AccountForms from './account-forms';
import ThirdPartyLogins from '../login-page/third-party-logins';
import { useAuthContext } from '../../firebase/AuthProvider';
import ROUTES from '../../common/routes';
import FullLoadingOverlay from '../common/full-loading-overlay';

const BackgroundContainer = styled.div`
  background-color: ${COLORS.DarkBlue};
  height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  padding-bottom: 1rem;
`;

const StyledSegnaIcon = styled(SegnaWhiteIcon)`
  height: 40px;
  width: 40px;
  padding-right: 0.5rem;
`;

const SegnaIconText = styled(Typography)`
  padding-bottom: 6px;
  color: white;
`;

const PaperContainer = styled(Paper)`
  background-color: ${COLORS.Grey};
  border-radius: 4px;
  padding: 32px;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const PaperHeaderSection = styled.header`
  text-align: center;
  margin-bottom: 16px;
`;

const PaperContentSection = styled.div`
  display: flex;
`;

const MiddleDividerWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 32px;
  align-items: center;
  justify-content: center;
`;

const VerticalDividerWrapper = styled.div`
  display: flex;
  flex: 1;
`;

const DividerOrText = styled(Typography)`
  margin: 8px 0;
`;

/**
 * Requested by Aryan, new route to a sign-in only page (no sign up). This
 * sign-in form is the same as the existing one which is left untouched but
 * this will have a new URL route.
 */
const NewSignInPage: React.FC = () => {
  const [formType, setFormType] = useState<FormType>(FormType.Login);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const { isAuthenticated, isLoadingAuthState } = useAuthContext();

  const history = useHistory();

  if (isAuthenticated && !isLoading) {
    history.push(ROUTES.App);
  }

  return (
    <>
      <BackgroundContainer>
        <HeaderContainer>
          <StyledSegnaIcon />
          <SegnaIconText variant="h4">segna</SegnaIconText>
        </HeaderContainer>
        <PaperContainer>
          <PaperHeaderSection>
            <Typography variant="h5" style={{ fontWeight: 'bold' }}>
              {formType === FormType.Login ? 'Login' : 'Forgot your password?'}
            </Typography>
          </PaperHeaderSection>
          <PaperContentSection>
            <AccountForms formType={formType} setFormType={setFormType} />
            <MiddleDividerWrapper>
              <VerticalDividerWrapper>
                <Divider flexItem orientation="vertical" />
              </VerticalDividerWrapper>
              <DividerOrText variant="caption">Or</DividerOrText>
              <VerticalDividerWrapper>
                <Divider flexItem orientation="vertical" />
              </VerticalDividerWrapper>
            </MiddleDividerWrapper>
            <ThirdPartyLogins setIsLoading={setIsLoading} />
          </PaperContentSection>
        </PaperContainer>
      </BackgroundContainer>
      <FullLoadingOverlay isLoading={isLoadingAuthState || isLoading} />
    </>
  );
};

export default NewSignInPage;
