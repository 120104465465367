import { TextField, withStyles } from '@material-ui/core';
import styled from 'styled-components';

const NoMarginTextField = withStyles({
  root: {
    margin: 0,
  },
})(TextField);

export default NoMarginTextField;

export const TextFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;
