import React, { forwardRef, useState } from 'react';
import { Button } from '@material-ui/core';
import styled from 'styled-components';
import { useHistory, useParams } from 'react-router-dom';
import useErrorAlert from '../../../../../utils/useErrorAlert';
import ROUTES, {
  PIPELINEAPIS,
  PIPELINEPAGES,
  PIPELINESTATUSROUTES,
} from '../../../../../common/routes';
import FullLoadingOverlay from '../../../../common/full-loading-overlay';
import usePostSimpleService from '../../../../../api/usePostSimpleService';
import useResourceRouteWithId from '../../../../../utils/useResourceRouteWithId';

const StyledButton = styled(Button)`
  max-width: 200px;
`;

const ButtonContainer = styled.div``;

const SubmitButton = forwardRef<HTMLDivElement>((props, ref) => {
  const { pipelineId } = useParams<{ pipelineId: string }>();
  const idRoute = useResourceRouteWithId();
  const history = useHistory();

  const [submit, error] = usePostSimpleService<
    Record<string, never>,
    Record<string, never>
  >(`${idRoute}${PIPELINEAPIS.Data_Sources}`);
  useErrorAlert(error);

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await submit({});
    if (response) {
      history.push(
        `${ROUTES.App}${ROUTES.Pipeline}/${pipelineId}${PIPELINESTATUSROUTES.Draft}/${PIPELINEPAGES.Schema}`
      );
    }
    setIsLoading(false);
  };

  return (
    <ButtonContainer ref={ref}>
      <span>
        <StyledButton
          variant="contained"
          color="secondary"
          onClick={handleSubmit}
        >
          Next
        </StyledButton>
      </span>
      <FullLoadingOverlay isLoading={isLoading} />
    </ButtonContainer>
  );
});

export default SubmitButton;
