import React, { useEffect } from 'react';
import { ThemeProvider, StylesProvider } from '@material-ui/core/styles';
import mixpanel from 'mixpanel-browser';
import { init } from '@amplitude/analytics-browser';
import PageRouter from './components/page-router';
import mainTheme from './themes';
import { trackEvent } from './utils/useTrackEvent';

mixpanel.init('4bd14042c329d5e0985f50cc76f67f64');

if (process.env.REACT_APP_ENV === 'production') {
  init('ce34da65d425e23ff30cbbb8c170dd6e');
} else {
  init('f16981d36832c2b326025a1c0dba581b');
}

// nice
const App: React.FC = () => {
  useEffect(() => {
    trackEvent('app loaded');
  }, []);

  return (
    <StylesProvider injectFirst>
      <ThemeProvider theme={mainTheme}>
        <PageRouter />
      </ThemeProvider>
    </StylesProvider>
  );
};

export default App;
