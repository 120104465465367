import React from 'react';
import styled from 'styled-components';
import GoogleSignInButton from './google-signin-button';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

interface Props {
  setIsLoading: (isLoading: boolean) => void;
}

const ThirdPartyLogins: React.FC<Props> = ({ setIsLoading }) => {
  return (
    <Wrapper>
      <GoogleSignInButton setIsLoading={setIsLoading} />
    </Wrapper>
  );
};

export default ThirdPartyLogins;
