import { useCallback, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { useAuthContext } from '../../../../../firebase/AuthProvider';
import { BASE_URL } from '../../../../../common/constants';
import { PAGEAPIROUTES } from '../../../../../common/routes';
import useResourceRouteWithId from '../../../../../utils/useResourceRouteWithId';

const timer = (ms: number) => new Promise((res) => setTimeout(res, ms));

const uploadFileService = (
  fileList: File[],
  isSample: boolean,
  idToken: string | null | undefined,
  fullRoute: string,
  setError: (error: Error) => void,
  setProgress: (progressValue: number) => void
): Promise<void | Response> => {
  const formData = new FormData();
  fileList.forEach((file) => {
    formData.append('files', file);
  });
  formData.append('isSample', JSON.stringify(isSample));
  return axios({
    url: `${BASE_URL}${fullRoute}`,
    method: 'POST',
    headers: {
      Authorization: `${idToken}`,
    },
    data: formData,
    onUploadProgress: async (p) => {
      const progressValue = (p.loaded / p.total) * 100;
      if (progressValue === 100) {
        for (let i = 1; i <= 5; i += 1) {
          // eslint-disable-next-line no-await-in-loop
          await timer(Math.random() * (3000 - 1000) + 1000); // Random intervals between 1-3 seconds, and 5 increments
          setProgress(90 + i * (10 / 5));
        }
      } else {
        setProgress(Math.max(1, progressValue - 10));
      }
    },
  })
    .then((response: AxiosResponse<Response>) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        setError(error.response.data.errorMessage);
      } else {
        setError(new Error(error));
      }
    });
};

type UploadResponse = {
  dataResources: {
    [dataResourceId: string]: {
      dataResourceName: string;
      dataResourceType: string;
    };
  };
};

const useUploadFileService = (): [
  (fileList: File[], isSample: boolean) => Promise<UploadResponse>,
  Error | null,
  () => void,
  number
] => {
  const idRoute = useResourceRouteWithId();
  const { user } = useAuthContext();
  const [error, setError] = useState<Error | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const fullRoute = idRoute + PAGEAPIROUTES.Sample_Data_Resource;

  const [progress, setProgress] = useState<number>(0);

  return [
    useCallback(
      async (fileList: File[], isSample = false) => {
        setProgress(0);
        clearError();
        const idToken = user && (await user.getIdToken(true));
        return uploadFileService(
          fileList,
          isSample,
          idToken,
          fullRoute,
          setError,
          setProgress
        ).then((responseData) => (responseData as unknown) as UploadResponse);
      },
      [clearError, fullRoute, user]
    ),
    error,
    clearError,
    progress,
  ];
};

export default useUploadFileService;
