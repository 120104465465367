import { SETTINGSAPI } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

type Request = Record<string, never>;

type Response = {
  apiKey: string;
};

const useRefreshApiKeyService = (): [
  (body: Request) => Promise<Response | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<Request, Response>(SETTINGSAPI.Api_Key);
};

export default useRefreshApiKeyService;
