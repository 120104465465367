import React, { useState } from 'react';
import styled from 'styled-components';
import { Link, Tab, Tabs, Typography, withStyles } from '@material-ui/core';
import { Link as RouterLink } from 'react-router-dom';
import COLORS from '../../../common/colors';
import ROUTES, { EXTERNAL } from '../../../common/routes';

const Wrapper = styled.div`
  margin-top: 24px;
  border: 1px solid ${COLORS.Grey};
  border-radius: 4px;
  padding: 8px;
`;

const CodeContainer = styled.div`
  font-family: Inconsolata, Monaco, sans-serif;
  text-align: center;
  margin: 8px 0;
  padding: 12px;
  border-radius: 4px;
  background-color: ${COLORS.LightGrey};
`;

const TabPanelWrapper = styled.div`
  min-height: 100px;
  background-color: white;
  border-bottom-left-radius: 4px;
  border-bottom-right-radius: 4px;
  padding: 8px;
`;

const StyledRouterLink = styled(RouterLink)`
  text-decoration: none;
  font-weight: bold;
  color: ${COLORS.Orange};
  &: hover {
    text-decoration: underline;
  }
`;

const SmallerTab = withStyles({
  root: {
    minWidth: '128px',
  },
})(Tab);

const ReferDocsText = styled(Typography)`
  padding: 12px 0;
`;

interface TabPanelProps {
  isVisible: boolean;
  code: string;
}

const TabPanel: React.FC<TabPanelProps> = ({ isVisible, code }) => {
  return (
    <TabPanelWrapper hidden={!isVisible}>
      {isVisible && (
        <>
          <CodeContainer>{code}</CodeContainer>
          <ReferDocsText align="center">
            Please refer to our{' '}
            <Link
              href={EXTERNAL.Docs}
              color="secondary"
              target="_blank"
              style={{ fontWeight: 'bold' }}
            >
              documentation
            </Link>{' '}
            for more information, or check out the{' '}
            <StyledRouterLink to={`${ROUTES.App}${ROUTES.Onboarding_Wizard}`}>
              Onboarding Tour
            </StyledRouterLink>{' '}
            to see how you can integrate Segna!
          </ReferDocsText>
        </>
      )}
    </TabPanelWrapper>
  );
};

const JAVASCRIPT_CODE = 'yarn add @segna/segna-sdk';
const API_CODE =
  'curl -H "x-api-key: <YOUR_API_KEY>" https://backend.segna.io/public/v1/<API>';

const CodeUsageTabs: React.FC = () => {
  const [value, setValue] = useState<number>(0);

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: number
  ) => {
    setValue(newValue);
  };

  return (
    <Wrapper>
      <Tabs value={value} onChange={handleChange}>
        <SmallerTab label="JavaScript" />
        <SmallerTab label="API" />
      </Tabs>
      <TabPanel isVisible={value === 0} code={JAVASCRIPT_CODE} />
      <TabPanel isVisible={value === 1} code={API_CODE} />
    </Wrapper>
  );
};

export default CodeUsageTabs;
