import { useParams } from 'react-router-dom';
import ROUTES, { PIPELINEAPIS } from '../../common/routes';

const useResourceRouteWithId = (): string => {
  const { jobId, pipelineId } = useParams<{
    jobId?: string;
    pipelineId?: string;
  }>();

  return jobId
    ? `${ROUTES.Job}/${jobId}`
    : `${ROUTES.Pipeline}${PIPELINEAPIS.Importing_Pipeline_Builder}/${pipelineId}`;
};

export default useResourceRouteWithId;
