import { useCallback, useState } from 'react';
import { BASE_URL } from '../../common/constants';
import { useAuthContext } from '../../firebase/AuthProvider';

const postSimpleService = <BodyData, ResponseData>(
  body: BodyData,
  idToken: string | null | undefined,
  route: string,
  setError: (error: Error) => void
): Promise<ResponseData | void> => {
  return fetch(`${BASE_URL}${route}`, {
    method: 'POST',
    headers: {
      Authorization: `${idToken}`,
      'Content-Type': 'application/json',
    },
    body: JSON.stringify(body),
  })
    .then((response) => {
      return response.json().then((responseData) => {
        if (response.ok) {
          return responseData;
        }
        setError(new Error(responseData.errorMessage));
        return undefined;
      });
    })
    .catch((error) => {
      setError(new Error(error));
    });
};

/* eslint-disable */

const usePostSimpleService = <BodyData, ResponseData>(
  route: string
): [((body: BodyData) => Promise<ResponseData | void>), Error | null, () => void] => {
  const { user } = useAuthContext();
  const [error, setError] = useState<Error | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  return [useCallback(
    async (body: BodyData) => {
      clearError();
      const idToken = user && (await user.getIdToken(true));
      return postSimpleService<BodyData, ResponseData>(body, idToken, route, setError).then(
        (responseData) => responseData
      );
    },
    [user, route]
  ), error, clearError]
};

// export const usePostWithJobIdService = <BodyData, ResponseData>(
//   route: string
// ): [((body: BodyData) => Promise<ResponseData>), Error | null, () => void] => {
//   const { idToken } = useAuthContext();
//   const { jobId } = useParams<{ jobId: string }>();
//   const [error, setError] = useState<Error | null>(null);
//
//   const clearError = useCallback(() => {
//     setError(null);
//   }, []);
//
//   return [useCallback(
//     (body: BodyData) => {
//       clearError();
//       return postSimpleService<BodyData, ResponseData>(body, idToken, `/${jobId}${route}`, setError).then(
//         (responseData) => responseData as ResponseData
//       )},
//     [idToken, jobId, route]
//   ), error, clearError];
// };

/* eslint-enable */

export default usePostSimpleService;
