import React, { useState } from 'react';
import { IconButton, TextField, TextFieldProps } from '@mui/material';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';

interface CustomProps {
  onSubmitSuccess?: (value: string) => void;
  onSubmitFailure?: (value: string) => void;
}

type Props = CustomProps & TextFieldProps;

const CategoryValueInput: React.FC<Props> = ({
  onSubmitFailure,
  onSubmitSuccess,
  ...textFieldProps
}) => {
  const [value, setValue] = useState<string>('');

  const clearValue = () => setValue('');

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setValue(event.target.value);
  };

  const handleSubmit = () => {
    const trimmed = value.trim();
    if (!trimmed) {
      onSubmitFailure && onSubmitFailure(value);
      return;
    }

    onSubmitSuccess && onSubmitSuccess(trimmed);
    clearValue();
  };

  const handleKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    if (event.key === 'Enter') {
      event.preventDefault();
      handleSubmit();
    }
  };

  return (
    <TextField
      {...textFieldProps} // All props below are not overridable
      value={value}
      onChange={handleChange}
      onKeyDown={handleKeyDown}
      InputProps={{
        endAdornment: (
          <IconButton size="small" onClick={handleSubmit}>
            <KeyboardReturnIcon />
          </IconButton>
        ),
      }}
    />
  );
};

export default CategoryValueInput;
