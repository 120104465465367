import React, { useRef } from 'react';
import { Divider, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import OutputSchema from './output-schema';
import { SchemaContainer, useSchemaStore } from './store';
import SubmitButton from './submit-button';
import { GetSchemaPage, OutputSchemaRefTypes } from './types';
import LoadingScreenCentered from '../../common/loading-screen-centered';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import ROUTES, { PIPELINEAPIS, PIPELINEPAGES } from '../../../common/routes';
import PipelineNameHeader from '../pipeline-name-header';
import useSpotlightWithLocalStorage from '../../spotlight-tour/useSpotlightWithLocalStorage';
import { Spotlight } from '../../spotlight-tour/types';

const PageContainer = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const PageHeader = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 8px;
`;

const OUTPUT_SCHEMA_CARD_SPOTLIGHT_TEXT =
  'Specify your desired output schema here. You can not change the schema if your output destination has a fixed schema.';

const SCHEMA_OPTIONS_CARD_SPOTLIGHT_TEXT =
  'You can also specify various options, such as duplicate dropping, that will run automatically on uploads';

export const SchemaPageInner: React.FC = () => {
  const [{ pipelineName, isEditing }] = useSchemaStore();

  // Output schema spotlight components
  const schemaCardRef = useRef<HTMLDivElement>(null);
  const schemaOptionsRef = useRef<HTMLDivElement>(null);
  const outputSchemaRef: React.MutableRefObject<OutputSchemaRefTypes> = useRef({
    schemaCardRef,
    schemaOptionsRef,
  });

  const spotlightData: Spotlight[] = [
    {
      el: schemaCardRef,
      placement: 'above',
      text: OUTPUT_SCHEMA_CARD_SPOTLIGHT_TEXT,
    },
    {
      el: schemaOptionsRef,
      placement: 'above',
      text: SCHEMA_OPTIONS_CARD_SPOTLIGHT_TEXT,
    },
  ];

  useSpotlightWithLocalStorage(spotlightData, PIPELINEPAGES.Schema);

  return (
    <>
      <PipelineNameHeader pipelineName={pipelineName} isEditing={isEditing} />
      <PageHeader>
        <Typography variant="h5">Output Schema</Typography>
        <SubmitButton />
      </PageHeader>
      <Divider />
      <OutputSchema ref={outputSchemaRef} />
    </>
  );
};

const SchemaPage: React.FC = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const [data, isLoading] = useFetchSimpleService<GetSchemaPage>(
    `${ROUTES.Pipeline}${PIPELINEAPIS.Importing_Pipeline_Builder}/${pipelineId}/${PIPELINEPAGES.Schema}`
  );

  return (
    <>
      {isLoading ? (
        <LoadingScreenCentered messageText="Loading..." />
      ) : (
        data && (
          <SchemaContainer {...data}>
            <PageContainer>
              <SchemaPageInner />
            </PageContainer>
          </SchemaContainer>
        )
      )}
    </>
  );
};

export default SchemaPage;
