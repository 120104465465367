import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';
import { ContainerProps, StoreState } from './types';

const initialState: StoreState = {
  scriptId: '',
  name: '',
  script: '',
  dataTable: [],
  testOutput: 'Please test your script first!',
  isOutputLoading: false,
  isTestSuccessful: false,
  description: '',
  isChanged: false,
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setInitialData: () => ({ setState }: StoreApi, data: ContainerProps) => {
    setState(data);
  },
  setScript: (script: string) => ({ setState, getState }: StoreApi) => {
    const { isChanged } = getState();
    if (isChanged) {
      setState({ script });
    } else {
      setState({ script, isChanged: true });
    }
  },
  setOutput: (testOutput: string[][] | string) => ({ setState }: StoreApi) => {
    setState({ testOutput });
  },
  setOutputLoading: (isOutputLoading: boolean) => ({ setState }: StoreApi) => {
    setState({ isOutputLoading });
  },
  setIsTestSuccessful: (isTestSuccessful: boolean) => ({
    setState,
  }: StoreApi) => {
    setState({ isTestSuccessful });
  },
  setDataTable: (dataTable: string[][]) => ({ setState }: StoreApi) => {
    setState({ dataTable });
  },
  setScriptName: (name: string) => ({ setState, getState }: StoreApi) => {
    const { isChanged } = getState();
    if (isChanged) {
      setState({ name });
    } else {
      setState({ name, isChanged: true });
    }
  },
  setScriptId: (scriptId: string) => ({ setState }: StoreApi) => {
    setState({ scriptId });
  },
  setScriptDescription: (description: string) => ({
    setState,
    getState,
  }: StoreApi) => {
    const { isChanged } = getState();
    if (isChanged) {
      setState({ description });
    } else {
      setState({ description, isChanged: true });
    }
  },
  clearAllTables: () => ({ setState }: StoreApi) => {
    setState({ dataTable: [], testOutput: [] });
  },
  setIsChanged: (isChanged: boolean) => ({ setState }: StoreApi) => {
    setState({ isChanged });
  },
};
type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

const getCurrentInputData = (state: StoreState) => {
  return state.dataTable;
};

const getCurrentOutputData = (state: StoreState) => {
  return state.testOutput;
};

const getDescription = (state: StoreState) => {
  return state.description;
};

const getIsChanged = (state: StoreState) => {
  return state.isChanged;
};

const getIsTestSuccessful = (state: StoreState) => {
  return state.isTestSuccessful;
};

export const useScriptsStore = createHook(Store);
export const useInputData = createHook(Store, {
  selector: getCurrentInputData,
});
export const useTestOutput = createHook(Store, {
  selector: getCurrentOutputData,
});
export const useScriptDescription = createHook(Store, {
  selector: getDescription,
});
export const useActions = createHook(Store, {
  selector: null,
});
export const useIsChanged = createHook(Store, {
  selector: getIsChanged,
});
export const useIsTestSuccessful = createHook(Store, {
  selector: getIsTestSuccessful,
});

export const ScriptsStoreContainer = createContainer(Store, {
  onInit: actions.setInitialData,
  onUpdate: actions.setInitialData,
});
