import React from 'react';
import styled from 'styled-components';
import { Chip, TableCell, TableRow } from '@material-ui/core';
import { JobStatusLozenge } from '../../../../../common/status-lozenge';
import COLORS from '../../../../../../common/colors';
import convertApiDateStringToDate from '../../../../../../utils/convertApiDateStringToDate';
import { Job } from '../../../../../job-pages/dashboard-page/types';
import CopyIdLozenge from '../../../../../common/copy-id-lozenge';

interface Props {
  jobId: string;
  job: Job;
}

const StyledTableCell = styled(TableCell)`
  padding: 8px 16px;
  height: 48px;
`;

const HoverRow = styled(TableRow)`
  // Keep hover UI out until we add navigational functionality
  // transition: background-color 0.2s ease-in-out;
  // cursor: pointer;
  // :hover {
    //   background-color: ${COLORS.Grey};
  // }
  border-radius: 4px;
`;

const NameCell = styled(StyledTableCell)`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
  font-size: 1.2rem;
  width: 25%;
`;

const RunDateCell = styled(StyledTableCell)`
  width: 20%;
`;

const JobStatusCell = styled(StyledTableCell)`
  width: 10%;
`;

const DataSourceCell = styled(StyledTableCell)`
  width: 35%;
`;

const DateText = styled.div`
  display: flex;
  flex-direction: column;
`;

const DataSourceChip = styled(Chip)`
  max-width: 200px;
`;

const getDateText = (runDate: string | null) => {
  const date = convertApiDateStringToDate(runDate);
  if (date) {
    return (
      <DateText>
        <span>
          {`${date.toDateString()} ${date.toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h12',
          })}`}
        </span>
      </DateText>
    );
  }
  return null;
};

const JobListRow: React.FC<Props> = ({ jobId, job }) => {
  return (
    <HoverRow key={jobId}>
      <NameCell>{job.name}</NameCell>
      <RunDateCell>
        <CopyIdLozenge resourceId={jobId} />
      </RunDateCell>
      <RunDateCell>{getDateText(job.runDate)}</RunDateCell>
      <JobStatusCell>
        <div style={{ display: 'flex' }}>
          <JobStatusLozenge status={job.status} />
        </div>
      </JobStatusCell>
      <DataSourceCell>
        {job.dataSourceNames.map((name) => (
          <DataSourceChip
            key={name}
            label={name}
            color="secondary"
            size="small"
          />
        ))}
      </DataSourceCell>
    </HoverRow>
  );
};

export default JobListRow;
