import { track } from '@amplitude/analytics-browser';
import React, { useEffect } from 'react';

export const trackEvent = (
  eventName: string,
  eventProperties?: { [key: string]: string }
): void => {
  track(eventName, eventProperties);
};

export const TrackPageVisited: React.FC<{ pageName: string }> = ({
  pageName,
}) => {
  useEffect(() => {
    trackEvent('page visited', { page_name: pageName });
  }, [pageName]);

  return null;
};

export const trackButtonClicked = (pageName: string, buttonName: string) => {
  trackEvent('button clicked', {
    page_name: pageName,
    button_name: buttonName,
  });
};
