import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
} from '@material-ui/core';
import styled from 'styled-components';
import COLORS from '../../../common/colors';

interface Props {
  headerColumns?: string[];
  bodyData: string[][];
}

const StyledCell = styled(TableCell)`
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  max-width: 200px;
  min-width: 100px;

  &:not(:last-child) {
    border-right: 1px solid #d3d3d3;
  }
`;

const StyledHeadCell = styled(StyledCell)`
  font-weight: bold;
  font-size: 1rem;
  background-color: ${COLORS.Primary};
  color: white;
  padding-top: 8px;
  padding-bottom: 8px;

  &:not(:last-child) {
    border-right: none;
  }
`;

const StyledRow = styled(TableRow)`
  &:nth-of-type(odd) {
    background-color: ${COLORS.LightGrey};
  }
`;

const DataSnippet: React.FC<Props> = ({ bodyData, headerColumns }) => {
  return (
    <TableContainer
      variant="outlined"
      component={Paper}
      style={{
        maxHeight: 500,
      }}
    >
      <Table stickyHeader size="small">
        {headerColumns && (
          <TableHead>
            <TableRow>
              {headerColumns.map((colName) => (
                <StyledHeadCell>{colName}</StyledHeadCell>
              ))}
            </TableRow>
          </TableHead>
        )}

        <TableBody>
          {bodyData.map((row) => (
            <StyledRow>
              {row.map((col) => (
                <StyledCell>{col || <>&nbsp;</>}</StyledCell>
              ))}
            </StyledRow>
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DataSnippet;
