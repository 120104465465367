import { useCallback, useEffect, useRef, useState } from 'react';
import { ONBOARDINGAPI } from '../../../../../common/routes';
import { useOnboardingWizardStore } from '../../../store';
import { BASE_URL } from '../../../../../common/constants';
import { useAuthContext } from '../../../../../firebase/AuthProvider';

const delayBeforeNextRequest = (delayMs: number) =>
  new Promise((resolve) => setTimeout(resolve, delayMs));

const useJobRunDataPollingService = () => {
  const [
    { hasRunJob },
    { setHasRunJob, setDataTable, setIsDataTableLoading },
  ] = useOnboardingWizardStore();

  const { user } = useAuthContext();

  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const [error, setError] = useState<Error | null>(null);

  const isCancelled = useRef(false);

  const pollData = useCallback(async () => {
    if (!isCancelled.current) {
      try {
        const idToken = user && (await user.getIdToken(true));
        const response = await fetch(BASE_URL + ONBOARDINGAPI.Job_Status, {
          method: 'GET',
          headers: {
            Authorization: `${idToken}`,
          },
        });
        const responseData = await response.json();
        if (response.ok) {
          if (responseData.jobStatus === 'done') {
            setHasRunJob(true);

            // Fetch Job Data Table early
            try {
              setIsDataTableLoading(true);
              const jobDataResponse = await fetch(
                BASE_URL + ONBOARDINGAPI.Job_Data,
                {
                  method: 'GET',
                  headers: {
                    Authorization: `${idToken}`,
                  },
                }
              );
              const jobDataResponseData = await jobDataResponse.json();
              if (jobDataResponse.ok) {
                setDataTable(jobDataResponseData.dataTable);
                setIsDataTableLoading(false);
              } else {
                setError(new Error(jobDataResponseData.errorMessage));
                setIsDataTableLoading(false);
              }
            } catch (e) {
              setError(e);
              setIsDataTableLoading(false);
            }
          } else {
            await delayBeforeNextRequest(5000);
            pollData();
          }
        } else {
          setError(new Error(responseData.errorMessage));
          await delayBeforeNextRequest(5000);
          pollData();
        }
      } catch (e) {
        setError(e);
      }
    }
  }, [setDataTable, setHasRunJob, setIsDataTableLoading, user]);

  useEffect(() => {
    if (!hasRunJob) {
      pollData();
    }
    return () => {
      isCancelled.current = true;
    };
  }, [hasRunJob, pollData]);
};

export default useJobRunDataPollingService;
