import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import { Button, IconButton, Typography } from '@material-ui/core';
import { Close } from '@material-ui/icons';
import DatabaseTile from './database-tile';
import {
  AthenaConnector,
  AwsRdsMysqlConnector,
  GCSConnector,
  S3Connector,
  SnowflakeConnector,
  SupabasePostgresqlConnector,
  FirestoreConnector,
} from './connector-types';
import { ConnectorComponentProps } from './connector-types/types';
import { ConnectorType } from '../types';
import ConnectorForm from './connector-form';

const DETAILS_TEXT = 'Select the type of database you wish to connect with';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
  overflow: hidden;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DatabaseTilesGrid = styled.div`
  flex: 1;
  margin: 8px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-row-gap: 8px;
  grid-column-gap: 8px;
  overflow: auto;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonGrouping = styled.div`
  display: flex;
  & > * {
    margin-right: 8px;
  }
`;

type ConnectorTypeInfo = {
  type: ConnectorType;
  name: string;
  connectorComponent: FunctionComponent<ConnectorComponentProps>;
};

export const DATABASE_CONNECTORS: Record<string, any> = {
  [ConnectorType.ATHENA]: {
    connectorComponent: AthenaConnector,
    name: 'Athena',
  },
  [ConnectorType.FIRESTORE]: {
    connectorComponent: FirestoreConnector,
    name: 'Firestore',
  },
  [ConnectorType.S3]: {
    connectorComponent: S3Connector,
    name: 'S3',
  },
  [ConnectorType.SNOWFLAKE]: {
    connectorComponent: SnowflakeConnector,
    name: 'Snowflake',
  },
  [ConnectorType.AWS_RDS_MYSQL]: {
    connectorComponent: AwsRdsMysqlConnector,
    name: 'AWS RDS MySQL',
  },
  [ConnectorType.GCS]: {
    connectorComponent: GCSConnector,
    name: 'Google Cloud Storage',
  },
  [ConnectorType.SUPABASE_POSTGRESQL]: {
    connectorComponent: SupabasePostgresqlConnector,
    name: 'Supabase PostgreSQL',
  },
};

export const EDITING_VALUE = '*****************';

interface Props {
  onBack: () => void;
  onClose: () => void;
  onAddDatabaseConnector: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType,
    createdTime: string
  ) => void;
  onSuccess: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType
  ) => void;
}

const NewDatabaseConnectorModal: React.FC<Props> = ({
  onBack,
  onClose,
  onSuccess,
  onAddDatabaseConnector,
}) => {
  const [
    currentConnectorType,
    setCurrentConnectorType,
  ] = useState<ConnectorTypeInfo | null>(null);

  const onExitConnector = () => {
    setCurrentConnectorType(null);
  };

  return (
    <Wrapper>
      {currentConnectorType === null ? (
        <>
          <HeaderContainer>
            <Typography variant="h5" gutterBottom>
              Add New Database Connector
            </Typography>
            <div>
              <IconButton onClick={onClose} size="small">
                <Close fontSize="small" />
              </IconButton>
            </div>
          </HeaderContainer>
          {DETAILS_TEXT}
          <DatabaseTilesGrid>
            {Object.entries(DATABASE_CONNECTORS).map(([type, connector]) => {
              const connectorTypeInfo: ConnectorTypeInfo = {
                type: type as ConnectorType,
                name: connector.name,
                connectorComponent: connector.connectorComponent,
              };
              return (
                <DatabaseTile
                  name={connector.name}
                  onClick={() => setCurrentConnectorType(connectorTypeInfo)}
                  type={type as ConnectorType}
                />
              );
            })}
          </DatabaseTilesGrid>
          <ButtonFooter>
            <ButtonGrouping>
              <Button onClick={onBack} variant="outlined">
                Back
              </Button>
            </ButtonGrouping>
          </ButtonFooter>
        </>
      ) : (
        <ConnectorForm
          currentConnectorType={currentConnectorType}
          onClose={onClose}
          onSuccess={onSuccess}
          onAddDatabaseConnector={onAddDatabaseConnector}
          onExitConnector={onExitConnector}
        />
      )}
    </Wrapper>
  );
};

export default NewDatabaseConnectorModal;
