import firebase from 'firebase/app';
import 'firebase/auth';
import 'firebase/analytics';
import {
  productionFirebaseConfig,
  canaryFirebaseConfig,
  stagingFirebaseConfig,
} from './firebaseConfig';

if (process.env.REACT_APP_ENV === 'production') {
  firebase.initializeApp(productionFirebaseConfig);
} else if (process.env.REACT_APP_ENV === 'canary') {
  firebase.initializeApp(canaryFirebaseConfig);
} else {
  firebase.initializeApp(stagingFirebaseConfig);
}

const analytics = firebase.analytics();
analytics.logEvent('app_initialized');

export default firebase;
