import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, CircularProgress, Typography } from '@material-ui/core';
import { setUserId } from '@amplitude/analytics-browser';
import { toast } from 'react-toastify';
import firebase from '../../../firebase/firebase';
import useCreateNewUserService from '../service/useCreateNewUserService';
import FullLoadingOverlay from '../../common/full-loading-overlay';
import { fireEvent } from '../../../utils/useFireEvent';
import { trackEvent } from '../../../utils/useTrackEvent';
import useErrorAlert from '../../../utils/useErrorAlert';
import NoMarginTextField, {
  TextFieldWrapper,
} from '../../common/no-margin-textfield';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const RegisterForm: React.FC = () => {
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [isFullPageLoading, setIsFullPageLoading] = useState<boolean>(false);
  const [emailState, setEmailState] = useState<string>('');
  const [passwordState, setPasswordState] = useState<string>('');
  const [displayNameState, setDisplayNameState] = useState<string>('');
  const [createUserService, error] = useCreateNewUserService();
  useErrorAlert(error);

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailState(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState(event.target.value);
  };

  const onDisplayNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setDisplayNameState(event.target.value);
  };

  const onSubmit = async () => {
    setIsLoading(true);
    const response = await createUserService({
      email: emailState,
      password: passwordState,
      displayName: displayNameState,
    });
    if (response) {
      setIsFullPageLoading(true);
      fireEvent('signup finished');
      firebase
        .auth()
        .signInWithEmailAndPassword(emailState, passwordState)
        .then(
          (userCredentials) => {
            userCredentials.user?.uid && setUserId(userCredentials.user.uid);
            trackEvent('signup completed');
          },
          (firebaseError) => {
            // setErrorText(firebaseError.message);
            toast.error(firebaseError.message);
            setIsFullPageLoading(false);
            trackEvent('signup failed');
          }
        );
    }
    setIsLoading(false);
  };

  return (
    <Wrapper>
      <Form onSubmit={onSubmit}>
        <TextFieldWrapper>
          <Typography variant="caption">Email*</Typography>
          <NoMarginTextField
            variant="outlined"
            margin="dense"
            onChange={onEmailChange}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <Typography variant="caption">Password*</Typography>
          <NoMarginTextField
            variant="outlined"
            margin="dense"
            type="password"
            onChange={onPasswordChange}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <Typography variant="caption">Display Name*</Typography>
          <NoMarginTextField
            variant="outlined"
            margin="dense"
            onChange={onDisplayNameChange}
          />
        </TextFieldWrapper>
      </Form>
      <Button
        onClick={onSubmit}
        color="secondary"
        variant="contained"
        disabled={
          emailState.length === 0 ||
          passwordState.length === 0 ||
          displayNameState.length === 0
        }
      >
        {isLoading ? (
          <CircularProgress color="inherit" size={20} />
        ) : (
          'Create new account'
        )}
      </Button>
      <FullLoadingOverlay isLoading={isFullPageLoading} />
    </Wrapper>
  );
};

export default RegisterForm;
