import ROUTES, { PIPELINEAPIS } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

interface CreateJobRequest {
  name: string;
}

interface CreateJobResponse {
  id: string;
  redirectState: string;
}

const useCreatePipelineService = (): [
  (body: CreateJobRequest) => Promise<CreateJobResponse | void>,
  Error | null,
  () => void
] =>
  usePostSimpleService<CreateJobRequest, CreateJobResponse>(
    ROUTES.Pipeline + PIPELINEAPIS.Importing_Pipeline_Builder + PIPELINEAPIS.Create_Pipeline
  );

export default useCreatePipelineService;
