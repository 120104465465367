import usePostSimpleService from '../../../../../api/usePostSimpleService';
import ROUTES, { SCRIPTSAPIS } from '../../../../../common/routes';

interface SaveScriptRequest {
  script: string;
  name: string;
  description: string;
}

interface SaveScriptResponse {
  scriptId: string;
}

const UseSaveScriptService = (
  id: string
): [
  (body: SaveScriptRequest) => Promise<SaveScriptResponse | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<SaveScriptRequest, SaveScriptResponse>(
    `${ROUTES.Scripts}/${id}${SCRIPTSAPIS.Save_Script}`
  );
};

export default UseSaveScriptService;
