import styled from 'styled-components';
import React, { useState } from 'react';
import { useHistory } from 'react-router-dom';
import {
  Card,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  Typography,
} from '@material-ui/core';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import PeopleIcon from '@material-ui/icons/People';
import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import ROUTES, {
  PIPELINEDASHBOARDPAGES,
  PIPELINESTATUSROUTES,
} from '../../../../common/routes';
import { Pipeline } from '../types';
import ShareModal from '../../../common/share-modal';
import convertApiDateStringToDate from '../../../../utils/convertApiDateStringToDate';
import StatusLozenge from '../../../common/status-lozenge';
import DeleteModal from '../../../common/delete-modal';
import useDeleteSimpleService from '../../../../api/useDeleteSimpleService';
import { usePipelineDashboardStore } from '../store';
import useErrorAlert from '../../../../utils/useErrorAlert';
import { useAuthContext } from '../../../../firebase/AuthProvider';
import CopyIdLozenge from '../../../common/copy-id-lozenge';
import COLORS from '../../../../common/colors';

const StyledCard = styled(Card)`
  display: flex;
  flex-direction: column;
  padding: 8px 8px 16px 8px;
  transition: background-color 0.2s;
  &:hover {
    cursor: pointer;
    background-color: ${COLORS.LightGrey};
  }
`;

const ProjectNameWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const TopCornerWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const SharedIconWrapper = styled.div`
  display: flex;
  align-items: center;
  margin: 0 8px;
`;

const BodySection = styled.div`
  display: flex;
  flex-direction: column;
`;

const BodyDetailsSection = styled.div`
  display: grid;
  grid-template-columns: 1.5fr 1.5fr 1fr;
  grid-gap: 8px;
  margin-bottom: 8px;
`;

const IdAndStatusWrapper = styled.div`
  display: flex;
  margin-bottom: 8px;
`;

const IdContainer = styled.div`
  display: flex;
  align-items: center;
  flex: 1;
  min-width: 0;
  margin-right: 8px;
`;

const IdLabelTypography = styled(Typography)`
  flex-shrink: 0;
  margin-right: 4px;
`;

const DateText = styled.div`
  display: flex;
  flex-direction: column;
`;

const getDateText = (runDate: string | null) => {
  const date = convertApiDateStringToDate(runDate);
  if (date) {
    return (
      <DateText>
        <span>{date.toDateString()}</span>
        <span>
          {date.toLocaleString('en-GB', {
            hour: '2-digit',
            minute: '2-digit',
            hourCycle: 'h12',
          })}
        </span>
      </DateText>
    );
  }
};

const convertStateToReadable = (pipelineState: string): string => {
  switch (pipelineState) {
    case 'get_data_sources':
      return 'data-sources';
    case 'post_data_sources':
      return 'data-sources';
    case 'get_schema_page':
      return 'schema';
    case 'post_schema_page':
      return 'schema';
    default:
      return pipelineState;
  }
};

interface Props {
  pipelineId: string;
  pipelineData: Pipeline;
}

const PipelineCard: React.FC<Props> = ({
  pipelineId,
  pipelineData: {
    name,
    createdDate,
    lastEdited,
    // dataSourceNames,
    pipelineState,
    isShared,
    isPublished,
    ownerId,
  },
}) => {
  const { user } = useAuthContext();

  const history = useHistory();

  const [menuAnchorEl, setMenuAnchorEl] = React.useState<null | HTMLElement>(
    null
  );
  const handleMenuClick = (event: React.MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    setMenuAnchorEl(event.currentTarget);
  };
  const handleMenuClose = () => {
    setMenuAnchorEl(null);
  };

  const [isShareModalOpen, setIsShareModalOpen] = useState<boolean>(false);
  const handleShareClick = () => {
    handleMenuClose();
    setIsShareModalOpen(true);
  };
  const onShareModalClose = () => {
    setIsShareModalOpen(false);
  };

  const [deleteLoading, setDeleteLoading] = useState<boolean>(false);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState<boolean>(false);
  const [sendDeleteRequest, deleteError] = useDeleteSimpleService();
  const [, { deletePipeline }] = usePipelineDashboardStore();
  const handleOpenDeleteModal = () => {
    handleMenuClose();
    setIsDeleteModalOpen(true);
  };
  const handleCloseDeleteModal = () => {
    setIsDeleteModalOpen(false);
  };
  const handleDeleteConfirm = async () => {
    setDeleteLoading(true);
    const response = await sendDeleteRequest(
      `${ROUTES.Pipeline}/${pipelineId}`
    );
    if (response) {
      deletePipeline(pipelineId);
      toast.success(`Pipeline '${name}' deleted successfully`);
    }
    setDeleteLoading(false);
    handleCloseDeleteModal();
  };
  useErrorAlert(deleteError);

  const displayPipelineState = convertStateToReadable(pipelineState);

  const handleCardClick = () => {
    if (!menuAnchorEl && !isDeleteModalOpen && !isShareModalOpen) {
      history.push(
        `${ROUTES.App}${ROUTES.Pipeline}/${pipelineId}${
          isPublished
            ? `${PIPELINEDASHBOARDPAGES.Activity}`
            : `${PIPELINESTATUSROUTES.Draft}/${displayPipelineState}`
        }`
      );
    }
  };

  const DELETE_CONFIRMATION_TEXT = `Are you sure you want to delete pipeline: '${name}' with ID: ${pipelineId}`;

  return (
    <StyledCard elevation={4} onClick={handleCardClick}>
      <ProjectNameWrapper>
        <Typography
          variant="h5"
          color="secondary"
          style={{ fontWeight: 'bold' }}
          noWrap
        >
          {name}
        </Typography>
        <TopCornerWrapper>
          {isShared && (
            <SharedIconWrapper>
              <PeopleIcon color="disabled" />
            </SharedIconWrapper>
          )}
          <IconButton color="primary" onClick={handleMenuClick}>
            <MoreHorizIcon />
          </IconButton>
          <Menu
            anchorEl={menuAnchorEl}
            open={Boolean(menuAnchorEl)}
            onClose={handleMenuClose}
            MenuListProps={{
              'aria-labelledby': 'basic-button',
            }}
            getContentAnchorEl={null}
            anchorOrigin={{ vertical: 'top', horizontal: 'left' }}
            transformOrigin={{ vertical: 'top', horizontal: 'right' }}
          >
            <MenuItem onClick={handleShareClick} dense>
              <ListItemIcon>
                <PeopleIcon fontSize="small" />
              </ListItemIcon>
              <ListItemText>Share</ListItemText>
            </MenuItem>
            {user?.uid === ownerId && (
              <MenuItem onClick={handleOpenDeleteModal} dense>
                <ListItemIcon>
                  <DeleteIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Delete</ListItemText>
              </MenuItem>
            )}
          </Menu>
        </TopCornerWrapper>
      </ProjectNameWrapper>
      <BodySection>
        <IdAndStatusWrapper>
          <IdContainer>
            <IdLabelTypography variant="caption">
              Pipeline Id:
            </IdLabelTypography>

            <CopyIdLozenge resourceId={pipelineId} />
          </IdContainer>
          {isPublished ? (
            <StatusLozenge rgbColor="0, 200, 0">PUBLISHED</StatusLozenge>
          ) : (
            <StatusLozenge rgbColor="169, 169, 169">DRAFT</StatusLozenge>
          )}
        </IdAndStatusWrapper>
        <BodyDetailsSection>
          <div>
            <Typography variant="caption">Created Date:</Typography>
            <div>{getDateText(createdDate)}</div>
          </div>
          <div>
            <Typography variant="caption">Last Edited:</Typography>
            <div>{getDateText(lastEdited)}</div>
          </div>
          {!isPublished && (
            <div>
              <Typography variant="caption">Current State:</Typography>
              <Typography style={{ fontWeight: 'bold' }}>
                {displayPipelineState}
              </Typography>
            </div>
          )}
        </BodyDetailsSection>
      </BodySection>
      <ShareModal
        isOpen={isShareModalOpen}
        onClose={onShareModalClose}
        onSuccess={onShareModalClose}
        resourceId={pipelineId}
      />
      <DeleteModal
        isOpen={isDeleteModalOpen}
        onClose={handleCloseDeleteModal}
        confirmationText={DELETE_CONFIRMATION_TEXT}
        handleDelete={handleDeleteConfirm}
        isLoading={deleteLoading}
      />
    </StyledCard>
  );
};

export default PipelineCard;
