import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Backdrop, Typography } from '@material-ui/core';
import { ReactComponent as Logo } from '../../../common/images/segna-logo-white-icon.svg';

const StyledBackdrop = styled(Backdrop)`
  z-index: 12;
  color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const StyledText = styled(Typography)`
  padding: 10px;
  color: white;
`;

const StyledIcon = styled(Logo)`
  height: 50px;
  width: 50px;
  padding: 15px;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.95);
  }
  25% {
    transform: scale(0.9);
  }
  35% {
    transform: scale(1.0);
    box-shadow: 0 0 0 0 rgba(255, 255, 255, 0.3);
  }
  40% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 15px rgba(255,255,255, 0);
  }
`;

const OuterDiv = styled.div`
  animation: ${pulse} 2s infinite ease-in-out;
  border-radius: 50%;
`;

interface Props {
  isLoading: boolean;
}

const FullLoadingOverlay: React.FC<Props> = ({ isLoading }) => (
  <StyledBackdrop open={isLoading}>
    <OuterDiv>
      <StyledIcon fill="white" />
    </OuterDiv>
    <StyledText variant="subtitle1"> </StyledText>
  </StyledBackdrop>
);

export default FullLoadingOverlay;
