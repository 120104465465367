import React, { ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  Divider,
  IconButton,
  Popper,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { useForm } from 'react-hook-form';
import { Close } from '@material-ui/icons';
import useAddOutputDataResourceService from '../service/useAddOutputDataResourceService';
import useAddInputDataResourceService from '../service/useAddInputDataResourceService';
import {
  AthenaFormInput,
  AwsRdsMysqlFormInput,
  SnowflakeFormInput,
} from './input-connector-types';
import {
  AthenaFormOutput,
  AwsRdsMysqlFormOutput,
  FirestoreFormOutput,
  GCSFormOutput,
  SnowflakeFormOutput,
  SupabasePostgresqlFormOutput,
} from './output-connector-types';
import { Connector, ConnectorType } from '../types';
import useErrorAlert from '../../../../utils/useErrorAlert';
import S3Form from './output-connector-types/s3-form';
import SampleSwitch from '../../sample-switch';

const INPUT_DETAILS_TEXT =
  'Select from an existing database connector, or add a new connector, and specify the table you would like to pull data from.';

const OUTPUT_DETAILS_TEXT =
  'Select from an existing database connector, or add a new connector, and specify the table you would like to push data to.';

const Wrapper = styled.div`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const DatabaseConnectorSelectorWrapper = styled.div`
  margin-top: 8px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const AddAutocomplete = styled(Autocomplete)`
  display: flex;
  min-width: 300px;
  margin-bottom: 4px;
`;

const CustomPopper = styled(Popper)`
  z-index: 1301;
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface Props {
  onClose: () => void;
  onAddNewConnector: () => void;
  databaseConnectors: {
    [connectorId: string]: {
      connectorName: string;
      connectorType: ConnectorType;
    };
  };
  onConnectorChange: (connector: Connector | null) => void;
  selectedConnector: Connector | null;
  onAddDatabaseTable: (
    dataResourceId: string,
    dataResourceName: string,
    dataResourceType: string,
    isSample?: boolean
  ) => void;
  isOutput?: boolean;
  allowSampleOption?: boolean;
}

const DatabaseTableInfoForm: React.FC<Props> = ({
  onClose,
  onAddNewConnector,
  databaseConnectors,
  onConnectorChange,
  selectedConnector,
  onAddDatabaseTable,
  isOutput,
  allowSampleOption = false,
}) => {
  const [
    addInputDatabaseTableService,
    inputError,
  ] = useAddInputDataResourceService();
  const [
    addOutputDataResourceService,
    outputError,
  ] = useAddOutputDataResourceService();
  useErrorAlert(inputError);
  useErrorAlert(outputError);

  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSample, setIsSample] = useState(false);

  const [connectorList, setConnectorList] = useState<Connector[]>([]);
  // Use to trigger useEffect when databaseConnectors object updates
  const databaseConnectorsLength = Object.keys(databaseConnectors).length;
  useEffect(() => {
    const currentConnectorList = Object.entries(databaseConnectors).map(
      ([connectorId, { connectorType, connectorName }]) => ({
        connectorId,
        connectorType,
        connectorName,
      })
    );
    setConnectorList(currentConnectorList);
  }, [databaseConnectors, databaseConnectorsLength]);

  const onConnectorNameChange = (newValue: unknown) => {
    if (newValue === null) {
      reset({});
      onConnectorChange(null);
    } else if (connectorList.includes(newValue as Connector)) {
      reset({});
      onConnectorChange(newValue as Connector);
    }
  };

  const renderSelectedDatabaseTableForm = (): ReactNode => {
    if (selectedConnector) {
      const { connectorType } = selectedConnector;
      if (isOutput) {
        switch (connectorType) {
          case ConnectorType.ATHENA:
            return <AthenaFormOutput formRegister={register} errors={errors} />;
          case ConnectorType.AWS_RDS_MYSQL:
            return (
              <AwsRdsMysqlFormOutput formRegister={register} errors={errors} />
            );
          case ConnectorType.S3:
            return <S3Form formRegister={register} errors={errors} />;
          case ConnectorType.SNOWFLAKE:
            return (
              <SnowflakeFormOutput formRegister={register} errors={errors} />
            );
          case ConnectorType.FIRESTORE:
            return (
              <FirestoreFormOutput formRegister={register} errors={errors} />
            );
          case ConnectorType.GCS:
            return <GCSFormOutput formRegister={register} errors={errors} />;
          case ConnectorType.SUPABASE_POSTGRESQL:
            return (
              <SupabasePostgresqlFormOutput
                formRegister={register}
                errors={errors}
              />
            );
          default:
            return (
              <p>This connector can not be used as an output data resource.</p>
            );
        }
      } else {
        switch (connectorType) {
          case 'athena':
            return <AthenaFormInput formRegister={register} errors={errors} />;
          case 'aws_rds_mysql':
            return (
              <AwsRdsMysqlFormInput formRegister={register} errors={errors} />
            );
          case ConnectorType.SNOWFLAKE:
            return (
              <SnowflakeFormInput formRegister={register} errors={errors} />
            );
          default:
            return (
              <p>This connector can not be used as an input data resource.</p>
            );
        }
      }
    }
    return <>Please select a connector</>;
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (selectedConnector !== null) {
      const { connectorId } = selectedConnector;
      const response = isOutput
        ? await addOutputDataResourceService({
            connectorId,
            isSample,
            dataResourceInfo: {
              ...data,
            },
          })
        : await addInputDatabaseTableService({
            connectorId,
            isSample,
            dataResourceInfo: {
              ...data,
            },
          });
      if (response) {
        Object.entries(response.dataResources).forEach(
          ([dataResourceId, { dataResourceName, dataResourceType }]) => {
            onAddDatabaseTable(
              dataResourceId,
              dataResourceName,
              dataResourceType,
              isSample
            );
          }
        );
        reset({});
        onClose();
      }
    }
    setIsLoading(false);
  });

  const isImportDisabled = isLoading || selectedConnector === null;

  return (
    <Wrapper>
      <HeaderContainer>
        <Typography variant="h5" gutterBottom>
          Select Table from a Database
        </Typography>
        <div>
          <IconButton onClick={onClose} disabled={isLoading} size="small">
            <Close fontSize="small" />
          </IconButton>
        </div>
      </HeaderContainer>
      {isOutput ? OUTPUT_DETAILS_TEXT : INPUT_DETAILS_TEXT}
      <DatabaseConnectorSelectorWrapper>
        <AddAutocomplete
          PopperComponent={CustomPopper}
          value={selectedConnector}
          renderInput={(params) => {
            return (
              <TextField
                {...params}
                variant="outlined"
                color="primary"
                margin="dense"
              />
            );
          }}
          options={connectorList.filter(
            ({ connectorId }) => connectorId !== selectedConnector?.connectorId
          )}
          getOptionLabel={(option) => {
            return (option as Connector).connectorName;
          }}
          onChange={(_, value) => {
            onConnectorNameChange(value);
          }}
        />
        <Divider orientation="vertical" />
        <Button onClick={onAddNewConnector} variant="contained" color="primary">
          Add New Connector
        </Button>
      </DatabaseConnectorSelectorWrapper>
      <Form onSubmit={onSubmit}>{renderSelectedDatabaseTableForm()}</Form>
      <ButtonFooter>
        {allowSampleOption && (
          <SampleSwitch isSample={isSample} setIsSample={setIsSample} />
        )}
        <div>
          <Button
            onClick={onSubmit}
            color="secondary"
            variant="contained"
            disabled={isImportDisabled}
          >
            {isLoading ? (
              <CircularProgress color="inherit" size={24} />
            ) : (
              <>{isOutput ? 'Add Output' : 'Add Input'}</>
            )}
          </Button>
        </div>
      </ButtonFooter>
    </Wrapper>
  );
};

export default DatabaseTableInfoForm;
