import React from 'react';
import styled from 'styled-components';
import { Divider, Tab, Tabs, Typography } from '@material-ui/core';
import { TabContext, TabPanel } from '@material-ui/lab';
import { useHistory, useParams } from 'react-router-dom';
import PipelineJobsTab from '../pipeline-jobs-tab';
import PipelineActivityTab from '../pipeline-activity-tab';
import ROUTES from '../../../../common/routes';
import PipelineSummaryTab from '../pipeline-summary-tab';
import COLORS from '../../../../common/colors';
import TestJobConfigTab from '../test-job-config-tab';
import usePipelineSummaryService from './service/usePipelineSummaryService';
import useInitSegnaApiKeyService from './service/useInitSegnaApiKeyService';
import DetailsCard from './details-card';

const Wrapper = styled.div`
  justify-content: space-around;
  max-width: 1280px;
  margin: 0 auto;
`;

const HeaderSection = styled.div`
  margin-bottom: 8px;
  display: flex;
  align-items: flex-start;
`;

const TitleSection = styled.div`
  flex: 1;
`;

const StyledTabPanel = styled(TabPanel)`
  padding: 32px;
  background-color: ${COLORS.Grey};
  border-radius: 0 0 8px 8px;
`;

const StyledTab = styled(Tab)`
  font-size: medium;
`;

const StyledTabContext = styled(TabContext)`
  background-color: white;
`;

enum PipelineInformation {
  ACTIVITY = 'Activity',
  JOBS = 'Jobs',
  SUMMARY = 'Summary',
  TEST_JOB = 'Run A Test Job',
}

enum PipelineRoute {
  ACTIVITY = 'activity',
  JOBS = 'jobs',
  SUMMARY = 'summary',
  TEST_JOB = 'test-job',
}

interface Props {
  pipelineName: string;
  shownInformation: string;
}

const PipelineContent: React.FC<Props> = ({
  pipelineName,
  shownInformation,
}) => {
  const { pipelineId } = useParams<{ pipelineId: string }>();
  const history = useHistory();

  const handleChange = (
    event: React.ChangeEvent<unknown>,
    newValue: PipelineInformation
  ) => {
    shownInformation &&
      history.push(
        `${ROUTES.App}${
          ROUTES.Pipeline
        }/${pipelineId}/${newValue.toLowerCase()}`
      );
  };

  const [summaryData, summaryIsLoading] = usePipelineSummaryService();

  // TODO This assumes the api key is successfully loaded when loading is false
  // Need to handle the case when api key not loaded successfully
  // If fails, the data importer simply shows the api key error
  const [apiKeyData, apiKeyDataIsLoading] = useInitSegnaApiKeyService();

  return (
    <Wrapper>
      <HeaderSection>
        <TitleSection>
          <Typography variant="h3">{pipelineName}</Typography>
        </TitleSection>
        <DetailsCard
          pipelineId={pipelineId}
          apiKey={apiKeyData?.apiKey || null}
        />
      </HeaderSection>
      <StyledTabContext value={shownInformation}>
        <Tabs
          onChange={handleChange}
          value={shownInformation}
          indicatorColor="secondary"
          textColor="secondary"
        >
          <StyledTab
            label={PipelineInformation.ACTIVITY}
            value={PipelineRoute.ACTIVITY}
          />
          <StyledTab
            label={PipelineInformation.JOBS}
            value={PipelineRoute.JOBS}
          />
          <StyledTab
            label={PipelineInformation.SUMMARY}
            value={PipelineRoute.SUMMARY}
          />
          <StyledTab
            label={PipelineInformation.TEST_JOB}
            value={PipelineRoute.TEST_JOB}
          />
        </Tabs>
        <Divider />
        <StyledTabPanel value={PipelineRoute.ACTIVITY}>
          <PipelineActivityTab />
        </StyledTabPanel>
        <StyledTabPanel value={PipelineRoute.JOBS}>
          <PipelineJobsTab />
        </StyledTabPanel>
        <StyledTabPanel value={PipelineRoute.SUMMARY}>
          <PipelineSummaryTab
            isLoading={summaryIsLoading}
            summaryData={summaryData}
          />
        </StyledTabPanel>
        <StyledTabPanel value={PipelineRoute.TEST_JOB}>
          <TestJobConfigTab
            isLoadingApiKeyData={apiKeyDataIsLoading}
            pipelineData={summaryData}
          />
        </StyledTabPanel>
      </StyledTabContext>
    </Wrapper>
  );
};

export default PipelineContent;
