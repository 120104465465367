import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';
import { ContainerProps, StoreState } from './types';

const initialState: StoreState = {
  scriptHistory: {},
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setInitialData: () => ({ setState }: StoreApi, data: ContainerProps) => {
    setState(data);
  },
  deleteScript: (scriptId: string) => ({ getState, setState }: StoreApi) => {
    const { scriptHistory } = getState();

    delete scriptHistory[scriptId];

    setState({ scriptHistory });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const useScriptsListStore = createHook(Store);
export const ScriptsListContainer = createContainer(Store, {
  onInit: actions.setInitialData,
  onUpdate: actions.setInitialData,
});
