import React from 'react';
import { Route, Switch, useRouteMatch } from 'react-router-dom';
import ScriptsDashboard from '../../scripts-pages/scripts-dashboard';
import ScriptsEditorOuter from '../../scripts-pages/scripts-editor-page';
import {TrackPageVisited} from "../../../utils/useTrackEvent";

const ScriptsRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <TrackPageVisited pageName="Scripts" />
        <ScriptsDashboard />
      </Route>
      <Route exact path={`${path}/:scriptId`}>
        <TrackPageVisited pageName="Scripts_Editor" />
        <ScriptsEditorOuter />
      </Route>
    </Switch>
  );
};

export default ScriptsRoutes;
