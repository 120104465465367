import { PAGEAPIROUTES } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';
import useResourceRouteWithId from '../../../../../utils/useResourceRouteWithId';

type AthenaDatabaseTableInfo = {
  tableName: string;
};

export type OutputDataResourceInfo = AthenaDatabaseTableInfo;

interface AddRequest {
  connectorId: string;
  isSample: boolean;
  dataResourceInfo: Record<string, string>;
}

type AddResponse = {
  dataResources: {
    [dataResourceId: string]: {
      dataResourceName: string;
      dataResourceType: string;
    };
  };
};

const useAddOutputDataResourceService = (): [
  (body: AddRequest) => Promise<AddResponse | void>,
  Error | null,
  () => void
] => {
  const idRoute = useResourceRouteWithId();

  return usePostSimpleService<AddRequest, AddResponse>(
    `${idRoute}${PAGEAPIROUTES.Output_Data_Resource}`
  );
};

export default useAddOutputDataResourceService;
