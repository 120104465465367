import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import DataSourcesInner from './data-sources-inner';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import { DataSourcesImportEndpointResponse } from '../../../api/types/pipeline';
import ROUTES, { PIPELINEAPIS, PIPELINEPAGES } from '../../../common/routes';
import { DataSourcesImportingStoreContainer } from './store';
import LoadingScreenCentered from '../../common/loading-screen-centered';

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 0 auto;
`;

const DataSourcesImporting: React.FC = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const [data, isLoading] = useFetchSimpleService<
    DataSourcesImportEndpointResponse
  >(
    `${ROUTES.Pipeline}${PIPELINEAPIS.Importing_Pipeline_Builder}/${pipelineId}/${PIPELINEPAGES.Data_Sources}`
  );

  return (
    <>
      {isLoading ? (
        <LoadingScreenCentered messageText="Loading..." />
      ) : (
        data && (
          <DataSourcesImportingStoreContainer {...data}>
            <Wrapper>
              <DataSourcesInner />
            </Wrapper>
          </DataSourcesImportingStoreContainer>
        )
      )}
    </>
  );
};

export default DataSourcesImporting;
