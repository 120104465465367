import React from 'react';
import { Button, Link, Paper, Typography } from '@material-ui/core';
import styled from 'styled-components';
import CloudDownloadIcon from '@material-ui/icons/CloudDownload';
import COLORS from '../../../common/colors';
import CSV_SVG from '../../../common/images/csv.svg';
import { EXTERNAL } from '../../../common/routes';
import StepHeading from '../step-heading';
import FadeSlideUpwards from '../../common/animations/fade-slide-upwards';
import OnboardingWizardBottomBar from '../onboarding-wizard-bottombar';
import DataSnippet from '../data-snippet';
import {
  SAMPLE_FILE_BODY_DATA,
  SAMPLE_FILE_DOWNLOAD_LINK,
  SAMPLE_FILESIZE,
  SAMPLE_FILENAME,
} from './sample-file-data';
import BackToAppButton from '../back-to-app-button';
import useHasCompletedOnboarding from '../hooks/useHasCompletedOnboarding';

const PageContainer = styled.div`
  margin: 60px auto 0;
  width: 100%;
`;

const Heading = styled(Typography)`
  font-size: 64px;
  font-family: 'Nexa Bold', Quicksand, sans-serif;
`;

const SubHeading = styled(Typography)`
  margin-bottom: 16px;
`;

const DocsExternalLink = styled(Typography)`
  margin-bottom: 64px;
  color: ${COLORS.Blue};
  font-weight: bold;
`;

const StyledPaper = styled(Paper)`
  padding: 16px;
  margin: 24px auto;
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const FileContainer = styled.div`
  display: flex;
  align-items: center;
`;

const FileMetaData = styled.div`
  margin-left: 12px;
`;

const Filename = styled(Typography)`
  font-size: 20px;
`;

const FileSize = styled(Typography)`
  color: grey;
  font-size: 14px;
`;

const WelcomePage: React.FC = () => {
  const { completed } = useHasCompletedOnboarding();

  return (
    <>
      {completed && <BackToAppButton />}
      <PageContainer>
        <Heading variant="h1" gutterBottom align="center">
          Welcome to Segna{' '}
          <span role="img" aria-label="wave">
            👋
          </span>
        </Heading>
        <SubHeading variant="h6" align="center">
          To get started on your journey, you&apos;re going to learn how to run
          a job with a sample pipeline.
        </SubHeading>
        <DocsExternalLink align="center">
          <Link
            href={EXTERNAL.Docs}
            color="inherit"
            target="_blank"
            rel="noopener"
          >
            Find out more about jobs and pipelines
          </Link>
        </DocsExternalLink>
        <StepHeading stepNumber={1} bgColor={COLORS.DarkBlue}>
          Download the Sample Data
        </StepHeading>
        <FadeSlideUpwards>
          <StyledPaper variant="outlined" square>
            <FileContainer>
              <img src={CSV_SVG} alt="csv icon" height={60} />
              <FileMetaData>
                <Filename>{SAMPLE_FILENAME}</Filename>
                <FileSize>{SAMPLE_FILESIZE}</FileSize>
              </FileMetaData>
            </FileContainer>
            <Link
              href={SAMPLE_FILE_DOWNLOAD_LINK}
              target="_blank"
              color="inherit"
              style={{ textDecoration: 'none' }}
            >
              <Button
                startIcon={<CloudDownloadIcon />}
                color="secondary"
                variant="contained"
              >
                Download
              </Button>
            </Link>
          </StyledPaper>
          <Typography
            style={{ fontWeight: 'bold', textAlign: 'center' }}
            gutterBottom
          >
            A preview of the messy Sample Data:
          </Typography>
          <DataSnippet bodyData={SAMPLE_FILE_BODY_DATA} />
        </FadeSlideUpwards>
      </PageContainer>
      <OnboardingWizardBottomBar />
    </>
  );
};

export default WelcomePage;
