import styled from 'styled-components';
import { Button, Divider, Typography } from '@material-ui/core';
import React, { useState } from 'react';
import AddIcon from '@material-ui/icons/Add';
import ConnectorsTable from './connectors-table';
import { ConnectorsContainer } from './store';
import useFetchSimpleService from '../../api/useFetchSimpleService';
import ROUTES, { CONNECTORAPIS } from '../../common/routes';
import NewDataBaseConnector from './new-database-connector';
import LoadingScreenCentered from '../common/loading-screen-centered';
import { ConnectorType } from '../common/add-database-modal/types';
import useErrorAlert from '../../utils/useErrorAlert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ConnectorsPage: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [connectors, , error] = useFetchSimpleService<{
    connectors: {
      [connectorId: string]: {
        connectorType: ConnectorType;
        name: string;
        createdTime: string;
      };
    };
  }>(ROUTES.Connectors + CONNECTORAPIS.Connectors);
  useErrorAlert(error);

  return (
    <Wrapper>
      <TitleContainer>
        <Typography variant="h3">Connectors</Typography>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
        >
          Add new connector
        </Button>
      </TitleContainer>
      <Divider style={{ height: 2, borderRadius: 1 }} />
      {connectors ? (
        <ConnectorsContainer {...connectors}>
          <ConnectorsTable />
          {isModalOpen && (
            <NewDataBaseConnector
              isOpen={isModalOpen}
              onClose={handleCloseModal}
            />
          )}
        </ConnectorsContainer>
      ) : (
        <LoadingScreenCentered messageText="" />
      )}
    </Wrapper>
  );
};

export default ConnectorsPage;
