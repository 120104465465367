import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';

import { StoreState, ContainerProps } from './types';

const initialState: StoreState = {
  apiKey: null,
  hasRunJob: false,
  dataTable: null,
  isDataTableLoading: false,
  alreadyCompletedOnboarding: null,
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setInitialData: () => (
    { setState }: StoreApi,
    { apiKey }: ContainerProps
  ) => {
    setState({ apiKey });
  },
  setHasRunJob: (hasRunJob: boolean) => ({ setState }: StoreApi) => {
    setState({ hasRunJob });
  },
  setDataTable: (dataTable: string[][]) => ({ setState }: StoreApi) => {
    setState({ dataTable });
  },
  setIsDataTableLoading: (isDataTableLoading: boolean) => ({
    setState,
  }: StoreApi) => {
    setState({ isDataTableLoading });
  },
  setAlreadyCompletedOnboarding: (completed: boolean) => ({
    setState,
  }: StoreApi) => {
    setState({ alreadyCompletedOnboarding: completed });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const useOnboardingWizardStore = createHook(Store);
export const OnboardingWizardStoreContainer = createContainer(Store, {
  onInit: actions.setInitialData,
  onUpdate: actions.setInitialData,
});
