import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ScriptsListTable from './scripts-list-table';
import CreateScriptModal from './create-script-modal';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import ROUTES, { SCRIPTSAPIS } from '../../../common/routes';
import LoadingScreenCentered from '../../common/loading-screen-centered';
import { ScriptsListContainer } from './scripts-list-store';

const PageContainer = styled.div`
  display: flex;
  flex-direction: column;
  max-width: 1280px;
  padding-bottom: 32px;
  margin: 0 auto;
`;

const TitleContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-bottom: 16px;
`;

const ScriptsDashboard: React.FC = () => {
  const [isModalOpen, setIsModalOpen] = useState<boolean>(false);
  const handleOpenModal = () => {
    setIsModalOpen(true);
  };

  const handleCloseModal = () => {
    setIsModalOpen(false);
  };

  const [scriptsData, isLoading] = useFetchSimpleService<{
    scriptHistory: {
      [scriptId: string]: {
        name: string;
        description: string;
        lastUpdated: string;
        language: string;
        ownerId: string;
        isShared: boolean;
      };
    };
  }>(ROUTES.Scripts + SCRIPTSAPIS.DashBoard);

  return (
    <PageContainer>
      <TitleContainer>
        <Typography variant="h3">Scripts</Typography>
        <Button
          onClick={handleOpenModal}
          variant="contained"
          color="secondary"
          startIcon={<AddIcon />}
        >
          Add new Script
        </Button>
      </TitleContainer>
      <Divider style={{ height: 2, borderRadius: 1 }} />
      {isLoading ? (
        <LoadingScreenCentered messageText="" />
      ) : (
        scriptsData && (
          <ScriptsListContainer {...scriptsData}>
            <ScriptsListTable />
          </ScriptsListContainer>
        )
      )}
      {isModalOpen && (
        <CreateScriptModal
          isModalOpen={isModalOpen}
          onClose={handleCloseModal}
        />
      )}
    </PageContainer>
  );
};

export default ScriptsDashboard;
