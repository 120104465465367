import { Fade, Modal, Paper } from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import NewDatabaseConnectorModal from '../../common/add-database-modal/new-database-connector-modal';
import { useConnectorsStore } from '../store';

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalComponent = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 510px;
  height: 600px;
  padding: 16px;

  // TODO: Find a better fix that doesn't remove the ability to focus for a11y
  &:focus-visible {
    outline: none;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
}

const NewDataBaseConnector: React.FC<Props> = ({ isOpen, onClose }) => {
  const [, { addConnector }] = useConnectorsStore();
  return (
    <ModalContainer open={isOpen} onClose={onClose} keepMounted>
      <Fade in>
        <ModalComponent>
          <NewDatabaseConnectorModal
            onBack={onClose}
            onClose={onClose}
            onSuccess={onClose}
            onAddDatabaseConnector={addConnector}
          />
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default NewDataBaseConnector;
