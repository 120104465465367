import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Fab, Typography } from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { useActions } from '../../scripts-store';
import InputDataTable from './input-data-table';
import FileUploadZone from './file-upload-zone';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  height: 360px;
  background-color: whitesmoke;
  margin: 4px;
  padding: 12px;
  align-content: center;
`;

const FileInput = forwardRef<HTMLDivElement>((props, ref) => {
  const [fileUploaded, setFileUploaded] = useState<boolean>(false);

  const [, { clearAllTables }] = useActions();

  const handleClearInput = () => {
    clearAllTables();
    setFileUploaded(false);
  };

  return (
    <Container ref={ref}>
      <Typography variant="h6">Upload your data</Typography>
      {!fileUploaded ? (
        <FileUploadZone setFileUploaded={setFileUploaded} />
      ) : (
        <>
          <Fab
            onClick={handleClearInput}
            variant="round"
            style={{ top: 12, left: 580, width: 24, minHeight: 24, height: 24 }}
          >
            <CloseIcon fontSize="small" />
          </Fab>
          <InputDataTable />
        </>
      )}
    </Container>
  );
});

export default FileInput;
