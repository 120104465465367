import { useCallback, useState } from 'react';
import axios, { AxiosResponse } from 'axios';
import { BASE_URL } from '../../../../../common/constants';
import { useAuthContext } from '../../../../../firebase/AuthProvider';
import ROUTES, { SCRIPTSAPIS } from '../../../../../common/routes';

const uploadFileService = (
  file: File,
  idToken: string | null | undefined,
  fullRoute: string,
  setError: (error: Error) => void
): Promise<void | Response> => {
  const formData = new FormData();
  formData.append('files', file);
  console.log(file);
  console.log(formData);

  return axios({
    url: `${BASE_URL}${fullRoute}`,
    method: 'POST',
    headers: {
      Authorization: `${idToken}`,
    },
    data: formData,
  })
    .then((response: AxiosResponse<Response>) => {
      return response.data;
    })
    .catch((error) => {
      if (error.response) {
        setError(error.response.data.errorMessage);
      } else {
        setError(new Error(error));
      }
    });
};

type DataUploadResponse = {
  data: string[][];
};

const useUploadTestDataService = (
  scriptId: string
): [(file: File) => Promise<DataUploadResponse>, Error | null, () => void] => {
  const { user } = useAuthContext();
  const [error, setError] = useState<Error | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const fullRoute = `${ROUTES.Scripts}/${scriptId}${SCRIPTSAPIS.Upload_Test_Data}`;

  return [
    useCallback(
      async (file: File) => {
        clearError();
        const idToken = user && (await user.getIdToken(true));
        return uploadFileService(file, idToken, fullRoute, setError).then(
          (responseData) => (responseData as unknown) as DataUploadResponse
        );
      },
      [clearError, fullRoute, user]
    ),
    error,
    clearError,
  ];
};

export default useUploadTestDataService;
