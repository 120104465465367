enum AddFieldActionTypes {
  OpenModal = 'openModal',
  CloseModal = 'closeModal',
  FieldName = 'fieldName',
  DataType = 'dataType',
  OutputUnit = 'outputUnit',
  DateTime = 'dateTime',
  Optional = 'optional',
  DropMissing = 'dropMissing',
  SetError = 'setError',
  ClearError = 'clearError',
  AddCategoryValue = 'addCategoryValue',
  RemoveCategoryValue = 'removeCategoryValue',
}

export default AddFieldActionTypes;
