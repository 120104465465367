import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@material-ui/core';
import { useParams } from 'react-router-dom';
import LineGraph from './line-graph';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import ROUTES, {
  PIPELINEAPIS,
  PIPELINEDASHBOARDAPIS,
} from '../../../../common/routes';
import useErrorAlert from '../../../../utils/useErrorAlert';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
`;

const GraphContainer = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-content: center;
  background-color: white;
  padding: 8px 32px 8px 8px;
  border-radius: 8px;
  height: 600px;
  margin-bottom: 40px;
`;

const TitleContainer = styled.div`
  margin: 16px 0;
  padding: 16px;
  text-align: left;
`;

const Container = styled.div`
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const DateLength = 30;

const getFilterDates = () => {
  const endDate = new Date();
  const startDate = new Date();
  startDate.setDate(endDate.getDate() - DateLength);

  const endDateUTC = endDate.getUTCDate();
  const startDateUTC = startDate.getUTCDate();

  endDate.setDate(endDateUTC);
  startDate.setDate(startDateUTC);

  const endDateString = endDate.toDateString();
  const startDateString = startDate.toDateString();

  return { startDateString, endDateString };
};

const PipelineActivityTab: React.FC = () => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const dates = getFilterDates();

  const dateQuery = new URLSearchParams({
    startDate: dates.startDateString,
    endDate: dates.endDateString,
  });

  const [dataUsage, , dataError] = useFetchSimpleService<{
    data: {
      value: number;
      time: string;
    }[];
    unit: string;
  }>(
    `/${ROUTES.Pipeline}/${pipelineId}${PIPELINEAPIS.Pipeline_Dashboard}${PIPELINEDASHBOARDAPIS.Data_Usage}?${dateQuery}`
  );

  const [jobErrorsData, , jobErrorsDataError] = useFetchSimpleService<
    {
      value: number;
      time: string;
    }[]
  >(
    `/${ROUTES.Pipeline}/${pipelineId}${PIPELINEAPIS.Pipeline_Dashboard}${PIPELINEDASHBOARDAPIS.Job_Errors}?${dateQuery}`
  );

  const [jobsData, , jobsDataError] = useFetchSimpleService<
    {
      value: number;
      time: string;
    }[]
  >(
    `/${ROUTES.Pipeline}/${pipelineId}${PIPELINEAPIS.Pipeline_Dashboard}${PIPELINEDASHBOARDAPIS.Job_Number}?${dateQuery}`
  );

  useErrorAlert(jobsDataError);
  useErrorAlert(dataError);
  useErrorAlert(jobErrorsDataError);

  return (
    <Wrapper>
      <GraphContainer>
        <TitleContainer>
          <Typography variant="h4">
            Data Usage ({dataUsage && dataUsage.unit.toUpperCase()})
          </Typography>
        </TitleContainer>
        {dataUsage ? (
          <LineGraph
            data={dataUsage.data}
            contentType="Data"
            dataUsageUnit={dataUsage.unit}
            dataLength={DateLength}
          />
        ) : (
          <Container>
            <CircularProgress />
          </Container>
        )}
      </GraphContainer>
      <GraphContainer>
        <TitleContainer>
          <Typography variant="h4">Number of Errored Jobs</Typography>
        </TitleContainer>
        {jobErrorsData ? (
          <LineGraph
            data={jobErrorsData}
            contentType="Errors"
            dataLength={DateLength}
          />
        ) : (
          <Container>
            <CircularProgress />
          </Container>
        )}
      </GraphContainer>
      <GraphContainer>
        <TitleContainer>
          <Typography variant="h4">Number of Jobs Run</Typography>
        </TitleContainer>
        {jobsData ? (
          <LineGraph
            data={jobsData}
            contentType="Jobs"
            dataLength={DateLength}
          />
        ) : (
          <Container>
            <CircularProgress />
          </Container>
        )}
      </GraphContainer>
    </Wrapper>
  );
};

export default PipelineActivityTab;
