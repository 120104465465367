import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import * as Sentry from '@sentry/react';
import { BrowserTracing } from '@sentry/tracing';
import { SamplingContext } from '@sentry/types/types/transaction';
import App from './App';
import * as serviceWorker from './serviceWorker';

const tracesSamplerForEnv = (
  samplingContext: SamplingContext
): number | boolean => {
  if (process.env.REACT_APP_ENV === 'development') {
    return 0;
  }
  return 1.0;
};

Sentry.init({
  dsn:
    'https://d614becba6ab4eb9939acc74c2e30eff@o1234234.ingest.sentry.io/6554224',
  integrations: [new BrowserTracing()],
  environment: process.env.REACT_APP_ENV,
  tracesSampler: tracesSamplerForEnv,
});

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
