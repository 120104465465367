import {
  createStore,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state';
import { StoreState } from './types';
import { PIPELINEPAGES } from '../../../common/routes';

const initialState: StoreState = {
  pipelineState: null,
  pipelineName: null,
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setPipelineState: (pipelineState: PIPELINEPAGES | null) => ({
    setState,
  }: StoreApi) => {
    setState({
      pipelineState,
    });
  },
  setPipelineName: (pipelineName: string) => ({ setState }: StoreApi) => {
    setState({
      pipelineName,
    });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const usePipelineStore = createHook(Store);
export const PipelineStoreContainer = createContainer(Store);
