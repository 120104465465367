import {TextField, Typography} from '@material-ui/core';
import React from 'react';
import styled from 'styled-components';
import { useScriptDescription, useScriptsStore } from '../../../scripts-store';

const EditorContainer = styled.div`
  height: 80px;
  display: flex;
  flex-direction: column;
  width: 100%;
`;

const DescriptionEditor: React.FC = () => {
  const [description] = useScriptDescription();
  const [, { setScriptDescription }] = useScriptsStore();

  const saveScriptDescription = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const description = event.target.value;
    setScriptDescription(description);
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      setScriptDescription(description);
    }
  };

  return (
    <EditorContainer>
      <Typography variant="subtitle2" style={{ marginLeft: 2 }}>Script Description: </Typography>
      <TextField
        variant="outlined"
        value={description}
        onChange={saveScriptDescription}
        onKeyPress={handleKeyPress}
        placeholder="Please give your script a description"
        fullWidth
        size="small"
        autoFocus={description === ''}
        rows={2}
        multiline
      />
    </EditorContainer>
  );
};

export default DescriptionEditor;
