import React from 'react';
import styled from 'styled-components';
import { Chip, Tooltip, Typography } from '@material-ui/core';

const StyledChip = styled(Chip)`
  max-width: 100%;
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  font-weight: bold;
`;

interface Props {
  label: string;
  color: 'primary' | 'secondary';
  wrapperWidth?: string | number;
}

const TooltipChip: React.FC<Props> = ({ label, color, wrapperWidth }) => {
  return (
    <div style={{ width: wrapperWidth || 200 }}>
      <Tooltip title={<Typography variant="caption">{label}</Typography>} arrow>
        <StyledChip label={label} size="small" color={color} />
      </Tooltip>
    </div>
  );
};

export default TooltipChip;
