import { PAGEAPIROUTES } from '../../../../../common/routes';
import useDeleteSimpleService from '../../../../../api/useDeleteSimpleService';
import useResourceRouteWithId from '../../../../../utils/useResourceRouteWithId';

const useDeleteDataResourceService = (
  isOutput: boolean
): [
  (dataResourceId: string) => Promise<unknown | void>,
  Error | null,
  () => void
] => {
  const idRoute = useResourceRouteWithId();
  const [deleteSimpleService, error, clearError] = useDeleteSimpleService();

  const deleteDataResourceService = (dataResourceId: string) =>
    deleteSimpleService(
      `${idRoute}${
        isOutput
          ? PAGEAPIROUTES.Output_Data_Resource
          : PAGEAPIROUTES.Sample_Data_Resource
      }/${dataResourceId}`
    );

  return [deleteDataResourceService, error, clearError];
};

export default useDeleteDataResourceService;
