import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Card } from '@material-ui/core';

const StyledCard = styled(Card)<{ disabled?: boolean }>`
  padding: 20px;
  margin: 24px 0;
  background-color: ${(props) => (props.disabled ? '#e8e8e8' : 'white')};
`;

// const CardHeading = styled(Typography)`
//   padding-top: 10px;
//   padding-bottom: 4px;
// `;

const ChildrenWrapper = styled.div`
  margin-top: 24px;
`;

interface Props {
  heading: string;
  disabled?: boolean;
  children: React.ReactNode;
}

const SectionCard = forwardRef<HTMLDivElement, Props>(
  ({ heading, children, disabled }, ref) => {
    return (
      <StyledCard elevation={disabled ? 0 : 4} disabled={disabled} ref={ref}>
        {/*<CardHeading variant="h5" color="secondary" align="center">*/}
        {/*  {heading}*/}
        {/*</CardHeading>*/}
        <ChildrenWrapper>{children}</ChildrenWrapper>
      </StyledCard>
    );
  }
);

export default SectionCard;
