import React, { FunctionComponent, useState } from 'react';
import {
  Button,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import { Close } from '@material-ui/icons';
import styled from 'styled-components';
import { useForm } from 'react-hook-form';
import useAddDatabaseConnectorService, {
  ConnectorInfo,
} from '../../service/useAddDatabaseConnectorService';
import { ConnectorType } from '../../types';
import useErrorAlert from '../../../../../utils/useErrorAlert';
import useEditDatabaseConnectorService from '../../service/useEditDatabaseConnector';
import { useConnectorsStore } from '../../../../connectors/store';
import { ConnectorComponentProps } from '../connector-types/types';

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const Form = styled.form`
  flex: 1;
  display: flex;
  flex-direction: column;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: space-between;
`;

const ButtonGrouping = styled.div`
  display: flex;
  & > * {
    margin-right: 8px;
  }
`;

export type ConnectorTypeInfo = {
  type: string;
  name: string;
  connectorComponent: FunctionComponent<ConnectorComponentProps>;
};

interface Props {
  currentConnectorType: ConnectorTypeInfo;
  onClose: () => void;
  onAddDatabaseConnector?: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType,
    createdTime: string
  ) => void;
  onSuccess: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType
  ) => void;
  connectorData?: Record<string, string>;
  id?: string;
  onExitConnector: () => void;
}

const ConnectorForm: React.FC<Props> = ({
  currentConnectorType,
  onClose,
  onSuccess,
  onAddDatabaseConnector,
  connectorData,
  id,
  onExitConnector,
}) => {
  const [addDataBaseConnector, addError] = useAddDatabaseConnectorService();
  const [editDataBaseConnector, editError] = useEditDatabaseConnectorService(
    id
  );

  const isEditing = Boolean(id);

  const [, { editConnector }] = useConnectorsStore();

  useErrorAlert(addError);
  useErrorAlert(editError);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const buttonText = isEditing ? `Save Connector` : `Add Connector`;

  const {
    handleSubmit,
    reset,
    formState: { errors },
    control,
  } = useForm({
    defaultValues: connectorData,
  });

  const onConnectorSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (currentConnectorType) {
      if (isEditing) {
        const response = await editDataBaseConnector({
          connectorType: currentConnectorType.type,
          connectorInfo: { ...((data as unknown) as ConnectorInfo) },
        });

        if (response) {
          editConnector(
            response.connectorId,
            data.connectorName,
            currentConnectorType.type as ConnectorType
          );
          onSuccess(
            response.connectorId,
            data.connectorName,
            currentConnectorType.type as ConnectorType
          );
          reset({});
        }
      } else {
        const response = await addDataBaseConnector({
          connectorType: currentConnectorType.type,
          connectorInfo: { ...((data as unknown) as ConnectorInfo) },
        });

        if (response) {
          if (onAddDatabaseConnector) {
            onAddDatabaseConnector(
              response.connectorId,
              data.connectorName,
              currentConnectorType.type as ConnectorType,
              response.createdTime
            );
          }
          // success
          onSuccess(
            response.connectorId,
            data.connectorName,
            currentConnectorType.type as ConnectorType
          );
          reset({});
        }
      }
    }
    setIsLoading(false);
  });

  const onExit = () => {
    reset({});
    onExitConnector();
  };

  return (
    <>
      <HeaderContainer>
        <Typography variant="h5" gutterBottom>
          {currentConnectorType.name}
        </Typography>
        <div>
          <IconButton onClick={onClose} disabled={isLoading} size="small">
            <Close fontSize="small" />
          </IconButton>
        </div>
      </HeaderContainer>
      <Form>
        <currentConnectorType.connectorComponent
          errors={errors}
          reset={reset}
          control={control}
          isEditing={isEditing}
        />
      </Form>
      <ButtonFooter>
        <ButtonGrouping>
          {isEditing ? (
            <div />
          ) : (
            <Button onClick={onExit} variant="outlined" disabled={isLoading}>
              Back
            </Button>
          )}
        </ButtonGrouping>
        <Button
          onClick={onConnectorSubmit}
          color="secondary"
          variant="contained"
          disabled={isLoading}
        >
          {isLoading ? (
            <CircularProgress color="inherit" size={20} />
          ) : (
            buttonText
          )}
        </Button>
      </ButtonFooter>
    </>
  );
};

export default ConnectorForm;
