import React from 'react';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import SummaryOutput from './summary-output';
import SchemaAndRules from './schema-and-rules';
import { GetPipelineSummaryData } from '../types';

const Wrapper = styled.div`
  width: 100%;
`;

const InnerContainer = styled.div`
  & > * {
    margin-bottom: 48px;
  }
`;

const Container = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

interface Props {
  isLoading: boolean;
  summaryData: GetPipelineSummaryData | null;
}

const PipelineSummaryTab: React.FC<Props> = ({ summaryData, isLoading }) => {
  return (
    <Wrapper>
      {isLoading ? (
        <Container>
          <CircularProgress />
        </Container>
      ) : (
        summaryData && (
          <InnerContainer>
            <SummaryOutput output={summaryData.output} />
            <SchemaAndRules
              fields={summaryData.fields}
              canAddAdditionalFields={summaryData.canAddAdditionalFields}
              combineAdditionalField={summaryData.combineAdditionalField}
              duplicateDropFields={summaryData.duplicateDropFields}
              mappingRules={summaryData.mappingRules}
            />
          </InnerContainer>
        )
      )}
    </Wrapper>
  );
};

export default PipelineSummaryTab;
