import { MappingRule } from '../../types';
import { useSchemaStore } from '../../store';

const useGetRulesUsedByFieldName = (fieldName: string): MappingRule[] => {
  const [{ mappingRules }] = useSchemaStore();

  return mappingRules.filter((rule) => fieldName === rule.outputField);
};

export default useGetRulesUsedByFieldName;
