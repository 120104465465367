const productionFirebaseConfig = {
  apiKey: 'AIzaSyADi6wKSPSp1n4gEoLgMQP5Bbf0R5UIHlA',
  authDomain: 'platform.segna.io',
  databaseURL: 'https://segna-backend.firebaseio.com',
  projectId: 'segna-backend',
  storageBucket: 'segna-backend.appspot.com',
  messagingSenderId: '721713516385',
  appId: '1:721713516385:web:9d17b0f59a2dfa2a2dff20',
  measurementId: 'G-TQN18DM4KG',
};

const canaryFirebaseConfig = {
  apiKey: 'AIzaSyBx_zQf0RxEUD7noZbgXeAIFpks1FIQErs',
  authDomain: 'segna-platform-canary.firebaseapp.com',
  projectId: 'segna-platform-canary',
  storageBucket: 'segna-platform-canary.appspot.com',
  messagingSenderId: '340337802636',
  appId: '1:340337802636:web:5ab0cb6272e4b5a15faaa9',
};

const stagingFirebaseConfig = {
  apiKey: 'AIzaSyAh_BrC5McplYPBfjDDO1rGHp6za3HHG3I',
  authDomain: 'segna-platform-staging.firebaseapp.com',
  projectId: 'segna-platform-staging',
  storageBucket: 'segna-platform-staging.appspot.com',
  messagingSenderId: '1058788925933',
  appId: '1:1058788925933:web:39defe48b28fcbb69cc7e4',
  measurementId: 'G-8PF73KL6L6',
};

export {
  productionFirebaseConfig,
  canaryFirebaseConfig,
  stagingFirebaseConfig,
};
