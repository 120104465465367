import React from 'react';
import { Checkbox, FormControlLabel, Typography } from '@material-ui/core';
import styled from 'styled-components';
import { Select, SelectChangeEvent, MenuItem } from '@mui/material';
import { useSchemaStore } from '../../../store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
`;

const StyledLabel = styled(FormControlLabel)`
  background-color: transparent;
  padding-right: 12px;
`;

const CombineAdditionalFieldContainer = styled.div`
  display: flex;
  align-items: center;
  margin-left: 32px;
`;

const AdditionalFieldsOption: React.FC = () => {
  const [
    { isSetOutputSchema, canAddFields, combineAdditionalField, fields },
    { setCanAddFields, setCombineAdditionalField },
  ] = useSchemaStore();

  const handleClick = (event: React.ChangeEvent<HTMLInputElement>) => {
    setCanAddFields(event.target.checked);
  };

  const handleFieldChange = (event: SelectChangeEvent) => {
    const newCombineAdditionalField = event.target.value as string;
    if (newCombineAdditionalField === '') {
      setCombineAdditionalField(null);
    } else {
      setCombineAdditionalField(newCombineAdditionalField);
    }
  };

  return (
    <Wrapper>
      <StyledLabel
        control={<Checkbox color="secondary" onChange={handleClick} />}
        checked={canAddFields}
        label="Allow columns from uploaded data to be added as additional fields"
        labelPlacement="end"
        disabled={isSetOutputSchema}
      />
      {canAddFields && (
        <CombineAdditionalFieldContainer>
          <Typography>
            Combine all additional fields as JSON into one field:
          </Typography>
          <Select
            value={combineAdditionalField || ''}
            renderValue={(value) => {
              return value === '' ? (
                <span style={{ fontStyle: 'italic' }}>Do not combine</span>
              ) : (
                value
              );
            }}
            onChange={handleFieldChange}
            size="small"
            displayEmpty
            sx={{ width: '320px', ml: '8px', backgroundColor: 'white' }}
          >
            <MenuItem value="" key="">
              <span style={{ fontStyle: 'italic' }}>Do not combine</span>
            </MenuItem>
            {Object.keys(fields).map((field) => (
              <MenuItem value={field}>{field}</MenuItem>
            ))}
          </Select>
        </CombineAdditionalFieldContainer>
      )}
    </Wrapper>
  );
};

export default AdditionalFieldsOption;
