import React from 'react';
import styled from 'styled-components';
import EditableInput from '../../../../../common/editable-input';
import { useScriptsStore } from '../../../scripts-store';

const Wrapper = styled.div`
  margin: 0 24px 0 12px;
  flex: 1;
  min-width: 0;
`;

const ScriptNameEditor: React.FC = () => {
  const [{ name }, { setScriptName }] = useScriptsStore();

  return (
    <Wrapper>
      <EditableInput
        value={name}
        onSave={setScriptName}
        allowPunctuation
        textStyles={{
          fontWeight: 'bold',
          fontSize: 28,
        }}
      />
    </Wrapper>
  );
};

export default ScriptNameEditor;
