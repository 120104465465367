import React, { useEffect, useState } from 'react';
import {
  BrowserRouter as Router,
  Switch,
  Route,
  useRouteMatch,
  Redirect,
  useLocation,
} from 'react-router-dom';
import styled from 'styled-components';
import { ErrorBoundary } from 'react-error-boundary';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import mixpanel from 'mixpanel-browser';
import HeaderMenu, { HEADER_HEIGHT } from '../header-menu';
import LoginPage from '../login-page';
import ROUTES from '../../common/routes';
import { useAuthContext, AuthProvider } from '../../firebase/AuthProvider';
import FullLoadingOverlay from '../common/full-loading-overlay';
import ErrorScreen from './error-screen';
import SideMenuWithContentWrapper from '../side-menu-with-content-wrapper';
import PipelineRoutes from './pipeline-routes';
import SettingsRoutes from './settings-routes';
import QuickStartPage from '../quick-start-page';
import { SpotlightProvider } from '../spotlight-tour/SpotlightProvider';
import ScriptsRoutes from './scripts-routes';
import { TrackPageVisited } from '../../utils/useTrackEvent';
import OnboardingWizardRoutes from './onboarding-wizard-routes';
import ConnectorsPage from '../connectors';
import NewSignInPage from '../new-signin-page';

const PageWithHeaderPadding = styled.div`
  padding-top: ${HEADER_HEIGHT}px;
  box-sizing: border-box;
`;

const StyledToastContainer = styled(ToastContainer)`
  &&&.Toastify__toast-container {
  }
  .Toastify__toast {
  }
  .Toastify__toast-body {
    min-width: 0;
    font-size: 0.9rem;
    overflow-wrap: break-word;
  }
  .Toastify__progress-bar {
  }
`;

const PrivateRoutes: React.FC = () => {
  const { path } = useRouteMatch();
  const location = useLocation();
  const { isAuthenticated, isLoadingAuthState } = useAuthContext();
  const [errorBoundaryKey, setErrorBoundaryKey] = useState<number>(0);

  useEffect(() => {
    mixpanel.track('page visited');
  }, [location.pathname]);

  const onManualResetErrorBoundary = () => {
    setErrorBoundaryKey(errorBoundaryKey + 1);
  };

  if (isLoadingAuthState) {
    return (
      <>
        <HeaderMenu />
        <FullLoadingOverlay isLoading />
      </>
    );
  }

  if (isAuthenticated) {
    if (
      location.pathname.includes(`${ROUTES.App}${ROUTES.Onboarding_Wizard}`)
    ) {
      return (
        <Route path={`${path}${ROUTES.Onboarding_Wizard}`}>
          <OnboardingWizardRoutes />
        </Route>
      );
    }

    return (
      <>
        <HeaderMenu onManualResetErrorBoundary={onManualResetErrorBoundary} />
        <PageWithHeaderPadding>
          <SideMenuWithContentWrapper
            onManualResetErrorBoundary={onManualResetErrorBoundary}
          >
            <ErrorBoundary
              FallbackComponent={ErrorScreen}
              key={errorBoundaryKey}
            >
              <SpotlightProvider>
                <Switch>
                  <Route exact path={path}>
                    <Redirect to={`${path}${ROUTES.Pipeline}`} />
                  </Route>
                  <Route path={`${path}${ROUTES.Quick_Start}`}>
                    <TrackPageVisited pageName="Quick_Start" />
                    <QuickStartPage />
                  </Route>
                  {/* <Route path={`${path}${ROUTES.Job}`}> */}
                  {/*  <JobRoutes /> */}
                  {/* </Route> */}
                  <Route path={`${path}${ROUTES.Pipeline}`}>
                    <PipelineRoutes />
                  </Route>
                  <Route path={`${path}${ROUTES.Settings}`}>
                    <SettingsRoutes />
                  </Route>
                  <Route path={`${path}${ROUTES.Scripts}`}>
                    <ScriptsRoutes />
                  </Route>
                  <Route path={`${path}${ROUTES.Connectors}`}>
                    <ConnectorsPage />
                  </Route>
                  <Redirect to={ROUTES.App} />
                </Switch>
              </SpotlightProvider>
            </ErrorBoundary>
          </SideMenuWithContentWrapper>
        </PageWithHeaderPadding>
      </>
    );
  }

  // Not authenticated
  return <Redirect to={ROUTES.SignIn} />;

  // Not authenticated
  // return <Redirect to={ROUTES.Login} />;
};

const PageRouter: React.FC = () => {
  const [errorBoundaryKey] = useState<number>(0);

  return (
    <AuthProvider>
      <Router>
        <Switch>
          <Route exact path={ROUTES.SignIn}>
            <TrackPageVisited pageName="Login" />
            <NewSignInPage />
          </Route>
          <Route exact path={ROUTES.Login}>
            <TrackPageVisited pageName="Login" />
            <LoginPage />
            <StyledToastContainer
              position="bottom-left"
              hideProgressBar
              autoClose={5000}
              pauseOnFocusLoss
              newestOnTop={false}
              closeOnClick
              rtl={false}
              draggable
            />
          </Route>
          <Route path={ROUTES.App}>
            <ErrorBoundary
              FallbackComponent={ErrorScreen}
              key={errorBoundaryKey}
            >
              <PrivateRoutes />
            </ErrorBoundary>
          </Route>
          <Redirect to={ROUTES.SignIn} />
        </Switch>
        <StyledToastContainer
          position="bottom-left"
          hideProgressBar
          autoClose={10000}
          pauseOnFocusLoss
          newestOnTop={false}
          closeOnClick
          rtl={false}
          draggable
        />
      </Router>
    </AuthProvider>
  );
};

export default PageRouter;
