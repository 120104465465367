import React from 'react';
import { useHistory } from 'react-router-dom';
import { Step, StepButton } from '@material-ui/core';
import styled from 'styled-components';
import ROUTES, { ONBOARDINGWIZARDPAGES } from '../../../../common/routes';

interface Props {
  canGoBack: boolean;
  label: string;
  to: ONBOARDINGWIZARDPAGES;
}

const StyledStepButton = styled(StepButton)`
  transition: background-color 250ms ease-out;
`;

const RedirectableStepButton: React.FC<Props> = ({
  canGoBack,
  label,
  to,
  ...other // Have to do this otherwise styling and stuff breaks (MUI thing)
}) => {
  const history = useHistory();

  const handleRedirect = () => {
    history.push(`${ROUTES.App}${ROUTES.Onboarding_Wizard}/${to}`);
  };

  return (
    <Step {...other}>
      <StyledStepButton
        completed={canGoBack}
        disabled={!canGoBack}
        onClick={handleRedirect}
        disableRipple
      >
        {label}
      </StyledStepButton>
    </Step>
  );
};

export default RedirectableStepButton;
