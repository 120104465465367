import React from 'react';
import styled from 'styled-components';
import { Button, Paper, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useParams } from 'react-router-dom';
import { selectLogoFromType } from '../../../../common/data-resources-list';
import ROUTES, {
  PIPELINEPAGES,
  PIPELINESTATUSROUTES,
} from '../../../../../common/routes';

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
`;

const OutputContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 8px 0;
`;

const LogoSvg = styled.img`
  width: 80px;
  height: 80px;
`;

const DatabasePaper = styled(Paper)`
  display: flex;
  flex-direction: row;
  min-height: 100px;
  padding: 16px;
  margin: 8px 0;
  align-items: center;
`;

const OutputName = styled.div`
  text-overflow: ellipsis;
  white-space: normal;
  overflow: hidden;
  padding: 16px;
  margin: 8px;
`;

interface Props {
  output: {
    [dataResourceId: string]: {
      dataResourceName: string;
      dataResourceType: string;
    };
  };
}

const SummaryOutput: React.FC<Props> = ({ output }) => {
  const history = useHistory();
  const { pipelineId } = useParams<{
    pipelineId?: string;
  }>();

  const onEdit = () => {
    history.push(
      `${ROUTES.App}${ROUTES.Pipeline}/${pipelineId}${PIPELINESTATUSROUTES.Draft}/${PIPELINEPAGES.Data_Sources}`
    );
  };

  return (
    <StyledPaper>
      <HeaderWrapper>
        <Typography variant="h4">Output Destinations</Typography>
        <div>
          <Button
            onClick={onEdit}
            startIcon={<EditIcon />}
            variant="outlined"
            size="small"
          >
            Edit
          </Button>
        </div>
      </HeaderWrapper>
      <OutputContainer>
        {Object.entries(output).map(([id, dataResourceInfo]) => (
          <DatabasePaper elevation={5} key={id}>
            <LogoSvg
              src={selectLogoFromType(dataResourceInfo.dataResourceType)}
            />
            <OutputName>
              <Typography variant="h6">
                {dataResourceInfo.dataResourceName}
              </Typography>
            </OutputName>
          </DatabasePaper>
        ))}
      </OutputContainer>
    </StyledPaper>
  );
};

export default SummaryOutput;
