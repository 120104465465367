import React from 'react';
import {
  Table,
  TableBody,
  TableCell,
  TableHead,
  TableRow,
  Typography,
} from '@material-ui/core';
import styled from 'styled-components';
import ScriptsListRow from './scripts-list-row';
import { useScriptsListStore } from '../scripts-list-store';

const TableContainer = styled.div`
  margin: 16px 0;
`;

const TitleContainer = styled.div``;

const MessageContainer = styled(TableCell)`
  margin: 16px 0;
  padding: 8px 0;
  text-align: center;
  background-color: white;
`;

const ScriptsListTable: React.FC = () => {
  const [{ scriptHistory }] = useScriptsListStore();

  return (
    <TableContainer>
      <Table>
        <TableHead>
          <TableRow>
            <TableCell style={{ width: 160 }}>
              <TitleContainer style={{ width: 120 }}>
                <Typography variant="subtitle1">Name</Typography>
              </TitleContainer>
            </TableCell>
            <TableCell style={{ width: 100 }}>
              <Typography variant="subtitle1">ID</Typography>
            </TableCell>
            <TableCell style={{ width: 140 }}>
              <Typography variant="subtitle1">Last Updated</Typography>
            </TableCell>
            {/* <TableCell> */}
            {/*  <Typography variant="subtitle1">Language</Typography> */}
            {/* </TableCell> */}
            <TableCell style={{ width: 580 }}>
              <Typography variant="subtitle1">Description</Typography>
            </TableCell>
            {/* need to include these last two to increase the width of the bottom horizontal border - best to figure out how to get around this */}
            <TableCell />
            <TableCell />
            {/* <TableCell> */}
            {/*  <Typography variant="subtitle1">Shared?</Typography> */}
            {/* </TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(scriptHistory).length > 0 ? (
            Object.entries(scriptHistory).map(([scriptId, scriptInfo]) => (
              <ScriptsListRow scriptId={scriptId} {...scriptInfo} />
            ))
          ) : (
            <TableRow>
              <MessageContainer colSpan={4}>
                <Typography variant="subtitle1">
                  No scripts available, please create a new script
                </Typography>
              </MessageContainer>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default ScriptsListTable;
