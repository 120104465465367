import React from 'react';
import styled from 'styled-components';
import { useSchemaStore } from '../../store';
import { Field } from '../../types';
import CategoryValueInput from '../common/category-value-input';
import CategoryChipBox from '../common/category-chip-box';

const Label = styled.p`
  margin: 0 0 8px 4px;
  font-size: 14px;
`;

interface Props {
  field: Field;
}

const CategoryInputChips: React.FC<Props> = ({ field }) => {
  const [, { addCategoryValue, removeCategoryValue }] = useSchemaStore();

  const handleAddValue = (value: string) => {
    addCategoryValue(field.name, value);
  };

  const handleDelete = (value: string) => {
    removeCategoryValue(field.name, value);
  };

  return (
    <>
      <Label>Category Values</Label>
      <CategoryValueInput
        placeholder="Enter a value"
        size="small"
        margin="normal"
        fullWidth
        sx={{ mt: 0 }}
        onSubmitSuccess={handleAddValue}
      />
      <CategoryChipBox
        values={field.allowedValues}
        onDeleteValue={handleDelete}
      />
    </>
  );
};

export default CategoryInputChips;
