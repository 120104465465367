import { useLocation } from 'react-router-dom';
import { ONBOARDINGWIZARDPAGES } from '../../../../common/routes';

interface WizardPageData {
  pages: ONBOARDINGWIZARDPAGES[];
  currentPage: ONBOARDINGWIZARDPAGES;
  currentStepIndex: number;
  isLastStep: boolean;
}

/**
 * Hook for centralizing the logic of getting route/page data which can be used
 * in other component logic (e.g. header step-bar or footer back/next actions)
 */
const useGetWizardRoutingData = (): WizardPageData => {
  const location = useLocation();

  const pages = Object.values(ONBOARDINGWIZARDPAGES);

  const currentPage = location.pathname
    .split('/')
    .pop() as ONBOARDINGWIZARDPAGES;

  const currentStepIndex = pages.indexOf(currentPage);

  const isLastStep = currentStepIndex === pages.length - 1;

  return {
    pages,
    currentPage,
    currentStepIndex,
    isLastStep,
  };
};

export default useGetWizardRoutingData;
