import useResourceRouteWithId from '../../../../../utils/useResourceRouteWithId';
import { GetPipelineSummaryData } from '../../types';
import useFetchSimpleService from '../../../../../api/useFetchSimpleService';
import {
  PIPELINEAPIS,
  PIPELINEDASHBOARDAPIS,
} from '../../../../../common/routes';

const constructRoute = (resourceRouteWithId: string) => {
  return `${resourceRouteWithId}${PIPELINEAPIS.Pipeline_Dashboard}${PIPELINEDASHBOARDAPIS.Summary}`;
};

const usePipelineSummaryService = (): [
  GetPipelineSummaryData | null,
  boolean,
  Error | null
] => {
  const idRoute = useResourceRouteWithId();
  const summaryRoute = constructRoute(idRoute);

  const [data, isLoading, error] = useFetchSimpleService<
    GetPipelineSummaryData
  >(summaryRoute);

  return [data, isLoading, error];
};

export default usePipelineSummaryService;
