import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import { PipelineStoreContainer } from '../../pipeline-pages/pipeline-store';
import {
  PIPELINEDASHBOARDPAGES,
  PIPELINEPAGES,
  PIPELINESTATUSROUTES,
} from '../../../common/routes';
import PipelinesListPage from '../../pipeline-pages/pipelines-list-page';
import InvalidPage from '../invalid-page';
import SchemaPage from '../../data-onboarding-pages/schema-page';
import DataSourcesImporting from '../../data-onboarding-pages/data-sources-importing';
import PipelineDashboardPage from '../../pipeline-pages/pipeline-dashboard-page';
import { TrackPageVisited } from '../../../utils/useTrackEvent';

const PipelineBuilderRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <PipelineStoreContainer>
        <Route exact path={`${path}/${PIPELINEPAGES.Data_Sources}`}>
          <TrackPageVisited pageName="Data_Sources" />
          <DataSourcesImporting />
        </Route>
        <Route exact path={`${path}/${PIPELINEPAGES.Schema}`}>
          <TrackPageVisited pageName="Schema" />
          <SchemaPage />
        </Route>
      </PipelineStoreContainer>
    </Switch>
  );
};

const PipelineRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  return (
    <Switch>
      <Route exact path={path}>
        <TrackPageVisited pageName="Pipelines" />
        <PipelinesListPage />
      </Route>
      <Redirect
        exact
        from={`${path}/:pipelineId`}
        to={`${path}/:pipelineId${PIPELINEDASHBOARDPAGES.Activity}`}
      />
      <Route
        exact
        path={`${path}/:pipelineId${PIPELINEDASHBOARDPAGES.Activity}`}
      >
        <TrackPageVisited pageName="Pipeline_Activity" />
        <PipelineDashboardPage shownInformation="activity" />
      </Route>
      <Route exact path={`${path}/:pipelineId${PIPELINEDASHBOARDPAGES.Jobs}`}>
        <TrackPageVisited pageName="Pipeline_Jobs" />
        <PipelineDashboardPage shownInformation="jobs" />
      </Route>
      <Route
        exact
        path={`${path}/:pipelineId${PIPELINEDASHBOARDPAGES.Summary}`}
      >
        <TrackPageVisited pageName="Pipeline_Summary" />
        <PipelineDashboardPage shownInformation="summary" />
      </Route>
      <Route
        exact
        path={`${path}/:pipelineId${PIPELINEDASHBOARDPAGES.Test_Job}`}
      >
        <TrackPageVisited pageName="Pipeline_Test_Job" />
        <PipelineDashboardPage shownInformation="test-job" />
      </Route>
      <Route path={`${path}/:pipelineId${PIPELINESTATUSROUTES.Draft}`}>
        <PipelineBuilderRoutes />
      </Route>
      <Route render={() => <InvalidPage />} />
    </Switch>
  );
};

export default PipelineRoutes;
