import { DataTypes } from '../../../../decision-pages/units-page/units-store/types';
import { DateTimeFormats } from '../../../../decision-pages/units-page/field-column-units/units-row/date-format-input';
import AddFieldActionTypes from './add-field-action-types';

interface FormValues {
  fieldName: string;
  dataType: DataTypes;
  outputUnit: string | null;
  dateTimeFormat: string | null;
  optional: boolean;
  dropMissing: boolean;
  allowedCategoryValues: string[];
}

interface State {
  modalIsOpen: boolean;
  form: FormValues;
  error: string;
}

const initialFormValues: FormValues = {
  fieldName: '',
  dataType: DataTypes.RICH_TEXT,
  outputUnit: null,
  dateTimeFormat: null,
  optional: false,
  dropMissing: false,
  allowedCategoryValues: [],
};

export const initialState: State = {
  modalIsOpen: false,
  form: initialFormValues,
  error: '',
};

type Action =
  | { type: AddFieldActionTypes.OpenModal }
  | { type: AddFieldActionTypes.CloseModal }
  | { type: AddFieldActionTypes.FieldName; payload: string }
  | { type: AddFieldActionTypes.DataType; payload: DataTypes }
  | { type: AddFieldActionTypes.OutputUnit; payload: string }
  | { type: AddFieldActionTypes.DateTime; payload: string }
  | { type: AddFieldActionTypes.Optional; payload: boolean }
  | { type: AddFieldActionTypes.DropMissing; payload: boolean }
  | { type: AddFieldActionTypes.SetError; payload: string }
  | { type: AddFieldActionTypes.ClearError }
  | { type: AddFieldActionTypes.AddCategoryValue; payload: string }
  | { type: AddFieldActionTypes.RemoveCategoryValue; payload: string };

const handleDataTypeCase = (state: State, payload: DataTypes): State => {
  switch (payload) {
    case DataTypes.NUMBER:
      return {
        ...state,
        form: {
          ...state.form,
          dataType: DataTypes.NUMBER,
          outputUnit: null,
          dateTimeFormat: null,
        },
      };
    case DataTypes.RICH_TEXT:
      return {
        ...state,
        form: {
          ...state.form,
          dataType: DataTypes.RICH_TEXT,
          outputUnit: null,
          dateTimeFormat: null,
        },
      };
    case DataTypes.CATEGORY:
      return {
        ...state,
        form: {
          ...state.form,
          dataType: DataTypes.CATEGORY,
          outputUnit: null,
          dateTimeFormat: null,
        },
      };
    case DataTypes.DATETIME:
      return {
        ...state,
        form: {
          ...state.form,
          dataType: DataTypes.DATETIME,
          outputUnit: 'none',
          dateTimeFormat: DateTimeFormats[0].format,
        },
      };
    default:
      return state;
  }
};

const addFieldReducer = (
  state: State = initialState,
  action: Action
): State => {
  switch (action.type) {
    case AddFieldActionTypes.OpenModal:
      return { modalIsOpen: true, form: initialFormValues, error: '' };
    case AddFieldActionTypes.CloseModal:
      return { modalIsOpen: false, form: initialFormValues, error: '' };
    case AddFieldActionTypes.FieldName:
      return { ...state, form: { ...state.form, fieldName: action.payload } };
    case AddFieldActionTypes.DataType:
      return handleDataTypeCase(state, action.payload);
    case AddFieldActionTypes.OutputUnit:
      return { ...state, form: { ...state.form, outputUnit: action.payload } };
    case AddFieldActionTypes.DateTime:
      return {
        ...state,
        form: { ...state.form, dateTimeFormat: action.payload },
      };
    case AddFieldActionTypes.Optional:
      return { ...state, form: { ...state.form, optional: action.payload } };
    case AddFieldActionTypes.DropMissing:
      return { ...state, form: { ...state.form, dropMissing: action.payload } };
    case AddFieldActionTypes.SetError:
      return { ...state, error: action.payload };
    case AddFieldActionTypes.ClearError:
      return { ...state, error: '' };
    case AddFieldActionTypes.AddCategoryValue:
      return {
        ...state,
        form: {
          ...state.form,
          allowedCategoryValues: [
            ...state.form.allowedCategoryValues,
            action.payload,
          ],
        },
      };
    case AddFieldActionTypes.RemoveCategoryValue:
      return {
        ...state,
        form: {
          ...state.form,
          allowedCategoryValues: state.form.allowedCategoryValues.filter(
            (value) => value !== action.payload
          ),
        },
      };
    default:
      return state;
  }
};

export default addFieldReducer;
