import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CircularProgress,
  Typography,
  withStyles,
} from '@material-ui/core';
import { toast } from 'react-toastify';
import RefreshIcon from '@material-ui/icons/Refresh';
import EditableInput from '../../../common/editable-input';
import { useAuthContext } from '../../../../firebase/AuthProvider';
import useRefreshApiKeyService from '../service/useRefreshApiKeyService';
import useErrorAlert from '../../../../utils/useErrorAlert';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import { SETTINGSAPI } from '../../../../common/routes';
import AccessKeyFields from './access-key-fields';

const Wrapper = styled(Card)`
  margin: 16px 0;
  padding: 24px;
`;

const PublicContainer = styled.div`
  padding: 12px 12px 4px 12px;
  display: grid;
  grid-template-rows: 48px 1fr;
  grid-column-gap: 16px;
`;

const DetailsGrid = styled.div`
  display: grid;
  grid-template-columns: 140px 1fr;
  grid-column-gap: 16px;
  grid-row-gap: 16px;
  align-items: center;
  margin: 24px 0;
`;

const KeyWrapper = styled.div`
  margin-left: 14px;
  height: 40px;
  display: flex;
  align-items: center;
`;

const TextWrapper = styled.div`
  display: flex;
  align-items: center;
`;

const KeyTextWrapper = styled.div`
  display: flex;
  align-items: center;
  padding-left: 14px;
`;

const SquareButtonWrapper = withStyles({
  root: {
    minWidth: '0',
  },
})(Button);

const RefreshButton = styled(SquareButtonWrapper)`
  margin-left: 8px;
`;

const ProfileInfoSection: React.FC = () => {
  const [data, isLoading] = useFetchSimpleService<{
    apiKey: string | null;
  }>(SETTINGSAPI.Api_Key);

  const { user, refreshCurrentUser } = useAuthContext();
  const [refreshApiKey, errorApiKey] = useRefreshApiKeyService();
  useErrorAlert(errorApiKey);

  const [apiKey, setApiKey] = useState<string | null>(null);

  const [isApiKeyLoading, setIsApiKeyLoading] = useState<boolean>(false);

  const onDisplayNameSave = async (newName: string) => {
    try {
      await user?.updateProfile({
        displayName: newName,
      });
      refreshCurrentUser();
      toast.success('Name has updated successfully!');
    } catch (e) {
      toast.error(e.message);
    }
  };

  const onRefreshApiKey = async () => {
    setIsApiKeyLoading(true);
    const response = await refreshApiKey({});
    if (response) {
      setApiKey(response.apiKey);
    }
    setIsApiKeyLoading(false);
  };

  useEffect(() => {
    if (data?.apiKey) {
      setApiKey(data.apiKey);
    }
  }, [data]);

  const isKeySectionLoading = isLoading || isApiKeyLoading;

  return (
    <Wrapper>
      <PublicContainer>
        <Typography variant="h5">Profile Information</Typography>
        <DetailsGrid>
          <span>Name:</span>
          <KeyWrapper>
            <TextWrapper>
              {user?.displayName && (
                <EditableInput
                  value={user.displayName}
                  onSave={onDisplayNameSave}
                />
              )}
            </TextWrapper>
          </KeyWrapper>
          <span>API Key:</span>
          <KeyWrapper>
            {isKeySectionLoading ? (
              <CircularProgress color="primary" size={28} />
            ) : (
              <KeyTextWrapper>
                {apiKey ?? 'Generate a new API Key!'}
                <RefreshButton
                  onClick={onRefreshApiKey}
                  size="small"
                  variant="outlined"
                  disabled={isKeySectionLoading}
                >
                  <RefreshIcon />
                </RefreshButton>
              </KeyTextWrapper>
            )}
          </KeyWrapper>
        </DetailsGrid>
      </PublicContainer>
      <AccessKeyFields />
    </Wrapper>
  );
};

export default ProfileInfoSection;
