const UnitsTextMapping: { [unitKey: string]: string } = {
  none: 'none', // TODO: Remove this once 'none' is no longer returned from BE

  KG: 'Kilograms',
  G: 'Grams',
  POUND: 'Pounds',
  OUNCE: 'Ounces',
  THOU: 'Thousandths of an Inch',
  INCH: 'Inches',
  FOOT: 'Feet',
  YARD: 'Yards',
  MILE: 'Miles',
  MILLIMETRE: 'Millimeters',
  CENTIMETRE: 'Centimeters',
  METRE: 'Meters',
  MILLILITRE: 'Milliliters',
  LITRE: 'Liters',
  FLUID_OUNCE: 'Fluid Ounces',
  PINT: 'Pints',
  GALLON: 'Gallons',
  CUBIC: 'Cubic Meters',
  CELSIUS: 'Celsius',
  FAHRENHEIT: 'Fahrenheit',
  KELVIN: 'Kelvin',
  SECOND: 'Seconds',
  MINUTE: 'Minutes',
  HOUR: 'Hours',
  DAY: 'Days',
  WEEK: 'Weeks',
  MONTH: 'Months',
  YEAR: 'Years',

  UTC_m12: 'UTC - 12',
  UTC_m11: 'UTC - 11',
  UTC_m10: 'UTC - 10',
  UTC_m930: 'UTC - 9.5',
  UTC_m9: 'UTC - 9',
  UTC_m8: 'UTC - 8',
  UTC_m7: 'UTC - 7',
  UTC_m6: 'UTC - 6',
  UTC_m5: 'UTC - 5',
  UTC_m4: 'UTC - 4',
  UTC_m330: 'UTC - 3.5',
  UTC_m3: 'UTC - 3',
  UTC_m2: 'UTC - 2',
  UTC_m1: 'UTC - 1',
  UTC_0: 'UTC',
  UTC_p14: 'UTC + 14',
  UTC_p13: 'UTC + 13',
  UTC_p1245: 'UTC + 12.75',
  UTC_p12: 'UTC + 12',
  UTC_p11: 'UTC + 11',
  UTC_p1030: 'UTC + 10.5',
  UTC_p10: 'UTC + 10',
  UTC_p930: 'UTC + 9.5',
  UTC_p9: 'UTC + 9',
  UTC_p845: 'UTC + 8.75',
  UTC_p8: 'UTC + 8',
  UTC_p7: 'UTC + 7',
  UTC_p630: 'UTC + 6.5',
  UTC_p6: 'UTC + 6',
  UTC_p545: 'UTC + 5.75',
  UTC_p530: 'UTC + 5.5',
  UTC_p5: 'UTC + 5',
  UTC_p430: 'UTC + 4.5',
  UTC_p4: 'UTC + 4',
  UTC_p330: 'UTC + 3.5',
  UTC_p3: 'UTC + 3',
  UTC_p2: 'UTC + 2',
  UTC_p1: 'UTC + 1',
};

const convertUnitText = (unit: string | null): string => {
  if (unit) {
    return UnitsTextMapping[unit];
  }

  return 'none';
};

export default convertUnitText;
