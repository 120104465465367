import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  ClickAwayListener,
  InputAdornment,
  TextField,
  Tooltip,
  withStyles,
} from '@material-ui/core';
import DoneIcon from '@material-ui/icons/Done';
import CloseIcon from '@material-ui/icons/Close';
import COLORS from '../../../common/colors';

const Wrapper = styled.div`
  width: 100%;
`;

const TextWrapper = styled.div`
  padding: 10.5px 14px;
  transition: background-color 200ms ease;
  ${(props: { isDisabled?: boolean }) =>
    !props.isDisabled &&
    `cursor: pointer;
     :hover {
      background-color: ${COLORS.Grey};
     }`};

  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  border-radius: 4px;
`;

const NoMarginTextField = withStyles({
  root: {
    margin: 0,
  },
})(TextField);

const TextFieldWrapper = styled.div`
  position: relative;
`;

const FloatingButtonGroupWrapper = styled.div`
  position: absolute;
  top: 100%;
  right: 0;
  display: flex;
  margin-top: 2px;
  z-index: 10;
`;

const SquareButton = withStyles({
  root: {
    width: 32,
    minWidth: 32,
    height: 32,
  },
})(Button);

const ActionButton = styled(SquareButton)`
  color: white;
  margin-left: 4px;
`;

export interface Props {
  value: string;
  onSave: (newValue: string) => void;
  disabledValues?: string[];
  disabledValuesTooltipText?: string;
  isDisabled?: boolean;
  allowPunctuation?: boolean;
  textStyles?: React.CSSProperties;
}

const EditableInput: React.FC<Props> = ({
  value,
  onSave,
  disabledValues,
  disabledValuesTooltipText,
  isDisabled,
  allowPunctuation,
  textStyles,
}) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [editValue, setEditValue] = useState<string>(value);
  const tooltipText = useRef<string>('');

  // eslint-disable-next-line
  const isNotValidText = /[~`!#$%\^&*+=\-\[\]\\';,/{}|\\":<>\?]/g.test(
    editValue
  ) || editValue.trim() === '';
  const isDisabledValue =
    disabledValues && disabledValues.includes(editValue) && editValue !== value;

  if (isNotValidText) {
    tooltipText.current = 'Special characters are not allowed';
  } else if (isDisabledValue && disabledValuesTooltipText) {
    tooltipText.current = disabledValuesTooltipText;
  } else {
    tooltipText.current = '';
  }

  const isSaveDisabled = allowPunctuation
    ? editValue === '' || isDisabledValue
    : editValue === '' || isNotValidText || isDisabledValue;

  const handleChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEditValue(event.target.value);
  };

  const handleTextClick = () => {
    if (!isDisabled) {
      setIsEditMode(true);
    }
  };

  const handleSave = () => {
    if (!isSaveDisabled) {
      setIsLoading(true);
      if (editValue !== value) {
        onSave(editValue);
      }
      setIsEditMode(false);
      setIsLoading(false);
    }
  };

  const handleClose = () => {
    if (!isLoading) {
      setIsEditMode(false);
    }
  };

  const handleCancel = () => {
    setEditValue(value);
    handleClose();
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      handleSave();
    }
    if (event.key === 'Escape') {
      handleClose();
    }
  };

  const handleFocus = (event: React.FocusEvent<HTMLInputElement>) => {
    event.target.select();
  };

  useEffect(() => {
    setEditValue(value);
  }, [value]);

  return (
    <Wrapper>
      {isEditMode ? (
        <ClickAwayListener onClickAway={handleClose} mouseEvent="onMouseDown">
          <TextFieldWrapper>
            <NoMarginTextField
              onChange={handleChange}
              onKeyPress={handleKeyPress}
              onKeyDown={handleKeyPress}
              onFocus={handleFocus}
              value={editValue}
              variant="outlined"
              margin="dense"
              fullWidth
              autoFocus
              disabled={isDisabled || isLoading}
              InputProps={{
                endAdornment: (
                  <InputAdornment position="end">
                    {isLoading && (
                      <CircularProgress color="inherit" size={20} />
                    )}
                  </InputAdornment>
                ),
                style: textStyles,
              }}
            />
            <FloatingButtonGroupWrapper>
              <Tooltip
                title={tooltipText.current}
                disableFocusListener={!tooltipText.current}
                disableHoverListener={!tooltipText.current}
                disableTouchListener={!tooltipText.current}
              >
                <span>
                  <ActionButton
                    onClick={handleSave}
                    variant="contained"
                    color="secondary"
                    disabled={isSaveDisabled || isLoading}
                  >
                    <DoneIcon />
                  </ActionButton>
                </span>
              </Tooltip>
              <ActionButton
                onClick={handleCancel}
                variant="contained"
                disabled={isLoading}
              >
                <CloseIcon />
              </ActionButton>
            </FloatingButtonGroupWrapper>
          </TextFieldWrapper>
        </ClickAwayListener>
      ) : (
        <TextWrapper
          onClick={handleTextClick}
          isDisabled={isDisabled}
          style={textStyles}
        >
          {value}
        </TextWrapper>
      )}
    </Wrapper>
  );
};

export default EditableInput;
