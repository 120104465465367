import { PAGEAPIROUTES } from '../../../../common/routes';
import usePostSimpleService from '../../../../api/usePostSimpleService';

interface AddRequest {
  email: string;
  password: string;
  displayName: string;
}

type AddResponse = {
  email: string;
  password: string;
};

const useCreateNewUserService = (): [
  (body: AddRequest) => Promise<AddResponse | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<AddRequest, AddResponse>(
    PAGEAPIROUTES.Create_User
  );
};

export default useCreateNewUserService;
