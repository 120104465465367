import React from 'react';
import styled from 'styled-components';
import ConfigStepHeading from '../../config-step-heading';
import COLORS from '../../../../../common/colors';
import InlineCodeBlock from '../../../../common/inline-code-block';
import { useOnboardingWizardStore } from '../../../store';
import useGetSamplePipelineId from '../../service/useGetSamplePipelineId';
import CopyCodeBlock from '../../../../common/copy-code-block';

const StepSection = styled.div`
  margin-bottom: 24px;
`;

const HttpsPanel: React.FC = () => {
  const [{ apiKey }] = useOnboardingWizardStore();
  const samplePipelineId = useGetSamplePipelineId();

  const START_JOB_TEXT = `curl -X POST https://backend.segna.io/public/client-side/v1/start \\
    -H 'x-api-key: ${apiKey || '<API_KEY>'}' \\
    -F 'pipelineId=${samplePipelineId}' \\
    -F "files=@path/to/file.csv" \\
`;

  const RUN_JOB_TEXT = `curl -X POST https://backend.segna.io/public/client-side/v1/run/<JOB_ID> \\
    -H 'x-api-key: ${apiKey || '<API_KEY>'}'
`;
  return (
    <div>
      <StepSection>
        <ConfigStepHeading stepNumber={1} bgColor={COLORS.DarkBlue}>
          Start Job
        </ConfigStepHeading>
        To begin an import, call our <InlineCodeBlock>startJob</InlineCodeBlock>{' '}
        API. This will return a unique <InlineCodeBlock>jobId</InlineCodeBlock>.
        <CopyCodeBlock text={START_JOB_TEXT} language="shell" hideLineNumbers />
      </StepSection>
      <StepSection>
        <ConfigStepHeading stepNumber={2} bgColor={COLORS.DarkBlue}>
          Run Job
        </ConfigStepHeading>
        Call <InlineCodeBlock>runJob</InlineCodeBlock> with the{' '}
        <InlineCodeBlock>jobId</InlineCodeBlock> from above!
        <CopyCodeBlock text={RUN_JOB_TEXT} language="shell" hideLineNumbers />
      </StepSection>
    </div>
  );
};

export default HttpsPanel;
