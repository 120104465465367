import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

import UpcomingInvoice from './upcoming-invoice';
import PortalManageCard from './portal-manage-card';
import StatusRow from './status-row';
import { STATUS } from '../types';
import DataUsage from './data-usage';
import { useAuthContext } from '../../../firebase/AuthProvider';

const Wrapper = styled.div`
  margin: 24px auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
`;

export interface BillingStatusEndpointResponse {
  status: STATUS;
}

export const BillingInnerPage: React.FC<BillingStatusEndpointResponse> = ({
  status,
}) => {
  return (
    <Wrapper>
      <Typography variant="h4" style={{ marginBottom: 16 }}>
        Billing
      </Typography>
      <StatusRow status={status} />
      <PortalManageCard status={status} />
      {status !== STATUS.CANCELED && status !== STATUS.INCOMPLETE_EXPIRED && (
        <>
          <UpcomingInvoice />
          <DataUsage />
        </>
      )}
    </Wrapper>
  );
};

const BillingPage: React.FC = () => {
  const { subscriptionStatus } = useAuthContext();

  return subscriptionStatus && <BillingInnerPage status={subscriptionStatus} />;
};

export default BillingPage;
