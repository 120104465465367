import { PAGEAPIROUTES } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

interface AthenaConnectorInfo {
  bucketName: string;
  accountKey: string;
  accountSecret: string;
  path: string;
  region: string;
}

export type ConnectorInfo = { connectorName: string } & AthenaConnectorInfo; // Add other types for other connector here

interface AddRequest {
  connectorType: string;
  connectorInfo: ConnectorInfo;
}

type AddResponse = { connectorId: string; createdTime: string };

const useAddDatabaseConnectorService = (): [
  (body: AddRequest) => Promise<AddResponse | void>,
  Error | null,
  () => void
] =>
  usePostSimpleService<AddRequest, AddResponse>(
    PAGEAPIROUTES.Create_Database_Connector
  );

export default useAddDatabaseConnectorService;
