import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Typography } from '@material-ui/core';
import { ReactComponent as Logo } from '../../../common/images/segna-logo-white-icon.svg';
import COLORS from '../../../common/colors';

const CenterDivWithHeader = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  pointer-events: none;
`;

const StyledText = styled(Typography)`
  padding: 10px;
`;

const pulse = keyframes`
  0% {
    transform: scale(0.9);
  }
  20% {
    transform: scale(0.95);
  }
  25% {
    transform: scale(0.9);
  }
  35% {
    transform: scale(1.0);
    box-shadow: 0 0 0 0 rgba(10,26,70, 0.3);
  }
  40% {
    transform: scale(0.85);
  }
  100% {
    transform: scale(0.9);
    box-shadow: 0 0 0 15px rgba(10,26,70, 0);
  }
`;

const OuterDiv = styled.div`
  animation: ${pulse} 2s infinite ease-in-out;
  border-radius: 50%;
  background-color: ${COLORS.DarkBlue};
  height: 80px;
  width: 80px;
`;

const StyledIcon = styled(Logo)`
  height: 50px;
  width: 50px;
  padding: 15px;
`;

export interface LoadingProps {
  messageText: string;
}

const LoadingScreenCentered: React.FC<LoadingProps> = ({ messageText }) => (
  <CenterDivWithHeader>
    <OuterDiv>
      <StyledIcon fill="white" />
    </OuterDiv>
    <StyledText variant="subtitle1">{messageText}</StyledText>
  </CenterDivWithHeader>
);

export default LoadingScreenCentered;
