import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { toast } from 'react-toastify';
import { setUserId } from '@amplitude/analytics-browser';
import firebase from '../../../firebase/firebase';
import FullLoadingOverlay from '../../common/full-loading-overlay';
import NoMarginTextfield, {
  TextFieldWrapper,
} from '../../common/no-margin-textfield';
import { trackEvent } from '../../../utils/useTrackEvent';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-bottom: 8px;
`;

const FieldsContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const LoginForm: React.FC = () => {
  const [emailState, setEmailState] = useState<string>('');
  const [passwordState, setPasswordState] = useState<string>('');
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const onEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setEmailState(event.target.value);
  };

  const onPasswordChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setPasswordState(event.target.value);
  };

  const submitLogin = () => {
    setIsLoading(true);
    firebase
      .auth()
      .signInWithEmailAndPassword(emailState, passwordState)
      .then((userCredential) => {
        userCredential.user?.uid && setUserId(userCredential.user.uid);
        trackEvent('signin completed');
      })
      .catch((error) => {
        setIsLoading(false);
        toast.error(error.message);
        trackEvent('signin failed', { errorMessage: error.message });
      });
  };

  const handleKeyPress = (event: React.KeyboardEvent<HTMLDivElement>) => {
    if (event.key === 'Enter') {
      submitLogin();
    }
  };

  return (
    <Wrapper>
      <FieldsContainer>
        <TextFieldWrapper>
          <Typography variant="caption">Email*</Typography>
          <NoMarginTextfield
            variant="outlined"
            margin="dense"
            onChange={onEmailChange}
            onKeyPress={handleKeyPress}
          />
        </TextFieldWrapper>
        <TextFieldWrapper>
          <Typography variant="caption">Password*</Typography>
          <NoMarginTextfield
            variant="outlined"
            margin="dense"
            type="password"
            onChange={onPasswordChange}
            onKeyPress={handleKeyPress}
          />
        </TextFieldWrapper>
      </FieldsContainer>
      <Button
        variant="contained"
        color="secondary"
        onClick={submitLogin}
        disabled={emailState.length === 0 || passwordState.length === 0}
      >
        Login
      </Button>
      <FullLoadingOverlay isLoading={isLoading} />
    </Wrapper>
  );
};

export default LoginForm;
