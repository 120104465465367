import React from 'react';
import styled from 'styled-components';
import { FormLabel, TextField, Typography } from '@material-ui/core';
import {
  DeepMap,
  FieldError,
  FieldValues,
  UseFormRegister,
} from 'react-hook-form';

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ErrorMessage = styled(Typography)`
  color: red;
`;

interface Props {
  formRegister: UseFormRegister<Record<string, string>>;
  errors: DeepMap<FieldValues, FieldError>;
}

const SnowflakeForm: React.FC<Props> = ({ formRegister, errors }) => {
  return (
    <>
      <FormFieldWrapper>
        <FormLabel required>Schema name</FormLabel>
        <TextField
          {...formRegister('schemaName', {
            required: 'Please enter a schema',
          })}
          variant="outlined"
          color="primary"
          margin="dense"
          // placeholder="e.g UserTable"
        />
      </FormFieldWrapper>
      <FormFieldWrapper>
        <FormLabel required>Table name</FormLabel>
        <TextField
          {...formRegister('tableName', {
            required: 'Please enter a table name',
          })}
          variant="outlined"
          color="primary"
          margin="dense"
          // placeholder="e.g UserTable"
        />
      </FormFieldWrapper>
      {errors && <ErrorMessage>{errors?.tableName?.message}</ErrorMessage>}
    </>
  );
};

export default SnowflakeForm;
