import React from 'react';
import styled from 'styled-components';
import { Card, CircularProgress, Typography } from '@material-ui/core';
import DataUsageGraph from './data-usage-graph';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import ROUTES, { BILLINGAPI } from '../../../../common/routes';
import useErrorAlert from '../../../../utils/useErrorAlert';

const Wrapper = styled(Card)`
  margin: 16px 0;
  padding: 24px;
`;

const DataContainer = styled.div`
  display: flex;
  justify-content: space-between;
  height: 400px;
  margin-top: 32px;
`;

const GraphTitle = styled(Typography)``;

interface BillingDataUsageResponse {
  data: { date: string; usage: number }[];
  unit: string;
}

const DataUsage: React.FC = () => {
  const [usageData, , error] = useFetchSimpleService<BillingDataUsageResponse>(
    ROUTES.Billing + BILLINGAPI.Data_Usage
  );
  useErrorAlert(error);

  return (
    <Wrapper>
      <GraphTitle variant="h5">Data Usage for Current Period</GraphTitle>
      {usageData ? (
        <DataContainer>
          <DataUsageGraph data={usageData.data} unit={usageData.unit} />
        </DataContainer>
      ) : (
        <CircularProgress />
      )}
    </Wrapper>
  );
};

export default DataUsage;
