import React from 'react';
import { CopyBlock, dracula } from 'react-code-blocks';
import styled from 'styled-components';

const Wrapper = styled.div`
  margin: 8px 0;
  font-family: Inconsolata, Monaco, sans-serif;
  font-size: 1rem;
  font-weight: bold;

  & button {
    opacity: 0.85;
  }

  & button:hover {
    opacity: 1;
    cursor: pointer;
  }
`;

interface Props {
  text: string;
  language: string;
  hideLineNumbers?: boolean;
}

const CopyCodeBlock: React.FC<Props> = ({
  text,
  language,
  hideLineNumbers,
}) => {
  return (
    <Wrapper>
      <CopyBlock
        text={text}
        language={language}
        theme={dracula}
        codeBlock
        showLineNumbers={!hideLineNumbers}
      />
    </Wrapper>
  );
};

export default CopyCodeBlock;
