import React, { useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import GavelIcon from '@material-ui/icons/Gavel';
import COLORS from '../../common/colors';
import CodeUsageTabs from './code-usage-tabs';
import PipelineCreateModal from '../pipeline-pages/pipelines-list-page/pipeline-create-modal';
import { ReactComponent as APISVG } from '../../common/images/segna-feature.svg';

const Wrapper = styled.div`
  max-width: 1280px;
  margin: 32px auto;
  display: flex;
  align-items: stretch;
`;

const LeftTextSection = styled.div`
  white-space: pre-line;
`;

const HeaderSection = styled.div`
  margin-bottom: 40px;
`;

const StepCircle = styled.div`
  border-radius: 50%;
  height: 40px;
  width: 40px;
  background-color: ${COLORS.DarkBlue};
  flex-shrink: 0;
  margin-right: 12px;
  display: flex;
  justify-content: center;
  align-items: center;
  color: white;
`;

const PipelineSection = styled.div`
  display: flex;
  margin-bottom: 48px;
`;

const StepContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const PipelineContentWrapper = styled.div`
  display: flex;
`;

const PipelineContentLeft = styled.div``;

const PipelineBuildText = styled(Typography)`
  margin: 6px 0 16px;
`;

const PipelineContentRight = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-left: 44px;
`;

const GraphicWrapper = styled.div`
  height: 440px;
  width: 440px;
`;

const Graphic = styled(APISVG)`
  height: 100%;
  width: 100%;
`;

const JobSection = styled.div`
  display: flex;
`;

const ButtonWrapper = styled.div`
  margin-top: 8px;
`;

const JobText = styled(Typography)`
  margin-bottom: 16px;
`;

const PIPELINE_TEXT = `The first step to implementing your own data importing experience is to create a Pipeline. A Pipeline defines the destination of your data importer and desired schema of data.`;

const JOB_TEXT = `Every user upload or session is called a Job. A Job is a run through of data through a specified Pipeline.`;

const JOB_CODE_TEXT = `A Job can be run using our Pre-Built Components, which can be directly integrated to your frontend application, or through our Client Libraries and APIs.`;

const QuickStartPage: React.FC = () => {
  const [isPipelineModalOpen, setIsPipelineModalOpen] = useState<boolean>(
    false
  );

  const handlePipelineModalOpen = () => {
    setIsPipelineModalOpen(true);
  };

  const handlePipelineModalClose = () => {
    setIsPipelineModalOpen(false);
  };

  return (
    <Wrapper>
      <LeftTextSection>
        <HeaderSection>
          <Typography variant="h3">
            Let&apos;s Get Started{' '}
            <span role="img" aria-label="celebrate">
              🎉
            </span>
          </Typography>
        </HeaderSection>
        <PipelineSection>
          <StepCircle>
            <Typography variant="h5">1</Typography>
          </StepCircle>
          <PipelineContentWrapper>
            <PipelineContentLeft>
              <Typography variant="h5">Build a Pipeline</Typography>
              <PipelineBuildText>{PIPELINE_TEXT}</PipelineBuildText>
              <ButtonWrapper>
                <Button
                  variant="contained"
                  color="secondary"
                  onClick={handlePipelineModalOpen}
                  startIcon={<GavelIcon />}
                >
                  Build Pipeline
                </Button>
              </ButtonWrapper>
            </PipelineContentLeft>
          </PipelineContentWrapper>
        </PipelineSection>
        <JobSection>
          <StepCircle>
            <Typography variant="h5">2</Typography>
          </StepCircle>
          <StepContentWrapper>
            <Typography variant="h5">Run a Job</Typography>
            <JobText>{JOB_TEXT}</JobText>
            <Typography>{JOB_CODE_TEXT}</Typography>
            <CodeUsageTabs />
          </StepContentWrapper>
        </JobSection>
      </LeftTextSection>
      <PipelineContentRight>
        <GraphicWrapper>
          <Graphic />
        </GraphicWrapper>
      </PipelineContentRight>
      <PipelineCreateModal
        isOpen={isPipelineModalOpen}
        handleClose={handlePipelineModalClose}
      />
    </Wrapper>
  );
};

export default QuickStartPage;
