import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import ScriptsEditor from './scripts-editor';
import { ScriptsStoreContainer } from './scripts-store';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import ROUTES from '../../../common/routes';
import useErrorAlert from '../../../utils/useErrorAlert';
import { ContainerProps } from './scripts-store/types';
import LoadingScreenCentered from '../../common/loading-screen-centered';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  max-width: 1280px;
`;

const ScriptsEditorInner: React.FC = () => {
  return (
    <>
      <ScriptsEditor />
    </>
  );
};

const ScriptsEditorOuter: React.FC = () => {
  const { scriptId } = useParams<{ scriptId: string }>();

  const [data, , error] = useFetchSimpleService<ContainerProps>(
    `${ROUTES.Scripts}/${scriptId}`
  );
  useErrorAlert(error);

  return (
    <Wrapper>
      {data ? (
        <ScriptsStoreContainer {...data}>
          <ScriptsEditorInner />
        </ScriptsStoreContainer>
      ) : (
        <LoadingScreenCentered messageText="Loading script" />
      )}
    </Wrapper>
  );
};

export default ScriptsEditorOuter;
