import React, { useCallback, useState } from 'react';
import styled from 'styled-components';
import { Button, Divider, Typography } from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import ROUTES, { PIPELINEAPIS } from '../../../common/routes';
import { DashboardEndpointResponse } from '../../../api/types/pipeline';
import LoadingScreenCentered from '../../common/loading-screen-centered';
import PipelineCard from './pipeline-card';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import PipelineCreateModal from './pipeline-create-modal';
import {
  PipelineDashboardStoreContainer,
  usePipelineDashboardStore,
} from './store';

const Wrapper = styled.div`
  margin: 0 auto;
  max-width: 1280px;
`;

const InnerContainer = styled.div`
  display: flex;
  flex-direction: column;
  padding: 24px 0;
`;

const TitleContainer = styled.div`
  margin-bottom: 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const PipelinesWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 32px;
  grid-row-gap: 32px;
  width: 100%;
  margin: 16px 0;
`;

const EmptyPipelinesMessage = styled.div`
  grid-column: 1 / span 2;
  text-align: center;
`;

export const DashboardPageInner: React.FC = () => {
  const [{ pipelines }] = usePipelineDashboardStore();

  return (
    <InnerContainer>
      <PipelinesWrapper>
        {Object.keys(pipelines).length === 0 ? (
          <EmptyPipelinesMessage>
            There are no pipelines - click &apos;Build new pipeline&apos; above
            to create a new pipeline!
          </EmptyPipelinesMessage>
        ) : (
          Object.entries(pipelines).map(([pipelineId, pipelineData]) => (
            <PipelineCard
              pipelineData={pipelineData}
              pipelineId={pipelineId}
              key={pipelineId}
            />
          ))
        )}
      </PipelinesWrapper>
    </InnerContainer>
  );
};

const PipelinesListPage: React.FC = () => {
  const [data, isLoading] = useFetchSimpleService<DashboardEndpointResponse>(
    ROUTES.Pipeline + PIPELINEAPIS.Dashboard
  );

  const [isCreatePipelineModalOpen, setIsCreatePipelineModalOpen] = useState<
    boolean
  >(false);

  const handleOpen = useCallback(() => {
    setIsCreatePipelineModalOpen(true);
  }, [setIsCreatePipelineModalOpen]);

  const handleClose = useCallback(() => {
    setIsCreatePipelineModalOpen(false);
  }, [setIsCreatePipelineModalOpen]);

  return (
    <Wrapper>
      <TitleContainer>
        <Typography variant="h3">Pipelines</Typography>
        <Button
          variant="contained"
          color="secondary"
          onClick={handleOpen}
          startIcon={<AddIcon />}
        >
          Build new Pipeline
        </Button>
      </TitleContainer>
      <Divider style={{ height: 2, borderRadius: 1 }} />
      {isLoading ? (
        <LoadingScreenCentered messageText="Loading" />
      ) : (
        data && (
          <PipelineDashboardStoreContainer pipelines={data.pipelines}>
            <DashboardPageInner />
          </PipelineDashboardStoreContainer>
        )
      )}
      {isCreatePipelineModalOpen && (
        <PipelineCreateModal
          isOpen={isCreatePipelineModalOpen}
          handleClose={handleClose}
        />
      )}
    </Wrapper>
  );
};

export default PipelinesListPage;
