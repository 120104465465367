import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import AddDatabaseModal from '../../../../common/add-database-modal';
import AddFileModal from '../../../../common/add-file-modal';
import { useDataSourcesImportingStore } from '../../store';
import COLORS from '../../../../../common/colors';
import SampleDataSourceCard from './sample-data-source-card';

const Wrapper = styled.div`
  flex: 1;
  padding: 8px;
`;

const ButtonGrouping = styled.div`
  display: flex;
  flex-direction: row;
  & > * {
    margin-left: 8px;
  }
  justify-content: flex-end;
`;

const SampleDataContainer = styled.div`
  background-color: ${COLORS.LightGrey};
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 16px;
  align-items: center;
  border-radius: 8px;
`;

const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const StyledButton = styled(Button)`
  height: 36px;
`;

const SampleDataResources = forwardRef<HTMLDivElement>((props, ref) => {
  const [
    { sampleDataResource, databaseConnectors },
    { addSampleDataResource, addDatabaseConnector },
  ] = useDataSourcesImportingStore();

  const [isAddFileModalOpen, setIsAddFileModalOpen] = useState<boolean>(false);
  const [isAddDatabaseModalOpen, setIsAddDatabaseModalOpen] = useState<boolean>(
    false
  );

  const handleAddFileModalOpen = () => {
    setIsAddFileModalOpen(true);
  };

  const handleAddFileModalClose = () => {
    setIsAddFileModalOpen(false);
  };

  const handleAddDataModalOpen = () => {
    setIsAddDatabaseModalOpen(true);
  };

  const handleAddDataModelClose = () => {
    setIsAddDatabaseModalOpen(false);
  };

  return (
    <Wrapper>
      <SampleDataContainer ref={ref}>
        <TitleContainer>
          <Typography variant="subtitle2" style={{ fontStyle: 'italic' }}>
            Optional
          </Typography>
          <Typography variant="h6">Sample Schema Data:</Typography>
        </TitleContainer>
        {sampleDataResource ? (
          <SampleDataSourceCard
            dataSourceType={sampleDataResource.dataResourceType}
            dataSourceName={sampleDataResource.dataResourceName}
            dataSourceId={sampleDataResource.dataResourceId}
          />
        ) : (
          <ButtonGrouping>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleAddFileModalOpen}
            >
              Add File
            </StyledButton>
            <StyledButton
              variant="contained"
              color="primary"
              onClick={handleAddDataModalOpen}
            >
              Add Database
            </StyledButton>
          </ButtonGrouping>
        )}
      </SampleDataContainer>
      <AddFileModal
        isOpen={isAddFileModalOpen}
        onClose={handleAddFileModalClose}
        onAddDataSource={addSampleDataResource}
        limit={1}
      />
      <AddDatabaseModal
        isOpen={isAddDatabaseModalOpen}
        onClose={handleAddDataModelClose}
        databaseConnectors={databaseConnectors}
        onAddDatabaseTable={addSampleDataResource}
        onAddDatabaseConnector={addDatabaseConnector}
      />
    </Wrapper>
  );
});

export default SampleDataResources;
