import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import COLORS from '../../../../../common/colors';
import s3svg from '../../../../../common/images/s3.svg';
import mysqlsvg from '../../../../../common/images/aws_rds_mysql.svg';
import athenasvg from '../../../../../common/images/athena.svg';
import snowflakesvg from '../../../../../common/images/snowflake.svg';
import firestoresvg from '../../../../../common/images/firestore.svg';
import gcssvg from '../../../../../common/images/gcs.svg';
import csvsvg from '../../../../../common/images/csv.svg';
import supabasesvg from '../../../../../common/images/supabase.svg';
import { ConnectorType } from '../../types';

const Wrapper = styled.div`
  border: 1px solid #dddedf;
  border-radius: 6px;
  padding: 8px;
  width: 220px;
  height: 220px;
  transition: 0.3s;
  display: flex;
  flex-direction: column;
  
  &:hover {
    background-color: ${COLORS.Grey};
    cursor: pointer;
  }
`;

const TitleSection = styled.div`
  text-align: center;
`;

const LogoSection = styled.div`
  display: flex;
  flex: 1;
  pointer-events: none;
  justify-content: center;
  align-items: center;
  margin: 16px;
`;

const LogoSvg = styled.img`
  max-height: 90%;
  max-width: 90%;
`;

const selectLogoFromType = (dataSourceType: ConnectorType) => {
  switch (dataSourceType) {
    case ConnectorType.ATHENA:
      return athenasvg;
    case ConnectorType.AWS_RDS_MYSQL:
      return mysqlsvg;
    case ConnectorType.S3:
      return s3svg;
    case ConnectorType.SNOWFLAKE:
      return snowflakesvg;
    case ConnectorType.FIRESTORE:
      return firestoresvg;
    case ConnectorType.GCS:
      return gcssvg;
    case ConnectorType.SUPABASE_POSTGRESQL:
      return supabasesvg;
    default:
      return csvsvg;
  }
};

interface Props {
  name: string;
  onClick: () => void;
  type: ConnectorType;
}

const DatabaseTile: React.FC<Props> = ({ name, onClick, type }) => {
  return (
    <Wrapper onClick={onClick}>
      <TitleSection>
        <Typography variant="h6">{name}</Typography>
      </TitleSection>
      <LogoSection>
        <LogoSvg src={selectLogoFromType(type)} />
      </LogoSection>
    </Wrapper>
  );
};

export default DatabaseTile;
