import React, { useState } from 'react';
import styled from 'styled-components';
import { Alert } from '@material-ui/lab';
import { Button, CircularProgress } from '@material-ui/core';
import useCreatePortalSessionService from '../service/useCreatePortalSessionService';
import useErrorAlert from '../../../../utils/useErrorAlert';
import useCreateCheckoutSessionService from '../service/useCreateCheckoutSessionService';
import { STATUS } from '../../types';

const StyledAlert = styled(Alert)`
  padding: 12px 24px;
`;
interface Props {
  status: STATUS;
}

const StatusRow: React.FC<Props> = ({ status }) => {
  const [
    getPortalService,
    portalServiceError,
  ] = useCreatePortalSessionService();
  useErrorAlert(portalServiceError);

  const [
    getCheckoutService,
    checkoutServiceError,
  ] = useCreateCheckoutSessionService();
  useErrorAlert(checkoutServiceError);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const handleManageClick = async () => {
    setIsButtonLoading(true);
    const response = await getPortalService();
    if (response) {
      window.location.href = response.url;
    }
    setIsButtonLoading(false);
  };

  const handleRestartClick = async () => {
    setIsButtonLoading(true);
    const response = await getCheckoutService();
    if (response) {
      window.location.href = response.url;
    }
    setIsButtonLoading(false);
  };

  switch (status) {
    case STATUS.ACTIVE:
      return (
        <StyledAlert severity="success">
          Your subscription is currently active!
        </StyledAlert>
      );
    case STATUS.TRIALING:
      return (
        <StyledAlert
          severity="info"
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handleManageClick}
            >
              {isButtonLoading ? <CircularProgress size={24} /> : 'Manage'}
            </Button>
          }
        >
          You are currently on a Free Trial. Please make sure you have entered
          valid payment details before the upcoming billing date!
        </StyledAlert>
      );
    case STATUS.PAST_DUE:
      return (
        <StyledAlert
          severity="warning"
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handleManageClick}
            >
              {isButtonLoading ? <CircularProgress size={24} /> : 'Manage'}
            </Button>
          }
        >
          Your payment for the previous invoice has failed. Please update your
          payment details! You can also immediately pay the failed invoice, or
          we'll try to process again automatically at a later date!
        </StyledAlert>
      );
    case STATUS.INCOMPLETE:
      return (
        <StyledAlert
          severity="error"
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handleManageClick}
            >
              {isButtonLoading ? <CircularProgress size={24} /> : 'Manage'}
            </Button>
          }
        >
          There seems to be an issue with your payment. Please pay the
          outstanding invoice to start your subscription!
        </StyledAlert>
      );
    case STATUS.UNPAID:
      return (
        <StyledAlert
          severity="error"
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handleManageClick}
            >
              {isButtonLoading ? <CircularProgress size={24} /> : 'Manage'}
            </Button>
          }
        >
          Your subscription is on hold until payment is successful. Please
          update your payment details!
        </StyledAlert>
      );
    case STATUS.INCOMPLETE_EXPIRED:
      return (
        <StyledAlert
          severity="error"
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handleRestartClick}
            >
              {isButtonLoading ? (
                <CircularProgress size={24} />
              ) : (
                'Restart Plan'
              )}
            </Button>
          }
        >
          Your subscription has been canceled as we were unable to make payment
          with your details. Restart your plan to use Segna!
        </StyledAlert>
      );
    case STATUS.CANCELED:
      return (
        <StyledAlert
          severity="error"
          action={
            <Button
              color="secondary"
              variant="contained"
              onClick={handleRestartClick}
            >
              {isButtonLoading ? (
                <CircularProgress size={24} />
              ) : (
                'Restart Plan'
              )}
            </Button>
          }
        >
          Your subscription has been canceled. Restart your plan to use Segna!
        </StyledAlert>
      );
    default:
      return null;
  }
};

export default StatusRow;
