import React, { useState } from 'react';
import {
  Button,
  IconButton,
  ListItemIcon,
  ListItemText,
  Menu,
  MenuItem,
  TableCell,
  TableRow,
  withStyles,
} from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import DeleteIcon from '@material-ui/icons/Delete';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import MoreHorizIcon from '@material-ui/icons/MoreHoriz';
import { useAuthContext } from '../../../../../firebase/AuthProvider';
import useDeleteSimpleService from '../../../../../api/useDeleteSimpleService';
import useErrorAlert from '../../../../../utils/useErrorAlert';
import ROUTES, { SCRIPTSAPIS } from '../../../../../common/routes';
import DeleteModal from '../../../../common/delete-modal';
import ShareModal from '../../../../common/share-modal';
import { useScriptsListStore } from '../../scripts-list-store';
import CopyIdLozenge from '../../../../common/copy-id-lozenge';
import PeopleIcon from '@material-ui/icons/People';
import convertApiDateStringToDate from '../../../../../utils/convertApiDateStringToDate';

interface Props {
  scriptId: string;
  name: string;
  description: string;
  lastUpdated: string;
  // language: string;
  ownerId: string;
  isShared: boolean;
}

const StyledTableRow = withStyles(() => ({
  root: {
    backgroundColor: 'white',
    borderRight: 8,
  },
}))(TableRow);

const DescriptionContainer = styled.div`
  text-overflow: ellipsis;
  overflow: hidden;
  white-space: nowrap;
  max-width: 580px;
`;

const MenuTableCell = styled.div`
  width: 32px;
  justify-content: right;
  align-items: center;
`;

const StyledButton = styled(Button)`
  width: 28px;
  padding: 4px;
`;

const DateContainer = styled.div`
  max-width: 120px;
  display: flex;
  flex-direction: column;
`;

const IdContainer = styled.div`
  width: 100px;
`;

const ScriptsListRow: React.FC<Props> = ({
  scriptId,
  name,
  description,
  lastUpdated,
  ownerId,
}) => {
  const history = useHistory();
  const { user } = useAuthContext();
  const [, { deleteScript }] = useScriptsListStore();
  const [shareModalId, setShareModalId] = useState<string>('');
  const [isMenuOpen, setIsMenuOpen] = useState<boolean>(false);
  const [anchorEl, setAnchorEl] = useState<HTMLElement | null>(null);

  const handleMenuClose = () => {
    setIsMenuOpen(false);
    setAnchorEl(null);
  };

  const handleMenuClick = (e: React.MouseEvent<HTMLButtonElement>) => {
    setAnchorEl(e.currentTarget);
    setIsMenuOpen(true);
  };

  const handleShareModalClose = () => {
    setShareModalId('');
  };

  const handleOpenScriptEditor = () => {
    history.push(`${ROUTES.App}${ROUTES.Scripts}/${scriptId}`);
  };

  const [sendDeleteRequest, deleteError] = useDeleteSimpleService();
  useErrorAlert(deleteError);
  const [deleteModalId, setDeleteModalId] = useState<string>('');
  const [isDeleting, setIsDeleting] = useState<boolean>(false);

  const handleDeleteClick = () => {
    handleMenuClose();
    setDeleteModalId(scriptId);
  };

  const handleDeleteScript = (scriptId: string) => async () => {
    setIsDeleting(true);
    const response = await sendDeleteRequest(
      `${ROUTES.Scripts}/${scriptId}${SCRIPTSAPIS.Delete_Script}`
    );
    if (response) {
      deleteScript(scriptId);
      toast.success(`Script deleted successfully`);
    }
    setDeleteModalId('');
    setIsDeleting(false);
  };

  const handleCloseDeleteModal = () => {
    setDeleteModalId('');
  };

  const handleShareClick = () => {
    handleMenuClose();
    setShareModalId(scriptId);
  };

  const date = convertApiDateStringToDate(lastUpdated);

  return (
    <>
      <StyledTableRow>
        <TableCell>{name}</TableCell>
        <TableCell>
          <IdContainer>
            <CopyIdLozenge resourceId={scriptId} />
          </IdContainer>
        </TableCell>
        <TableCell>
          <DateContainer>
            {date && (
              <>
                <span>{date.toDateString()}</span>
                <span>
                  {date.toLocaleString('en-GB', {
                    hour: '2-digit',
                    minute: '2-digit',
                    hourCycle: 'h12',
                  })}
                </span>
              </>
            )}
          </DateContainer>
        </TableCell>
        <TableCell>
          <DescriptionContainer>{description}</DescriptionContainer>
        </TableCell>
        {/* <StyledTableCell style={{ textAlign: 'center', width: 10 }}> */}
        {/*  {isShared ? `Yes` : `No`} */}
        {/* </StyledTableCell> */}
        <TableCell>
          <MenuTableCell>
            <IconButton onClick={handleMenuClick}>
              <MoreHorizIcon />
            </IconButton>
            <Menu
              open={isMenuOpen}
              onClose={handleMenuClose}
              anchorEl={anchorEl}
              MenuListProps={{
                'aria-labelledby': 'basic-button',
              }}
              getContentAnchorEl={null}
              transformOrigin={{
                vertical: 'top',
                horizontal: 'right',
              }}
            >
              <MenuItem onClick={handleShareClick} dense>
                <ListItemIcon>
                  <PeopleIcon fontSize="small" />
                </ListItemIcon>
                <ListItemText>Share</ListItemText>
              </MenuItem>
              {user?.uid === ownerId && (
                <MenuItem onClick={handleDeleteClick} dense>
                  <ListItemIcon>
                    <DeleteIcon fontSize="small" />
                  </ListItemIcon>
                  <ListItemText>Delete</ListItemText>
                </MenuItem>
              )}
            </Menu>
          </MenuTableCell>
        </TableCell>
        <TableCell>
          <StyledButton onClick={handleOpenScriptEditor}>
            <ArrowForwardIcon fontSize="small" color="secondary" />
          </StyledButton>
        </TableCell>
      </StyledTableRow>

      <ShareModal
        isOpen={shareModalId === scriptId}
        onClose={handleShareModalClose}
        resourceId={scriptId}
        onSuccess={handleShareModalClose}
      />
      <DeleteModal
        isOpen={deleteModalId === scriptId}
        onClose={handleCloseDeleteModal}
        confirmationText={`Are you sure you want to delete '${name}' with ID: ${scriptId}`}
        handleDelete={handleDeleteScript(scriptId)}
        isLoading={isDeleting}
      />
    </>
  );
};

export default ScriptsListRow;
