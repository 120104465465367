import React from 'react';
import styled from 'styled-components';
import { IconButton, MenuItem, Select, Typography } from '@material-ui/core';
import ChangeHistoryIcon from '@material-ui/icons/ChangeHistory';
import CloseIcon from '@material-ui/icons/Close';
import { useModalStore } from '../modal-store';
import { ACCESS_TYPE } from '../../../../common/constants';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`;

const SharedUsersTable = styled.div`
  display: grid;
  grid-template-columns: 2fr 2fr 1fr;
  grid-column-gap: 8px;
  grid-row-gap: 8px;
  margin: 16px 4px;
  align-items: center;
`;

const AccessTypeWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
`;

const AccessTypeSelect = styled(Select)`
  width: 120px;
  height: 40px;
  margin: 0 8px;
`;

const SharedUsersSection: React.FC = () => {
  const [
    { sharedUsers, updatedSharedUsers, userAccessType },
    { updateSharedUserAccessType },
  ] = useModalStore();

  const handleUpdateAccessType = (email: string) => (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    updateSharedUserAccessType(email, event.target.value as ACCESS_TYPE);
  };

  const handleDeleteAccess = (email: string) => () => {
    updateSharedUserAccessType(email, null);
  };

  const isDisabled =
    userAccessType !== ACCESS_TYPE.ADMIN &&
    userAccessType !== ACCESS_TYPE.OWNER;

  return (
    <Wrapper>
      <Typography variant="h5">Currently Shared Users</Typography>
      {Object.keys(sharedUsers).length === 0 ? (
        <p>This resource is currently not shared with anyone</p>
      ) : (
        <SharedUsersTable>
          {Object.entries(sharedUsers).map(([email, { name, accessType }]) => (
            <>
              <div>{name}</div>
              <div style={{ fontWeight: 'bold' }}>{email}</div>
              <AccessTypeWrapper>
                {updatedSharedUsers[email] !== undefined && (
                  <ChangeHistoryIcon />
                )}
                <AccessTypeSelect
                  value={updatedSharedUsers[email] ?? accessType}
                  onChange={handleUpdateAccessType(email)}
                  label="Access"
                  variant="outlined"
                  disabled={
                    isDisabled ||
                    updatedSharedUsers[email] === null ||
                    accessType === ACCESS_TYPE.OWNER
                  }
                >
                  {accessType === ACCESS_TYPE.OWNER ? (
                    <MenuItem value={accessType}>{accessType}</MenuItem>
                  ) : (
                    Object.entries(ACCESS_TYPE).map(
                      ([, accessTypeString]) =>
                        accessTypeString !== ACCESS_TYPE.OWNER && (
                          <MenuItem value={accessTypeString}>
                            {accessTypeString}
                          </MenuItem>
                        )
                    )
                  )}
                </AccessTypeSelect>
                <IconButton
                  onClick={handleDeleteAccess(email)}
                  disabled={
                    isDisabled ||
                    updatedSharedUsers[email] === null ||
                    accessType === ACCESS_TYPE.OWNER
                  }
                >
                  <CloseIcon />
                </IconButton>
              </AccessTypeWrapper>
            </>
          ))}
        </SharedUsersTable>
      )}
    </Wrapper>
  );
};

export default SharedUsersSection;
