import React from 'react';
import styled from 'styled-components';

const CodeSpan = styled.span`
  background-color: lightgray;
  font-family: Inconsolata, Monaco, sans-serif;
  border-radius: 4px;
  padding: 2px 4px;
`;

const InlineCodeBlock: React.FC = ({ children }) => {
  return <CodeSpan>{children}</CodeSpan>;
};

export default InlineCodeBlock;
