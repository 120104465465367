import React, { forwardRef, useState } from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';
import { toast } from 'react-toastify';
import useSaveScriptService from '../service/useSaveScriptService';
import { useScriptsStore } from '../../scripts-store';
import Colors from '../../../../../common/colors';
import useErrorAlert from '../../../../../utils/useErrorAlert';

const StyledButton = styled(Button)`
  height: 40px;
  width: 80px;
  background-color: ${Colors.Orange};
`;

const SaveButton = forwardRef<HTMLButtonElement>((props, ref) => {
  const [
    { script, name, scriptId, description, isChanged },
    { setIsChanged },
  ] = useScriptsStore();

  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [saveScript, saveError] = useSaveScriptService(scriptId);
  useErrorAlert(saveError);

  const handleSaveScript = async () => {
    setIsLoading(true);
    const response = await saveScript({ script, name, description });
    if (response) {
      setIsChanged(false);
      toast.success('Script saved successfully!');
    } else {
      toast.error(
        'An error occurred while saving your script, please try again.'
      );
    }
    setIsLoading(false);
  };

  return (
    <StyledButton
      onClick={handleSaveScript}
      disabled={!isChanged}
      variant="contained"
      color="secondary"
      ref={ref}
    >
      {isLoading ? <CircularProgress size={24} /> : `Save`}
    </StyledButton>
  );
});

export default SaveButton;
