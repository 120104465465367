import React, { useState } from 'react';
import { Button, CircularProgress } from '@material-ui/core';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import { useModalStore } from '../modal-store';
import useShareService from '../service/useShareService';
import useErrorAlert from '../../../../utils/useErrorAlert';

const StyledButton = styled(Button)`
  max-width: 200px;
`;

const ButtonContainer = styled.div``;

interface Props {
  resourceId: string;
  onSuccess: () => void;
}

const ShareButton: React.FC<Props> = ({ resourceId, onSuccess }) => {
  const [shareService, error] = useShareService(resourceId);
  useErrorAlert(error);
  const [
    { updatedSharedUsers, selectedEmails, selectedAccessType },
  ] = useModalStore();
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleSubmit = async () => {
    setIsLoading(true);
    const response = await shareService({
      selectedEmails,
      selectedAccessType,
      updatedSharedUsers,
    });
    if (response) {
      // Successful
      onSuccess();
      toast.success('Sharing rules have updated successfully!');
    }
    setIsLoading(false);
  };

  const isShareDisabled =
    Object.keys(updatedSharedUsers).length === 0 && selectedEmails.length === 0;

  return (
    <ButtonContainer>
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={handleSubmit}
        disabled={isShareDisabled}
      >
        {isLoading ? <CircularProgress color="inherit" size={20} /> : `Update`}
      </StyledButton>
    </ButtonContainer>
  );
};

export default ShareButton;
