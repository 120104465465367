import usePostSimpleService from '../../../../api/usePostSimpleService';
import ROUTES, { SCRIPTSAPIS } from '../../../../common/routes';

interface CreateScriptRequest {
  name: string;
  language: string;
  description: string;
}

interface CreateScriptResponse {
  scriptId: string;
}

const useCreateScriptService = (): [
  (body: CreateScriptRequest) => Promise<CreateScriptResponse | void>,
  Error | null,
  () => void
] =>
  usePostSimpleService<CreateScriptRequest, CreateScriptResponse>(
    ROUTES.Scripts + SCRIPTSAPIS.Create_Script
  );

export default useCreateScriptService;
