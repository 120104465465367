import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Card,
  CircularProgress,
  IconButton,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import { toast } from 'react-toastify';
import { selectLogoFromType } from '../../../../../common/data-resources-list';
import { useDataSourcesImportingStore } from '../../../store';
import useDeleteDataResourceService from '../../../../../common/data-resources-list/service/useDeleteDataResourceService';
import useErrorAlert from '../../../../../../utils/useErrorAlert';

const CardContainer = styled(Card)`
  flex-direction: row;
  display: flex;
  align-items: center;
  padding: 8px 16px;
`;

const LogoSvg = styled.img`
  height: 36px;
`;

const FileNameContainer = styled.div`
  flex: 1;
  white-space: nowrap;
  margin: 0 16px;
  width: 0;
`;

const FileName = styled(Typography)`
  text-overflow: ellipsis;
  overflow: hidden;
`;

const StyledButton = styled(IconButton)`
  min-width: 32px;
  padding: 8px;
`;

interface Props {
  dataSourceType: string;
  dataSourceName: string;
  dataSourceId: string;
}

const SampleDataSourceCard: React.FC<Props> = ({
  dataSourceType,
  dataSourceName,
  dataSourceId,
}) => {
  const [, { removeSampleDataResource }] = useDataSourcesImportingStore();
  const [isDeletingFile, setIsDeletingFile] = useState<boolean>(false);
  const [deleteSampleService, error] = useDeleteDataResourceService(false);
  useErrorAlert(error);

  const deleteFile = async () => {
    setIsDeletingFile(true);
    const response = await deleteSampleService(dataSourceId);
    if (response) {
      removeSampleDataResource();
      toast.success(
        <>
          <span
            style={{
              fontStyle: 'italic',
            }}
          >
            {dataSourceName}
          </span>{' '}
          removed successfully
        </>
      );
    }
    setIsDeletingFile(false);
  };

  return (
    <CardContainer>
      <LogoSvg src={selectLogoFromType(dataSourceType)} />
      <FileNameContainer>
        <FileName variant="h6">{dataSourceName}</FileName>
      </FileNameContainer>
      <StyledButton onClick={deleteFile}>
        {isDeletingFile ? <CircularProgress size={24} /> : <CloseIcon />}
      </StyledButton>
    </CardContainer>
  );
};

export default SampleDataSourceCard;
