import React, { useReducer } from 'react';
import styled from 'styled-components';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import {
  Button,
  Checkbox,
  FormControlLabel,
  IconButton,
  MenuItem,
  Modal,
  Paper,
  TextField,
  Typography,
} from '@material-ui/core';
import CloseIcon from '@material-ui/icons/Close';
import COLORS from '../../../../../common/colors';
import { DataTypes } from '../../../../decision-pages/units-page/units-store/types';
import getReadableDataType from '../../../../decision-pages/units-page/field-column-units/units-row/util';
import {
  Units,
  UnitType,
} from '../../../../decision-pages/units-page/field-column-units/units-row';
import addFieldReducer, { initialState } from './add-field-reducer';
import convertUnitText from '../../../../../utils/convertUnitText';
import { DateTimeFormats } from '../../../../decision-pages/units-page/field-column-units/units-row/date-format-input';
import AddFieldActionTypes from './add-field-action-types';
import { useSchemaStore } from '../../store';
import CategoryValueInput from '../common/category-value-input';
import CategoryChipBox from '../common/category-chip-box';

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  min-width: 100px;
  min-height: 540px;
  border: 1px solid rgba(0, 0, 0, 0.12);
  transition: all 250ms ease-out;

  &:hover {
    background-color: ${COLORS.LightGrey};
    cursor: pointer;
  }
`;

const AddFieldContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`;

const AddFieldText = styled(Typography)`
  font-size: 14px;
  margin-top: 4px;
`;

const ModalPaper = styled(Paper)`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -75%);
  width: 420px;
  padding: 16px;
`;

const ModalHeader = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

const ModalContent = styled.div`
  margin: 16px 0;
`;

const Form = styled.form`
  padding-right: 8px;
`;

const FormCheckboxOptions = styled.div`
  display: flex;
  flex-direction: column;
`;

const CategoryChipBoxWrapper = styled.div`
  margin-bottom: 12px;
`;

const ModalActions = styled.div`
  display: flex;
  align-items: center;
  justify-content: flex-end;
  padding-right: 8px;
`;

const FORM_ID = 'add-field-form';

const AddField: React.FC = () => {
  const [{ fields }, { addField }] = useSchemaStore();

  const [state, dispatch] = useReducer(addFieldReducer, initialState);

  const handleOpenModal = () => {
    dispatch({ type: AddFieldActionTypes.OpenModal });
  };

  const handleCloseModal = () => {
    dispatch({ type: AddFieldActionTypes.CloseModal });
  };

  const handleFieldNameChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const fieldName = event.target.value;
    if (fields[fieldName]) {
      dispatch({
        type: AddFieldActionTypes.SetError,
        payload: 'Field name already used',
      });
    } else {
      dispatch({
        type: AddFieldActionTypes.ClearError,
      });
    }

    dispatch({
      type: AddFieldActionTypes.FieldName,
      payload: fieldName,
    });
  };

  const handleDataTypeChange = (
    event: React.ChangeEvent<HTMLInputElement & { value: DataTypes }>
  ) => {
    dispatch({
      type: AddFieldActionTypes.DataType,
      payload: event.target.value,
    });
  };

  const handleOutputUnitChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: AddFieldActionTypes.OutputUnit,
      payload: event.target.value,
    });
  };

  const handleDateTimeFormatChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: AddFieldActionTypes.DateTime,
      payload: event.target.value,
    });
  };

  const handleOptionalFlagChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: AddFieldActionTypes.Optional,
      payload: event.target.checked,
    });
  };

  const handleDropMissingFlagChange = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    dispatch({
      type: AddFieldActionTypes.DropMissing,
      payload: event.target.checked,
    });
  };

  const handleAddCategoryValueSuccess = (value: string) => {
    dispatch({
      type: AddFieldActionTypes.AddCategoryValue,
      payload: value,
    });
  };

  const handleRemoveCategoryValue = (value: string) => {
    dispatch({
      type: AddFieldActionTypes.RemoveCategoryValue,
      payload: value,
    });
  };

  const handleSubmit = (event: React.FormEvent<HTMLFormElement>) => {
    event.preventDefault();
    const {
      fieldName,
      dataType,
      dateTimeFormat,
      outputUnit,
      optional,
      dropMissing,
      allowedCategoryValues,
    } = state.form;

    const fieldUnitType = Units[dataType].find(
      (unitType) => unitType.unit === outputUnit
    );

    addField({
      name: fieldName,
      dataType,
      unitType: fieldUnitType,
      dateTimeFormat: dateTimeFormat || undefined,
      optional,
      dropMissing,
      allowedValues:
        dataType === DataTypes.CATEGORY ? allowedCategoryValues : undefined,
    });

    handleCloseModal();
  };

  return (
    <>
      <Wrapper onClick={handleOpenModal}>
        <AddFieldContainer>
          <AddCircleIcon fontSize="large" />
          <AddFieldText>Add Field</AddFieldText>
        </AddFieldContainer>
      </Wrapper>
      <Modal open={state.modalIsOpen} onClose={handleCloseModal}>
        <ModalPaper elevation={6}>
          <ModalHeader>
            <Typography variant="h5">Add New Field</Typography>
            <IconButton size="small" onClick={handleCloseModal}>
              <CloseIcon fontSize="small" />
            </IconButton>
          </ModalHeader>
          <ModalContent>
            <Form id={FORM_ID} onSubmit={handleSubmit}>
              <TextField
                required
                value={state.form.fieldName}
                onChange={handleFieldNameChange}
                label="Field Name"
                variant="outlined"
                size="small"
                fullWidth
                margin="normal"
                error={Boolean(state.error)}
                helperText={state.error}
                autoFocus
              />
              <TextField
                value={state.form.dataType}
                onChange={handleDataTypeChange}
                select
                label="Data Type"
                variant="outlined"
                size="small"
                margin="normal"
                fullWidth
              >
                {Object.values(DataTypes).map((dataType) => (
                  <MenuItem key={dataType} value={dataType}>
                    {getReadableDataType(dataType)}
                  </MenuItem>
                ))}
              </TextField>
              {state.form.outputUnit && (
                <TextField
                  value={state.form.outputUnit}
                  onChange={handleOutputUnitChange}
                  select
                  variant="outlined"
                  size="small"
                  margin="normal"
                  label="Output Unit"
                  fullWidth
                  SelectProps={{
                    MenuProps: {
                      PaperProps: {
                        style: {
                          maxHeight: 400,
                        },
                      },
                    },
                  }}
                >
                  <MenuItem value="none">none</MenuItem>
                  {Units[state.form.dataType].map(
                    (unitType: UnitType) =>
                      unitType.unit && (
                        <MenuItem key={unitType.unit} value={unitType.unit}>
                          {convertUnitText(unitType.unit)}
                        </MenuItem>
                      )
                  )}
                </TextField>
              )}
              {state.form.dataType === DataTypes.DATETIME &&
                state.form.dateTimeFormat && (
                  <TextField
                    value={state.form.dateTimeFormat}
                    onChange={handleDateTimeFormatChange}
                    select
                    variant="outlined"
                    size="small"
                    margin="normal"
                    label="Date Time Format"
                    fullWidth
                  >
                    {DateTimeFormats.map((dtf) => (
                      <MenuItem key={dtf.format} value={dtf.format}>
                        {dtf.format}
                      </MenuItem>
                    ))}
                  </TextField>
                )}
              {state.form.dataType === DataTypes.CATEGORY && (
                <>
                  <CategoryValueInput
                    label="Category Values"
                    size="small"
                    margin="normal"
                    fullWidth
                    onSubmitSuccess={handleAddCategoryValueSuccess}
                  />
                  <CategoryChipBoxWrapper>
                    <CategoryChipBox
                      values={state.form.allowedCategoryValues}
                      onDeleteValue={handleRemoveCategoryValue}
                    />
                  </CategoryChipBoxWrapper>
                </>
              )}
              <FormCheckboxOptions>
                <FormControlLabel
                  control={
                    <Checkbox
                      value={state.form.optional}
                      onChange={handleOptionalFlagChange}
                    />
                  }
                  label="Optional"
                />
                <FormControlLabel
                  control={
                    <Checkbox
                      value={state.form.dropMissing}
                      onChange={handleDropMissingFlagChange}
                    />
                  }
                  label="Drop Missing Values"
                />
              </FormCheckboxOptions>
            </Form>
          </ModalContent>
          <ModalActions>
            <Button
              variant="contained"
              color="primary"
              type="submit"
              form={FORM_ID}
              disabled={
                Boolean(state.error) || state.form.fieldName.trim() === ''
              }
            >
              Add Field
            </Button>
          </ModalActions>
        </ModalPaper>
      </Modal>
    </>
  );
};

export default AddField;
