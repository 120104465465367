import React from 'react';
import { AppBar, Step, StepButton, Stepper, Toolbar } from '@material-ui/core';
import styled from 'styled-components';

import Logo from '../../../common/images/segna-logo-teal-black.svg';
import useGetWizardRoutingData from '../hooks/useGetWizardRoutingData';
import { ONBOARDINGWIZARDPAGES } from '../../../common/routes';
import RedirectableStepButton from './redirectable-step-button';

const StyledAppbar = styled(AppBar)`
  background-color: white;
  height: 80px;
`;

const StyledToolbar = styled(Toolbar)`
  justify-content: space-between;
  color: black;
  align-items: center;
  height: 100%;
`;

const Left = styled.div`
  height: 32px;
  margin-top: 8px;
  flex: 1;
`;

const Middle = styled.div`
  flex: 2;
  max-width: 800px;

  @media screen and (max-width: 900px) {
    display: none;
  }
`;

const Right = styled.div`
  flex: 1;
  display: flex;
  justify-content: flex-end;
`;

enum STEPS {
  DOWNLOAD,
  RUN_JOB,
  VIEW_DATA,
}

const OnboardingWizardNavBar: React.FC = () => {
  const { currentStepIndex } = useGetWizardRoutingData();

  const isDownloadComplete = currentStepIndex > STEPS.DOWNLOAD;
  const isRunJobComplete = currentStepIndex > STEPS.RUN_JOB;

  return (
    <StyledAppbar>
      <StyledToolbar>
        <Left>
          <img src={Logo} alt="Segna Logo" height="100%" />
        </Left>
        <Middle>
          <Stepper nonLinear activeStep={currentStepIndex}>
            <RedirectableStepButton
              canGoBack={isDownloadComplete}
              label="Download"
              to={ONBOARDINGWIZARDPAGES.Welcome}
            />
            <RedirectableStepButton
              canGoBack={isRunJobComplete}
              label="Run Job"
              to={ONBOARDINGWIZARDPAGES.RunJob}
            />
            {/* Last step so can't really 'go back' to it */}
            <Step>
              <StepButton disabled>View Data</StepButton>
            </Step>
          </Stepper>
        </Middle>
        <Right />
      </StyledToolbar>
    </StyledAppbar>
  );
};

export default OnboardingWizardNavBar;
