import React from 'react';
import styled from 'styled-components';
import { Card, CircularProgress, Divider, Typography } from '@material-ui/core';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import ROUTES from '../../../../common/routes';
import useErrorAlert from '../../../../utils/useErrorAlert';
import { convertUnixTimestampToDateString } from '../utils';

const CardContainer = styled(Card)`
  padding: 24px;
`;

const Container = styled.div`
  display: flex;
  flex-direction: column;
`;

const InfoRows = styled.div`
  display: grid;
  grid-template-columns: 3fr 1fr 1fr;
  margin-bottom: 16px;
`;

const TextContainer = styled(Typography)``;

export interface Props {
  currentData: number;
}

interface UpcomingInvoiceResponse {
  periodStart: number;
  periodEnd: number;
  nextPaymentAttempt: number;
  total: number;
  currency: string;
  discountAmount?: number;
  lines: {
    amount: number;
    currency: string;
    description: string;
    quantity: number;
    periodStart: number;
    periodEnd: number;
    usageType: string;
    nickname: string;
  }[];
}

const UpcomingInvoice: React.FC = () => {
  const [data, isLoading, error] = useFetchSimpleService<
    UpcomingInvoiceResponse
  >(`${ROUTES.Billing}/invoice`);
  useErrorAlert(error);

  return (
    <CardContainer>
      <Typography variant="h5" gutterBottom>
        Upcoming Bill
      </Typography>
      {isLoading ? (
        <CircularProgress />
      ) : (
        data && (
          <Container>
            <Typography variant="subtitle1" gutterBottom>
              {`To be charged on: ${convertUnixTimestampToDateString(
                data.nextPaymentAttempt
              )}`}
            </Typography>
            <Divider style={{ marginBottom: 16 }} />
            {data.lines.map((line) => {
              const {
                amount,
                currency,
                quantity,
                periodStart,
                periodEnd,
                nickname,
              } = line;
              return (
                <InfoRows>
                  <div>
                    <Typography variant="subtitle1">{nickname}</Typography>
                    <Typography variant="caption">{`For period of: ${convertUnixTimestampToDateString(
                      periodStart
                    )} - ${convertUnixTimestampToDateString(
                      periodEnd
                    )}`}</Typography>
                  </div>
                  <Typography variant="subtitle1">{quantity}</Typography>
                  <TextContainer
                    variant="subtitle1"
                    style={{
                      justifyContent: 'flex-end',
                      textAlign: 'right',
                    }}
                  >
                    ${amount / 100} {currency.toUpperCase()}
                  </TextContainer>
                </InfoRows>
              );
            })}
            {data.discountAmount && (
              <InfoRows>
                <div>
                  <Typography variant="subtitle1">Discount Applied</Typography>
                </div>
                <div />
                <TextContainer
                  variant="subtitle1"
                  style={{
                    justifyContent: 'flex-end',
                    textAlign: 'right',
                  }}
                >
                  - ${data.discountAmount / 100} {data.currency.toUpperCase()}
                </TextContainer>
              </InfoRows>
            )}
            <Divider />
            <div>
              <Typography
                variant="h6"
                style={{ justifyContent: 'flex-end', textAlign: 'right' }}
              >
                ${data.total / 100} {data.currency.toUpperCase()}
              </Typography>
            </div>
          </Container>
        )
      )}
    </CardContainer>
  );
};

export default UpcomingInvoice;
