import React from 'react';
import styled from 'styled-components';
import { CircularProgress, Typography } from '@material-ui/core';
import { FileCopyOutlined, CancelOutlined } from '@material-ui/icons';

const Container = styled.div`
  display: flex;
  flex-direction: column;
  align-content: center;
  text-align: center;
  padding: 16px;
  height: 260px;
  margin: auto;
`;

const IconContainer = styled.div`
  margin: auto;
`;

export const UploadFilePrompt: React.FC = () => {
  return (
    <Container>
      <IconContainer>
        <FileCopyOutlined fontSize="large" />
        <Typography>Click here or drag to upload a test file!</Typography>
      </IconContainer>
    </Container>
  );
};

export const DropFilePrompt: React.FC = () => {
  return (
    <Container>
      <IconContainer>
        <FileCopyOutlined fontSize="large" color="secondary" />
        <Typography>Drop your file to upload!</Typography>
      </IconContainer>
    </Container>
  );
};

export const RejectFilePrompt: React.FC = () => {
  return (
    <Container>
      <IconContainer>
        <CancelOutlined fontSize="large" />
        <Typography>
          This is an invalid file type - please try something else
        </Typography>
      </IconContainer>
    </Container>
  );
};

export const FileUploadingPrompt: React.FC = () => {
  return (
    <Container>
      <IconContainer>
        <CircularProgress />
        <Typography>Uploading file!</Typography>
      </IconContainer>
    </Container>
  );
};
