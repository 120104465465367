import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { Table, TableCell, TableRow } from '@material-ui/core';
import { toast } from 'react-toastify';
import { useInputData } from '../../../scripts-store';

const TableContainer = styled.div`
  overflow: scroll;
  background-color: white;
`;

const StyledTableCell = styled(TableCell)`
  min-width: 80px;
`;

const InputDataTable: React.FC = () => {
  const [inputData] = useInputData();
  const [tableBody, setTableBody] = useState<string[][]>([]);

  useEffect(() => {
    if (inputData) {
      setTableBody(inputData.slice(0, 100));
    } else {
      toast.error('Data could not be uploaded');
    }
  }, [inputData]);

  return (
    <TableContainer>
      <Table size="small">
        {tableBody &&
          tableBody.map((row) => (
            <TableRow>
              {row.map((value) => (
                <StyledTableCell>{value}</StyledTableCell>
              ))}
            </TableRow>
          ))}
      </Table>
    </TableContainer>
  );
};

export default InputDataTable;
