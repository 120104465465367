import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';

import { StoreState, ContainerProps } from './types';

const initialState: StoreState = {
  pipelines: {},
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setPipelines: () => (
    { setState }: StoreApi,
    { pipelines }: ContainerProps
  ) => {
    setState({
      pipelines,
    });
  },
  deletePipeline: (deletedPipelineId: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const { pipelines } = getState();

    delete pipelines[deletedPipelineId];

    setState({
      pipelines,
    });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const usePipelineDashboardStore = createHook(Store);
export const PipelineDashboardStoreContainer = createContainer(Store, {
  onInit: actions.setPipelines,
  onUpdate: actions.setPipelines,
});
