import React, { ReactNode } from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import { NavLink } from 'react-router-dom';
import COLORS from '../../../common/colors';

const WrapperLink = styled(NavLink)`
  display: grid;
  grid-template-columns: 1fr 4fr;
  padding: 8px;
  border-radius: 2px;
  transition: all 50ms ease-in-out;
  text-decoration: none;
  color: inherit;

  &:hover {
    background-color: ${COLORS.Grey};
  }
`;

interface Props {
  children: ReactNode;
  path: string;
  text: string;
  onManualResetErrorBoundary?: () => void;
}

const NavItem: React.FC<Props> = ({
  children,
  path,
  text,
  onManualResetErrorBoundary,
}) => (
  <WrapperLink
    to={path}
    activeStyle={{ backgroundColor: COLORS.Grey }}
    onClick={onManualResetErrorBoundary}
  >
    {children}
    <Typography>{text}</Typography>
  </WrapperLink>
);

export default NavItem;
