import { useEffect } from 'react';
import { Spotlight } from '../types';
import useSpotlight from '../useSpotlight';
import { getSpotlightLocalStorageKey } from '../spotlight-local-storage';

const useSpotlightWithLocalStorage = (
  spotlightData: Spotlight[],
  page: string
): void => {
  const [startSpotlight] = useSpotlight(spotlightData);

  useEffect(() => {
    const key = getSpotlightLocalStorageKey(page);
    const seen = localStorage.getItem(key);
    if (!seen) {
      startSpotlight(page);
    }
  }, [page, startSpotlight]);
};

export default useSpotlightWithLocalStorage;
