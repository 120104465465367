import { BILLINGAPI } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

type Response = {
  url: string;
};

const useCreateCheckoutSessionService = (): [
  () => Promise<Response | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<void, Response>(
    BILLINGAPI.Create_Checkout_Session
  );
};

export default useCreateCheckoutSessionService;
