import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  CircularProgress,
  Fade,
  IconButton,
  LinearProgress,
  Modal,
  Paper,
  Typography,
} from '@material-ui/core';
import AddIcon from '@material-ui/icons/Add';
import Divider from '@material-ui/core/Divider';
import Chip from '@material-ui/core/Chip';
import CancelIcon from '@material-ui/icons/Cancel';
import { toast } from 'react-toastify';
import { Close } from '@material-ui/icons';
import useUploadFileService from './service/useUploadFileSerivce';
import useErrorAlert from '../../../utils/useErrorAlert';
import SampleSwitch from '../sample-switch';

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalComponent = styled(Paper)`
  width: 500px;
  height: 600px;
  padding: 16px;
  display: flex;
  flex-direction: column;

  // TODO: Find a better fix that doesn't remove the ability to focus
  &:focus-visible {
    outline: none;
  }
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const BorderContainer = styled.div`
  flex: 1;
  border: 1px solid #dddedf;
  border-radius: 6px;
  padding: 8px;
  margin: 16px 0;
`;

const LoadButtonContainer = styled.div`
  margin: 8px;
`;

const ChipContainer = styled.div``;

const ChipCancelIcon = styled(CancelIcon)`
  color: #ef6a6a;
`;

const FileChip = styled(Chip)`
  max-width: 100%;
  margin: 4px;
  padding: 8px;
`;

const ProgressBarWrapper = styled.div`
  height: 20px;
`;

const ButtonFooter = styled.div`
  display: flex;
  justify-content: flex-end;
  align-items: flex-end;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  onAddDataSource: (
    dataResourceId: string,
    dataResourceName: string,
    dataResourceType: string,
    isSample?: boolean
  ) => void;
  allowSampleOption?: boolean;
  limit?: number;
}

const AddFileModal: React.FC<Props> = ({
  isOpen,
  onClose,
  onAddDataSource,
  allowSampleOption = false,
  limit,
}) => {
  const [uploadFileService, error, , progress] = useUploadFileService();
  useErrorAlert(error);

  const [fileList, setFileList] = useState<File[]>([]);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const [isSample, setIsSample] = useState(true);

  const onLoadInFile = (files: FileList | null) => {
    const fileListAsArray = files ? Array.from(files) : [];
    setFileList((prevFileList) => [...prevFileList, ...fileListAsArray]);
  };

  const onDeleteFile = (file: File) => {
    const cloneFileList = [...fileList];
    const index = cloneFileList.indexOf(file);
    if (index > -1) {
      cloneFileList.splice(index, 1);
      setFileList(cloneFileList);
    }
  };

  const onUploadFile = async () => {
    setIsLoading(true);
    const response = await uploadFileService(fileList, isSample);
    if (response) {
      Object.entries(response.dataResources).forEach(
        ([dataResourceId, { dataResourceName, dataResourceType }]) => {
          onAddDataSource(
            dataResourceId,
            dataResourceName,
            dataResourceType,
            isSample
          );
          toast.success(
            <>
              <span
                style={{
                  fontStyle: 'italic',
                }}
              >
                {dataResourceName}
              </span>
              {' '}uploaded successfully
            </>
          );
        }
      );
      setFileList([]);
      onClose();
    }
    setIsLoading(false);
  };

  const isLocalLoadDisabled = limit ? fileList.length >= limit : false;
  const isUploadDisabled = isLoading || fileList.length === 0;

  const handleModalClose = () => {
    if (!isLoading) {
      onClose();
    }
  };

  return (
    <ModalContainer open={isOpen} onClose={handleModalClose}>
      <Fade in={isOpen}>
        <ModalComponent>
          <HeaderContainer>
            <Typography variant="h5" gutterBottom>
              Upload your data
            </Typography>
            <div>
              <IconButton
                onClick={handleModalClose}
                disabled={isLoading}
                size="small"
              >
                <Close fontSize="small" />
              </IconButton>
            </div>
          </HeaderContainer>
          {limit && <Typography>You can upload {limit} file(s)</Typography>}
          <BorderContainer>
            <LoadButtonContainer>
              <label htmlFor="contained-button-file">
                <input
                  id="contained-button-file"
                  type="file"
                  onChange={(e: React.ChangeEvent<HTMLInputElement>) => {
                    onLoadInFile(e.target.files);
                    e.target.value = ''; // Resets the file loader to allow same file to be added again
                  }}
                  hidden
                  accept=".xlsx, .xls, .csv"
                  disabled={isLocalLoadDisabled}
                />
                <Button
                  variant="contained"
                  color="primary"
                  component="span"
                  startIcon={<AddIcon />}
                  disabled={isLocalLoadDisabled}
                >
                  Load In Data
                </Button>
              </label>
            </LoadButtonContainer>
            <Divider variant="middle" />
            <ChipContainer>
              {fileList.map((file) => (
                <FileChip
                  key={file.name}
                  label={file.name}
                  deleteIcon={<ChipCancelIcon />}
                  onDelete={() => onDeleteFile(file)}
                />
              ))}
            </ChipContainer>
          </BorderContainer>
          <ProgressBarWrapper>
            {isLoading && (
              <LinearProgress
                variant="determinate"
                color="primary"
                value={progress}
              />
            )}
          </ProgressBarWrapper>
          <ButtonFooter>
            {allowSampleOption && (
              <SampleSwitch isSample={isSample} setIsSample={setIsSample} />
            )}
            <div>
              <Button
                onClick={onUploadFile}
                color="secondary"
                variant="contained"
                disabled={isUploadDisabled}
              >
                {isLoading ? (
                  <CircularProgress color="inherit" size={24} />
                ) : (
                  `Add`
                )}
              </Button>
            </div>
          </ButtonFooter>
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default AddFileModal;
