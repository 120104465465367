import React from 'react';
import { Grid, TextField, Typography } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import styled from 'styled-components';
import { DataTypes } from '../../units-store/types';
import convertUnitText from '../../../../../utils/convertUnitText';
import getReadableDataType from './util';
import DateFormatInput from './date-format-input';

const UnitsRowContainer = styled(Grid)`
  align-content: center;
  width: 100%;
`;

const UnitRowItem = styled(Grid)`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 340px;
  justify-content: center;
`;

const SubTitle = styled(Typography)`
  color: rgb(150, 150, 150);
  padding: 5px 15px 5px 5px;
`;

const DataSourceSubTitle = styled(Typography)`
  color: rgb(150, 150, 150);
  margin-right: 16px;
`;

const StyledAutocomplete = styled(Autocomplete)`
  width: 100%;
`;

const Label = styled(Typography)`
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  padding-left: 5px;
  height: 30px;
  align-content: center;
`;

const DataSourceNameWrapper = styled.div`
  width: 100%;
`;

const DataSourceName = styled(Typography)`
  width: 100%;
  white-space: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
  font-weight: bold;
`;

const StyledTextField = styled(TextField)`
  background-color: white;
  border-radius: 5px;
`;

const FieldOrColumnLabelContainer = styled.div`
  padding: 0 5px;
  color: rgb(150, 150, 150);
  display: flex;
  align-items: center;
  //justify-content: center;
  width: 100%;
`;

const FieldColumnNameContainer = styled.div`
  display: grid;
  grid-template-rows: 1fr 1fr;
  align-content: normal;
  //justify-content: center;
  width: 200px;
`;

export const Units = {
  [DataTypes.NUMBER]: [
    { group: '', unit: null },
    { group: 'mass', unit: 'KG' },
    { group: 'mass', unit: 'G' },
    { group: 'mass', unit: 'POUND' },
    { group: 'mass', unit: 'OUNCE' },
    { group: 'length', unit: 'THOU' },
    { group: 'length', unit: 'INCH' },
    { group: 'length', unit: 'FOOT' },
    { group: 'length', unit: 'YARD' },
    { group: 'length', unit: 'MILE' },
    { group: 'length', unit: 'MILLIMETRE' },
    { group: 'length', unit: 'CENTIMETRE' },
    { group: 'length', unit: 'METRE' },
    { group: 'volume', unit: 'MILLILITRE' },
    { group: 'volume', unit: 'LITRE' },
    { group: 'volume', unit: 'FLUID_OUNCE' },
    { group: 'volume', unit: 'PINT' },
    { group: 'volume', unit: 'GALLON' },
    { group: 'volume', unit: 'CUBIC' },
    { group: 'temperature', unit: 'CELSIUS' },
    { group: 'temperature', unit: 'FAHRENHEIT' },
    { group: 'temperature', unit: 'KELVIN' },
    // { group: 'currency', unit: 'USD' },
    // { group: 'currency', unit: 'POUND' },
    // { group: 'currency', unit: 'EURO' },
    // { group: 'currency', unit: 'NZD' },
    // { group: 'currency', unit: 'AUD' },
    { group: 'time', unit: 'SECOND' },
    { group: 'time', unit: 'MINUTE' },
    { group: 'time', unit: 'HOUR' },
    { group: 'time', unit: 'DAY' },
    { group: 'time', unit: 'WEEK' },
    { group: 'time', unit: 'MONTH' },
    { group: 'time', unit: 'YEAR' },
  ],
  [DataTypes.CATEGORY]: [],
  [DataTypes.RICH_TEXT]: [],
  [DataTypes.DATETIME]: [
    { group: '', unit: null },
    { group: '', unit: 'UTC_m12' },
    { group: '', unit: 'UTC_m11' },
    { group: '', unit: 'UTC_m10' },
    { group: '', unit: 'UTC_m930' },
    { group: '', unit: 'UTC_m9' },
    { group: '', unit: 'UTC_m8' },
    { group: '', unit: 'UTC_m7' },
    { group: '', unit: 'UTC_m6' },
    { group: '', unit: 'UTC_m5' },
    { group: '', unit: 'UTC_m4' },
    { group: '', unit: 'UTC_m330' },
    { group: '', unit: 'UTC_m3' },
    { group: '', unit: 'UTC_m2' },
    { group: '', unit: 'UTC_m1' },
    { group: '', unit: 'UTC_0' },
    { group: '', unit: 'UTC_p1' },
    { group: '', unit: 'UTC_p2' },
    { group: '', unit: 'UTC_p3' },
    { group: '', unit: 'UTC_p330' },
    { group: '', unit: 'UTC_p4' },
    { group: '', unit: 'UTC_p430' },
    { group: '', unit: 'UTC_p5' },
    { group: '', unit: 'UTC_p530' },
    { group: '', unit: 'UTC_p545' },
    { group: '', unit: 'UTC_p6' },
    { group: '', unit: 'UTC_p630' },
    { group: '', unit: 'UTC_p7' },
    { group: '', unit: 'UTC_p8' },
    { group: '', unit: 'UTC_p845' },
    { group: '', unit: 'UTC_p9' },
    { group: '', unit: 'UTC_p930' },
    { group: '', unit: 'UTC_p10' },
    { group: '', unit: 'UTC_p1030' },
    { group: '', unit: 'UTC_p11' },
    { group: '', unit: 'UTC_p12' },
    { group: '', unit: 'UTC_p1245' },
    { group: '', unit: 'UTC_p13' },
    { group: '', unit: 'UTC_p14' },
  ],
};

export interface UnitType {
  unit: string | null;
  group: string;
}

interface UnitsRowProps {
  dataType: DataTypes;
  dataUnit: string | null;
  onDataTypeChange: ((value: DataTypes) => void) | (() => void);
  onDataUnitChange: ((value: string | null) => void) | (() => void);
  onDateFormatChange?: (value: string) => void;
  label?: string;
  dataSource?: string;
  className?: string;
  isField?: boolean;
  disabledDateTimeInput: boolean;
  dateTimeFormat?: string;
}

const UnitsRow: React.FC<UnitsRowProps> = ({
  dataType,
  dataUnit,
  onDataTypeChange,
  onDataUnitChange,
  onDateFormatChange,
  label,
  dataSource,
  className,
  isField,
  disabledDateTimeInput,
  dateTimeFormat,
}) => {
  const handleUnitChange = (value: UnitType) => {
    onDataUnitChange(value.unit);
  };

  const handleDataTypeChange = (value: DataTypes) => {
    onDataTypeChange(value);
  };

  return (
    <UnitsRowContainer
      container
      spacing={2}
      className={className}
      wrap="nowrap"
    >
      <FieldColumnNameContainer>
        {isField ? (
          <FieldOrColumnLabelContainer>
            <Typography variant="subtitle1">Field:</Typography>
          </FieldOrColumnLabelContainer>
        ) : (
          <FieldOrColumnLabelContainer>
            <Typography variant="subtitle1">Field:</Typography>
          </FieldOrColumnLabelContainer>
        )}
        {label && (
          <Label
            style={{
              fontWeight: 'bold',
            }}
          >
            {label}
          </Label>
        )}
      </FieldColumnNameContainer>

      <UnitRowItem item xs={3}>
        <SubTitle>Data type:</SubTitle>
        <StyledAutocomplete
          disableClearable
          value={dataType}
          style={{ width: '160px' }}
          renderInput={(params) => (
            <StyledTextField
              {...params}
              variant="outlined"
              color="primary"
              margin="dense"
            />
          )}
          options={Object.values(DataTypes)}
          getOptionLabel={(option) => {
            return getReadableDataType(option as DataTypes);
          }}
          onChange={(event, value) => {
            const newValue = value as DataTypes;
            if (Object.values(DataTypes).includes(newValue)) {
              handleDataTypeChange(newValue);
            }
          }}
        />
      </UnitRowItem>
      {Units[dataType].length === 0 ? (
        <UnitRowItem item xs={4} />
      ) : (
        <>
          <UnitRowItem item xs={4}>
            <SubTitle>{isField ? 'Output Unit' : 'Input Unit'}:</SubTitle>
            <StyledAutocomplete
              disableClearable
              value={{ unit: dataUnit }}
              onChange={(_, value) => {
                handleUnitChange(value as UnitType);
              }}
              renderInput={(params) => (
                <StyledTextField
                  {...params}
                  color="primary"
                  variant="outlined"
                  margin="dense"
                />
              )}
              options={dataType == null ? [] : Units[dataType]}
              getOptionLabel={(option) => {
                const typedOption = option as UnitType;
                return convertUnitText(typedOption.unit);
              }}
              getOptionSelected={(option) => {
                const typedOption = option as UnitType;
                return typedOption.unit === dataUnit;
              }}
              groupBy={(option) => {
                const typedOption = option as UnitType;
                return typedOption.group;
              }}
            />
          </UnitRowItem>
          {dataType === DataTypes.DATETIME && onDateFormatChange && (
            <UnitRowItem item xs={4}>
              <SubTitle>Date Format:</SubTitle>
              {dateTimeFormat !== undefined && (
                <DateFormatInput
                  value={dateTimeFormat}
                  onDateTimeFormatChange={onDateFormatChange}
                  disabled={disabledDateTimeInput}
                />
              )}
            </UnitRowItem>
          )}
        </>
      )}
      {dataSource && (
        <UnitRowItem item xs={3}>
          <DataSourceNameWrapper>
            <DataSourceSubTitle>Data source:</DataSourceSubTitle>
            <DataSourceName noWrap>{dataSource}</DataSourceName>
          </DataSourceNameWrapper>
        </UnitRowItem>
      )}
    </UnitsRowContainer>
  );
};

export default UnitsRow;
