import React from 'react';
import styled from 'styled-components';
import { TextField, Typography, FormLabel } from '@material-ui/core';
import { Controller } from 'react-hook-form';
import { ConnectorComponentProps } from '../types';
import { EDITING_VALUE } from '../../index';

const CONNECTOR_DETAILS_TEXT = `For more details on how to connect to your S3 Bucket, please refer to our documentation.`;

const FieldsSection = styled.div`
  height: 425px;
  overflow: auto;
  margin: 8px 0;
`;

const FormFieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px 0;
`;

const ErrorMessage = styled(Typography)`
  color: red;
`;

const S3Connector: React.FC<ConnectorComponentProps> = ({
  errors,
  reset,
  control,
  isEditing,
}) => {
  return (
    <>
      {CONNECTOR_DETAILS_TEXT}
      <FieldsSection>
        <FormFieldWrapper>
          <FormLabel required>Connector Name</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. My S3 Connector"
              />
            )}
            name="connectorName"
            control={control}
            rules={{ required: 'Please enter a connector name' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Bucket Name</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. myBucket"
              />
            )}
            name="bucketName"
            control={control}
            rules={{ required: 'Please enter a bucket name' }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Account Key</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. ACCOUNTKEY"
                value={isEditing ? EDITING_VALUE : field.value}
                disabled={isEditing}
              />
            )}
            name="accountKey"
            control={control}
            rules={{
              required: isEditing ? undefined : 'Please enter your account key',
            }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Account Secret</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. abcdefghijklmnop1234"
                value={isEditing ? EDITING_VALUE : field.value}
                disabled={isEditing}
              />
            )}
            name="accountSecret"
            control={control}
            rules={{
              required: isEditing
                ? undefined
                : 'Please enter your account secret',
            }}
          />
        </FormFieldWrapper>
        <FormFieldWrapper>
          <FormLabel required>Region</FormLabel>
          <Controller
            render={({ field }) => (
              <TextField
                {...field}
                variant="outlined"
                color="primary"
                margin="dense"
                placeholder="e.g. ap-southeast-1"
              />
            )}
            name="region"
            control={control}
            rules={{ required: 'Please enter your region' }}
          />
        </FormFieldWrapper>
      </FieldsSection>
      {errors && (
        <ErrorMessage>{Object.entries(errors)?.[0]?.[1]?.message}</ErrorMessage>
      )}
    </>
  );
};

export default S3Connector;

// User ARN
// External ID
// Table names
// Bucket name
