import { PAGEAPIROUTES } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';
import useResourceRouteWithId from '../../../../../utils/useResourceRouteWithId';

type AthenaDatabaseSourceInfo = {
  tableName: string;
};

export type InputDatabaseTableInfo = AthenaDatabaseSourceInfo; // Add other types for other connector here

interface AddRequest {
  connectorId: string;
  isSample: boolean;
  dataResourceInfo: Record<string, string>;
}

type AddResponse = {
  dataResources: {
    [dataResourceId: string]: {
      dataResourceName: string;
      dataResourceType: string;
    };
  };
};

const useAddInputDataResourceService = (): [
  (body: AddRequest) => Promise<AddResponse | void>,
  Error | null,
  () => void
] => {
  const idRoute = useResourceRouteWithId();

  return usePostSimpleService<AddRequest, AddResponse>(
    `${idRoute}${PAGEAPIROUTES.Sample_Data_Resource}`
  );
};

export default useAddInputDataResourceService;
