import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';

const StyledTypography = styled(Typography)`
  display: flex;
  align-items: center;
  font-size: 28px;
  font-weight: bold;
`;

const Number = styled.span<{ bgColor: string }>`
  background-color: ${(props) => props.bgColor};
  border-radius: 50%;
  height: 32px;
  width: 32px;
  display: inline-flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin-right: 12px;
  text-align: center;
  font-size: 20px;
  color: white;
`;

interface Props {
  stepNumber: number;
  bgColor: string;
}

const StepHeading: React.FC<Props> = ({ children, stepNumber, bgColor }) => {
  return (
    <StyledTypography>
      <Number bgColor={bgColor}>{stepNumber}</Number>
      <span>{children}</span>
    </StyledTypography>
  );
};

export default StepHeading;
