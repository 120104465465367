import React from 'react';
import styled from 'styled-components';
import { Button, Paper, Typography } from '@material-ui/core';
import EditIcon from '@material-ui/icons/Edit';
import { useHistory, useParams } from 'react-router-dom';
import SummarySchema, { Props as SummarySchemaProps } from './summary-schema';
import { Props as SummaryRulesProps } from './summary-rules';
import ROUTES, {
  PIPELINEPAGES,
  PIPELINESTATUSROUTES,
} from '../../../../../common/routes';

const StyledPaper = styled(Paper)`
  padding: 24px;
`;

const HeaderWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  margin-bottom: 24px;
`;

type Props = SummarySchemaProps & SummaryRulesProps;

const SchemaAndRules: React.FC<Props> = ({
  fields,
  canAddAdditionalFields,
  combineAdditionalField,
  duplicateDropFields,
}) => {
  const history = useHistory();
  const { pipelineId } = useParams<{
    pipelineId?: string;
  }>();

  const onEdit = () => {
    history.push(
      `${ROUTES.App}${ROUTES.Pipeline}/${pipelineId}${PIPELINESTATUSROUTES.Draft}/${PIPELINEPAGES.Schema}`
    );
  };

  return (
    <StyledPaper>
      <HeaderWrapper>
        <Typography variant="h4"> Output Schema</Typography>
        <div>
          <Button
            onClick={onEdit}
            startIcon={<EditIcon />}
            variant="outlined"
            size="small"
          >
            Edit
          </Button>
        </div>
      </HeaderWrapper>
      <SummarySchema
        fields={fields}
        canAddAdditionalFields={canAddAdditionalFields}
        combineAdditionalField={combineAdditionalField}
        duplicateDropFields={duplicateDropFields}
      />
    </StyledPaper>
  );
};

export default SchemaAndRules;
