export interface Product {
  productName: string;
  price: number;
  priceId: string;
  currency: string;
  interval: string;
  description: string;
  status?: string;
}

export enum STATUS {
  ACTIVE = 'active',
  PAST_DUE = 'past_due',
  UNPAID = 'unpaid',
  CANCELED = 'canceled',
  INCOMPLETE = 'incomplete',
  INCOMPLETE_EXPIRED = 'incomplete_expired',
  TRIALING = 'trialing',
}
