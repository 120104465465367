import React from 'react';
import { AppBar, Button, CircularProgress, Toolbar } from '@material-ui/core';
import styled from 'styled-components';
import ArrowBackIcon from '@material-ui/icons/ArrowBack';
import ArrowForwardIcon from '@material-ui/icons/ArrowForward';
import DoneIcon from '@material-ui/icons/Done';
import { useHistory } from 'react-router-dom';
import { toast } from 'react-toastify';
import ROUTES from '../../../common/routes';
import useGetWizardRoutingData from '../hooks/useGetWizardRoutingData';
import { trackButtonClicked } from '../../../utils/useTrackEvent';

const BottomBar = styled(AppBar)`
  top: auto;
  background-color: white;
  bottom: 0;
  left: 0;
  height: 72px;
`;

const StyledToolbar = styled(Toolbar)`
  align-items: center;
  height: 100%;
  width: 900px;
  justify-content: space-between;
  margin: 0 auto;
  padding: 0;
  box-sizing: border-box;

  @media screen and (max-width: 900px) {
    width: 100%;
    padding-left: 16px;
    padding-right: 16px;
  }
`;

interface Props {
  onProgressionCallback?: () => Promise<void>;
  isOnProgressionDisabled?: boolean;
  isOnProgressionLoading?: boolean;
  children?: React.ReactNode;
}

const OnboardingWizardBottomBar: React.FC<Props> = ({
  onProgressionCallback,
  isOnProgressionDisabled = false,
  isOnProgressionLoading = false,
  children,
}) => {
  const history = useHistory();
  const { currentStepIndex, pages, isLastStep } = useGetWizardRoutingData();

  const onBack = () => {
    const prevPage = pages[currentStepIndex - 1];
    history.push(`${ROUTES.App}${ROUTES.Onboarding_Wizard}/${prevPage}`);
  };

  const onNext = async () => {
    trackButtonClicked(pages[currentStepIndex], 'next');
    onProgressionCallback && (await onProgressionCallback());
    const nextPage = pages[currentStepIndex + 1];
    history.push(`${ROUTES.App}${ROUTES.Onboarding_Wizard}/${nextPage}`);
  };

  const onFinish = async () => {
    trackButtonClicked(pages[currentStepIndex], 'finish');
    try {
      onProgressionCallback && (await onProgressionCallback());
      history.push(`${ROUTES.App}${ROUTES.Quick_Start}`);
    } catch (e) {
      toast.error(e.message);
    }
  };

  const hasPrevStep = currentStepIndex > 0;

  return (
    <BottomBar component="footer">
      <StyledToolbar>
        <div>
          {hasPrevStep && (
            <Button
              variant="contained"
              startIcon={<ArrowBackIcon />}
              onClick={onBack}
            >
              Back
            </Button>
          )}
        </div>
        <div>{children}</div>
        <div>
          {isLastStep ? (
            <Button
              variant="contained"
              color="secondary"
              endIcon={<DoneIcon />}
              onClick={onFinish}
              disabled={isOnProgressionDisabled || isOnProgressionLoading}
            >
              {isOnProgressionLoading ? (
                <CircularProgress size={24} />
              ) : (
                'Finish'
              )}
            </Button>
          ) : (
            <Button
              variant="contained"
              color="primary"
              endIcon={<ArrowForwardIcon />}
              onClick={onNext}
              disabled={isOnProgressionDisabled || isOnProgressionLoading}
            >
              {isOnProgressionLoading ? <CircularProgress size={24} /> : 'Next'}
            </Button>
          )}
        </div>
      </StyledToolbar>
    </BottomBar>
  );
};

export default OnboardingWizardBottomBar;
