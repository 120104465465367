enum COLORS {
  Grey = '#f0f0f1',
  LightGrey = '#f5f5f5',
  Orange = '#f1a400',
  Primary = '#091946',
  Blue = '#3C74DD',
  DarkPurple = '#201629',
  DarkBlue = '#0a1a46',
  Red = 'red',
  DarkRed = '#d32d2f',
  Teal = '#1fc7d9',
}

export default COLORS;
