import ROUTES from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

interface AthenaConnectorInfo {
  bucketName: string;
  accountKey: string;
  accountSecret: string;
  path: string;
  region: string;
}

export type ConnectorInfo = { connectorName: string } & AthenaConnectorInfo; // Add other types for other connector here

export interface EditRequest {
  connectorType: string;
  connectorInfo: ConnectorInfo;
}

export interface EditResponse {
  connectorId: string;
}

const useEditDatabaseConnectorService = (
  id: string | undefined
): [
  (body: EditRequest) => Promise<EditResponse | void>,
  Error | null,
  () => void
] =>
  usePostSimpleService<EditRequest, EditResponse>(`${ROUTES.Connectors}/${id}`);

export default useEditDatabaseConnectorService;
