import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Table,
  TableCell,
  TableContainer,
  TableRow,
  Typography,
} from '@material-ui/core';
import { useIsTestSuccessful, useTestOutput } from '../../../scripts-store';

const Wrapper = styled.div`
  height: 360px;
  align-content: center;
  justify-content: center;
`;

const StyledTableContainer = styled(TableContainer)`
  overflow: scroll;
  height: 360px;
`;

const StyledTableCell = styled(TableCell)`
  min-width: 80px;
`;

const OutputDataTable: React.FC = () => {
  const [testOutput] = useTestOutput();
  const [isTestSuccessful] = useIsTestSuccessful();
  const [dataTableBody, setDataTableBody] = useState<string[][]>([]);
  const [errorMessage, setErrorMessage] = useState<string | null>(null);

  useEffect(() => {
    setErrorMessage(null);
    if (typeof testOutput !== 'string') {
      setDataTableBody(testOutput.slice(0, 100));
      setErrorMessage(null);
    } else {
      setErrorMessage(testOutput);
      setDataTableBody([]);
    }
  }, [isTestSuccessful, testOutput]);

  return (
    <Wrapper>
      {typeof testOutput !== 'string' ? (
        <StyledTableContainer>
          <Table size="small" style={{ backgroundColor: 'white' }}>
            {dataTableBody.map((row) => (
              <TableRow>
                {row.map((value) => (
                  <StyledTableCell>{value}</StyledTableCell>
                ))}
              </TableRow>
            ))}
          </Table>
        </StyledTableContainer>
      ) : (
        <Typography style={{ color: 'red' }}>{errorMessage}</Typography>
      )}
    </Wrapper>
  );
};

export default OutputDataTable;
