import React from 'react';
import {
  CartesianGrid,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import styled from 'styled-components';
import { Container, Typography } from '@material-ui/core';
import COLORS from '../../../../../common/colors';

interface Props {
  data: {
    value: number;
    time: string;
  }[];
  contentType: string;
  dataUsageUnit?: string;
  dataLength: number;
}

const LineColor = (contentType: string) => {
  switch (contentType) {
    case 'Errors':
      return 'red';
    case 'Data':
      return COLORS.Primary;
    case 'Jobs':
      return COLORS.DarkPurple;
    default:
      return 'black';
  }
};

const dataUnit = (contentType: string): string => {
  switch (contentType) {
    case 'Data':
      return 'Usage: ';
    case 'Jobs':
      return 'Jobs: ';
    case 'Errors':
      return 'Errors: ';
    default:
      return '';
  }
};

const ValueContainer = styled.div`
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  ${({ contentType }: { contentType: string }) => {
    switch (contentType) {
      case 'Data':
        return `border: 1px ${COLORS.Primary} solid;`;
      case 'Jobs':
        return `border: 1px ${COLORS.DarkPurple} solid;`;
      case 'Errors':
        return `border: 1px red solid;`;
      default:
        return `border: 1px ${COLORS.Primary} solid;`;
    }
  }};
`;

const CustomTooltip = ({
  active,
  payload,
  contentType,
  dataUsageUnit,
}: any) => {
  if (active) {
    return (
      <ValueContainer contentType={contentType}>
        <Typography>Date: {payload[0].payload.time} </Typography>
        <Typography>
          {dataUnit(contentType)}
          {contentType === 'Data'
            ? `${payload[0].payload.value.toFixed(
                2
              )} ${dataUsageUnit.toUpperCase()}`
            : payload[0].payload.value}
        </Typography>
      </ValueContainer>
    );
  }

  return null;
};

const LineGraph: React.FC<Props> = ({
  data,
  contentType,
  dataUsageUnit,
  dataLength,
}) => {
  return (
    <>
      {data.length !== 0 ? (
        <ResponsiveContainer width="100%" height="100%">
          <LineChart data={data} layout="vertical">
            <CartesianGrid vertical={false} strokeDasharray="4" />
            <XAxis
              dataKey="time"
              height={100}
              dy={4}
              label="Date (UTC)"
              tickCount={dataLength}
            />
            <YAxis dataKey="value" type="number" reversed />
            <Line
              type="linear"
              dataKey="time"
              stroke={LineColor(contentType)}
              dot={{ strokeWidth: 1, fontSize: 'small' }}
              strokeWidth={2}
            />
            <Tooltip
              cursor={{
                stroke: LineColor(contentType),
                opacity: 0.2,
              }}
              content={
                dataUsageUnit ? (
                  <CustomTooltip
                    contentType={contentType}
                    dataUsageUnit={dataUsageUnit}
                  />
                ) : (
                  <CustomTooltip contentType={contentType} />
                )
              }
            />
          </LineChart>
        </ResponsiveContainer>
      ) : (
        <Container>
          <Typography variant="h6">No data available</Typography>
        </Container>
      )}
    </>
  );
};

export default LineGraph;
