import React from 'react';
import styled, { keyframes } from 'styled-components';
import { Typography } from '@material-ui/core';
import ListeningSignal from '../../../common/listening-signal';
import COLORS from '../../../../common/colors';
import useJobRunDataPollingService from '../service/useJobRunDataPollingService';
import { useOnboardingWizardStore } from '../../store';
import AnimatedCheckmark from '../../../common/animations/animated-checkmark';

const FadeInAndBump = keyframes`
  0% {
    transform: none;
    opacity: 0;
  }
  
  50% {
    transform: scale3d(1.25, 1.25, 1);
  }
  
  100% {
    transform: none;
    opacity: 1;
  }
`;

const Flex = styled.div`
  display: inline-flex;
  align-items: center;
`;

const Surface = styled(Flex)`
  padding: 8px 16px;
`;

const RanText = styled(Typography)`
  margin-right: 12px;
  font-weight: bold;
  animation: ${FadeInAndBump} 0.3s ease-in-out both;
`;

const WaitingText = styled(Typography)`
  margin-right: 24px;
  font-weight: bold;
`;

const RanContainer = styled(Flex)`
  color: green;
`;

const WaitingContainer = styled(Flex)`
  color: ${COLORS.Red};
`;

const ListeningJobRun: React.FC = () => {
  useJobRunDataPollingService();
  const [{ hasRunJob }] = useOnboardingWizardStore();

  return (
    <Surface>
      {hasRunJob ? (
        <RanContainer>
          <RanText>Job run detected</RanText>
          <AnimatedCheckmark radius={24} />
        </RanContainer>
      ) : (
        <WaitingContainer>
          <WaitingText>Checking for Job run</WaitingText>
          <ListeningSignal color={COLORS.Red} />
        </WaitingContainer>
      )}
    </Surface>
  );
};

export default ListeningJobRun;
