import React from 'react';
import styled from 'styled-components';
import { Switch, Typography } from '@material-ui/core';

const SampleSwitchWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100px;
`;

interface Props {
  isSample: boolean;
  setIsSample: (isSample: boolean) => void;
}

const SampleSwitch: React.FC<Props> = ({ isSample, setIsSample }) => {
  const onSwitchChange = () => {
    setIsSample(!isSample);
  };

  return (
    <SampleSwitchWrapper>
      <Switch checked={isSample} onChange={onSwitchChange} size="small" />
      <Typography variant="caption" color={isSample ? 'secondary' : 'primary'}>
        {isSample ? 'Sample' : 'Permanent'}
      </Typography>
    </SampleSwitchWrapper>
  );
};

export default SampleSwitch;
