const convertApiDateStringToDate = (apiDateString: string | null) => {
  if (apiDateString) {
    const tempDate = new Date(apiDateString);
    return new Date(
      Date.UTC(
        tempDate.getFullYear(),
        tempDate.getMonth(),
        tempDate.getDate(),
        tempDate.getHours(),
        tempDate.getMinutes(),
        tempDate.getSeconds()
      )
    );
  }
  return null;
};

export default convertApiDateStringToDate;
