import { useEffect } from 'react';
import { useOnboardingWizardStore } from '../../store';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import { ONBOARDINGAPI } from '../../../../common/routes';

interface CompletedOnboardingData {
  completed: boolean;
}

/**
 * Custom hook for encapsulating logic to handle whether the user
 * has completed onboarding to prevent unnecessary fetching between
 * onboarding pages.
 *
 * First checks the store if a non-null (T/F) value exists in the store. If
 * there is a null it means we do not know whether they have or not completed
 * onboarding, so we need to fetch, otherwise we do not need to fetch.
 *
 * If we do fetch we need to also update the store with the fetched value
 * to prevent further fetching.
 */
const useHasCompletedOnboarding = (): CompletedOnboardingData => {
  const [
    { alreadyCompletedOnboarding },
    { setAlreadyCompletedOnboarding },
  ] = useOnboardingWizardStore();

  const shouldFetch = alreadyCompletedOnboarding === null;

  const [data] = useFetchSimpleService<{
    completedOnboarding: boolean;
  }>(ONBOARDINGAPI.Status, shouldFetch);

  const fetchedCompletedOnboarding = data?.completedOnboarding;

  useEffect(() => {
    if (fetchedCompletedOnboarding !== undefined) {
      setAlreadyCompletedOnboarding(fetchedCompletedOnboarding);
    }
  }, [fetchedCompletedOnboarding, setAlreadyCompletedOnboarding]);

  return {
    completed: !!alreadyCompletedOnboarding,
  };
};

export default useHasCompletedOnboarding;
