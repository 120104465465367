import React from 'react';
import { ToggleButton, ToggleButtonGroup } from '@material-ui/lab';
import styled from 'styled-components';

interface ToggleProps {
  toggleStatus: boolean;
  setToggleStatus: React.Dispatch<React.SetStateAction<boolean>>;
}

const StyledToggleButton = styled(ToggleButton)`
  width: 60px;
  height: 32px;
`;

const StyledToggle: React.FC<ToggleProps> = ({
  toggleStatus,
  setToggleStatus,
}) => {
  const handleChange = (
    event: React.MouseEvent<HTMLElement>,
    newStatus: string
  ) => {
    newStatus === 'Yes' ? setToggleStatus(true) : setToggleStatus(false);
  };

  return (
    <ToggleButtonGroup
      color="primary"
      value={toggleStatus ? 'Yes' : 'No'}
      exclusive
      onChange={handleChange}
    >
      <StyledToggleButton value="Yes">Yes</StyledToggleButton>
      <StyledToggleButton value="No">No</StyledToggleButton>
    </ToggleButtonGroup>
  );
};

export default StyledToggle;
