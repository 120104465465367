import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';
import { ContainerProps, StoreState } from './types';
import { ConnectorType } from '../../common/add-database-modal/types';

const initialState: StoreState = {
  connectors: {},
};

type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setInitialData: () => ({ setState }: StoreApi, data: ContainerProps) => {
    setState(data);
  },
  deleteConnector: (connectorId: string) => ({
    setState,
    getState,
  }: StoreApi) => {
    const { connectors } = getState();
    delete connectors[connectorId];
    setState({ connectors });
  },
  addConnector: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType,
    createdTime: string
  ) => ({ getState, setState }: StoreApi) => {
    const { connectors } = getState();
    if (createdTime) {
      setState({
        connectors: {
          [connectorId]: {
            connectorType,
            createdTime,
            name: connectorName,
          },
          ...connectors,
        },
      });
    }
  },
  editConnector: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType
  ) => ({ getState, setState }: StoreApi) => {
    const { connectors } = getState();
    connectors[connectorId].name = connectorName;
    setState({ connectors });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const useConnectorsStore = createHook(Store);
export const ConnectorsContainer = createContainer(Store, {
  onInit: actions.setInitialData,
  onUpdate: actions.setInitialData,
});
