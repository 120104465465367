import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Card,
  CircularProgress,
  Collapse,
  IconButton,
  InputAdornment,
  TextField,
  Typography,
  withStyles,
} from '@material-ui/core';
import { ExpandMore, Visibility, VisibilityOff } from '@material-ui/icons';
import { useForm } from 'react-hook-form';
import { toast } from 'react-toastify';
import firebase from '../../../../firebase/firebase';
import { useAuthContext } from '../../../../firebase/AuthProvider';

const Wrapper = styled(Card)`
  margin: 16px 0;
  padding: 24px;
`;

const TopSection = styled.div`
  display: flex;
  justify-content: space-between;
`;

const StyledIconButton = styled(IconButton)`
  transition: all 0.3s ease-out;
  transform: ${(props: { isExpanded: boolean }) =>
    props.isExpanded
      ? `
        rotate(180deg)
    `
      : ''};
`;

const Form = styled.form``;

const PasswordFieldsSection = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-end;
  margin-top: 16px;
`;

const FieldWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 400px;
`;

const ErrorMessage = styled.div`
  color: red;
  min-height: 24px;
`;

const NoMarginTextField = withStyles({
  root: {
    margin: 0,
  },
})(TextField);

const UpdatePasswordSection: React.FC = () => {
  const { user } = useAuthContext();
  const [isPasswordSectionOpen, setIsPasswordSectionOpen] = useState<boolean>(
    false
  );
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors },
  } = useForm({});
  const [showCurrentPassword, setShowCurrentPassword] = useState<boolean>(
    false
  );
  const [showNewPassword, setShowNewPassword] = useState<boolean>(false);
  const [isLoading, setIsLoading] = useState<boolean>(false);

  const handleExpandClick = () => {
    setIsPasswordSectionOpen((prev) => !prev);
  };

  const handleClickShowCurrentPassword = () => {
    setShowCurrentPassword((prev) => !prev);
  };

  const handleClickShowNewPassword = () => {
    setShowNewPassword((prev) => !prev);
  };

  const handleMouseDownPassword = (
    event: React.MouseEvent<HTMLButtonElement>
  ) => {
    event.preventDefault();
  };

  const onSubmit = handleSubmit(async (data) => {
    setIsLoading(true);
    if (user?.email) {
      try {
        await user.reauthenticateWithCredential(
          firebase.auth.EmailAuthProvider.credential(
            user.email,
            data.currentPassword
          )
        );
        await user.updatePassword(data.newPassword);
        toast.success('Password updated successfully');
        reset({});
      } catch (e) {
        toast.error(e.message);
      }
    }
    setIsLoading(false);
  });

  return (
    <Wrapper>
      <TopSection>
        <div>
          <Typography variant="h5">Password</Typography>
          <div>
            To update your password, please enter your current password and new
            password.
          </div>
        </div>
        <StyledIconButton
          isExpanded={isPasswordSectionOpen}
          onClick={handleExpandClick}
        >
          <ExpandMore color="secondary" />
        </StyledIconButton>
      </TopSection>
      <Collapse in={isPasswordSectionOpen}>
        <Form onSubmit={onSubmit}>
          <PasswordFieldsSection>
            <div>
              <FieldWrapper>
                <Typography variant="caption">Current Password:</Typography>
                <NoMarginTextField
                  {...register('currentPassword', {
                    required: 'Please enter your current password',
                  })}
                  variant="outlined"
                  margin="dense"
                  type={showCurrentPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowCurrentPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showCurrentPassword ? (
                            <Visibility />
                          ) : (
                            <VisibilityOff />
                          )}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage>{errors?.currentPassword?.message}</ErrorMessage>
              </FieldWrapper>
              <FieldWrapper>
                <Typography variant="caption">New Password:</Typography>
                <NoMarginTextField
                  {...register('newPassword', {
                    required: 'Please enter a new password',
                    minLength: {
                      value: 8,
                      message: 'The password must be 8 characters or more',
                    },
                  })}
                  variant="outlined"
                  margin="dense"
                  type={showNewPassword ? 'text' : 'password'}
                  InputProps={{
                    endAdornment: (
                      <InputAdornment position="end">
                        <IconButton
                          tabIndex={-1}
                          aria-label="toggle password visibility"
                          onClick={handleClickShowNewPassword}
                          onMouseDown={handleMouseDownPassword}
                        >
                          {showNewPassword ? <Visibility /> : <VisibilityOff />}
                        </IconButton>
                      </InputAdornment>
                    ),
                  }}
                />
                <ErrorMessage>{errors?.newPassword?.message}</ErrorMessage>
              </FieldWrapper>
            </div>
            <Button onClick={onSubmit} variant="contained" color="secondary">
              {isLoading ? (
                <CircularProgress color="inherit" size={20} />
              ) : (
                'Update'
              )}
            </Button>
          </PasswordFieldsSection>
        </Form>
      </Collapse>
    </Wrapper>
  );
};

export default UpdatePasswordSection;
