import React, { useState } from 'react';
import styled from 'styled-components';
import {
  Button,
  Chip,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from '@material-ui/core';
import KeyboardReturnIcon from '@material-ui/icons/KeyboardReturn';
import { ACCESS_TYPE } from '../../../../common/constants';
import { useModalStore } from '../modal-store';
import COLORS from '../../../../common/colors';

const Wrapper = styled.div`
  margin-top: 16px;
`;

const AddEmailRow = styled.div`
  margin-top: 16px;
  display: flex;
`;

const EmailTextField = styled(TextField)`
  flex: 1;
`;

const StyledKeyboardReturnIcon = styled(KeyboardReturnIcon)`
  color: ${(props: { isDisabled: boolean }) =>
    props.isDisabled ? COLORS.LightGrey : COLORS.DarkBlue};
`;

const AccessTypeSelect = styled(Select)`
  width: 120px;
  height: 40px;
`;

const SelectedEmailsContainer = styled.div`
  display: flex;
  min-height: 100px;
  margin: 8px 0;
`;

const ChipsContainer = styled.div`
  flex: 1;
  display: flex;
  flex-wrap: wrap;
  background-color: ${COLORS.LightGrey};
  border-radius: 4px;
  padding: 4px;
  margin-right: 16px;
`;

const EmailChip = styled(Chip)`
  margin: 4px;
`;

const NewUsersSection: React.FC = () => {
  const [
    { selectedEmails, selectedAccessType, sharedUsers, userAccessType },
    { addEmailToSelected, deleteEmailFromSelected, setSelectedAccessType },
  ] = useModalStore();
  const [emailText, setEmailText] = useState<string>('');

  const handleEmailChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.value !== ' ') {
      setEmailText(event.target.value);
    }
  };

  const handleAddEmailToList = () => {
    const trimEmail = emailText.trim();
    if (!(trimEmail in sharedUsers)) {
      addEmailToSelected(emailText);
      setEmailText('');
    }
  };

  const handleEmailDelete = (e: string) => () => {
    deleteEmailFromSelected(e);
  };

  const handleTextFieldKeyPress = (
    event: React.KeyboardEvent<HTMLDivElement>
  ) => {
    if (event.key === 'Enter' || event.key === ' ') {
      handleAddEmailToList();
    }
  };

  const handleAccessTypeChange = (
    event: React.ChangeEvent<{ value: unknown }>
  ) => {
    setSelectedAccessType(event.target.value as ACCESS_TYPE);
  };

  const isDisabled =
    userAccessType !== ACCESS_TYPE.ADMIN &&
    userAccessType !== ACCESS_TYPE.OWNER;

  return (
    <Wrapper>
      <Typography variant="h5" gutterBottom>
        Add New Users
      </Typography>
      <AddEmailRow>
        <EmailTextField
          label={
            isDisabled
              ? 'You do not have permission to share this resource'
              : 'Email'
          }
          variant="outlined"
          value={emailText}
          onChange={handleEmailChange}
          onKeyPress={handleTextFieldKeyPress}
          size="small"
          autoFocus
          disabled={isDisabled}
          InputProps={{
            endAdornment: (
              <InputAdornment position="end">
                <Button onClick={handleAddEmailToList} disabled={isDisabled}>
                  <StyledKeyboardReturnIcon isDisabled={isDisabled} />
                </Button>
              </InputAdornment>
            ),
          }}
        />
      </AddEmailRow>
      {selectedEmails.length !== 0 && (
        <SelectedEmailsContainer>
          <ChipsContainer>
            {selectedEmails.map((e) => (
              <EmailChip
                color="primary"
                label={e}
                onDelete={handleEmailDelete(e)}
              />
            ))}
          </ChipsContainer>
          <AccessTypeSelect
            value={selectedAccessType}
            onChange={handleAccessTypeChange}
            label="Access"
            variant="outlined"
          >
            {Object.entries(ACCESS_TYPE).map(
              ([, accessTypeString]) =>
                accessTypeString !== ACCESS_TYPE.OWNER && (
                  <MenuItem value={accessTypeString}>
                    {accessTypeString}
                  </MenuItem>
                )
            )}
          </AccessTypeSelect>
        </SelectedEmailsContainer>
      )}
    </Wrapper>
  );
};

export default NewUsersSection;
