import React from 'react';
import {
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  withStyles,
} from '@material-ui/core';
import styled from 'styled-components';
import { PipelineJobListEndpointResponse } from '../../../../../api/types/job';
import JobListRow from './job-list-row';

const StyledTableContainer = withStyles({})(TableContainer);

const StyledTableCell = withStyles({
  root: {
    padding: '8px 16px',
  },
})(TableCell);

const CenteredTableCellSpan = styled(TableCell)`
  text-align: center;
`;

const JobsList: React.FC<PipelineJobListEndpointResponse> = ({
  jobHistory,
}) => {
  return (
    <StyledTableContainer>
      <Table stickyHeader component={Paper} style={{ tableLayout: 'auto' }}>
        <TableHead>
          <TableRow style={{ borderRadius: 4 }}>
            <StyledTableCell>Name</StyledTableCell>
            <StyledTableCell>Job ID</StyledTableCell>
            <StyledTableCell>Run Date</StyledTableCell>
            <StyledTableCell>Job Status</StyledTableCell>
            <StyledTableCell>Data Sources</StyledTableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {Object.entries(jobHistory).length !== 0 ? (
            Object.entries(jobHistory).map(([jobId, job]) => (
              <JobListRow jobId={jobId} job={job} />
            ))
          ) : (
            <TableRow>
              <CenteredTableCellSpan colSpan={6}>
                No jobs have been run using this pipeline.
              </CenteredTableCellSpan>
            </TableRow>
          )}
        </TableBody>
      </Table>
    </StyledTableContainer>
  );
};

export default JobsList;
