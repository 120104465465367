import React from 'react';
import { toast } from 'react-toastify';
import { Tooltip } from '@material-ui/core';
import styled from 'styled-components';
import COLORS from '../../../common/colors';

interface Props {
  resourceId: string;
}

const LozengeContainer = styled.div`
  overflow: hidden;
  white-space: nowrap;
  text-overflow: ellipsis;
  text-align: center;
  font-family: Inconsolata, Monaco, sans-serif;
  background-color: ${COLORS.Grey};
  padding: 4px 8px;
  border-radius: 4px;
  transition: all 0.2s;
  &:hover {
    background-color: #d5d5d5;
    cursor: pointer;
  }
`;

const CopyIdLozenge: React.FC<Props> = ({ resourceId }) => {
  const handleCopyIdToClipboard = (event: React.MouseEvent<HTMLDivElement>) => {
    event.stopPropagation();
    navigator.clipboard.writeText(resourceId);
    toast(`Copied to clipboard: '${resourceId}'`, {
      position: 'top-center',
      autoClose: 3000,
    });
  };

  return (
    <Tooltip title={`Click to copy: ${resourceId}`} placement="top">
      <LozengeContainer onClick={handleCopyIdToClipboard}>
        {resourceId}
      </LozengeContainer>
    </Tooltip>
  );
};

export default CopyIdLozenge;
