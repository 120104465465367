import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import StepHeading from '../step-heading';
import COLORS from '../../../common/colors';
import ConfigTabPanels from './config-tab-panels';
import ListeningJobRun from './listening-job-run';
import FadeSlideUpwards from '../../common/animations/fade-slide-upwards';
import OnboardingWizardBottomBar from '../onboarding-wizard-bottombar';
import { useOnboardingWizardStore } from '../store';
import BackToAppButton from '../back-to-app-button';
import useHasCompletedOnboarding from '../hooks/useHasCompletedOnboarding';

const PageContainer = styled.div`
  margin: 60px auto 0;
`;

const SupportingText = styled(Typography)`
  margin-top: 4px;
  margin-bottom: 40px;
`;

const RunJobPage: React.FC = () => {
  const [{ hasRunJob }] = useOnboardingWizardStore();

  const { completed } = useHasCompletedOnboarding();

  return (
    <>
      {completed && <BackToAppButton />}
      <PageContainer>
        <StepHeading stepNumber={2} bgColor={COLORS.DarkBlue}>
          Run a Job Using Our Sample Data
        </StepHeading>
        <SupportingText>
          <span style={{ textAlign: 'center' }}>
            We&apos;ll listen in until a job has been run! If you are unsure on
            how you wish to integrate Segna, run a job using the{' '}
            <span style={{ fontWeight: 'bold' }}>Data Importer</span> example in
            the <span style={{ fontWeight: 'bold' }}>No Code</span> tab.
          </span>
        </SupportingText>
        <FadeSlideUpwards>
          <ConfigTabPanels />
        </FadeSlideUpwards>
      </PageContainer>
      <OnboardingWizardBottomBar isOnProgressionDisabled={!hasRunJob}>
        <ListeningJobRun />
      </OnboardingWizardBottomBar>
    </>
  );
};

export default RunJobPage;
