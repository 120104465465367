import mixpanel from 'mixpanel-browser';
import { useEffect } from 'react';

const useFireEvent = (
  eventName: string,
  properties?: { [key: string]: string }
): void => {
  useEffect(() => {
    mixpanel.track(eventName, properties);
  }, [eventName, properties]);
};

export default useFireEvent;

export const fireEvent = (
  eventName: string,
  properties?: { [key: string]: string }
): void => {
  mixpanel.track(eventName, properties);
};
