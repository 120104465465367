import React, {
  forwardRef,
  MutableRefObject,
  useCallback,
  useEffect,
  useRef,
  useState,
} from 'react';
import { createPortal } from 'react-dom';
import { ExpectedElements, Placement } from '../types';

interface PortalProps {
  children: React.ReactNode;
  placement: Placement;
}

interface Viewport {
  width: number;
  height: number;
}

const DISTANCE = 25;
const MARGIN = '24px';
const SPOTLIGHT_Z_INDEX = 50;
const MUI_DISABLED_CLASSNAME = 'Mui-disabled';

const throttle = (fn: () => void, delay: number) => {
  let inProgress = false;

  return () => {
    if (!inProgress) {
      inProgress = true;
      setTimeout(() => {
        fn();
        inProgress = false;
      }, delay);
    }
  };
};

const positionLeft = (
  ref: MutableRefObject<HTMLDivElement>,
  top: number,
  left: number
) => {
  const { style } = ref.current;
  style.top = `${top}px`;
  style.right = `calc(100vw - ${left - DISTANCE + window.pageXOffset}px)`;
  style.marginLeft = MARGIN;
};

const positionRight = (
  ref: MutableRefObject<HTMLDivElement>,
  top: number,
  right: number
) => {
  const { style } = ref.current;
  style.top = `${top}px`;
  style.left = `${right + DISTANCE + window.pageXOffset}px`;
  style.marginRight = MARGIN;
};

const positionAbove = (
  ref: MutableRefObject<HTMLDivElement>,
  top: number,
  left: number
) => {
  const { style } = ref.current;
  style.left = `${left}px`;
  style.bottom = `calc(100vh - ${top - DISTANCE + window.pageYOffset}px)`;
  style.marginTop = MARGIN;
};

const positionBelow = (
  ref: MutableRefObject<HTMLDivElement>,
  bottom: number,
  left: number
) => {
  const { style } = ref.current;
  style.left = `${left}px`;
  style.top = `${bottom + DISTANCE + window.pageYOffset}px`;
  style.marginBottom = MARGIN;
};

const Portal = forwardRef<ExpectedElements, PortalProps>((props, ref) => {
  const cardWrapperRef = useRef(document.createElement('div'));

  const [viewport, setViewport] = useState<Viewport>({
    height: window.innerHeight,
    width: window.innerWidth,
  });

  useEffect(() => {
    const handleResize = () => {
      setViewport({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    };

    const throttledHandleResize = throttle(handleResize, 100);
    window.addEventListener('resize', throttledHandleResize);

    return () => {
      window.removeEventListener('resize', throttledHandleResize);
    };
  }, []);

  const positionCard = useCallback(
    (cardWrapperRef: MutableRefObject<HTMLDivElement>, domRect: DOMRect) => {
      const { style } = cardWrapperRef.current;
      style.position = 'absolute';
      style.zIndex = (SPOTLIGHT_Z_INDEX + 1).toString();

      const { top, left, bottom, right } = domRect;
      switch (props.placement) {
        case 'left':
          positionLeft(cardWrapperRef, top, left);
          break;
        case 'right':
          positionRight(cardWrapperRef, top, right);
          break;
        case 'above':
          positionAbove(cardWrapperRef, top, left);
          break;
        case 'below':
          positionBelow(cardWrapperRef, bottom, left);
          break;
        default:
          positionLeft(cardWrapperRef, top, left);
      }
    },
    [props.placement]
  );

  const resetCardPosition = (
    cardWrapperRef: MutableRefObject<HTMLDivElement>
  ) => {
    const { style } = cardWrapperRef.current;
    style.top = '';
    style.bottom = '';
    style.left = '';
    style.right = '';
    style.margin = '';
  };

  useEffect(() => {
    const spotlightElement = (ref as MutableRefObject<ExpectedElements | null>)
      .current;
    const cardWrapperElement = cardWrapperRef.current;
    document.body.appendChild(cardWrapperElement);

    let savedZIndex: string;
    let savedPointerEvents: string;
    let savedPosition: string;
    let wasDisabled: boolean;

    if (spotlightElement) {
      savedZIndex = spotlightElement.style.zIndex;
      savedPointerEvents = spotlightElement.style.pointerEvents;
      savedPosition = spotlightElement.style.position;

      if (spotlightElement.nodeName === 'BUTTON') {
        // Temporarily removing the disabled styles from the Material UI button by removing the classname,
        // the buttons actual disabled state not affected. Nice. Yikes.
        wasDisabled = spotlightElement.classList.contains(
          MUI_DISABLED_CLASSNAME
        );
        if (wasDisabled) {
          spotlightElement.classList.remove(MUI_DISABLED_CLASSNAME);
        }
      }

      spotlightElement.style.zIndex = SPOTLIGHT_Z_INDEX.toString();
      spotlightElement.style.pointerEvents = 'none';
      spotlightElement.style.position = 'relative';

      // Position then scroll
      positionCard(cardWrapperRef, spotlightElement.getBoundingClientRect());
      cardWrapperRef.current.scrollIntoView({
        behavior: 'smooth',
        block: 'center',
        inline: 'center',
      });
    }

    return () => {
      if (spotlightElement) {
        spotlightElement.style.zIndex = savedZIndex;
        spotlightElement.style.pointerEvents = savedPointerEvents;
        spotlightElement.style.position = savedPosition;

        if (spotlightElement.nodeName === 'BUTTON' && wasDisabled) {
          spotlightElement.classList.add(MUI_DISABLED_CLASSNAME);
        }
      }
      resetCardPosition(cardWrapperRef);
      document.body.removeChild(cardWrapperElement);
    };
  }, [positionCard, ref, viewport]);

  return createPortal(props.children, cardWrapperRef.current);
});

export default Portal;
