import React, { useState } from 'react';
import Dropzone from 'react-dropzone';
import { toast } from 'react-toastify';
import styled from 'styled-components';
import { useScriptsStore } from '../../../scripts-store';
import useUploadTestDataService from '../../service/useUploadTestDataService';
import {
  DropFilePrompt,
  FileUploadingPrompt,
  RejectFilePrompt,
  UploadFilePrompt,
} from './file-upload-steps';
import useErrorAlert from "../../../../../../utils/useErrorAlert";
import COLORS from "../../../../../../common/colors";

const Container = styled.div`
  display: flex;
  justify-content: center;
  align-content: center;
  flex-direction: column;
  border: ${COLORS.Orange} dashed 2px;
  width: 100%;
  height: 100%;
`;

interface Props {
  setFileUploaded: React.Dispatch<React.SetStateAction<boolean>>;
}

const FileUploadZone: React.FC<Props> = ({ setFileUploaded }) => {
  const [isFileUploading, setIsFileUploading] = useState<boolean>(false);

  const [{ scriptId }] = useScriptsStore();

  const [uploadFile, error] = useUploadTestDataService(scriptId);
  useErrorAlert(error);

  const [, { setDataTable }] = useScriptsStore();

  const handleFileUpload = async (file: unknown[]) => {
    const uploadedFile = file[0] as File;
    setIsFileUploading(true);
    const response = await uploadFile(uploadedFile);

    if (response) {
      setFileUploaded(true);
      setDataTable(response.data);
      toast.success('File uploaded successfully');
    } else {
      toast.error('Could not upload file');
    }

    setIsFileUploading(false);
  };

  return (
    <Container>
      <Dropzone
        maxFiles={1}
        multiple={false}
        accept="text/csv, text/xslx, text/xls"
        onDropAccepted={(file) => handleFileUpload(file)}
      >
        {({ getRootProps, getInputProps, isDragActive, isDragReject }) => (
          <div {...getRootProps()}>
            <input {...getInputProps()} />
            {!isDragActive && !isFileUploading && <UploadFilePrompt />}
            {isDragActive && !isDragReject && <DropFilePrompt />}
            {isDragReject && <RejectFilePrompt />}
            {isFileUploading && <FileUploadingPrompt />}
          </div>
        )}
      </Dropzone>
    </Container>
  );
};

export default FileUploadZone;
