import React, { forwardRef } from 'react';
import styled from 'styled-components';
import { Button, CircularProgress } from '@material-ui/core';
import PlayArrowIcon from '@material-ui/icons/PlayArrow';
import { toast } from 'react-toastify';
import useRunTestService from '../../service/useRunTestService';
import useErrorAlert from '../../../../../../utils/useErrorAlert';
import { useScriptsStore } from '../../../scripts-store';

const Wrapper = styled.div`
  display: flex;
  justify-content: flex-end;
  align-content: center;
  align-items: center;
`;

const StyledButton = styled(Button)`
  margin: 12px 0 12px 12px;
  height: 32px;
`;

const ActionButtonBar = forwardRef<HTMLButtonElement>((props, ref) => {
  const [
    { script, isOutputLoading, scriptId, dataTable },
    { setOutput, setOutputLoading, setScript, setIsTestSuccessful },
  ] = useScriptsStore();

  const [runTest, testError] = useRunTestService(scriptId);
  useErrorAlert(testError);

  const isInputAdded = dataTable.length === 0;
  const isScriptEmpty = script === '';

  const handleClearButtonClick = () => {
    setScript('');
    setOutput([]);
    setIsTestSuccessful(false);
  };

  const handleTestScript = async () => {
    setOutputLoading(true);
    const response = await runTest({ script });
    if (response) {
      if (response.isSuccessful) {
        setIsTestSuccessful(true);
        toast.success('Script run successfully!');
      } else {
        setIsTestSuccessful(false);
      }
      setOutput(response.output);
    }
    setOutputLoading(false);
  };

  return (
    <Wrapper>
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={handleClearButtonClick}
        disabled={isScriptEmpty}
      >
        Clear
      </StyledButton>
      <StyledButton
        variant="contained"
        color="secondary"
        onClick={handleTestScript}
        disabled={isInputAdded}
        ref={ref}
      >
        {isOutputLoading ? <CircularProgress size={24} /> : <PlayArrowIcon />}
      </StyledButton>
    </Wrapper>
  );
});

export default ActionButtonBar;
