import React, { forwardRef } from 'react';
import styled from 'styled-components';
import AceEditor from 'react-ace';
import 'ace-builds/src-noconflict/mode-python';
import 'ace-builds/src-noconflict/snippets/python';
import 'ace-builds/src-noconflict/ext-language_tools';
import COLORS from '../../../../../../common/colors';
import { useScriptsStore } from '../../../scripts-store';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  height: 100%;
`;

const DisabledTopTemplateEditor = styled.div`
  width: 100%;
  pointer-events: none;
  overflow: hidden;
  background-color: ${COLORS.Grey};
`;

const DisabledAceEditor = styled(AceEditor)`
  opacity: 60%;
`;

const DisabledBottomTemplateEditor = styled.div`
  width: 100%;
  pointer-events: none;
  overflow: hidden;
`;

const StartTemplateCode = `import numpy as np
import pandas as pd

def run_script(input_dataframe):
`;

const EndTemplateCode = `      # Make sure you assign output to output_dataframe
      return output_dataframe
      `;

const CodeEditor = forwardRef<HTMLDivElement>((props, ref) => {
  const [{ script }, { setScript, setIsTestSuccessful }] = useScriptsStore();

  const onChange = (newScript: string) => {
    setIsTestSuccessful(false);
    setScript(newScript);
  };

  return (
    <Wrapper ref={ref}>
      <DisabledTopTemplateEditor>
        <DisabledAceEditor
          width="100%"
          maxLines={Infinity}
          mode="python"
          fontSize={14}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine={false}
          value={StartTemplateCode}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: false,
            tabSize: 4,
            readOnly: true,
            cursorStyle: 'slim',
            displayIndentGuides: false,
          }}
        />
      </DisabledTopTemplateEditor>
      <AceEditor
        width="100%"
        height="100%"
        placeholder="Write script here"
        mode="python"
        onChange={onChange}
        fontSize={14}
        showPrintMargin={false}
        showGutter
        highlightActiveLine
        value={script}
        setOptions={{
          enableBasicAutocompletion: true,
          enableLiveAutocompletion: true,
          showLineNumbers: true,
          tabSize: 2,
        }}
      />
      <DisabledBottomTemplateEditor>
        <DisabledAceEditor
          width="100%"
          maxLines={Infinity}
          mode="python"
          fontSize={14}
          showPrintMargin={false}
          showGutter={false}
          highlightActiveLine={false}
          value={EndTemplateCode}
          setOptions={{
            enableBasicAutocompletion: true,
            enableLiveAutocompletion: true,
            showLineNumbers: false,
            tabSize: 4,
            readOnly: true,
            cursorStyle: 'slim',
            displayIndentGuides: false,
          }}
        />
      </DisabledBottomTemplateEditor>
    </Wrapper>
  );
});

export default CodeEditor;
