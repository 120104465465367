import React, { useEffect, useState } from 'react';
import segna, { DataImporter } from '@segna/segna-sdk';
import styled from 'styled-components';
import { CircularProgress } from '@material-ui/core';
import COLORS from '../../../../../common/colors';
import useGetSamplePipelineId from '../../service/useGetSamplePipelineId';
import ConfigStepHeading from '../../config-step-heading';
import InlineCodeBlock from '../../../../common/inline-code-block';
import { useOnboardingWizardStore } from '../../../store';
import { BASE_URL } from '../../../../../common/constants';

const StepSection = styled.div`
  margin-bottom: 24px;
`;

const UploadSection = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  border: ${COLORS.DarkBlue} dashed 1px;
  padding: 8px;
  border-radius: 4px;
`;

const UploadSubtextSpan = styled.span`
  margin-top: 8px;
  text-align: center;
`;

const NoCodePanel: React.FC = () => {
  const samplePipelineId = useGetSamplePipelineId();
  const [{ apiKey }, { setHasRunJob }] = useOnboardingWizardStore();

  const [apiKeyInitialized, setApiKeyInitialized] = useState(false);

  useEffect(() => {
    if (apiKey) {
      segna.initialize({
        apiKey,
        basePath: `${BASE_URL}/public/client-side/v1`,
      });
      setApiKeyInitialized(true);
    }
  }, [apiKey]);

  const onJobRun = () => {
    setHasRunJob(true);
  };

  return (
    <>
      <StepSection>
        <ConfigStepHeading stepNumber={1} bgColor={COLORS.DarkBlue}>
          Click the <InlineCodeBlock>Import</InlineCodeBlock> button and upload
          our sample data
        </ConfigStepHeading>
      </StepSection>
      <UploadSection>
        {apiKeyInitialized ? (
          <DataImporter
            pipelineId={samplePipelineId}
            customTheme={{
              color: COLORS.Orange,
            }}
            onCompletion={{
              returnWhenComplete: true,
              callbacks: {
                afterCallback: onJobRun,
              },
            }}
          />
        ) : (
          <CircularProgress />
        )}
        <UploadSubtextSpan>
          This is an example of our{' '}
          <span style={{ fontWeight: 'bold' }}>Data Importer</span> Prebuilt UI
          Component.
          <div style={{ marginTop: 4 }}>
            Check out the <span style={{ fontWeight: 'bold' }}>React</span> tab
            to see how you can get integrate this directly into your app.
          </div>
        </UploadSubtextSpan>
      </UploadSection>
    </>
  );
};

export default NoCodePanel;
