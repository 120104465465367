import { useCallback, useEffect, useState } from 'react';
import { useErrorHandler } from 'react-error-boundary';
import { useAuthContext } from '../../firebase/AuthProvider';
import { BASE_URL } from '../../common/constants';

const useFetchSimpleService = <RestData,>(
  route: string,
  shouldFetch = true
): [RestData | null, boolean, Error | null] => {
  const { user } = useAuthContext();
  const [data, setData] = useState<RestData | null>(null);
  const [isLoading, setIsLoading] = useState<boolean>(false);
  const [error, setError] = useState<Error | null>(null);
  useErrorHandler(error);

  const fetchData = useCallback(async () => {
    setIsLoading(true);
    try {
      const idToken = user && (await user.getIdToken(true));
      const response = await fetch(BASE_URL + route, {
        method: 'GET',
        headers: {
          Authorization: `${idToken}`,
        },
      });
      const responseData = await response.json();
      if (response.ok) {
        setData(responseData);
        setIsLoading(false);
      } else {
        setError(new Error(responseData.errorMessage));
        setIsLoading(false);
      }
    } catch (e) {
      setError(e);
      setIsLoading(false);
    }
  }, [route, user]);

  useEffect(() => {
    if (shouldFetch) {
      fetchData();
    }
  }, [fetchData, shouldFetch]);

  return [data, isLoading, error];
};

export default useFetchSimpleService;
