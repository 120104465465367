import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { ExpandMore } from '@material-ui/icons';
import { useHistory, useLocation } from 'react-router-dom';
import COLORS from '../../common/colors';
import ROUTES, { ONBOARDINGAPI } from '../../common/routes';
import AppNavItems from './app-nav-items';
import SettingsNavItems from './settings-nav-items';
import useFetchSimpleService from '../../api/useFetchSimpleService';
import { HEADER_HEIGHT } from '../header-menu';

const SIDE_MENU_CONTENT_WIDTH = 240;
const SIDE_MENU_CONTENT_WIDTH_COLLAPSED = 16;

const SideBarSection = styled.div`
  position: fixed;
  height: calc(100% - ${HEADER_HEIGHT}px);
  display: flex;
  justify-content: space-between;
  z-index: 10;
`;

const SideBarContentSection = styled.div`
  transition: width 350ms ease-in-out;
  ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed
      ? `width: ${SIDE_MENU_CONTENT_WIDTH_COLLAPSED}px;`
      : `width: ${SIDE_MENU_CONTENT_WIDTH}px`};
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.5), 0 3px 1px -2px rgba(0, 0, 0, 0.5),
    0 1px 5px 0 rgba(0, 0, 0, 0.5);
  background-color: #f5f5f5;
  display: flex;
`;

const NavItemsWrapper = styled.nav`
  margin: 32px 24px 16px 16px;
  flex: 1;
  display: flex;
  flex-direction: column;
  transition: visibility 0s linear 100ms;
  ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? `visibility: hidden` : `visibility: visible`};
`;

const CollapseBarSection = styled.div`
  background-color: transparent;
`;

const CollapseButton = styled.div`
  position: relative;
  left: -12px;
  top: 48px;
  height: 24px;
  width: 24px;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: 0 2px 2px 0 rgba(0, 0, 0, 0.14),
    0 3px 1px -2px rgba(0, 0, 0, 0.12), 0 1px 5px 0 rgba(0, 0, 0, 0.2);
  transition: all 250ms ease-in-out;
  transform: ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed
      ? `
        rotate(270deg);
        background-color: ${COLORS.Orange};
        opacity: 0.6;
    `
      : 'rotate(90deg); background-color: white;'};
  :hover {
    background-color: ${COLORS.Orange};
    opacity: 1;
    fill: white;
    cursor: pointer;
  }
`;

const StyledExpandMore = styled(ExpandMore)`
  height: 100%;
  width: 100%;
  transition: fill 200ms linear;
  fill: ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed ? `white;` : COLORS.DarkBlue};
  :hover {
    fill: white;
  }
`;

const ContentSection = styled.div`
  position: relative;
  transition: margin-left 350ms ease-in-out;
  height: calc(100vh - ${HEADER_HEIGHT}px);
  box-sizing: border-box;
  ${(props: { isCollapsed: boolean }) =>
    props.isCollapsed
      ? `margin-left: ${SIDE_MENU_CONTENT_WIDTH_COLLAPSED}px;`
      : `margin-left: ${SIDE_MENU_CONTENT_WIDTH}px`};
`;

const PageWrapper = styled.div`
  padding: 48px 32px;
  margin: 0 auto;
  min-width: 1024px;
`;

interface Props {
  onManualResetErrorBoundary?: () => void;
  children?: React.ReactNode;
}

const SideMenuWithContentWrapper: React.FC<Props> = ({
  onManualResetErrorBoundary,
  children,
}) => {
  const location = useLocation();
  const history = useHistory();
  const [isCollapsed, setIsCollapsed] = useState<boolean>(false);
  const [currentNavItems, setCurrentNavItems] = useState<React.ReactNode>(
    <AppNavItems onManualResetErrorBoundary={onManualResetErrorBoundary} />
  );

  const onChangeCollapse = () => {
    setIsCollapsed(!isCollapsed);
  };

  useEffect(() => {
    const isCollapsedLocalStateString = window.localStorage.getItem(
      'isSideBarCollapsed'
    );
    if (isCollapsedLocalStateString) {
      const parsedIsCollapsedLocalState = JSON.parse(
        isCollapsedLocalStateString
      );
      setIsCollapsed(parsedIsCollapsedLocalState);
    }
  }, []);

  useEffect(() => {
    window.localStorage.setItem(
      'isSideBarCollapsed',
      JSON.stringify(isCollapsed)
    );
  }, [isCollapsed]);

  useEffect(() => {
    if (location.pathname.includes(`${ROUTES.App + ROUTES.Settings}`)) {
      setCurrentNavItems(
        <SettingsNavItems
          onManualResetErrorBoundary={onManualResetErrorBoundary}
        />
      );
    } else {
      setCurrentNavItems(
        <AppNavItems onManualResetErrorBoundary={onManualResetErrorBoundary} />
      );
    }
  }, [location, onManualResetErrorBoundary]);

  const [data] = useFetchSimpleService<{
    completedOnboarding: boolean;
  }>(ONBOARDINGAPI.Status);

  if (data?.completedOnboarding === false) {
    history.push(`${ROUTES.App}${ROUTES.Onboarding_Wizard}`);
  }

  return (
    <>
      <SideBarSection>
        <SideBarContentSection isCollapsed={isCollapsed}>
          <NavItemsWrapper isCollapsed={isCollapsed}>
            {currentNavItems}
          </NavItemsWrapper>
        </SideBarContentSection>
        <CollapseBarSection>
          <CollapseButton isCollapsed={isCollapsed} onClick={onChangeCollapse}>
            <StyledExpandMore isCollapsed={isCollapsed} fontSize="small" />
          </CollapseButton>
        </CollapseBarSection>
      </SideBarSection>
      <ContentSection isCollapsed={isCollapsed}>
        <PageWrapper>{children}</PageWrapper>
      </ContentSection>
    </>
  );
};

export default SideMenuWithContentWrapper;
