import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { STATUS } from '../../settings-pages/types';
import { useAuthContext } from '../../../firebase/AuthProvider';
import BillingActionBanner from './billing-action-banner';

const BannerWrapper = styled.div<{
  isVisible: boolean;
  isWarning: boolean;
}>`
  width: 100%;
  transition: height 0.1s ease-in-out;
  font-size: 0.85rem;
  padding: 4px;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  ${({ isVisible }) =>
    isVisible
      ? `visibility: visible; 
      height: auto;`
      : `visibility: hidden; 
      height: 0`}
  ${({ isWarning }) =>
    isWarning
      ? `  background-color: rgb(255, 244, 229);
  color: rgb(102, 60, 0);   border-bottom: 1px solid rgba(102, 60, 0, 0.1);

  `
      : `  background-color: rgb(253, 237, 237);
  color: rgb(95, 33, 32);
  border-bottom: 1px solid rgba(95, 33, 32, 0.1)`}
`;

export interface Props {
  status: STATUS | null;
}

const SHOWN_STATUSES = [
  STATUS.TRIALING,
  STATUS.PAST_DUE,
  STATUS.INCOMPLETE,
  STATUS.UNPAID,
  STATUS.INCOMPLETE_EXPIRED,
  STATUS.CANCELED,
];

export const BillingBannerInner: React.FC<Props> = ({ status }) => {
  const [showStatusBar, setShowStatusBar] = useState<boolean>(false);

  useEffect(() => {
    if (status && SHOWN_STATUSES.includes(status)) {
      setShowStatusBar(true);
    }
  }, [status]);

  const renderBannerType = () => {
    switch (status) {
      case STATUS.TRIALING:
        return (
          <BillingActionBanner message="You are currently on a Free Trial. Please update your payment details before your trial ends!" />
        );
      case STATUS.PAST_DUE:
        return (
          <BillingActionBanner message="Your previous payment failed. Please update your payment details to ensure continued access!" />
        );
      case STATUS.INCOMPLETE:
        return (
          <BillingActionBanner message="There was an issue with your payment. Please pay the outstanding invoice to start your subscription!" />
        );
      case STATUS.UNPAID:
        return (
          <BillingActionBanner message="Your subscription is on hold until payment is successful. Please update your payment details!" />
        );
      case STATUS.INCOMPLETE_EXPIRED:
        return (
          <BillingActionBanner
            message="Your subscription has been canceled due to a failed payment. Please restart your subscription!"
            isRestart
          />
        );
      case STATUS.CANCELED:
        return (
          <BillingActionBanner
            message="Your subscription has been canceled. Please restart your subscription!"
            isRestart
          />
        );
      default:
        return null;
    }
  };

  return (
    <BannerWrapper
      isVisible={showStatusBar}
      isWarning={status === STATUS.TRIALING || status === STATUS.PAST_DUE}
    >
      {renderBannerType()}
    </BannerWrapper>
  );
};

const BillingBanner: React.FC = () => {
  const { subscriptionStatus } = useAuthContext();

  return <BillingBannerInner status={subscriptionStatus} />;
};

export default BillingBanner;
