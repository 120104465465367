import { useAuthContext } from '../../../../../firebase/AuthProvider';

const useGetSamplePipelineId = (): string => {
  const { user } = useAuthContext();
  if (user?.uid) {
    return `sample_pipeline_${user.uid}`;
  }
  return '<PIPELINE_ID>';
};

export default useGetSamplePipelineId;
