export enum DataTypes {
  NUMBER = 'number',
  CATEGORY = 'category',
  RICH_TEXT = 'rich_text',
  DATETIME = 'date_time',
}

export interface ColumnUnitsType {
  unit: string | null;
  unitId: string;
  dataType: DataTypes;
  dataTypeId: string;
  column: string;
  dataSourceName: string;
}

export interface FieldUnitsType {
  field: string;
  unit: string | null;
  unitId: string;
  dataType: DataTypes;
  dataTypeId: string;
  dateTimeFormat?: string;
  columns: ColumnUnitsType[];
}

export interface StoreState {
  fields: FieldUnitsType[];
  fillData: { fields: FieldUnitsType[] } | null;
}

export interface ContainerProps {
  fields: FieldUnitsType[];
}
