import React from 'react';
import styled from 'styled-components';
import { Checkbox, FormControlLabel, TextField } from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import type { Field } from '../../types';
import { DataTypes } from '../../../../decision-pages/units-page/units-store/types';
import getReadableDataType from '../../../../decision-pages/units-page/field-column-units/units-row/util';
import { useSchemaStore } from '../../store';
import { DateTimeFormats } from '../../../../decision-pages/units-page/field-column-units/units-row/date-format-input';
import FieldColumnHeader from './field-column-header';
import { Units } from '../../../../decision-pages/units-page/field-column-units/units-row';
import convertUnitText from '../../../../../utils/convertUnitText';
import CategoryInputChips from '../category-input-chips';

interface Props {
  field: Field;
}

const BORDER_STYLE = '1px solid rgba(0, 0, 0, 0.12)';

const Column = styled.div<{ isSetOutputSchema: boolean }>`
  min-width: 240px;
  max-width: 240px;
  min-height: 540px;
  border: ${BORDER_STYLE};
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

const Top = styled.div`
  flex: 1;
`;

const Bottom = styled.div`
  padding: 0 16px 16px;
`;

const FieldControl = styled.div`
  padding: 0 16px;
  margin: 32px 0;
`;

const AutocompleteLabel = styled.p`
  margin: 0 0 8px 4px;
  font-size: 14px;
`;

const FieldColumn: React.FC<Props> = ({ field }) => {
  const [
    {
      isSetOutputSchema,
      isEditingAMappingRule,
      isAddingAMappingRule,
      combineAdditionalField,
    },
    {
      updateFieldDataType,
      updateFieldUnitType,
      updateFieldDateTimeFormat,
      updateFieldOptionalFlag,
      updateFieldDropMissingFlag,
    },
  ] = useSchemaStore();

  const disabled =
    isSetOutputSchema || isEditingAMappingRule || isAddingAMappingRule;

  return (
    <Column isSetOutputSchema={isSetOutputSchema}>
      <FieldColumnHeader fieldName={field.name} />
      <Top>
        <FieldControl>
          <AutocompleteLabel>Data Type</AutocompleteLabel>
          <Autocomplete
            value={field.dataType}
            options={Object.values(DataTypes)}
            disableClearable
            getOptionLabel={(option) => getReadableDataType(option)}
            onChange={(event, newValue) =>
              updateFieldDataType(field.name, newValue)
            }
            disabled={disabled}
            size="small"
            renderInput={(params) => (
              <TextField {...params} variant="outlined" />
            )}
          />
        </FieldControl>
        {field.dataType === DataTypes.CATEGORY && (
          <FieldControl>
            <CategoryInputChips field={field} />
          </FieldControl>
        )}
        {field.dataType === DataTypes.DATETIME && (
          <FieldControl>
            <AutocompleteLabel>Output Unit</AutocompleteLabel>
            <Autocomplete
              disabled={disabled}
              disableClearable
              size="small"
              value={field.unitType || { unit: null, group: '' }}
              options={Units[field.dataType]}
              getOptionLabel={(option) => convertUnitText(option.unit)}
              groupBy={(option) => option.group}
              onChange={(event, newValue) =>
                updateFieldUnitType(field.name, newValue)
              }
              getOptionSelected={(option) =>
                option.unit === field.unitType?.unit
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FieldControl>
        )}
        {field.dataType === DataTypes.DATETIME && (
          <FieldControl>
            <AutocompleteLabel>Date Time Format</AutocompleteLabel>
            <Autocomplete
              value={field.dateTimeFormat || DateTimeFormats[0].format}
              size="small"
              disableClearable
              disabled={disabled}
              options={DateTimeFormats.map((dtfObj) => dtfObj.format)}
              onChange={(e, newValue) =>
                updateFieldDateTimeFormat(field.name, newValue)
              }
              renderInput={(params) => (
                <TextField {...params} variant="outlined" />
              )}
            />
          </FieldControl>
        )}
      </Top>
      <Bottom>
        <FormControlLabel
          control={
            <Checkbox
              checked={field.optional || field.name === combineAdditionalField}
              disabled={field.name === combineAdditionalField}
              onChange={(e) =>
                updateFieldOptionalFlag(field.name, e.target.checked)
              }
            />
          }
          label="Optional"
          labelPlacement="end"
        />
        <FormControlLabel
          control={
            <Checkbox
              checked={field.dropMissing}
              onChange={(e) =>
                updateFieldDropMissingFlag(field.name, e.target.checked)
              }
            />
          }
          label="Drop Missing Values"
          labelPlacement="end"
        />
      </Bottom>
    </Column>
  );
};

export default FieldColumn;
