import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import {
  Checkbox,
  CircularProgress,
  Divider,
  TextField,
  Typography,
} from '@material-ui/core';
import { Autocomplete } from '@material-ui/lab';
import { DataImporter } from '@segna/segna-sdk';
import { useParams } from 'react-router-dom';
import { toast } from 'react-toastify';
import StyledToggle from './toggle-button';
import COLORS from '../../../../common/colors';
import TestScriptsList from './scripts-list';
import { DATA_RESOURCE_TYPES } from '../../../../common/constants';
import { ScriptsList } from './types';
import { GetPipelineSummaryData } from '../types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: center;
`;

const Container = styled.div`
  width: 560px;
  background-color: white;
  padding: 8px 12px;
  margin: 8px;
  border-radius: 5px;
`;

const ConfigContainer = styled.div`
  display: flex;
  justify-content: space-between;
  width: 100%;
  margin-bottom: 12px;
`;

const RightContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

const OptionContainer = styled.div`
  display: grid;
  grid-template-columns: 1fr 2fr;
  margin-bottom: 12px;
  align-items: center;
`;

const InputContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
`;

const LoadingContainer = styled.div`
  height: 200px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const StyledTextField = styled(TextField)``;

const StyledAutoComplete = styled(Autocomplete)``;

const FileTypes = ['excel', 'csv'];

interface Props {
  pipelineData: GetPipelineSummaryData | null;
  isLoadingApiKeyData: boolean;
}

const TestJobConfigTab: React.FC<Props> = ({
  pipelineData,
  isLoadingApiKeyData,
}) => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const [canUseTestJob, setCanUseTestJob] = useState<boolean>(true);

  const [canSetFileType, setCanSetFileType] = useState<boolean>(false);

  const [useFullDataSet, setUseFullDataSet] = useState<boolean>(false);
  const [fileName, setFileName] = useState<string>('');
  const [useFileName, setUseFileName] = useState<boolean>(false);

  const [fileType, setFileType] = useState<string>('csv');

  const [scriptsList, setScriptsList] = useState<ScriptsList>({
    preColumnMapping: [],
  });
  const [useScriptsList, setUseScriptsList] = useState<boolean>(false);

  const [jobName, setJobName] = useState<string>('');

  const [webhookUrl, setWebHookUrl] = useState<string>('');
  const [useWebhookUrl, setUseWebhookUrl] = useState<boolean>(false);

  const handleFileTypeChange = (newValue: string) => {
    setFileType(newValue);
  };

  const handleFileNameChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newFileName = event.target.value;
    const isNotValidText = /[~`!#$%^&*+=[\]\\';,/{}|":<>?]/g.test(newFileName);
    !isNotValidText && setFileName(newFileName);
  };

  const handleChangeUseFileName = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseFileName(e.target.checked);
  };

  const handleChangeJobName = (event: React.ChangeEvent<HTMLInputElement>) => {
    const newJobName = event.target.value;
    const isNotValidText = /[~`!#$%^&*+=[\]\\';,/{}|":<>?]/g.test(newJobName);
    !isNotValidText && setJobName(newJobName);
  };

  const handleChangeWebHookUrl = (
    event: React.ChangeEvent<HTMLInputElement>
  ) => {
    const newUrl = event.target.value;
    const isNotValidText = /^[~`!#$%^&*+=[\]';,{}|\\":<>?]*$/g.test(newUrl);
    !isNotValidText && setWebHookUrl(newUrl);
  };
  const handleChangeUseWebhookUrl = (
    e: React.ChangeEvent<HTMLInputElement>
  ) => {
    setUseWebhookUrl(e.target.checked);
  };

  const handleChangeUseScripts = (e: React.ChangeEvent<HTMLInputElement>) => {
    setUseScriptsList(e.target.checked);
  };

  useEffect(() => {
    if (pipelineData) {
      if (Object.keys(pipelineData.fields).length === 0) {
        setCanUseTestJob(false);
      }
    }
  }, [pipelineData]);

  useEffect(() => {
    if (pipelineData?.output) {
      const listOfOutputs = Object.values(pipelineData.output);
      listOfOutputs.forEach(({ dataResourceType }) => {
        if (
          dataResourceType === DATA_RESOURCE_TYPES.S3 ||
          dataResourceType === DATA_RESOURCE_TYPES.GCS
        ) {
          setCanSetFileType(true);
        }
      });
    }
  }, [pipelineData]);

  return (
    <>
      {/* eslint-disable-next-line no-nested-ternary */}
      {!isLoadingApiKeyData ? (
        canUseTestJob ? (
          <Wrapper>
            <ConfigContainer>
              <Container>
                <Typography variant="h6" style={{ marginBottom: 8 }}>
                  Start Job Configuration
                </Typography>
                <OptionContainer>
                  <Typography>Job name:</Typography>
                  <StyledTextField
                    fullWidth
                    size="small"
                    variant="outlined"
                    value={jobName}
                    onChange={handleChangeJobName}
                    required
                    placeholder="Test Job"
                  />
                </OptionContainer>
                <Divider style={{ marginBottom: 12 }} />
                <OptionContainer>
                  <Typography>Use full dataset?</Typography>
                  <StyledToggle
                    toggleStatus={useFullDataSet}
                    setToggleStatus={setUseFullDataSet}
                  />
                </OptionContainer>
                <OptionContainer>
                  <Typography>Destination File Name</Typography>
                  <InputContainer>
                    <StyledTextField
                      fullWidth
                      size="small"
                      variant="outlined"
                      value={fileName}
                      onChange={handleFileNameChange}
                      disabled={!useFileName}
                      placeholder="File_name"
                    />
                    <Checkbox
                      checked={useFileName}
                      onChange={handleChangeUseFileName}
                    />
                  </InputContainer>
                </OptionContainer>
                {canSetFileType && (
                  <OptionContainer>
                    <Typography>Output File Type</Typography>
                    <InputContainer>
                      <StyledAutoComplete
                        disableClearable
                        disabled={!canSetFileType}
                        value={fileType}
                        options={FileTypes}
                        style={{ width: 200 }}
                        renderInput={(params) => (
                          <TextField
                            {...params}
                            variant="outlined"
                            size="small"
                            fullWidth
                          />
                        )}
                        onChange={(event, fileType) => {
                          const newFileType = fileType as string;
                          handleFileTypeChange(newFileType);
                        }}
                        getOptionLabel={(fileType) => {
                          return fileType as string;
                        }}
                        getOptionSelected={(fileTypeAsString) => {
                          return fileType === (fileTypeAsString as string);
                        }}
                      />
                    </InputContainer>
                  </OptionContainer>
                )}
                <OptionContainer style={{ alignItems: 'start' }}>
                  <Typography style={{ marginTop: 20 }}>Scripts</Typography>
                  <InputContainer>
                    <TestScriptsList
                      scriptsList={scriptsList}
                      setScriptsList={setScriptsList}
                      disabled={!useScriptsList}
                    />
                    <Checkbox
                      style={{ marginTop: 12 }}
                      checked={useScriptsList}
                      onChange={handleChangeUseScripts}
                    />
                  </InputContainer>
                </OptionContainer>
              </Container>
              <RightContainer>
                <Container>
                  <Typography variant="h6" style={{ marginBottom: 8 }}>
                    Run Job Configuration
                  </Typography>
                  <OptionContainer>
                    <Typography>Web Hook URL</Typography>
                    <InputContainer>
                      <StyledTextField
                        fullWidth
                        size="small"
                        variant="outlined"
                        value={webhookUrl}
                        onChange={handleChangeWebHookUrl}
                        placeholder="https://your-server-to-pass-metadata-to/"
                        disabled={!useWebhookUrl}
                      />
                      <Checkbox
                        checked={useWebhookUrl}
                        onChange={handleChangeUseWebhookUrl}
                      />
                    </InputContainer>
                  </OptionContainer>
                </Container>
              </RightContainer>
            </ConfigContainer>
            <DataImporter
              pipelineId={pipelineId || ''}
              steps={{
                fileUpload: {
                  jobName: jobName !== '' ? jobName : 'Test Job',
                  useFullData: useFullDataSet,
                  destinationFileName: useFileName ? fileName : undefined,
                  outputFileType: fileType,
                  scripts: useScriptsList ? scriptsList : undefined,
                },
                schemaMapping: {},
                summary: {},
              }}
              customTheme={{
                color: COLORS.Orange,
              }}
              onCompletion={{
                returnWhenComplete: true,
                webhookUrl: useWebhookUrl ? webhookUrl : undefined,
                callbacks: {
                  afterCallback: ({ jobId }) => {
                    toast.success(`Your job (${jobId}) ran successfully!`);
                  },
                },
              }}
            >
              Run Test Job
            </DataImporter>
          </Wrapper>
        ) : (
          <Container>
            <Typography>
              There are no specified fields for this pipeline. You can not test
              jobs using our data importer for this pipeline.
            </Typography>
          </Container>
        )
      ) : (
        <LoadingContainer>
          <CircularProgress />
        </LoadingContainer>
      )}
    </>
  );
};

export default TestJobConfigTab;
