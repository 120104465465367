import React, { useRef } from 'react';
import styled from 'styled-components';
import { Paper } from '@material-ui/core';
import FileInput from './file-input';
import OutputData from './output-data';
import CodeEditorHeader from './code-editor-header';
import SaveButton from './save-button';
import useSpotlightWithLocalStorage from '../../../spotlight-tour/useSpotlightWithLocalStorage';
import { PIPELINEPAGES } from '../../../../common/routes';
import { CodeEditorHeaderRefTypes } from '../../../decision-pages/script-page/types';
import ScriptNameEditor from './code-editor-header/script-name-editor';

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  padding: 4px;
  margin: 4px;
`;

const Wrapper = styled(Paper)`
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  background-color: white;
  border: 5px;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

const DataTableContainer = styled.div`
  display: flex;
  flex-direction: column;
  min-width: 0;
`;

const ButtonContainer = styled.div`
  margin: 20px 24px;
  display: flex;
  justify-content: flex-end;
`;

const CODE_EDITOR_SPOTLIGHT_TEXT =
  'Write your custom python script in the code editor to run against an input data frame.';

const UPLOAD_DATA_SPOTLIGHT_TEXT =
  'Upload test data to test your script. Drag/drop your file or alternatively click on the box.';

const RUN_SCRIPT_SPOTLIGHT_TOUR =
  'Run and see the output of your script on the test data you uploaded.';

const SAVE_SCRIPT_TEXT = "Once you are satisfied with your script, hit 'Save'.";

const ScriptsEditor: React.FC = () => {
  // 1. Code Editor -> 2. Upload data -> 3. Run -> 4. Save
  const codeEditorRef = useRef<HTMLDivElement>(null);
  const uploadRef = useRef<HTMLDivElement>(null);
  const runScriptRef = useRef<HTMLButtonElement>(null);
  const saveScriptRef = useRef<HTMLButtonElement>(null);

  const codeEditorHeaderRef: React.MutableRefObject<CodeEditorHeaderRefTypes> = useRef(
    {
      codeEditorRef,
      runScriptRef,
    }
  );

  useSpotlightWithLocalStorage(
    [
      {
        el: codeEditorRef,
        placement: 'right',
        text: CODE_EDITOR_SPOTLIGHT_TEXT,
      },
      {
        el: uploadRef,
        placement: 'left',
        text: UPLOAD_DATA_SPOTLIGHT_TEXT,
      },
      {
        el: runScriptRef,
        placement: 'left',
        text: RUN_SCRIPT_SPOTLIGHT_TOUR,
      },
      {
        el: saveScriptRef,
        placement: 'left',
        text: SAVE_SCRIPT_TEXT,
      },
    ],
    PIPELINEPAGES.Script
  );

  return (
    <Wrapper>
      <HeaderContainer>
        <ScriptNameEditor />
        <ButtonContainer>
          <SaveButton ref={saveScriptRef} />
        </ButtonContainer>
      </HeaderContainer>
      <Container>
        <CodeEditorHeader ref={codeEditorHeaderRef} />
        <DataTableContainer>
          <FileInput ref={uploadRef} />
          <OutputData />
        </DataTableContainer>
      </Container>
    </Wrapper>
  );
};

export default ScriptsEditor;
