import React, { useState } from 'react';
import { CircularProgress, Link } from '@material-ui/core';
import styled from 'styled-components';
import useCreatePortalSessionService from '../../../settings-pages/billing-page/service/useCreatePortalSessionService';
import useErrorAlert from '../../../../utils/useErrorAlert';
import useCreateCheckoutSessionService from '../../../settings-pages/billing-page/service/useCreateCheckoutSessionService';

const StyledLink = styled(Link)`
  cursor: pointer;
  text-decoration: underline;
`;

const ActionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 80px;
`;

interface Props {
  message: string;
  isRestart?: boolean;
}

const BillingActionBanner: React.FC<Props> = ({
  message,
  isRestart = false,
}) => {
  const [
    getPortalService,
    portalServiceError,
  ] = useCreatePortalSessionService();
  useErrorAlert(portalServiceError);

  const [
    getCheckoutService,
    checkoutServiceError,
  ] = useCreateCheckoutSessionService();
  useErrorAlert(checkoutServiceError);

  const [isButtonLoading, setIsButtonLoading] = useState<boolean>(false);

  const handleManageClick = async () => {
    setIsButtonLoading(true);
    const response = await getPortalService();
    if (response) {
      window.location.href = response.url;
    }
    setIsButtonLoading(false);
  };

  const handleRestartClick = async () => {
    setIsButtonLoading(true);
    const response = await getCheckoutService();
    if (response) {
      window.location.href = response.url;
    }
    setIsButtonLoading(false);
  };

  return (
    <>
      {message}
      <ActionWrapper>
        {isButtonLoading ? (
          <CircularProgress size="0.85rem" color="primary" />
        ) : (
          <StyledLink
            color="primary"
            onClick={isRestart ? handleRestartClick : handleManageClick}
          >
            Manage
          </StyledLink>
        )}
      </ActionWrapper>
    </>
  );
};

export default BillingActionBanner;
