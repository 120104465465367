import React from 'react';
import styled from 'styled-components';
import COLORS from '../../../common/colors';

const NameSection = styled.div`
  display: flex;
  position: relative;
  flex-direction: column;
  align-items: center;
  margin: 0 auto 16px auto;
  padding: 0 80px;
  color: #808080;
  font-size: 0.75rem;
`;

const NameSpan = styled.span`
  margin-left: 4px;
  color: ${COLORS.DarkBlue};
  font-weight: bold;
  font-size: 1.25rem;
`;

const EditingLozenge = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  background-color: lightgray;
  padding: 4px 8px;
  align-items: center;
  color: gray;
  white-space: normal;
  font-weight: 700;
  font-size: 0.8rem;
  border-radius: 4px;
  text-transform: uppercase;
`;

interface Props {
  pipelineName: string;
  isEditing: boolean;
}

const PipelineNameHeader: React.FC<Props> = ({ pipelineName, isEditing }) => {
  return (
    <NameSection>
      Pipeline Name:
      <NameSpan>{pipelineName}</NameSpan>
      {isEditing && <EditingLozenge>Editing</EditingLozenge>}
    </NameSection>
  );
};

export default PipelineNameHeader;
