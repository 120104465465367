import { CircularProgress, Fade, Modal, Paper } from '@material-ui/core';
import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import { toast } from 'react-toastify';
import ConnectorForm, {
  ConnectorTypeInfo,
} from '../../../common/add-database-modal/new-database-connector-modal/connector-form';
import { ConnectorType } from '../../../common/add-database-modal/types';
import useFetchSimpleService from '../../../../api/useFetchSimpleService';
import ROUTES from '../../../../common/routes';
import useErrorAlert from '../../../../utils/useErrorAlert';
import { DATABASE_CONNECTORS } from '../../../common/add-database-modal/new-database-connector-modal';

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalComponent = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 600px;
  padding: 16px;

  // TODO: Find a better fix that doesn't remove the ability to focus for a11y
  &:focus-visible {
    outline: none;
  }
`;

const LoadingContainer = styled.div`
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  id: string;
}

const EditConnectorModal: React.FC<Props> = ({ isOpen, onClose, id }) => {
  const [connectorData, , error] = useFetchSimpleService<{
    connectorType: ConnectorType;
    connectorInfo: {
      connectorName: string & Record<string, string>;
    };
  }>(`${ROUTES.Connectors}/${id}`);
  useErrorAlert(error);

  const [
    connectorTypeInfo,
    setConnectorTypeInfo,
  ] = useState<ConnectorTypeInfo | null>(null);

  const onSuccess = () => {
    toast.success('Connector has been updated');
    onClose();
  };

  useEffect(() => {
    if (connectorData) {
      setConnectorTypeInfo({
        name: connectorData.connectorInfo.connectorName,
        type: connectorData.connectorType,
        connectorComponent:
          DATABASE_CONNECTORS[connectorData.connectorType].connectorComponent,
      });
    }
  }, [connectorData]);

  return (
    <ModalContainer open={isOpen} onClose={onClose} keepMounted>
      <Fade in={isOpen}>
        <ModalComponent>
          {connectorData && connectorTypeInfo ? (
            <ConnectorForm
              currentConnectorType={connectorTypeInfo}
              onClose={onClose}
              onSuccess={onSuccess}
              connectorData={connectorData.connectorInfo}
              id={id}
              onExitConnector={onClose}
            />
          ) : (
            <LoadingContainer>
              <CircularProgress style={{ margin: 'auto' }} />
            </LoadingContainer>
          )}
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default EditConnectorModal;
