import React, { useCallback, useContext, useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import SpotlightTour from './index';
import { Spotlight } from './types';

interface ContextProps {
  /* eslint-disable @typescript-eslint/no-unused-vars */
  setSpotlightData: (s: Spotlight[]) => void;
  startSpotlight: (page: string) => void;
  spotlightData: Spotlight[];
  spotlightIsRunning: boolean;
}

const SpotlightContext = React.createContext<ContextProps>({
  startSpotlight: (page: string) => {},
  spotlightData: [],
  setSpotlightData: () => {},
  spotlightIsRunning: false,
});

export const SpotlightProvider: React.FC = ({ children }) => {
  const [spotlightIsRunning, setSpotlightIsRunning] = useState<boolean>(false);
  const [spotlightData, setSpotlightData] = useState<Spotlight[]>([]);
  const [page, setPage] = useState<string>('');

  const startSpotlight = useCallback((page: string) => {
    setSpotlightIsRunning(true);
    setPage(page);
  }, []);

  const finishSpotlight = useCallback(() => {
    setSpotlightIsRunning(false);
    setSpotlightData([]);
    setPage('');
  }, []);

  // Prevents spotlight from showing when changing page (i.e. using the back/forward browser buttons)
  const location = useLocation();
  useEffect(() => {
    finishSpotlight();
  }, [location, finishSpotlight]);

  return (
    <SpotlightContext.Provider
      value={{
        startSpotlight,
        spotlightData,
        setSpotlightData,
        spotlightIsRunning,
      }}
    >
      {children}
      <SpotlightTour
        spotlightData={spotlightData}
        isOpen={spotlightIsRunning && spotlightData.length > 0}
        finishSpotlight={finishSpotlight}
        page={page}
      />
    </SpotlightContext.Provider>
  );
};

export const useSpotlightContext = (): ContextProps =>
  useContext(SpotlightContext);
