import React from 'react';
import styled from 'styled-components';
import Typography from '@material-ui/core/Typography';
import { Chip } from '@mui/material';

const ChipsContainer = styled.div`
  flex: 1;
  height: 120px;
  overflow: auto;
  border: 1px solid lightgrey;
  border-radius: 4px;
  display: flex;
  flex-direction: column-reverse;
`;

const InnerChipsContainer = styled.div`
  flex: 1 1 0;
  padding: 4px;
`;

const InnerTextWrapper = styled.div`
  text-align: center;
  padding: 4px;
`;

const ChipWrapper = styled.div`
  margin: 4px;
`;

interface Props {
  values: string[] | undefined;
  onDeleteValue: (value: string) => void;
}

const CategoryChipBox: React.FC<Props> = ({ values, onDeleteValue }) => {
  const handleDelete = (value: string) => () => {
    onDeleteValue(value);
  };

  return (
    <ChipsContainer>
      <InnerChipsContainer>
        {values?.length ? (
          values.map((value) => (
            <ChipWrapper key={value}>
              <Chip label={value} onDelete={handleDelete(value)} />
            </ChipWrapper>
          ))
        ) : (
          <InnerTextWrapper>
            <Typography variant="caption">
              No category values specified - add allowed values above!
            </Typography>
          </InnerTextWrapper>
        )}
      </InnerChipsContainer>
    </ChipsContainer>
  );
};

export default CategoryChipBox;
