import usePostSimpleService from '../../../../../api/usePostSimpleService';
import ROUTES, { SCRIPTSAPIS } from '../../../../../common/routes';

interface RunTestScriptRequest {
  script: string;
}

interface RunTestScriptResponse {
  output: string[][];
  isSuccessful: boolean;
}

const UseRunTestService = (
  scriptId: string
): [
  (body: RunTestScriptRequest) => Promise<RunTestScriptResponse | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<RunTestScriptRequest, RunTestScriptResponse>(
    `${ROUTES.Scripts}/${scriptId}${SCRIPTSAPIS.Run_Test_Data}`
  );
};

export default UseRunTestService;
