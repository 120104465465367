import React from 'react';
import { Redirect, Route, Switch, useRouteMatch } from 'react-router-dom';
import styled, { keyframes } from 'styled-components';
import { ONBOARDINGWIZARDPAGES, SETTINGSAPI } from '../../../common/routes';
import WelcomePage from '../../onboarding-wizard-pages/welcome-page';
import { OnboardingWizardStoreContainer } from '../../onboarding-wizard-pages/store';
import RunJobPage from '../../onboarding-wizard-pages/run-job-page';
import ViewDataPage from '../../onboarding-wizard-pages/view-data-page';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import OnboardingWizardNavBar from '../../onboarding-wizard-pages/onboarding-wizard-navbar';
import { TrackPageVisited } from '../../../utils/useTrackEvent';

const FadeSlideUpKeyframes = keyframes`
  0% {
    opacity: 0;
  }

  100% {
    opacity: 1;
  }
`;

const Wrapper = styled.div`
  animation: 500ms linear ${FadeSlideUpKeyframes};
`;

const PageContent = styled.div`
  margin: 120px auto;
  padding: 0 16px;
  width: 900px;
  display: flex;
  flex-direction: column;
`;

const OnboardingWizardRoutes: React.FC = () => {
  const { path } = useRouteMatch();

  const [apiKeyData] = useFetchSimpleService<{
    apiKey: string | null;
  }>(SETTINGSAPI.Api_Key);

  const apiKey = apiKeyData ? apiKeyData.apiKey : null;

  return (
    <OnboardingWizardStoreContainer apiKey={apiKey}>
      <Wrapper>
        <OnboardingWizardNavBar />
        <PageContent>
          <Switch>
            <Route exact path={path}>
              <Redirect to={`${path}/${ONBOARDINGWIZARDPAGES.Welcome}`} />
            </Route>
            <Route exact path={`${path}/${ONBOARDINGWIZARDPAGES.Welcome}`}>
              <TrackPageVisited pageName="Onboarding_Welcome" />
              <WelcomePage />
            </Route>
            <Route exact path={`${path}/${ONBOARDINGWIZARDPAGES.RunJob}`}>
              <TrackPageVisited pageName="Onboarding_Run_Job" />
              <RunJobPage />
            </Route>
            <Route exact path={`${path}/${ONBOARDINGWIZARDPAGES.ViewData}`}>
              <TrackPageVisited pageName="Onboarding_View_Data" />
              <ViewDataPage />
            </Route>
            <Redirect to={path} />
          </Switch>
        </PageContent>
      </Wrapper>
    </OnboardingWizardStoreContainer>
  );
};

export default OnboardingWizardRoutes;
