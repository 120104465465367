import React, { useState } from 'react';
import { AppBar, Box, Paper, Tab, Tabs } from '@material-ui/core';
import COLORS from '../../../../common/colors';
import ReactPanel from './react-panel';
import JsPanel from './js-panel';
import HttpsPanel from './https-panel';
import NoCodePanel from './no-code-panel';
import { trackButtonClicked } from '../../../../utils/useTrackEvent';
import { ONBOARDINGWIZARDPAGES } from '../../../../common/routes';

enum TABS {
  REACT = 'React',
  JS = 'JS',
  HTTPS = 'HTTPS (cURL)',
  NO_CODE = 'No Code (Data Importer)',
}

interface TabPanelProps {
  show: boolean;
  tab: TABS;
}

const TabPanel: React.FC<TabPanelProps> = ({ children, show, tab }) => {
  return (
    <div
      role="tabpanel"
      hidden={!show}
      id={`config-tabpanel-${tab}`}
      aria-labelledby={`config-tab-${tab}`}
    >
      {show && <Box p={3}>{children}</Box>}
    </div>
  );
};

// Get accessibility props
const a11yProps = (tab: TABS) => {
  return {
    id: `config-tab-${tab}`,
    'aria-controls': `config-tabpanel-${tab}`,
  };
};

const ConfigTabPanels: React.FC = () => {
  const [tab, setTab] = useState<TABS>(TABS.REACT);

  const handleChange = (e: React.ChangeEvent<unknown>, newValue: TABS) => {
    trackButtonClicked(ONBOARDINGWIZARDPAGES.RunJob, newValue);
    setTab(newValue);
  };

  return (
    <Paper square variant="outlined">
      <AppBar position="sticky" color="default" elevation={1}>
        <Tabs
          value={tab}
          centered
          onChange={handleChange}
          aria-label="config tabs"
          TabIndicatorProps={{
            style: {
              backgroundColor: COLORS.DarkBlue,
            },
          }}
        >
          <Tab
            label={TABS.REACT}
            value={TABS.REACT}
            {...a11yProps(TABS.REACT)}
          />
          <Tab label={TABS.JS} value={TABS.JS} {...a11yProps(TABS.JS)} />
          <Tab
            label={TABS.HTTPS}
            value={TABS.HTTPS}
            {...a11yProps(TABS.HTTPS)}
          />
          <Tab
            label={TABS.NO_CODE}
            value={TABS.NO_CODE}
            {...a11yProps(TABS.NO_CODE)}
          />
        </Tabs>
      </AppBar>
      <TabPanel show={tab === TABS.REACT} tab={TABS.REACT}>
        <ReactPanel />
      </TabPanel>
      <TabPanel show={tab === TABS.JS} tab={TABS.JS}>
        <JsPanel />
      </TabPanel>
      <TabPanel show={tab === TABS.HTTPS} tab={TABS.HTTPS}>
        <HttpsPanel />
      </TabPanel>
      <TabPanel show={tab === TABS.NO_CODE} tab={TABS.NO_CODE}>
        <NoCodePanel />
      </TabPanel>
    </Paper>
  );
};

export default ConfigTabPanels;
