import { useCallback, useState } from 'react';
import { PAGEAPIROUTES } from '../../../../common/routes';
import { BASE_URL } from '../../../../common/constants';

type AddResponse = Record<string, never>;

const createGoogleAccount = (
  authToken: string,
  route: string,
  setError: (error: Error) => void
): Promise<void | Response> => {
  return fetch(`${BASE_URL}${route}`, {
    method: 'POST',
    headers: {
      Authorization: `${authToken}`,
      'Content-Type': 'application/json',
    },
  })
    .then((response) => {
      if (response.status === 204) {
        return {} as Response;
      }
      return response.json().then((responseData) => {
        if (response.ok) {
          return responseData;
        }
        setError(new Error(responseData.errorMessage));
        return undefined;
      });
    })
    .catch((error) => {
      setError(new Error(error));
    });
};

const useCreateNewGoogleUserService = (): [
  (authToken: string) => Promise<AddResponse>,
  Error | null,
  () => void
] => {
  const [error, setError] = useState<Error | null>(null);

  const clearError = useCallback(() => {
    setError(null);
  }, []);

  const route = PAGEAPIROUTES.Create_User + PAGEAPIROUTES.Google;

  return [
    useCallback(
      async (authToken) => {
        clearError();
        return createGoogleAccount(authToken, route, setError).then(
          (responseData) => (responseData as unknown) as AddResponse
        );
      },
      [clearError, route]
    ),
    error,
    clearError,
  ];
};

export default useCreateNewGoogleUserService;
