import {
  createStore,
  createHook,
  createContainer,
  StoreActionApi,
} from 'react-sweet-state';

import { ConnectorType } from '../../../common/add-database-modal/types';
import { ContainerProps, SampleSourceData, StoreState } from './types';

const initialState: StoreState = {
  pipelineName: '',
  isEditing: false,
  databaseConnectors: {},
  sampleDataResource: null,
  outputDataResources: {},
};
type StoreApi = StoreActionApi<StoreState>;

const actions = {
  setInitialData: () => (
    { setState }: StoreApi,
    {
      pipelineName,
      isEditing,
      databaseConnectors,
      sampleDataResources,
      outputDataResources,
    }: ContainerProps
  ) => {
    if (Object.keys(sampleDataResources).length > 0) {
      const firstSampleDataResourceId = Object.keys(sampleDataResources)[0];
      const firstSampleDataResourceValue =
        sampleDataResources[firstSampleDataResourceId];
      const flatSampleDataResource: SampleSourceData = {
        dataResourceId: firstSampleDataResourceId,
        dataResourceName: firstSampleDataResourceValue.dataResourceName,
        dataResourceType: firstSampleDataResourceValue.dataResourceType,
      };
      setState({
        pipelineName,
        databaseConnectors,
        sampleDataResource: flatSampleDataResource,
        outputDataResources,
      });
    } else {
      setState({
        pipelineName,
        isEditing,
        databaseConnectors,
        sampleDataResource: null,
        outputDataResources,
      });
    }
  },
  addDatabaseConnector: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType
  ) => ({ getState, setState }: StoreApi) => {
    const { databaseConnectors } = getState();
    databaseConnectors[connectorId] = {
      connectorName,
      connectorType,
    };
    setState({
      databaseConnectors,
    });
  },
  addSampleDataResource: (
    dataResourceId: string,
    dataResourceName: string,
    dataResourceType: string
  ) => ({ setState }: StoreApi) => {
    const newSample = {
      dataResourceId,
      dataResourceName,
      dataResourceType,
    };

    setState({ sampleDataResource: newSample });
  },
  removeSampleDataResource: () => ({ setState }: StoreApi) => {
    const sampleDataResource = null;
    setState({ sampleDataResource });
  },
  addOutputDataResource: (
    dataResourceId: string,
    dataResourceName: string,
    dataResourceType: string
  ) => ({ getState, setState }: StoreApi) => {
    const { outputDataResources } = getState();
    outputDataResources[dataResourceId] = {
      dataResourceName,
      dataResourceType,
    };
    setState({
      outputDataResources,
    });
  },
  removeOutputDataResource: (dataResourceId: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const { outputDataResources } = getState();
    delete outputDataResources[dataResourceId];
    setState({ outputDataResources });
  },
};

type Actions = typeof actions;

const Store = createStore<StoreState, Actions>({
  initialState,
  actions,
});

export const useDataSourcesImportingStore = createHook(Store);
export const DataSourcesImportingStoreContainer = createContainer(Store, {
  onInit: actions.setInitialData,
  onUpdate: actions.setInitialData,
});
