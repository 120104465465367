import React from 'react';
import styled from 'styled-components';
import { Button, Typography } from '@material-ui/core';
import { useHistory } from 'react-router-dom';
import ThinkingEmojiSvg from '../../../../common/images/thinking_emoji.svg';
import ROUTES from '../../../../common/routes';

const Wrapper = styled.div`
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

const ErrorMessageWrapper = styled.span`
  margin: 16px 80px;
`;

const ThinkingSvg = styled.img`
  height: 200px;
  width: 200px;
`;

const ButtonWrapper = styled.div`
  margin: 8px 0;
`;

interface Props {
  error: Error;
  resetErrorBoundary: (...args: Array<unknown>) => void;
}

const DataError: React.FC<Props> = ({ error, resetErrorBoundary }) => {
  const history = useHistory();

  const handleGoBack = () => {
    resetErrorBoundary();
    history.push(`${ROUTES.App + ROUTES.Job}`);
  };

  return (
    <Wrapper>
      <ThinkingSvg src={ThinkingEmojiSvg} />
      <Typography variant="h5">Hmmm...</Typography>
      Your data is an unexpected shape :(
      <ErrorMessageWrapper>
        <Typography variant="body2">Error: {error.message}</Typography>
      </ErrorMessageWrapper>
      Please go back to the previous step and try again.
      <ButtonWrapper>
        <Button onClick={handleGoBack} color="primary" variant="contained">
          Go back!
        </Button>
      </ButtonWrapper>
    </Wrapper>
  );
};

export default DataError;
