export const SAMPLE_FILENAME = 'onboarding_sample.csv';
export const SAMPLE_FILESIZE = '6 KB';
export const SAMPLE_FILE_DOWNLOAD_LINK =
  'https://drive.google.com/u/0/uc?id=1aDuEDFDWpOfYOzlyrQdQW_QWYmJQ4av8&export=download';

export const SAMPLE_FILE_BODY_DATA: string[][] = [
  ['Name', 'Segna', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  ['', '', '', '', '', '', '', '', '', '', '', '', '', '', '', '', ''],
  [
    'Kickstarter data',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    '',
    '',
    'project id',
    'project name',
    'url',
    'start date',
    'status',
    'top category',
    'category',
    'project goal',
    'amount pledged',
    'num backers',
    'end date',
    'days',
    'FB',
    'has vids',
    'vid',
  ],
  [
    '',
    '',
    '409068451',
    'Aurora Keys - First Electronic Track Release',
    'https://www.kickstarter.com/projects/1590542209/aurora-keys-first-electronic-track-release',
    'Thursday, August 2 1985 08:30 PM',
    'failed',
    'Music',
    'Electronic Music',
    '350',
    '64',
    '8',
    '26/12/2013',
    '30',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '',
    '',
    'https://www.kickstarter.com/projects/395030433/the-in-between-web-series',
    'Thu, Aug 2 1985 08:30 PM',
    '',
    'Film & Video',
    'Webseries',
    '1000',
    '220',
    '',
    '',
    '',
    '',
    '',
    '',
  ],
  [
    '',
    '',
    '',
    'The In-Between Web Series',
    '',
    'Aug 2 1985',
    '',
    'Music',
    'Music',
    '10000',
    '',
    '',
    '',
    '28',
    '',
    '',
    '',
  ],
  [
    '',
    '',
    '831761274',
    'The In-Between Web Series',
    'https://www.kickstarter.com/projects/395030433/the-in-between-web-series',
    'Thu, Aug 2 1985 08:30 PM',
    'failed',
    'Film & Video',
    'Webseries',
    '1000',
    '220',
    '5',
    '21/02/2014',
    '28',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    'Not a number',
    "Life Lessons, Brownish Black's Debut Full Length Album",
    'https://www.kickstarter.com/projects/1307802904/life-lessons-brownish-blacks-debut-full-length-alb',
    'Aug 2 1985',
    'successful',
    'Music',
    'Music',
    '10000',
    '12236',
    '194',
    '22/03/2014',
    '30',
    'No',
    'Yes',
    '1',
  ],
  [
    '',
    '',
    '772007761',
    "The World's Easiest 30 Gallon Designer Aquarium",
    'https://www.kickstarter.com/projects/1063049260/the-worlds-easiest-30-gallon-designer-aquarium',
    '2:00pm 2/08/1985',
    'successful',
    'Design',
    'Product Design',
    '1500',
    '1542',
    '6',
    '12/04/2014',
    '45',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1490151419',
    'Verge - handcrafted wallet with revolutionary spirit',
    'https://www.kickstarter.com/projects/verge-style/verge-handcrafted-wallet-with-revolutionary-spirit',
    'Aug 2 1985 14:00',
    'successful',
    'Design',
    'Product Design',
    '3000',
    '11818',
    'Not a number',
    '20/05/2014',
    '30',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1615831140',
    'LIMBO',
    'https://www.kickstarter.com/projects/1766249470/limbo',
    'August 2 1985',
    'successful',
    'Design',
    'Graphic Design',
    '550',
    '703',
    '30',
    '7/03/2014',
    '28',
    'Yes',
    'No',
    '0',
  ],
  [
    '',
    '',
    '258172886',
    'TG Wilkinson Sequential Staged Narrative Photography Project',
    'https://www.kickstarter.com/projects/tgwilkinson/tg-wilkinson-sequential-staged-narrative-photograp',
    '',
    'successful',
    'Photography',
    'Photography',
    '10000',
    '',
    '197',
    '',
    '31',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '46366002',
    '13 [Working Title]',
    'https://www.kickstarter.com/projects/621798229/13-working-title',
    'August 2 1985 13:00+00:00',
    'successful',
    'Film & Video',
    'Short Film',
    '500',
    '',
    '32',
    '',
    '27',
    'Yes',
    'No',
    '0',
  ],
  [
    '',
    '',
    '348374994',
    'Her Eyes Open - A Feature-ish Film',
    'https://www.kickstarter.com/projects/104878252/her-eyes-open-a-feature-ish-film',
    'August 2 1985 13:00+03:00',
    'successful',
    'Film & Video',
    'Film & Video',
    '2900',
    '',
    'Not a number',
    '',
    '30',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '942348184',
    'BEACON POINT Feature Film - Final Finishing Funds Needed',
    'https://www.kickstarter.com/projects/1603025734/beacon-point-feature-film-final-finishing-funds-ne',
    'August 2 1985 13:00-02:00',
    'successful',
    'Film & Video',
    'Narrative Film',
    '20000',
    '',
    '246',
    '',
    '30',
    'Yes',
    'Yes',
    '1',
  ],
  [
    '',
    '',
    '474320632',
    'Musica Humana',
    'https://www.kickstarter.com/projects/73437470/musica-humana',
    '1985-08-02T04:25:03Z',
    'successful',
    'Music',
    'Music',
    '10500',
    '',
    '170',
    '',
    '29',
    'Yes',
    '',
    '',
  ],
  [
    '',
    '',
    '1176437420',
    'Canning Salsa with heirloom tomato and organic peppers',
    'https://www.kickstarter.com/projects/426979205/canning-salsa-with-heirloom-tomato-and-organic-pep',
    'August 2 1985 13:00',
    'failed',
    'Food',
    'Food',
    '3500',
    '305',
    '12',
    '8/03/2014',
    '53',
    'Yes',
    'Yes',
    '',
  ],
  [
    '',
    '',
    '1432172476',
    'Relinquish',
    'https://www.kickstarter.com/projects/1066460198/relinquish',
    'Thu, Aug 2 1985 08:30 PM',
    'failed',
    'Film & Video',
    'Short Film',
    '1000',
    '1',
    '1',
    '23/01/2014',
    '15',
    'Yes',
    'No',
    '',
  ],
  [
    '',
    '',
    '660583184',
    '3 EPs in 2014: THE ONE WORTH IT ALL, FRENCH EP, BORN AGAIN',
    'https://www.kickstarter.com/projects/briancampbellmusic/3-eps-in-2014-the-one-worth-it-all-french-ep-born',
    'Aug 2 1985',
    'successful',
    'Music',
    'Music',
    '4000',
    '4066',
    '45',
    'some not date',
    '20',
    'No',
    'Yes',
    '',
  ],
  [
    '',
    '',
    '1044713540',
    'Sow A Seed of HOPE',
    'https://www.kickstarter.com/projects/1857665973/sow-a-seed-of-hope',
    '2:00pm 2/08/1985',
    'failed',
    'Fashion',
    'Apparel',
    '500',
    '167',
    '7',
    '19/05/2014',
    '13',
    'Yes',
    'No',
    '',
  ],
  [
    '',
    '',
    '1326059468',
    '"Cosmic Love" New Album Project',
    'https://www.kickstarter.com/projects/jaidenstylez/cosmic-love-new-album-project',
    'Aug 2 1985 14:00',
    'failed',
    'Music',
    'Electronic Music',
    '2200',
    '30',
    '3',
    '1/02/2014',
    '30',
    'No',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '817388806',
    'The Odori Park Omnibus',
    'https://www.kickstarter.com/projects/chriswatkins/the-odori-park-omnibus',
    'August 2 1985',
    'successful',
    'Comics',
    'Comics',
    '2500',
    '3026',
    '77',
    '7/04/2014',
    '23',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '520307462',
    'The Wild Adventures of Bebop Fuzz',
    'https://www.kickstarter.com/projects/timscott/the-wild-adventures-of-bebop-fuzz',
    '',
    'successful',
    'Film & Video',
    'Animation',
    '7000',
    '7506',
    '80',
    '2/05/2014',
    '30',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1032793816',
    'The new Wozniak record!',
    'https://www.kickstarter.com/projects/wozniak/the-new-wozniak-record',
    'August 2 1985 13:00+00:00',
    'successful',
    'Music',
    'Indie Rock',
    '1000',
    '1117',
    '62',
    '24/01/2014',
    '30',
    'No',
    'Yes',
    '2',
  ],
  [
    '',
    '',
    '1302532413',
    "Newsstream's Smart TV News",
    'https://www.kickstarter.com/projects/1829645447/newsstreams-smart-tv-news',
    'August 2 1985 13:00+03:00',
    'failed',
    'Journalism',
    'Journalism',
    '20000',
    '20',
    '1',
    '20/03/2014',
    '30',
    'No',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '845765224',
    'Duck Farm',
    'https://www.kickstarter.com/projects/duckfarm/duck-farm',
    'August 2 1985 13:00-02:00',
    'failed',
    'Food',
    'Food',
    '15000',
    '',
    '10',
    '15/02/2014',
    '29',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1419687693',
    'PITANGA JUICE: Born in Brazil, Made in Brooklyn',
    'https://www.kickstarter.com/projects/1981220547/pitanga-juice-born-in-brazil-made-in-brooklyn',
    '1985-08-02T04:25:03Z',
    'successful',
    'Food',
    'Food',
    '50000',
    '',
    '102',
    '14/03/2014',
    '30',
    'No',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1315324340',
    'New Studio Album!',
    'https://www.kickstarter.com/projects/621406061/new-studio-album-1',
    'August 2 1985 13:00',
    'successful',
    'Music',
    'Music',
    '15000',
    '15536',
    '204',
    '22/02/2014',
    '24',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1551922993',
    'Around the World from A to Z with Ethnic Dolls (book)',
    'https://www.kickstarter.com/projects/castanedamuseum/around-the-world-from-a-to-z-with-ethnic-dolls-boo',
    'Thu, Aug 2 1985 08:30 PM',
    'failed',
    'Publishing',
    "Children's Book",
    '10000',
    '1000',
    '8',
    '16/04/2014',
    '50',
    'Yes',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1796571432',
    "L'Album UNISEXE et un VIDEO CLIP par LES GUERRES D\\u00e2\\u0080\\u0099L\\u00e2\\u0080\\u0099AMOUR!",
    'https://www.kickstarter.com/projects/1676270272/launching-of-the-album-unisexe-by-les-guerres-dlam',
    'Aug 2 1985',
    'successful',
    'Music',
    'Music',
    '4000',
    '4411',
    '43',
    '13/01/2014',
    '30',
    'Yes',
    'Yes',
    '1',
  ],
  [
    '',
    '',
    '81860571',
    'The Forgetory // Art Criticism // Issues 2, 3, & 4',
    'https://www.kickstarter.com/projects/317789666/the-forgetory-art-criticism-issues-2-3-and-4',
    '2:00pm 2/08/1985',
    'successful',
    'Publishing',
    'Nonfiction',
    '7000',
    '7744',
    '92',
    '2/01/2014',
    '45',
    'No',
    'Yes',
    '0',
  ],
  [
    '',
    '',
    '1213009857',
    'Ben (Working Title)',
    'https://www.kickstarter.com/projects/494058287/ben-working-title',
    'Aug 2 1985 14:00',
    'successful',
    'Film & Video',
    'Film & Video',
    '600',
    '610',
    '38',
    '29/01/2014',
    '20',
    'No',
    'Yes',
    '0',
  ],
];
