import React from 'react';
import styled from 'styled-components';
import { Typography } from '@material-ui/core';
import LoadingScreenCentered from '../../common/loading-screen-centered';
import { useAuthContext } from '../../../firebase/AuthProvider';
import UpdatePasswordSection from './update-password-section';
import ProfileInfoSection from './profile-info-section';

const Wrapper = styled.div`
  margin: 24px auto;
  max-width: 1280px;
  display: flex;
  flex-direction: column;
`;

export const AccountDetailsInnerPage: React.FC = () => {
  return (
    <Wrapper>
      <Typography variant="h4">Account Details </Typography>
      <ProfileInfoSection />
      <UpdatePasswordSection />
    </Wrapper>
  );
};

const AccountDetailsPage: React.FC = () => {
  const { user, isLoadingAuthState } = useAuthContext();

  return (
    <>
      {isLoadingAuthState ? (
        <LoadingScreenCentered messageText="Loading" />
      ) : (
        user && <AccountDetailsInnerPage />
      )}
    </>
  );
};

export default AccountDetailsPage;
