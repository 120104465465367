import React from 'react';
import styled from 'styled-components';
import { useParams } from 'react-router-dom';
import PipelineContent from './pipeline-content';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import ROUTES, { PIPELINEAPIS } from '../../../common/routes';
import useErrorAlert from '../../../utils/useErrorAlert';
import LoadingScreenCentered from '../../common/loading-screen-centered';

const PageWrapper = styled.div`
  padding: 8px;
`;

interface Props {
  shownInformation: string;
}

const PipelineDashboardPage: React.FC<Props> = ({ shownInformation }) => {
  const { pipelineId } = useParams<{ pipelineId: string }>();

  const [data, isLoading, error] = useFetchSimpleService<{
    pipelineId: string;
    pipelineName: string;
  }>(`/${ROUTES.Pipeline}/${pipelineId}${PIPELINEAPIS.Pipeline_Dashboard}`);
  useErrorAlert(error);

  return (
    <>
      {isLoading ? (
        <LoadingScreenCentered messageText="Loading" />
      ) : (
        data && (
          <PageWrapper>
            <PipelineContent
              pipelineName={data.pipelineName}
              shownInformation={shownInformation}
            />
          </PageWrapper>
        )
      )}
    </>
  );
};

export default PipelineDashboardPage;
