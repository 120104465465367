import React, { forwardRef } from 'react';
import styled from 'styled-components';
import CodeEditor from './code-editor';
import ActionButtonBar from './action-button-bar';
import DescriptionEditor from './description-editor';
import { CodeEditorHeaderRefTypes } from '../../../../decision-pages/script-page/types';

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin: 8px;
  padding: 4px;
`;

const CodeEditorHeader = forwardRef<CodeEditorHeaderRefTypes>((props, ref) => {
  const { codeEditorRef, runScriptRef } = (ref as React.MutableRefObject<
    CodeEditorHeaderRefTypes
  >).current;

  return (
    <Wrapper>
      <DescriptionEditor />
      <ActionButtonBar ref={runScriptRef} />
      <CodeEditor ref={codeEditorRef} />
    </Wrapper>
  );
});

export default CodeEditorHeader;
