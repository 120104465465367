import React, { useState } from 'react';
import styled from 'styled-components';
import { Modal, Fade, Paper } from '@material-ui/core';
import NewDatabaseConnectorModal from './new-database-connector-modal';
import DatabaseTableInfoForm from './database-table-info-form';
import { Connector, ConnectorType } from './types';

const ModalContainer = styled(Modal)`
  display: flex;
  justify-content: center;
  align-items: center;
`;

const ModalComponent = styled(Paper)`
  display: flex;
  flex-direction: column;
  width: 500px;
  height: 600px;
  padding: 16px;

  // TODO: Find a better fix that doesn't remove the ability to focus for a11y
  &:focus-visible {
    outline: none;
  }
`;

interface Props {
  isOpen: boolean;
  onClose: () => void;
  databaseConnectors: {
    [connectorId: string]: {
      connectorName: string;
      connectorType: ConnectorType;
    };
  };
  onAddDatabaseConnector: (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType
  ) => void;
  onAddDatabaseTable: (
    dataResourceId: string,
    dataResourceName: string,
    dataResourceType: string,
    isSample?: boolean
  ) => void;
  isOutput?: boolean;
  allowSampleOption?: boolean;
}

const AddDatabaseModal: React.FC<Props> = ({
  isOpen,
  onClose,
  databaseConnectors,
  onAddDatabaseTable,
  onAddDatabaseConnector,
  isOutput,
  allowSampleOption = false,
}) => {
  const [isAddNewConnector, setIsAddNewConnector] = useState<boolean>(false);
  const [selectedConnector, setSelectedConnector] = useState<Connector | null>(
    null
  );

  const handleAddNewConnector = () => {
    setIsAddNewConnector(true);
  };

  const onBack = () => {
    setIsAddNewConnector(false);
  };

  const onNewDatabaseConnectorSuccess = (
    connectorId: string,
    connectorName: string,
    connectorType: ConnectorType
  ) => {
    setSelectedConnector({ connectorId, connectorName, connectorType });
    setIsAddNewConnector(false);
  };

  const onConnectorChange = (connector: Connector | null) => {
    setSelectedConnector(connector);
  };

  return (
    <ModalContainer open={isOpen} onClose={onClose} keepMounted>
      <Fade in={isOpen}>
        <ModalComponent>
          {isAddNewConnector ? (
            <NewDatabaseConnectorModal
              onBack={onBack}
              onClose={onClose}
              onSuccess={onNewDatabaseConnectorSuccess}
              onAddDatabaseConnector={onAddDatabaseConnector}
            />
          ) : (
            <DatabaseTableInfoForm
              onClose={onClose}
              onAddNewConnector={handleAddNewConnector}
              databaseConnectors={databaseConnectors}
              onConnectorChange={onConnectorChange}
              selectedConnector={selectedConnector}
              onAddDatabaseTable={onAddDatabaseTable}
              isOutput={isOutput}
              allowSampleOption={allowSampleOption}
            />
          )}
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default AddDatabaseModal;
