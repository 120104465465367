import { SETTINGSAPI } from '../../../../../common/routes';
import usePostSimpleService from '../../../../../api/usePostSimpleService';

type Request = Record<string, never>;

type Response = {
  accessKeyId: string;
  secretAccessKey: string;
};

const useRefreshAccessKeysService = (): [
  (body: Request) => Promise<Response | void>,
  Error | null,
  () => void
] => {
  return usePostSimpleService<Request, Response>(SETTINGSAPI.Access_Keys);
};

export default useRefreshAccessKeysService;
