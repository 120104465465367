import React from 'react';
import {
  CartesianGrid,
  Label,
  Line,
  LineChart,
  ResponsiveContainer,
  Tooltip,
  XAxis,
  YAxis,
} from 'recharts';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import COLORS from '../../../../../common/colors';

const GraphContainer = styled.div`
  display: flex;
  width: 100%;
  margin-right: 16px;
`;

const ValueContainer = styled.div`
  padding: 8px;
  background-color: white;
  border-radius: 10px;
  border: 1px black solid;
`;

const CustomTooltip = ({ active, payload, unit }: any) => {
  if (active) {
    return (
      <ValueContainer>
        <Typography>Date: {payload[0].payload.time}</Typography>
        <Typography>
          Data Usage: {payload[0].payload.value.toFixed(2)}
          {unit.toUpperCase()}
        </Typography>
      </ValueContainer>
    );
  }

  return null;
};

export interface Props {
  data: { date: string; usage: number }[];
  unit: string;
}

const DataUsageGraph: React.FC<Props> = ({ data, unit }) => {
  return (
    <GraphContainer>
      <ResponsiveContainer width="100%" height="100%">
        <LineChart data={data}>
          <CartesianGrid vertical={false} strokeDasharray="4" />
          <XAxis dataKey="time" height={50}>
            <Label value="Date (UTC)" position="insideBottom" />
          </XAxis>
          <YAxis dataKey="value" type="number">
            <Label
              value={`Data Usage (${unit.toUpperCase()})`}
              position="insideLeft"
              angle={-90}
              style={{ textAnchor: 'middle' }}
            />
          </YAxis>
          <Line
            type="linear"
            dataKey="value"
            dot={{ strokeWidth: 1, fontSize: 'small' }}
            stroke={COLORS.Primary}
            strokeWidth={2}
          />
          <Tooltip
            cursor={{
              opacity: 0.5,
            }}
            content={<CustomTooltip unit={unit} />}
          />
        </LineChart>
      </ResponsiveContainer>
    </GraphContainer>
  );
};

export default DataUsageGraph;
