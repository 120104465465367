import { useEffect } from 'react';
import { toast } from 'react-toastify';
import * as Sentry from '@sentry/react';

export const alertError = (error: Error) => {
  toast.error(error.message);
  Sentry.captureException(error);
};

const useErrorAlert = (error: Error | null) => {
  useEffect(() => {
    if (error) {
      alertError(error);
    }
  }, [error]);
};

export default useErrorAlert;
