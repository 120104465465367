import React, { useEffect, useState } from 'react';
import styled from 'styled-components';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import {
  Button,
  CircularProgress,
  Divider,
  Paper,
  Typography,
} from '@material-ui/core';
import Modal from '@material-ui/core/Modal';
import { ModalStoreContainer, useModalStore } from './modal-store';
import SharedUsersSection from './shared-users-section';
import ShareButton from './share-button';
import NewUsersSection from './new-users-section';
import useFetchSimpleService from '../../../api/useFetchSimpleService';
import { PAGEAPIROUTES } from '../../../common/routes';
import { ShareEndpointResponse } from '../../../api/types/common';

const ModalContainer = styled(Modal)`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const ModalComponent = styled(Paper)`
  width: 800px;
  display: flex;
  flex-direction: column;
  padding: 16px;
`;

const CenteredLoadingWrapper = styled.div`
  width: 100%;
  height: 300px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const HeaderContainer = styled.div`
  display: flex;
  justify-content: space-between;
`;

const CurrentUserAccessTypeWrapper = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-end;
`;

const BodyContainer = styled.div`
  flex: 1;
`;

const FooterContainer = styled.div`
  display: flex;
  justify-content: space-between;
  margin-top: 16px;
`;

export interface InnerProps {
  resourceId: string;
  onSuccess: () => void;
  onClose: () => void;
  isOpen: boolean;
}

export const ShareModalInner: React.FC<InnerProps> = ({
  resourceId,
  onSuccess,
  onClose,
}) => {
  const [{ userAccessType }] = useModalStore();

  return (
    <>
      <HeaderContainer>
        <Typography variant="h4" style={{ fontWeight: 'bold' }} gutterBottom>
          Share
        </Typography>
        <CurrentUserAccessTypeWrapper>
          <Typography variant="caption">Your access:</Typography>
          <Typography style={{ fontWeight: 'bold' }}>
            {userAccessType}
          </Typography>
        </CurrentUserAccessTypeWrapper>
      </HeaderContainer>
      <BodyContainer>
        <SharedUsersSection />
        <Divider />
        <NewUsersSection />
      </BodyContainer>
      <FooterContainer>
        <Button onClick={onClose}>Close</Button>
        <ShareButton onSuccess={onSuccess} resourceId={resourceId} />
      </FooterContainer>
    </>
  );
};

export interface Props {
  isOpen: boolean;
  onClose: () => void;
  resourceId: string;
  onSuccess: () => void;
}

const ShareModal: React.FC<Props> = ({
  resourceId,
  isOpen,
  onClose,
  onSuccess,
}) => {
  const [hasFetched, setHasFetched] = useState<boolean>(false);

  const [data, isLoading] = useFetchSimpleService<ShareEndpointResponse>(
    `${PAGEAPIROUTES.Share}/${resourceId}`,
    isOpen && !hasFetched
  );

  useEffect(() => {
    if (data) {
      setHasFetched(true);
    }
  }, [data]);

  const onUpdateSuccess = () => {
    setHasFetched(false);
    onSuccess();
  };

  return (
    <ModalContainer
      open={isOpen}
      onClose={onClose}
      BackdropComponent={Backdrop}
      BackdropProps={{
        timeout: 500,
      }}
      keepMounted
    >
      <Fade in={isOpen}>
        <ModalComponent elevation={3}>
          {isLoading ? (
            <CenteredLoadingWrapper>
              <CircularProgress />
            </CenteredLoadingWrapper>
          ) : (
            data && (
              <ModalStoreContainer {...data}>
                <ShareModalInner
                  resourceId={resourceId}
                  onSuccess={onUpdateSuccess}
                  onClose={onClose}
                  isOpen={isOpen}
                />
              </ModalStoreContainer>
            )
          )}
        </ModalComponent>
      </Fade>
    </ModalContainer>
  );
};

export default ShareModal;
