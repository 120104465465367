export enum ConnectorType {
  ATHENA = 'athena',
  AWS_RDS_MYSQL = 'aws_rds_mysql',
  S3 = 's3',
  SNOWFLAKE = 'snowflake',
  FIRESTORE = 'firestore',
  GCS = 'gcs',
  SUPABASE_POSTGRESQL = 'supabase_postgresql',
}

export type Connector = {
  connectorId: string;
  connectorName: string;
  connectorType: ConnectorType;
};
