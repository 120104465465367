import React from 'react';
import Select, { SelectChangeEvent } from '@mui/material/Select';
import MenuItem from '@mui/material/MenuItem';
import {
  Checkbox,
  ListItemText,
  OutlinedInput,
  Theme,
  useTheme,
} from '@mui/material';
import { Typography } from '@material-ui/core';
import styled from 'styled-components';
import { useSchemaStore } from '../../../store';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
`;

function getStyles(name: string, personName: string[], theme: Theme) {
  return {
    fontWeight:
      personName.indexOf(name) === -1
        ? theme.typography.fontWeightRegular
        : theme.typography.fontWeightMedium,
  };
}

const DuplicateDrop: React.FC = () => {
  const [
    { fields, duplicateDropFields },
    { setDuplicateDrop },
  ] = useSchemaStore();

  const duplicateDropSet = duplicateDropFields[0] || [];
  const fieldsList = Object.keys(fields);

  const theme = useTheme();
  const handleChange = (event: SelectChangeEvent<typeof duplicateDropSet>) => {
    const {
      target: { value },
    } = event;
    const newDuplicateDropSet =
      typeof value === 'string' ? value.split(',') : value;
    setDuplicateDrop(newDuplicateDropSet);
  };

  return (
    <Wrapper>
      <Typography>
        Drop rows if there are duplicates in the set of fields:
      </Typography>
      <Select
        multiple
        displayEmpty
        value={duplicateDropSet}
        onChange={handleChange}
        input={<OutlinedInput />}
        renderValue={(selected) => {
          if (selected.length === 0) {
            return <em>No fields specified</em>;
          }

          return selected.join(', ');
        }}
        size="small"
        sx={{ width: '320px', ml: '8px', backgroundColor: 'white' }}
      >
        {fieldsList.map((fieldName) => (
          <MenuItem
            key={fieldName}
            value={fieldName}
            style={getStyles(fieldName, duplicateDropSet, theme)}
            sx={{ m: 0, p: 0 }}
          >
            <Checkbox
              checked={duplicateDropSet.indexOf(fieldName) > -1}
              size="small"
            />
            <ListItemText primary={fieldName} sx={{ m: 0, p: 0 }} />
          </MenuItem>
        ))}
      </Select>{' '}
    </Wrapper>
  );
};

export default DuplicateDrop;
