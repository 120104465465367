import {
  createContainer,
  createHook,
  createStore,
  StoreActionApi,
} from 'react-sweet-state';
import { ContainerProps, State, UpdateMappingRowArg } from './types';
import { Field, FieldCollection, MappingRule } from '../types';
import { DataTypes } from '../../../decision-pages/units-page/units-store/types';
import { UnitType } from '../../../decision-pages/units-page/field-column-units/units-row';
import { DateTimeFormats } from '../../../decision-pages/units-page/field-column-units/units-row/date-format-input';

type StoreApi = StoreActionApi<State>;

const initialState: State = {
  fields: {},
  inputColumns: [],
  mappingRules: [],
  isSetOutputSchema: false,
  isAddingAMappingRule: false,
  isEditingAMappingRule: false,
  canAddFields: false,
  combineAdditionalField: null,
  pipelineName: '',
  duplicateDropFields: [[]],
  emailValidationFields: [],
  isEditing: false,
};

// Deep copy
const createUpdatedFieldsCollection = (
  fields: FieldCollection
): FieldCollection => {
  const updatedFieldsCollection: FieldCollection = {};

  Object.entries(fields).forEach(([name, data]) => {
    updatedFieldsCollection[name] = {
      ...data,
    };
    if (data.unitType) {
      updatedFieldsCollection[name].unitType = { ...data.unitType };
    }
  });

  return updatedFieldsCollection;
};

const actions = {
  setInitialData: () => (
    { setState }: StoreApi,
    containerProps: ContainerProps
  ) => {
    const { duplicateDropFields, validateEmails } = containerProps;
    setState({
      ...containerProps,
      mappingRules: [],
      duplicateDropFields: duplicateDropFields?.[0]
        ? duplicateDropFields
        : [[]],
      emailValidationFields: validateEmails,
    });
  },
  setCanAddFields: (canAdd: boolean) => ({ setState }: StoreApi) => {
    setState({ canAddFields: canAdd });
  },
  setCombineAdditionalField: (fieldName: string | null) => ({
    setState,
  }: StoreApi) => {
    setState({ combineAdditionalField: fieldName });
  },
  setIsAddingAMappingRule: (isAdding: boolean) => ({ setState }: StoreApi) => {
    setState({ isAddingAMappingRule: isAdding });
  },
  setIsEditingAMappingRule: (isEditing: boolean) => ({
    setState,
  }: StoreApi) => {
    setState({ isEditingAMappingRule: isEditing });
  },
  addColumn: (name: string) => ({ getState, setState }: StoreApi) => {
    const updatedInputColumns = [...getState().inputColumns];
    updatedInputColumns.push(name);
    setState({ inputColumns: updatedInputColumns });
  },
  addMappingRule: (rule: MappingRule) => ({ getState, setState }: StoreApi) => {
    const updatedRules = [...getState().mappingRules];
    updatedRules.push(rule);
    setState({ mappingRules: updatedRules });
  },
  updateExactSmartMatch: (inputColumnName: string, isExactMatch: boolean) => ({
    getState,
    setState,
  }: StoreApi) => {
    const { mappingRules } = getState();
    const updatedRules = mappingRules.map((currentRule) =>
      currentRule.inputColumnName === inputColumnName
        ? { ...currentRule, exact: isExactMatch }
        : currentRule
    );
    setState({ mappingRules: updatedRules });
  },
  updateMappingRule: ({
    inputColumnName,
    outputField,
    oldInputColumn,
  }: UpdateMappingRowArg) => ({ getState, setState }: StoreApi) => {
    const { mappingRules } = getState();
    const updatedRules = mappingRules.map((currentRule) => {
      if (currentRule.inputColumnName !== oldInputColumn) {
        return currentRule;
      }

      return {
        inputColumnName,
        outputField,
        exact: currentRule.exact,
      };
    });
    setState({ mappingRules: updatedRules });
  },
  removeMappingRule: (inputColumnName: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const { mappingRules } = getState();
    const updatedRules = mappingRules.filter(
      (rule) => rule.inputColumnName !== inputColumnName
    );

    setState({ mappingRules: updatedRules });
  },
  removeAllMappingRules: () => ({ setState }: StoreApi) => {
    setState({ mappingRules: [] });
  },
  addField: (field: Field) => ({ getState, setState }: StoreApi) => {
    const { fields } = getState();
    const { name, ...fieldData } = field;
    if (!fields[name]) {
      setState({
        fields: { ...createUpdatedFieldsCollection(fields), [name]: fieldData },
      });
    }
  },
  updateFieldName: (savedName: string, newName: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const {
      fields,
      mappingRules,
      combineAdditionalField,
      duplicateDropFields,
      emailValidationFields,
    } = getState();

    // Yucky process to maintain the order after editing fieldName, but it works.
    const updatedFields: FieldCollection = {};
    Object.entries(fields).forEach(([name, data]) => {
      if (name === savedName) {
        updatedFields[newName] = { ...data };
        if (data.unitType) {
          updatedFields[newName].unitType = { ...data.unitType };
        }
      } else {
        updatedFields[name] = { ...data };
        if (data.unitType) {
          updatedFields[name].unitType = { ...data.unitType };
        }
      }
    });

    const updatedMappingRules = mappingRules.map((rule) => {
      if (rule.outputField !== savedName) {
        return rule;
      }

      return { ...rule, outputField: newName };
    });

    if (combineAdditionalField && savedName === combineAdditionalField) {
      setState({ combineAdditionalField: newName });
    }

    const index = duplicateDropFields[0].indexOf(savedName);
    if (index > -1) {
      duplicateDropFields[0][index] = newName;
      setState({ duplicateDropFields: [...duplicateDropFields] });
    }
    const indexEmailValidation = emailValidationFields.indexOf(savedName);
    if (indexEmailValidation > -1) {
      emailValidationFields[indexEmailValidation] = newName;
      setState({ emailValidationFields: [...emailValidationFields] });
    }

    setState({ fields: updatedFields, mappingRules: updatedMappingRules });
  },
  updateFieldDataType: (fieldName: string, newDataType: DataTypes) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    updatedFields[fieldName].dataType = newDataType;

    if (newDataType === DataTypes.DATETIME) {
      updatedFields[fieldName].unitType = { unit: null, group: '' };
      updatedFields[fieldName].dateTimeFormat = DateTimeFormats[0].format;
    } else if (newDataType === DataTypes.CATEGORY) {
      if (!updatedFields[fieldName].allowedValues) {
        updatedFields[fieldName].allowedValues = [];
      }
    } else {
      // No units
      delete updatedFields[fieldName].unitType;
      delete updatedFields[fieldName].dateTimeFormat;
    }

    setState({ fields: updatedFields });
  },
  updateFieldUnitType: (fieldName: string, newUnitType: UnitType) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    updatedFields[fieldName].unitType = newUnitType;
    setState({ fields: updatedFields });
  },
  updateFieldDateTimeFormat: (fieldName: string, newFormat: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    updatedFields[fieldName].dateTimeFormat = newFormat;
    setState({ fields: updatedFields });
  },
  updateFieldOptionalFlag: (fieldName: string, optional: boolean) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    updatedFields[fieldName].optional = optional;
    setState({ fields: updatedFields });
  },
  updateFieldDropMissingFlag: (fieldName: string, dropMissing: boolean) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    updatedFields[fieldName].dropMissing = dropMissing;
    setState({ fields: updatedFields });
  },
  removeProvidedFields: (fieldNames: string[]) => ({
    getState,
    setState,
  }: StoreApi) => {
    const {
      mappingRules,
      fields,
      combineAdditionalField,
      duplicateDropFields,
      emailValidationFields,
    } = getState();
    let updatedRules = [...mappingRules];
    const updatedFields = createUpdatedFieldsCollection(fields);
    fieldNames.forEach((fieldName) => {
      delete updatedFields[fieldName];
      updatedRules = updatedRules.filter(
        (rule) => rule.outputField !== fieldName
      );
      if (fieldName === combineAdditionalField) {
        setState({ combineAdditionalField: null });
      }
      const index = duplicateDropFields[0].indexOf(fieldName);
      if (index > -1) {
        duplicateDropFields[0].splice(index, 1);
        setState({ duplicateDropFields: [...duplicateDropFields] });
      }
      const indexEmailValidation = emailValidationFields.indexOf(fieldName);
      if (indexEmailValidation > -1) {
        emailValidationFields.splice(index, 1);
        setState({ emailValidationFields: [...emailValidationFields] });
      }
    });
    setState({
      fields: updatedFields,
      mappingRules: updatedRules,
    });
  },
  addCategoryValue: (fieldName: string, value: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    const updatedCategoryValues = updatedFields[fieldName].allowedValues || []; // Assign empty array if undefined

    // Unique values only
    if (!updatedCategoryValues.includes(value)) {
      updatedCategoryValues.push(value);
    }

    updatedFields[fieldName].allowedValues = updatedCategoryValues;

    setState({ fields: updatedFields });
  },
  removeCategoryValue: (fieldName: string, value: string) => ({
    getState,
    setState,
  }: StoreApi) => {
    const updatedFields = createUpdatedFieldsCollection(getState().fields);
    const updatedCategoryValues = updatedFields[fieldName].allowedValues;
    if (updatedCategoryValues) {
      updatedFields[fieldName].allowedValues = updatedCategoryValues.filter(
        (categoryValue) => categoryValue !== value
      );
    }
    setState({ fields: updatedFields });
  },
  addDuplicateDropSet: (duplicateDropSet: string[]) => ({
    getState,
    setState,
  }: StoreApi) => {
    const { duplicateDropFields } = getState();
    const duplicateDropSetString = duplicateDropSet.sort().join('');
    const alreadyExists = duplicateDropFields.some(
      (set) => set.sort().join('') === duplicateDropSetString
    );
    if (!alreadyExists) {
      setState({
        duplicateDropFields: [...duplicateDropFields, duplicateDropSet],
      });
    }
  },
  removeDuplicateDropSet: (duplicateDropSet: string[]) => ({
    getState,
    setState,
  }: StoreApi) => {
    const { duplicateDropFields } = getState();
    const duplicateDropSetString = duplicateDropSet.sort().join('');
    setState({
      duplicateDropFields: duplicateDropFields.filter(
        (set) => set.sort().join('') !== duplicateDropSetString
      ),
    });
  },
  setDuplicateDrop: (duplicateDropSet: string[]) => ({
    setState,
  }: StoreApi) => {
    setState({ duplicateDropFields: [duplicateDropSet] });
  },
  setEmailValidation: (emailValidationFields: string[]) => ({
    setState,
  }: StoreApi) => {
    setState({ emailValidationFields });
  },
};
const Store = createStore<State, typeof actions>({
  initialState,
  actions,
});

export const useSchemaStore = createHook(Store);
export const SchemaContainer = createContainer(Store, {
  onInit: actions.setInitialData,
});
